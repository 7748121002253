<template>
  <div class="terms-of-use">
    <p class="terms-of-use__rule">regulamin korzystania z serwisu</p>
    <TermsOfUseTitle
      v-for="(title, count) in firstTitles"
      :key="count"
      :page="count + 1"
      :uppercase="false"
    >
      {{ title }}
    </TermsOfUseTitle>
    <p class="spacer"></p>
    <template v-for="(rule, count) in [1, 2, 3, 4, 5, 6, 7, 8]" :key="rule">
      <TermsOfUseTitle :page="count + 1">TITLE</TermsOfUseTitle>
      <TermsOfUseDescription>
        Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
        Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
        Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
        Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
        Lorem ipsum
      </TermsOfUseDescription>
    </template>
  </div>
</template>

<script lang="ts">
import TermsOfUseTitle from '@/components/TermsOfUse/TermsOfUseTitle.vue';
import TermsOfUseDescription from '@/components/TermsOfUse/TermsOfUseDescription.vue';
import { ref } from 'vue';
import BaseSpacer from '@/components/Base/BaseSpacer.vue';
export default {
  name: 'TermsOfUse',
  components: { BaseSpacer, TermsOfUseDescription, TermsOfUseTitle },
  setup() {
    const firstTitles = ref([
      'Postanowienia ogólne',
      'Definicje',
      'Rodzaj i zakres usług elektronicznych',
      'Warunki świadczenia i zawierania umów o świadczenie usług elektronicznych',
      'Warunki rozwiązania umów o świadczenie usług elektronicznych',
      'Tryb postępowania reklamacyjnego',
      'Własność intelektualna',
      'Postanowienia końcowe'
    ]);

    return {
      firstTitles
    };
  }
};
</script>

<style lang="scss" scoped>
.terms-of-use {
  padding: 2rem;

  .spacer {
    margin: 2rem;
  }

  &__rule {
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 2rem;
    font-weight: bold;
  }
}
</style>
