export default {
  appConfig: 'Konfiguracja aplikacji',
  setTenant: 'Wybierz źródło, z którego będzie korzystała aplikacja',
  noPermissions:
    'Aplikacja nie jest skonfigurowana.<br>Skontaktuj się z administratorem systemu <b>wsparcie@pixel.com.pl</b>.',
  incorrectApplicationConfig: 'Błędna konfiguracja aplikacji',
  noTenants: 'Brak tenantów',
  savedTenants: 'Zapisano zmiany w tenantach',
  error: 'Błąd'
};
