import Settings from '@/views/Settings/Settings.vue';

const settingsRoute = [
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  }
];

export default settingsRoute;
