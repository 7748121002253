import jp from 'jsonpath';
import { ok, append, parseDate, dateMinutes } from '@fhir/pixel-commons-js';

export interface FhirWrapper {
  uuid: string;
  ts: number;
  resource: any;
  description: string;
  date: string;
  consentor?: string;
  doctor?: string;
}

export function getDescriptionFromResource(
  resource: object | any
): string | null {
  if (resource != null) {
    const paths = [
      '$.description',
      '$.type.text',
      '$.component[*].code.text',
      '$.code.coding[*].display',
      '$.category[*].coding[*].display',
      '$.type[*].coding[*].display'
    ];

    let ret = null;
    paths.some(s => {
      ret = jp.value(resource, s);
      return ret != null;
    });

    if (ret == null) {
      if (resource.period != null) {
        const ward = jp.value(resource, '$.serviceProvider.display');
        const start = dateMinutes(parseDate(resource.period.start));
        const end = dateMinutes(parseDate(resource.period.end));
        const range = append(start, end, ' - ');
        if (ok(ward)) {
          ret = append(ward, range, ': ');
        } else {
          ret = range;
        }
      }
    }

    if (ret == null) {
      ret = resource.resourceType;
    }

    return ret;
  }
  return null;
}

export function tenant(resource: any) {
  return jp.value(resource || {}, "$.meta.tag[?(@.system=='urn:tenant')].code");
}
export function type(resource: any) {
  return jp.value(resource || {}, '$.resourceType');
}
export function id(resource: any) {
  return jp.value(resource || {}, '$.id');
}
export function url(resource: any) {
  const makeTenant = tenant(resource);
  const makeType = type(resource);
  const makeId = id(resource);

  if (makeTenant && makeType && makeId) {
    return `${makeTenant}/${makeType}/${makeId}`;
  }
  return null;
}
