<template>
  <div
    class="expandable-panel"
    :class="{
      'expandable-panel-bordered': border,
      'expandable-panel-bordered-bottom': borderBottom
    }"
    ref="expandableContainer"
  >
    <div
      :class="[
        'expandable-panel-header-placeholder',
        !backgroundHeader ? 'title-expandable' : 'custom-background'
      ]"
      :style="{
        backgroundColor: backgroundHeader,
        borderRadius: '5px'
      }"
    >
      <button
        :class="[
          'expandable-panel-header',
          floating && show ? 'expandable-panel-header-floating' : '',
          notClicable && expanded ? 'not-clicable' : ''
        ]"
        @click="toggle"
        :style="headerFloatingStyle"
        tabindex="0"
        :aria-label="
          show
            ? `${name} ${$t('base.expandableExpand')} ${$t('base.hideExpand')}`
            : `${name} ${$t('base.showExpand')}`
        "
        :aria-controls="uuid"
        :aria-expanded="show"
        ref="expandableHeader"
      >
        <div
          :class="[
            'expandable-panel-header-content',
            notClicable ? 'bolder' : ''
          ]"
          :style="{
            color: colorHeader || ''
          }"
        >
          <slot name="header-controls" v-bind:expanded="show">
            <span v-if="name" class="header-title">{{ name }}</span>
          </slot>
        </div>
        <span class="to-right-slot">
          <slot name="to-right"></slot>
        </span>

        <div
          v-if="!floating && !notClicable"
          class="state-arrow"
          :class="{ 'state-arrow-active': show }"
          :aria-hidden="true"
        >
          &rsaquo;
        </div>
      </button>
    </div>
    <transition name="height">
      <div :id="uuid" class="expandable-panel-body" v-show="show">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  watch,
  computed,
  onMounted,
  onUnmounted
} from 'vue';
import { generateUuid } from '@/utils/utils';
import { debounce } from 'lodash';

export default defineComponent({
  name: 'ExpandablePanel',
  props: {
    name: {
      type: String,
      required: false
    },
    expanded: {
      type: Boolean,
      required: false,
      default: true
    },
    border: {
      type: Boolean,
      required: false,
      default: false
    },
    borderBottom: {
      type: Boolean,
      required: false,
      default: false
    },
    keepHeaderVisibleWhenScrolling: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Set default color for expandable header
     * required format for props: '#abcabc'
     */
    backgroundHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Set default color for expandable header
     * required format for props: '#abcabc'
     */
    colorHeader: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Check if study is displayed
     * (for example user choose button with 'study displaed')
     */
    studyIsDisplayed: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * @param notClicable - set exapndable panel not clicable,
     * this options will be used with @param exapanded
     */
    notClicable: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  setup(props) {
    const show = ref<boolean>(props.expanded);

    const uuid = computed(() => generateUuid());

    const scroll = (e: Event) => {
      console.log('scroll', e);
    };

    watch(
      () => props.expanded,
      v => {
        show.value = v;
      }
    );

    const floating = ref(false);

    const toggle = (): void => {
      if (props.notClicable && props.expanded) {
        show.value = true;
      } else {
        if (!floating.value) {
          show.value = !show.value;
        }
      }
    };
    const expandableContainer = ref<HTMLElement>();
    const expandableHeader = ref<HTMLElement>();
    const headerWidth = ref<number>(400);

    const headerFloatingStyle = computed(() => {
      return floating.value ? { width: `${headerWidth.value}px` } : {};
    });

    if (props.keepHeaderVisibleWhenScrolling) {
      const scrollListener = debounce((e: Event) => {
        if (show.value) {
          const top = 50;
          const cbb = expandableContainer.value?.getBoundingClientRect();
          const hbb = expandableHeader.value?.getBoundingClientRect();

          if (
            hbb?.height != null &&
            cbb?.top != null &&
            cbb?.bottom != null &&
            cbb?.top + hbb?.height < top &&
            cbb?.bottom - hbb?.height > top
          ) {
            floating.value = true;
            console.log('toggle float', props.name);
            headerWidth.value = hbb?.width;
          } else {
            floating.value = false;
          }
        }
      }, 100);

      onUnmounted(() => {
        window.removeEventListener('scroll', scrollListener);
      });

      onMounted(() => {
        window.addEventListener('scroll', scrollListener);
      });
    }

    return {
      show,
      toggle,
      uuid,
      floating,
      expandableContainer,
      expandableHeader,
      headerFloatingStyle
    };
  }
});
</script>

<style scoped lang="scss">
.expandable-panel {
  border-radius: var(--main-radius);
  padding: var(--app-base-space);
}

.expandable-panel-bordered {
  border: solid 1px var(--dark-gray);
}

.expandable-panel-bordered-bottom {
  border-radius: 0px;
  border-bottom: solid 1px var(--dark-gray);
}

.expandable-panel-header-placeholder {
  min-height: 2rem;
}

.expandable-panel-header {
  min-height: 2rem;
  cursor: pointer;
  display: flex;
  padding: 5px 10px;
  border: 2px solid transparent;
  background: none;
  width: 100%;
  font-weight: bold;
  outline: none;
  border-radius: var(--main-radius);

  &:focus {
    outline: none !important;
  }
  &:focus-visible {
    border-radius: var(--main-radius);
    border: 2px solid var(--accent);
  }

  &.not-clicable {
    cursor: auto;
    background-color: transparent;

    &:focus {
      outline: none !important;
      background-color: transparent;
    }
    &:focus-visible {
      border-radius: var(--main-radius);
      border: 2px solid transparent;
      background-color: transparent;
    }
    &:hover {
      background-color: transparent;
    }
  }
}

.expandable-panel-header-floating {
  //transition: background-color 0.5s ease-out;
  position: fixed;
  border-bottom-right-radius: var(--main-radius);
  border-bottom-left-radius: var(--main-radius);
  background-color: var(--primary);
  color: var(--light);
  z-index: 1;
  top: 50px;
  border-top: solid 1px var(--light);
  cursor: default;
  padding: 8px 5px;
}

.expandable-panel-header-floating:hover,
.expandable-panel-header-floating:focus {
  background-color: var(--primary) !important;
}

.expandable-panel-header-content {
  text-align: left;
  flex-grow: 10;
  color: var(--font-color);
  font-weight: 400;
  &.bolder {
    font-size: 1.225rem;
  }
}

.expandable-panel-header > * {
  //margin-left: 5px;
}

.expandable-panel-header:focus {
  outline: solid 2px var(--accent);
  background-color: var(--light-gray);
}

.expandable-panel-header:hover {
  background-color: var(--light-gray);
  border-radius: var(--main-radius);
}

.expandable-panel-body {
  height: auto;
  position: relative;
  margin-top: 4px;
}
.state-arrow {
  display: inline-block;
  padding-right: 5px;
  transition: all 0.5s ease;
  transform-origin: center center;
  color: var(--font-color);
  margin: auto 0;
}
.state-arrow-active {
  transform: rotate(90deg);
}
.header-title {
  color: var(--font-color);
}
.custom-background {
  border: 1px solid var(--dark-gray);
}
//.title-expandable {
//  font-size: 1.425rem;
//  font-weight: 300;
//}
</style>

<style>
.expandable-panel-header-floating * {
  border-color: var(--light) !important;
}

.to-right-slot {
  margin-right: 10px;
  display: block;
  font-weight: 300;
  white-space: nowrap;
}
</style>
