
import SettingsRefresh from '@/components/Settings/SettingsRefresh.vue';
import Address from '@/components/FhirComponents/Address/Address.vue';
import Patient from '@/components/FhirComponents/Patient/Patient.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TenantsRadio from '@/components/ONLOAD_CONFIGURATION/TenantsRadio.vue';
import useUser from '@/composables/useUser';
import BaseButton from '@/components/Base/BaseButton.vue';
import router from '@/router';

export default defineComponent({
  name: 'Settings',
  components: {
    BaseButton,
    Patient,
    Address,
    SettingsRefresh,
    UserAvatar,
    TenantsRadio
  },
  setup() {
    const i18n = useI18n();
    const params = computed(() => {
      const params = new URLSearchParams();
      params.append('redirect', '/portal-pacjenta/settings');
      params.append('lang', i18n.locale.value);
      params.append('theme', 'patientPortal');
      return params;
    });
    const url = (base: string) => `${base}?${params.value.toString()}`;

    const avatar = computed(() => url('/auth/avatar'));
    const pass = computed(() => url('/auth/registerPatient'));

    onMounted(() => {
      document.body.focus();
    });

    const isAdmin = computed(() => useUser().isAdmin());
    const handleGoToMainPage = () => router.push({ name: 'Patient' });

    return {
      isAdmin,
      avatar,
      pass,
      handleGoToMainPage
    };
  }
});
