import datetime from '../utils/datetime';
import { differenceInYears } from 'date-fns';

export function dateMoment(date) {
  // return moment(date).format('YYYY-MM-DD')
  return datetime.day(date);
}

export function dateTimeMoment(date) {
  /*
   * If date doesnt have hours - return only date, because
   * when we return date with time and time is empty,
   * we will get something like this: 2018-02-03 00:00
   */
  /*
  if (date === moment(date).format('YYYY-MM-DD')) {
    return dateMoment(date)
  } else {
    return moment(date).format('YYYY-MM-DD HH:mm')
  }
   */
  if (date === dateMoment(date)) {
    return dateMoment(date);
  } else {
    return datetime.dateMinutes(date);
  }
}

export function getAge(date) {
  // return moment().diff(date, 'years', false)
  return differenceInYears(new Date(), datetime.parseDate(date));
}

export function timeMoment(date) {
  // return moment(date).format('HH:mm:ss')
  return datetime.datetime(date);
}

export function timeMomentWithoutSec(date) {
  // return moment(date).format('HH:mm')
  return datetime.datehours(date);
}
