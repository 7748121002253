<template>
  <span class="tag">
    <span class="tag-content" :title="title">{{ tag }}</span>
    <span
      v-if="editable"
      class="tag-button"
      :title="$t('tags.remove')"
      @click="$emit('remove')"
    >
      x
    </span>
  </span>
</template>

<script>
import mixins from '../utils/mixins';

export default {
  name: 'Tag',
  mixins: [mixins],
  props: {
    tag: {},
    title: {},
    editable: { default: true, type: Boolean, required: false }
  }
};
</script>

<style scoped></style>
