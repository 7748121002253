import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import { CLEAR_SELECTED_STUDIES, SELECTED_STUDY, State } from './types';

export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  state: {
    selectedStudy: []
  },
  mutations: {
    [SELECTED_STUDY](state: State, id: string) {
      console.log(id);
      if (state.selectedStudy.find(it => it === id)) {
        state.selectedStudy = state.selectedStudy.filter(it => it !== id);
      } else {
        state.selectedStudy.push(id);
      }
    },
    [CLEAR_SELECTED_STUDIES](state: State) {
      state.selectedStudy = [];
    }
  },
  actions: {
    [CLEAR_SELECTED_STUDIES](context) {
      context.commit(CLEAR_SELECTED_STUDIES);
    }
  },
  getters: {
    [SELECTED_STUDY](state: State): Array<string> {
      return state.selectedStudy;
    }
  }
});

export function useStore(): Store<State> {
  return baseUseStore(key);
}
