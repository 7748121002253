<template>
  <div :id="uuid" class="datepicker">
    <div class="date">
      <label :for="`id-textbox-${uuid}`">
        {{ name || $t('patient.filters.date') }}
      </label>
      <span><slot></slot></span>
      <input
        :value="value"
        @input="value = $event.target.value"
        readonly
        type="text"
        placeholder="dd/mm/yyyy"
        :id="`id-textbox-${uuid}`"
        aria-autocomplete="none"
        tabindex="-1"
        class="date-input"
      />
      <button class="icon" :aria-label="$t('patient.filters.chooseDate')">
        <font-awesome-icon icon="calendar" :height="20"></font-awesome-icon>
      </button>
    </div>
    <div
      id="id-datepicker-1"
      class="datepickerDialog"
      role="dialog"
      aria-modal="true"
      aria-labelledby="id-dialog-label"
    >
      <div class="header">
        <button class="prevYear" :aria-label="$t('patient.filters.prevYear')">
          <font-awesome-icon
            icon="angle-double-left"
            :height="16"
          ></font-awesome-icon>
        </button>
        <button class="prevMonth" :aria-label="$t('patient.filters.prevMonth')">
          <font-awesome-icon icon="angle-left" :height="16"></font-awesome-icon>
        </button>
        <h2 id="id-dialog-label" class="monthYear" aria-live="polite">
          Month Year
        </h2>
        <button class="nextMonth" :aria-label="$t('patient.filters.nextMonth')">
          <font-awesome-icon
            icon="angle-right"
            :height="16"
          ></font-awesome-icon>
        </button>
        <button class="nextYear" :aria-label="$t('patient.filters.nextYear')">
          <font-awesome-icon
            icon="angle-double-right"
            :height="16"
          ></font-awesome-icon>
        </button>
      </div>
      <table
        id="myDatepickerGrid"
        class="dates"
        role="grid"
        aria-labelledby="id-dialog-label"
      >
        <thead>
          <tr>
            <th scope="col" :abbr="$t('date.Sunday')">
              {{ $t('date.Su') }}
            </th>
            <th scope="col" :abbr="$t('date.Monday')">
              {{ $t('date.Mo') }}
            </th>
            <th scope="col" :abbr="$t('date.Tuesday')">
              {{ $t('date.Tu') }}
            </th>
            <th scope="col" :abbr="$t('date.Wednesday')">
              {{ $t('date.We') }}
            </th>
            <th scope="col" :abbr="$t('date.Thursday')">
              {{ $t('date.Th') }}
            </th>
            <th scope="col" :abbr="$t('date.Friday')">
              {{ $t('date.Fr') }}
            </th>
            <th scope="col" :abbr="$t('date.Saturday')">
              {{ $t('date.Sa') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                25
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                26
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                27
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                28
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                29
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                30
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                1
              </button>
            </td>
          </tr>
          <tr>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                2
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                3
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                4
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                5
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                6
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                7
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                8
              </button>
            </td>
          </tr>
          <tr>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                9
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                10
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                11
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                12
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                13
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="0">
                14
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                15
              </button>
            </td>
          </tr>
          <tr>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                16
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                17
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                18
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                19
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                20
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                21
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                22
              </button>
            </td>
          </tr>
          <tr>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                23
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                24
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                25
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                26
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                27
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                28
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                29
              </button>
            </td>
          </tr>
          <tr>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                30
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1">
                31
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                1
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                2
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                3
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                4
              </button>
            </td>
            <td class="dateCell">
              <button class="dateButton" tabindex="-1" disabled="">
                5
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="dialogButtonGroup">
        <button class="dialogButton" value="cancel">
          {{ $t('date.Cancel') }}
        </button>
        <button class="dialogButton" value="ok">
          {{ $t('date.OK') }}
        </button>
      </div>
      <div class="message" aria-live="polite">
        Test
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DatePicker from '@/components/Date/datepicker.js';
import { onMounted, ref, defineComponent, watch, computed } from 'vue';
import { formatDate } from '@fhir/pixel-commons-js';
import { generateUuid } from '@/utils/utils';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AccessibleDatePicker',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = ref<string>(
      props.modelValue || formatDate(new Date(), 'dd/MM/yyyy')
    );

    watch(value, v => emit('update:modelValue', v));
    watch(
      () => props.modelValue,
      v => (value.value = v)
    );
    const uuid = computed(() => generateUuid());

    const loadCalendar = (id: string, i18n: (a: string) => string): void => {
      const datePickers = document.querySelectorAll(`#${id}`);

      datePickers.forEach(function(dp) {
        const inputNode = dp.querySelector('input');
        const buttonNode = dp.querySelector('button');
        const dialogNode = dp.querySelector('[role=dialog]');

        const datePicker = new DatePicker(
          inputNode,
          buttonNode,
          dialogNode,
          i18n
        );
        datePicker.init();
      });
    };

    const { t } = useI18n();

    onMounted(() => {
      loadCalendar(uuid.value, t);
    });
    return {
      value,
      uuid
    };
  }
});
</script>

<style scoped>
@import 'datepicker.css';

.date-input {
  display: none;
  visibility: hidden;
}
</style>
