<template>
  <span>
    <!--    <template v-if="!displayImportant">-->
    <!--      {{ getDisplayCoding }}-->
    <!--    </template>-->
    <!--    <template v-else-if="displayImportant">-->
    <!--      {{ getDisplayImportnant }}-->
    <!--    </template>-->
  </span>
</template>

<script>
import jp from 'jsonpath';
import mixins from '../utils/mixins';

export default {
  name: 'DisplayCoding',
  mixins: [mixins],
  emits: ['set-active-header'],
  props: {
    displayImportant: { required: false, type: Boolean, default: false }
  },
  computed: {
    getDisplayCoding() {
      /*
       * At the very beginning find first "display" in object,
       * if we cant find this resource, we must display "code"
       */
      if (jp.value(this.resource, 'resource.code.text')) {
        return jp.value(this.resource, 'resource.code.text');
      } else if (jp.value(this.resource, 'resource.code.coding[*].display')) {
        // code => coding = > display
        const code =
          jp.value(this.resource, 'resource.code.coding[*].code') || '';
        const display =
          jp.value(this.resource, 'resource.code.coding[*].display') || '';

        if (code && display) {
          const codeAndDisplay = `${code} - ${display}`;
          return codeAndDisplay;
        } else {
          return jp.value(this.resource, 'resource.code.coding[*].display');
        }
      } else if (
        jp.value(this.resource, 'resource.component[*].code.coding[*].display')
      ) {
        // component => code => coding = > display
        return jp.value(
          this.resource,
          'resource.component[*].code.coding[*].display'
        );
      } else if (
        jp.value(this.resource, 'resource.category[*].coding[*].display')
      ) {
        // category => coding = > display
        return jp.value(
          this.resource,
          'resource.category[*].coding[*].display'
        );
      } else if (
        jp.value(this.resource, 'resource.category.coding[*].display')
      ) {
        // category => coding = > display
        return jp.value(this.resource, 'resource.category.coding[*].display');
      } else if (
        jp.value(this.resource, 'resource.type[*].coding[*].display')
      ) {
        // type => coding = > display
        return jp.value(this.resource, 'resource.type[*].coding[*].display');
      } else if (jp.value(this.resource, 'resource.code.coding[*].code')) {
        // code => coding = > code
        return jp.value(this.resource, 'resource.code.coding[*].code');
      } else if (
        jp.value(this.resource, 'resource.component[*].code.coding[*].code')
      ) {
        // component => code => coding = > code
        return jp.value(
          this.resource,
          'resource.component[*].code.coding[*].code'
        );
      } else if (
        jp.value(this.resource, 'resource.category[*].coding[*].code')
      ) {
        // category => coding = > code
        return jp.value(this.resource, 'resource.category[*].coding[*].code');
      } else if (jp.value(this.resource, 'resource.category.coding[*].code')) {
        // category => coding = > code
        return jp.value(this.resource, 'resource.category.coding[*].code');
      } else if (jp.value(this.resource, 'resource.type[*].coding[*].code')) {
        // type => coding = > code
        return jp.value(this.resource, 'resource.type[*].coding[*].code');
      } else {
        return '';
      }
    },
    getDisplayImportnant() {
      if (jp.value(this.resource, 'resource.code.text')) {
        return jp.value(this.resource, 'resource.code.text');
      } else if (jp.value(this.resource, 'resource.code.coding[*].display')) {
        return jp.value(this.resource, 'resource.code.coding[*].display');
      } else if (
        jp.value(this.resource, 'resource.component[*].code.coding[*].display')
      ) {
        // component => code => coding = > display
        return jp.value(
          this.resource,
          'resource.component[*].code.coding[*].display'
        );
      } else if (
        jp.value(this.resource, 'resource.category[*].coding[*].display')
      ) {
        // category => coding = > display
        return jp.value(
          this.resource,
          'resource.category[*].coding[*].display'
        );
      } else if (
        jp.value(this.resource, 'resource.category.coding[*].display')
      ) {
        // category => coding = > display
        return jp.value(this.resource, 'resource.category.coding[*].display');
      } else if (
        jp.value(this.resource, 'resource.type[*].coding[*].display')
      ) {
        // type => coding = > display
        return jp.value(this.resource, 'resource.type[*].coding[*].display');
      } else if (jp.value(this.resource, 'resource.code.coding[*].code')) {
        // code => coding = > code
        return jp.value(this.resource, 'resource.code.coding[*].code');
      } else if (
        jp.value(this.resource, 'resource.component[*].code.coding[*].code')
      ) {
        // component => code => coding = > code
        return jp.value(
          this.resource,
          'resource.component[*].code.coding[*].code'
        );
      } else if (
        jp.value(this.resource, 'resource.category[*].coding[*].code')
      ) {
        // category => coding = > code
        return jp.value(this.resource, 'resource.category[*].coding[*].code');
      } else if (jp.value(this.resource, 'resource.category.coding[*].code')) {
        // category => coding = > code
        return jp.value(this.resource, 'resource.category.coding[*].code');
      } else if (jp.value(this.resource, 'resource.type[*].coding[*].code')) {
        // type => coding = > code
        return jp.value(this.resource, 'resource.type[*].coding[*].code');
      } else if (jp.value(this.resource, 'resource.description')) {
        return jp.value(this.resource, 'resource.description');
      } else if (jp.value(this.resource, 'resource.resourceType')) {
        const resourceType = jp.value(this.resource, 'resource.resourceType');
        return this.$t('resources.' + resourceType);
      } else if (jp.value(this.resource, 'resource.id')) {
        return jp.value(this.resource, 'resource.id');
      } else {
        return '';
      }
    }
  },
  mounted() {
    this.$emit(
      'set-active-header',
      this.displayImportant ? this.getDisplayImportnant : this.getDisplayCoding
    );
  }
};
</script>

<style scoped></style>
