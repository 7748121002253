<template>
  <div class="search-patient">
    <input
      type="text"
      class="search-patient__input"
      v-model="nameSearchValue"
      :placeholder="$t('admin.filters.searchByName')"
      @input="validatePatient('name')"
      @keypress.enter="addNameFilter"
      :disabled="isFiltering"
    />
    <button
      class="search-patient__button"
      @click="addNameFilter"
      :disabled="isFiltering"
    >
      {{ $t('admin.filters.add') }}
    </button>
  </div>
  <div class="search-patient">
    <input
      type="text"
      class="search-patient__input"
      v-model="peselSearchValue"
      :disabled="isFiltering"
      :placeholder="$t('admin.filters.searchByPesel')"
      @input="validatePatient('pesel')"
      @keypress.enter="addPeselFilter"
    />
    <button
      class="search-patient__button"
      @click="addPeselFilter"
      :disabled="isFiltering"
    >
      {{ $t('admin.filters.add') }}
    </button>
  </div>

  <!-- input do szukania po dacie urodzenia -->

  <!-- <div class="search-patient">
    <input
      type="date"
      class="search-patient__input"
      v-model="birthDateSearchValue"
      :placeholder="$t('admin.filters.searchByBirthDate')"
      @keypress.enter="addBirthDateFilter"
      :disabled="isFiltering"
    />
    <button class="search-patient__button" @click="addBirthDateFilter" :disabled="isFiltering">
      {{ $t('admin.filters.add') }}
    </button>
  </div> -->
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useStore as useSearchStore } from '@/store/search';
import {
  PATIENTS_NAMES,
  PATIENTS_BIRTH_DATES,
  PATIENTS_PESELS,
  FILTERING
} from '@/store/search/types';
import {
  allowLettersOnly,
  allowDigitsOnly,
  titleCaseSentence
} from '@/utils/utils';

export default defineComponent({
  name: 'PatientSearch',
  setup() {
    const searchStore = useSearchStore();

    const isFiltering = computed(() => searchStore.getters[FILTERING]);

    const peselSearchValue = ref<string>('');
    const nameSearchValue = ref<string>('');
    const birthDateSearchValue = ref<string>('');

    const addNameFilter = () => {
      const name = titleCaseSentence(nameSearchValue.value);
      searchStore.commit(PATIENTS_NAMES, name);
      nameSearchValue.value = '';
    };

    const addPeselFilter = () => {
      searchStore.commit(PATIENTS_PESELS, peselSearchValue.value);
      peselSearchValue.value = '';
    };

    const addBirthDateFilter = () => {
      searchStore.commit(PATIENTS_BIRTH_DATES, birthDateSearchValue.value);
      birthDateSearchValue.value = '';
    };

    const validatePatient = (inputName: string) => {
      if (inputName === 'name') {
        nameSearchValue.value = allowLettersOnly(nameSearchValue.value);
      } else if (inputName === 'pesel') {
        peselSearchValue.value.length > 11
          ? (peselSearchValue.value = peselSearchValue.value.slice(0, -1))
          : (peselSearchValue.value = allowDigitsOnly(peselSearchValue.value));
      }
    };

    return {
      validatePatient,
      peselSearchValue,
      birthDateSearchValue,
      addPeselFilter,
      addBirthDateFilter,
      addNameFilter,
      isFiltering,
      nameSearchValue
    };
  }
});
</script>
<style scoped lang="scss">
.search-patient {
  border: 1px solid var(--dark-gray);
  display: flex;
  margin-top: 5px;
  &__input,
  &__button {
    padding: 0.5rem;
    border: 1px solid transparent;
  }
  &__input {
    width: 100%;
    background-color: var(--light);
  }
  &__warning {
    display: block;
    font-size: 0.875rem;
    margin-top: 5px;
    text-align: center;
    color: var(--danger);
  }
  &__button {
    border-left: 1px solid var(--dark-gray);
    background-color: var(--light-gray);
    cursor: pointer;
  }
}
</style>
