
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  PropType,
  ref,
  watch
} from 'vue';
import RadioGroup from './Radio/RadioGroup.vue';
import RadioButton from './Radio/RadioButton.vue';
import {
  day,
  formatDate,
  parseDate,
  ts
} from '@fhir/pixel-commons-js/src/index';
import { addDays } from 'date-fns';
import { useStore as useSearchStore } from '@/store/search';
import { DATE_FILTER, DateFilter } from '@/store/search/types';
import i18n from '@/lang';
import AccessibleDatePicker from '@/components/Date/AccessibleDatePicker.vue';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import useUser from '@/composables/useUser';

export default defineComponent({
  name: 'DateFilters',
  components: { AccessibleDatePicker, RadioButton, RadioGroup },
  props: {
    modelValue: {
      type: Object as PropType<DateFilter>,
      required: false,
      default: () => ({})
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const admin = computed(() => useUser().isAdmin());

    const date = ref<number>(
      props.modelValue.type != null
        ? props.modelValue.type
        : admin.value
        ? 0
        : 0
    );

    const dateFrom = ref<string>(
      formatDate(addDays(new Date(), -3), 'dd/MM/yyyy')
    );
    const dateTo = ref<string>(formatDate(new Date(), 'dd/MM/yyyy'));
    const dateItems = computed(() => {
      return [
        {
          id: 0,
          label: 'patient.filters.fromTheNewest'
        },
        {
          id: 1,
          label: 'patient.filters.dateToday'
        },
        {
          id: 2,
          label: 'patient.filters.dateLast5'
        },
        {
          id: 3,
          label: 'patient.filters.dateLast30'
        },
        {
          id: 4,
          label: 'patient.filters.dateCustom'
        }
      ];
    });

    const finalDateFrom = computed(() =>
      day(parseDate(dateFrom.value, 'dd/MM/yyyy'))
    );
    const finalDateTo = computed(() =>
      day(parseDate(dateTo.value, 'dd/MM/yyyy'))
    );

    const processDate = (): void => {
      const dateFilter: DateFilter = {
        type: date.value
      };
      switch (date.value) {
        case 0:
          break;
        case 1:
          dateFilter.date = day(new Date());
          break;
        case 2:
          dateFilter.dateFrom = day(addDays(new Date(), -5));
          dateFilter.dateTo = day(new Date());
          break;
        case 3:
          dateFilter.dateFrom = day(addDays(new Date(), -30));
          dateFilter.dateTo = day(new Date());
          break;
        case 4:
          dateFilter.dateFrom = finalDateFrom.value;
          dateFilter.dateTo = finalDateTo.value;
          break;
      }
      emit('update:modelValue', dateFilter);
    };

    watch(
      () => props.modelValue,
      v => {
        if (v.type != null) {
          if (v.type === 4 && v.dateFrom != null && v.dateTo != null) {
            dateFrom.value = v.dateFrom;
            dateTo.value = v.dateTo;
          }
          date.value = v.type;
          processDate();
        }
      }
    );

    const checkRange = (dateFrom: string, dateTo: string): boolean => {
      const df = ts(parseDate(dateFrom, 'dd/MM/yyyy'));
      const dt = ts(parseDate(dateTo, 'dd/MM/yyyy'));
      return df <= dt;
    };

    let skipWatch = false;

    const notificationDateFormat = (date: string): string => {
      const parsed = parseDate(date, 'dd/MM/yyyy');
      return formatDate(parsed, 'dd/MM/yyyy');
    };

    watch(date, v => {
      if (skipWatch) {
        skipWatch = false;
        return;
      }
      processDate();
    });
    watch(dateFrom, (val, old) => {
      if (skipWatch) {
        skipWatch = false;
        return;
      }
      if (checkRange(val, dateTo.value)) {
        processDate();
      } else {
        const from = notificationDateFormat(val);
        const to = notificationDateFormat(dateTo.value);
        useNotification().addNotification(
          NotificationType.WARNING,
          '',
          i18n.global.t('date.wrongRange', { from, to })
        );

        nextTick(() => {
          skipWatch = true;
          dateFrom.value = old;
        });
      }
    });
    watch(dateTo, (val, old) => {
      if (skipWatch) {
        skipWatch = false;
        return;
      }
      if (checkRange(dateFrom.value, val)) {
        processDate();
      } else {
        const to = notificationDateFormat(val);
        const from = notificationDateFormat(dateFrom.value);
        useNotification().addNotification(
          NotificationType.WARNING,
          '',
          i18n.global.t('date.wrongRange', { from, to })
        );

        nextTick(() => {
          skipWatch = true;
          dateTo.value = old;
        });
      }
    });

    const searchStore = useSearchStore();

    const dateFilter = computed<DateFilter>(
      () => searchStore.getters[DATE_FILTER]
    );
    watch(dateFilter, v => {
      if (v.dateTo == null && v.dateFrom == null && v.date == null) {
        //skipWatch = true;
        date.value = 0;
      }
    });

    onMounted(() =>
      nextTick(() => {
        console.log('mount filters');
        processDate();
      })
    );

    return {
      date,
      dateItems,
      dateFrom,
      dateTo,
      finalDateFrom,
      finalDateTo
    };
  }
});
