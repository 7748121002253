import { messages } from '@/components/FhirComponents/utils/i18n';

export interface MessageI {
  id: string;
  msg: string;
  date: Date;
  read: boolean;
  source: any;
}

export interface CommunicatorI {
  sse: EventSource | undefined | null;
  messages: Array<MessageI>;
  addMessage: (msg: MessageI) => void;
}

export class Communicator implements CommunicatorI {
  sse = null;
  messages: Array<MessageI> = [];

  addMessage(msg: MessageI) {
    this.messages.push(msg);
  }
}

export const NOTIFICATION_MESSAGE_READ_ENDPOINT = 'notification/read';
export const NOTIFICATION_ALL_READ_ENDPOINT = 'notification/readAll';
export const NOTIFICATION_ADD_MESSAGE_ENDPOINT = 'notification/add';
export const NOTIFICATION_SSE_ENDPOINT = `${process.env.VUE_APP_NOTIFICATOR_URL}/notification/sse/list`;

export const NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE =
  'DOCUMENT_SHARED';

export interface NotificationRequestI {
  actionCode: string;
  receivers: Array<string>;
  notificationParams: any;
}

export class NotificationRequest implements NotificationRequestI {
  actionCode = '';
  receivers: Array<string> = [];
  notificationParams = {};

  constructor(actionCode: string, receivers: string[], notificationParams: {}) {
    this.actionCode = actionCode;
    this.receivers = receivers;
    this.notificationParams = notificationParams;
  }
}

export class Message implements MessageI {
  id = '';
  msg = '';
  date = new Date();
  read = false;
  source = {};

  constructor(id: string, msg: string, date: Date, read: boolean, source: any) {
    this.id = id;
    this.msg = msg;
    this.date = date;
    this.read = read;
    this.source = source;
  }
}
