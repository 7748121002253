export default {
  taskList: 'List zadań',
  openTaskList: 'Otwórz listę zadań',
  openNotifications: 'Otwórz powiadomienia',
  notifications: 'Powiadomienia',
  settings: 'Ustawienia',
  openSettings: 'Otwórz ustawienia',
  moreOptions: 'Więcej opcji',
  openMoreOptions: 'Otwórz więcej opcji',
  filters: 'Filtry',
  fullTextSearch: {
    search: 'Wyszukaj...',
    nameAndSurname: 'Imię i Nazwisko',
    identifier: 'Pesel',
    patients: 'Pacjenci',
    researchName: 'Nazwa badania',
    research: 'Badania',
    date: 'Data'
  }
};
