import { inject, InjectionKey, provide, App } from 'vue';
import useUser from '@/composables/useUser';

interface DynarolesPlugin {
  store: null;
  init: () => void;
  check: (action: string) => boolean;
}

interface Dynaroles {
  check: (action: string, groups: Array<string>) => boolean;
}

declare global {
  interface Window {
    DynaRoles?: Dynaroles;
    modalities?: any;
    patientPortalSharedUrl?: string;
  }
}

export const dynarolesPlugin: DynarolesPlugin = {
  store: null,
  init() {
    if (this.store == null) {
      //this.store = useUserStore();
    }
  },
  check(action) {
    // setTimeout(() => {
    //   console.log('check', action);
    // }, 3000);

    try {
      if (process.env.VUE_APP_DEBUG === 'true') {
        //return true;
      }
      if (window.DynaRoles === undefined) {
        console.log('WINDOWS');
        return false;
      } else {
        const ret = window?.DynaRoles?.check(action, useUser().getUserGroups());
        // console.log(
        //   'check dynaRoles',
        //   action,
        //   ret,
        //   this.store?.getters[USER_GROUPS]
        // );

        return ret;
      }
    } catch (e) {
      console.error('Błąd sparwdzania dyna-roles', action, e);
    }

    return false;
  }
};

/**
 * Plugin do użycia w metodzie use - ustawia w globalnych zmiennych dynaroles - dostępna w template'ach
 */
export default {
  install: (app: App, options: object) => {
    app.config.globalProperties.dynaroles = dynarolesPlugin.check;
  }
};

/**
 * Mechanizm provide / inject - aby móc uzywać dynarolesów w kodzie komponentów
 */
const dynarolesKey: InjectionKey<DynarolesPlugin> = Symbol();

export function provideDynaroles(): void {
  dynarolesPlugin.init();
  provide(dynarolesKey, dynarolesPlugin);
}

export function useDynaroles(): DynarolesPlugin | undefined {
  const dynaroles = inject(dynarolesKey);
  if (!dynaroles) {
    // throw error, no store provided
  }
  return dynaroles;
}
