import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { InjectionKey } from 'vue';

export const key: InjectionKey<Store<any>> = Symbol();

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});

export function useStore() {
  return baseUseStore(key);
}
