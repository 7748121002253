<template>
  <div v-if="show" class="tags">
    <Confirm ref="confirm" v-model="confirm" :lang="lang" />
    <span class="tag-add-button" @click="initTags">&#128472;</span>
    <b>
      {{ $t('tags.tags')
      }}{{ (tags || []).length > 0 ? ` (${tags.length})` : '' }}:
    </b>
    <Tag
      v-for="(t, i) in tags"
      :key="`tag-${i}-${t.code}`"
      :lang="lang"
      :editable="canEdit"
      :tag="t.display"
      :title="`${t.display} (${t.system}})`"
      @remove="deleteTag(i, t.code, t.system, t.display)"
    />
    <AddTag v-if="canEdit" :key="uid" :lang="lang" @add="add" />
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import jp from 'jsonpath';
import Tag from '../Tags/Tag';
import AddTag from '../Tags/AddTag';
import axios from 'axios';
import Confirm from '../layouts/Confirm';
import { availableTagSystems, defaultTagSystem } from './tagsUtils';

export default {
  name: 'Tags',
  components: { Confirm, AddTag, Tag },
  mixins: [mixins],
  props: {
    editable: { default: true, type: Boolean, required: false }
  },
  data() {
    return {
      tags: [],
      uid: Math.random(),
      loading: false,
      confirm: true
    };
  },
  computed: {
    canEdit() {
      return (
        this.editable && this.resource != null && this.access_token != null
      );
    },
    show() {
      if (this.canEdit) {
        return true;
      } else {
        return this.tags.length > 0;
      }
    },
    tenant() {
      return jp.value(
        this.resource || {},
        "$.resource.meta.tag[?(@.system=='urn:tenant')].code"
      );
    },
    type() {
      return jp.value(this.resource || {}, '$.resource.resourceType');
    },
    id() {
      return jp.value(this.resource || {}, '$.resource.id');
    },
    url() {
      if (this.tenant && this.type && this.id) {
        return `${process.env.VUE_APP_API_URL}${this.tenant}/${this.type}/${this.id}`;
      }
      return null;
    }
  },
  watch: {
    url(v) {
      if (v != null) {
        this.initTags();
      }
    }
  },
  mounted() {
    // this.initTags()
  },
  methods: {
    async initTags() {
      try {
        if (this.url == null) {
          return;
        }
        this.loading = true;

        const config = {
          headers: { Authorization: `Bearer ${this.access_token}` },
          contentType: 'application-json'
        };

        this.tags = [];
        const { data } = await axios.get(`${this.url}/$meta`, config);
        if (data != null && data.tag != null) {
          this.tags = data.tag
            .filter(f => availableTagSystems.find(s => s === f.system) != null)
            .map(this.mapTag);
        }
      } catch (e) {
        console.error('Błąd pobierania tagów', e);
      } finally {
        this.loading = false;
      }
    },
    async deleteTag(i, tag, system, display) {
      try {
        await this.$refs.confirm.confirm(
          this.$t('tags.caution'),
          this.$t('tags.removeConfirm', { tag: display })
        );

        try {
          this.loading = true;

          const config = {
            headers: { Authorization: `Bearer ${this.access_token}` },
            contentType: 'application-json'
          };

          const tags = {
            tag: [{ system: system, code: tag }]
          };

          await axios.post(`${this.url}/$meta-delete`, tags, config);
          this.tags.splice(i, 1);
        } catch (e) {
          console.error('Błąd usuwania tagu', e);
        } finally {
          this.loading = false;
        }
      } catch (e) {
        console.error(e);
      }
    },
    mapTag(tag) {
      const ret = { ...tag };
      ret.display = tag.code.replaceAll('$', ': ');
      return ret;
    },
    async add(tag) {
      if (!this.tags.find(f => f.code === tag)) {
        try {
          this.loading = true;

          const config = {
            headers: { Authorization: `Bearer ${this.access_token}` },
            contentType: 'application-json'
          };

          const tag2add = { system: defaultTagSystem, code: tag };
          const tags = {
            tag: [tag2add]
          };

          await axios.post(`${this.url}/$meta-add`, tags, config);
          this.tags.push(this.mapTag(tag2add));
          this.uid = Math.random();
        } catch (e) {
          console.error('Błąd dodawania tagu', e);
        } finally {
          this.loading = false;
        }
      }
    }
  }
};
</script>

<style scoped></style>
