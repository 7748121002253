export default {
  sharedAmountInfo: 'Number of people who were shared with the study',
  linkGeneratedInfo: 'A link to the study has been generated',
  favouriteStudyInfo: 'The study has been marked as a favourite',
  filters: {
    clearFilters: 'Clear Filters',
    takeFilters: 'Take Filters',
    placeholderSearchFilters:
      'Search for an examination / patient / doctor ...',
    tags: 'Tags',
    searchByTag: 'Search by tag',
    criterion: 'Criterion',
    sex: 'Sex',
    searchForPatient: 'Search for patient',
    searchByName: 'Enter name and/or last name',
    searchByBirthDate: 'Enter birth date',
    searchByPesel: 'Enter pesel',
    text: 'Text search',
    patients: 'Patients',
    pesel: 'Pesel',
    birthDates: 'Birth dates',
    favouriteResearch: 'Favourite search',
    showFavourites: 'Show favourites',
    enterNewFilter: 'Enter new filter',
    saveFilters: 'Save filters',
    add: 'Add',
    any: 'Any',
    patientValidation:
      'Patient is already searched or number of characters is too short',
    tagValidation: 'Tag already used or is too short',
    woman: 'Woman',
    man: 'Man',
    clickToRemove: 'Click to remove tag',
    clickToAdd: 'Click to add tag',
    newTag: 'Add tag'
  },
  results: {
    asFavourite: 'Resource marked as favourite',
    notAsFavourite: 'Resource unmarked as favourite',
    errorFavourite: 'Error setting favourite'
  }
};
