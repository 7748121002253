<template>
  <div class="filters_tags">
    <ul class="filters_tags__list">
      <li
        class="filters_tags__list__item"
        v-for="(tag, count) in tags"
        :key="count"
      >
        <span>{{ tag }}</span>
        <button
          :title="$t('admin.filters.clickToRemove')"
          @click="deleteOneTags(count)"
        >
          <font-awesome-icon
            class="delete-icon"
            icon="times"
            width="14"
            height="14"
          />
        </button>
      </li>
    </ul>

    <div class="filters_tags__maker">
      <div class="filters_tags__maker__text">
        {{ $t('admin.filters.newTag') }}
      </div>
      <input
        class="text-input"
        v-model="inputValue"
        id="filter-tags-input"
        type="text"
        @keyup="addTagsByEnter"
      />
      <button
        class="filters_tags__maker__add"
        @click="addTags"
        :title="$t('admin.filters.clickToAdd')"
      >
        <font-awesome-icon icon="plus" width="10" heigth="10" />
      </button>
    </div>

    <span class="filters_tags__warning" v-show="warning">
      {{ warning }}
    </span>
  </div>
</template>

<script lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'FiltersTag',
  props: {
    tagsList: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  emits: ['add-tag', 'delete-tag'],
  setup(
    props: any,
    context: { emit: (arg0: string, arg1: string | number) => void }
  ) {
    const { t } = useI18n();
    const inputValue = ref<string>('');
    const tags = computed(() => props.tagsList);
    const warning = ref<string | null>(null);

    const checkIfElementExist = computed(() => {
      return (
        tags.value.find((x: string) => {
          return x === inputValue.value;
        }) != null
      );
    });

    const canAddTag = computed(
      () => inputValue.value.length > 3 && !checkIfElementExist.value
    );

    const addTags = () => {
      if (canAddTag.value) {
        context.emit('add-tag', inputValue.value);
        inputValue.value = '';
      } else if (!canAddTag.value && !warning.value) {
        warning.value = t('admin.filters.tagValidation');
        setTimeout(() => (warning.value = ''), 3500);
      }
    };

    const deleteOneTags = (ind: number): void => {
      context.emit('delete-tag', ind);

      if (tags.value.length === 0) {
        document.getElementById('filter-tags-input')?.focus();
      }
    };

    const addTagsByEnter = (e?: KeyboardEvent): void => {
      if (e?.key === 'Enter') {
        addTags();
      }
    };

    return {
      inputValue,
      tags,
      addTags,
      addTagsByEnter,
      deleteOneTags,
      warning,
      canAddTag
    };
  }
};
</script>

<style scoped lang="scss">
.filters_tags {
  min-height: 50px;

  &__warning {
    display: block;
    font-size: 0.875rem;
    margin-top: 5px;
    text-align: center;
    color: var(--danger);
  }

  &__list {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    li {
      list-style: none;
      margin: 0.25rem;
      background-color: var(--light-gray);
      border: 1px solid var(--primary);
      border-radius: 6px;
      padding: 0.5rem 2.5rem 0.5rem 1.5rem;
      position: relative;

      span {
        width: 100%;
        color: var(--dark-main-color);
      }

      button {
        right: 4px;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        cursor: pointer;

        .delete-icon {
          position: absolute;
          right: 4px;
          top: 50%;
          transform: translateY(-50%);
          color: var(--font-color);
        }
      }
    }
  }

  &__maker {
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin-top: 1rem;

    &__text {
      border-top: 1px solid var(--dark-gray);
      border-bottom: 1px solid var(--dark-gray);
      border-left: 1px solid var(--dark-gray);
      padding: 0.5rem;
      background-color: var(--light-gray);
      color: var(--font-color);
    }

    input {
      padding-left: 0.5rem;
      width: 180px;
      border: 1px solid var(--dark-gray);
    }

    &__add {
      padding: 0.35rem 0.4rem 0.35rem 0.45rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--dark-gray);
      border-left: 0;
      color: var(--primary);
      cursor: pointer;
    }
  }
}
.text-input {
  background-color: var(--light);
  color: var(--font-color);
}
</style>
