import RepoConnector from '@/apiv2/RepoConnector';
import {
  fhirService,
  jsonStorageService,
  localService,
  notificatorService
} from '@/api/AxiosInstanceBuilder';

const Repo = {
  fhir: new RepoConnector(fhirService, ''),
  jsonStorageService: new RepoConnector(
    jsonStorageService,
    'jsonStorageService'
  ),
  notificator: new RepoConnector(notificatorService, ''),
  local: new RepoConnector(localService, '')
};

export default Repo;
