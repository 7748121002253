import TermsOfUse from '@/views/TermsOfUse/TermsOfUse.vue';

const termsOfUseRoute = [
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse
  }
];

export default termsOfUseRoute;
