
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import { PropType } from 'vue';
import { ResearchesType } from '@/components/FullTextSearch/FullTextSearchType';

export default {
  name: 'ResearchSearch',
  components: { ExpandablePanel },
  props: {
    researches: {
      type: Array as PropType<ResearchesType[]>,
      required: true,
      default: () => []
    }
  }
};
