
import { computed, onMounted, ref } from 'vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';

export default {
  name: 'SettingsRefresh',
  components: { RadioGroup, RadioButton },
  setup() {
    const refresh = ref<number>(1);

    onMounted(() => {
      refresh.value = Number(localStorage.getItem('refreshTime')) || 0;
    });

    const updateRefreshTime = (value: number) => {
      localStorage.setItem('refreshTime', value.toString());
    };

    const dateItems = computed(() => {
      return [
        {
          id: 0,
          label: 'settings.refresh.dontRefresh'
        },
        {
          id: 30,
          label: 'settings.refresh.refreshEvery30'
        },
        {
          id: 45,
          label: 'settings.refresh.refreshEvery45'
        },
        {
          id: 60,
          label: 'settings.refresh.refreshEvery60'
        },
        {
          id: 90,
          label: 'settings.refresh.refreshEvery90'
        },
        {
          id: 120,
          label: 'settings.refresh.refreshEvery120'
        }
      ];
    });

    return {
      dateItems,
      updateRefreshTime,
      refresh
    };
  }
};
