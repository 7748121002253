export default {
  resources: {
    AllergyIntolerance: 'Allergy intolerance',
    Condition: 'Condition',
    DiagnosticReport: 'Diagnostic report',
    DocumentReference: 'Document reference',
    Encounter: 'Encounter',
    Endpoint: 'Endpoint',
    EpisodeOfCare: 'Episode of care',
    HealthcareService: 'Healthcare service',
    ImagingStudy: 'Imaging study',
    List: 'List',
    MedicationAdministration: 'Medication administration',
    MedicationRequest: 'Medication request',
    Observation: 'Observation',
    OperationDefinition: 'Operation definition',
    Organization: 'Organization',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    ProcedureRequest: 'Procedure request',
    Questionnaire: 'Questionnaire',
    StructureDefinition: 'Structure definition',
    Medication: 'Medication',
    Procedure: 'Procedure',
    Consent: 'Consent',
    Specimen: 'Specimen',
    Location: 'Location',
    VitalSigns: 'Vital Signs',
    Imaging: 'Imaging',
    ServiceRequest: 'Request',
    Immunization: 'Immunization',
    Device: 'Device',
    QuestionnaireResponse: 'Questionnaire response'
  },
  wcagText: {
    addressTitle: 'The address is shown below',
    allergyIntoleranceTitle: 'The allergy intolerance is shown below'
  },
  labelsRes: {
    conclusion: 'Conclusion: ',
    patient: 'Patient',
    clinicalStatus: 'Clinical status',
    verificationStatus: 'Verification status',
    type: 'Type',
    category: 'Category',
    criticality: 'Criticality',
    code: 'Code',
    onsetDateTime: 'Onset date time',
    assertedDate: 'Asserted date',
    recorder: 'Recorder',
    asserter: 'Asserter',
    note: 'Note',
    reaction: 'Reaction',
    substance: 'Substance',
    manifestation: 'Manifestation',
    description: 'Description',
    onset: 'Onset',
    severity: 'Severity',
    exposureRoute: 'Exposure route',
    authoredOn: 'Written on this date',
    intent: 'Intent',
    priority: 'Priority',
    context: 'Context',
    name: 'Name',
    telecom: 'Telecom',
    line: 'Street',
    city: 'City',
    state: 'State',
    postalCode: 'Postal code',
    country: 'Country',
    _id: 'The ID of the resource',
    'clinical-status': 'The clinical status of the condition',
    date: 'The questionnaire publication date',
    identifier: 'External identifier for the questionnaire',
    'last-date': 'Date(/time) of last known occurrence of a reaction',
    'verification-status':
      'provisional | differential | confirmed | refuted | entered-in-error | unknown',
    'abatement-date': 'Date-related abatements (dateTime and period)',
    'abatement-string': 'Abatement as a string',
    'asserted-date': 'Date record was believed accurate',
    'body-site': 'Where procedure is going to be done',
    encounter: 'An encounter in which this request is made',
    evidence: 'Manifestation/symptom',
    'evidence-detail': 'Supporting information found elsewhere',
    'onset-date': 'Date related onsets (dateTime and Period)',
    'onset-info': 'Onsets as a string',
    stage: 'Simple summary (disease specific)',
    subject: 'Search by subject',
    'based-on': 'What request fulfills',
    diagnosis: 'Reason the encounter takes place (resource)',
    image: 'A reference to the image source.',
    issued: 'When the report was issued',
    performer: 'Requested perfomer',
    result: 'Link to an atomic result (observation resource)',
    specimen: 'Specimen to be tested',
    status: 'The current status of the questionnaire',
    authenticator: 'Who/what authenticated the document',
    author: 'Who and/or what authored the document',
    class: 'inpatient | outpatient | ambulatory | emergency +',
    created: 'Document creation time',
    custodian: 'Organization which maintains the document',
    event: 'Main clinical acts documented',
    facility: 'Kind of facility where patient was seen',
    format: 'Format/content rules for the document',
    indexed: 'When this document reference was created',
    period: 'Time of service that is being documented',
    'related-ref': 'Related Resource',
    relatesto: 'Target of the relationship',
    relation: 'replaces | transforms | signs | appends',
    securitylabel: 'Document security-tags',
    setting:
      'Additional details about where the content was created (e.g. clinical specialty)',
    appointment: 'The appointment that scheduled this encounter',
    episodeofcare:
      'Episode(s) of care that this encounter should be recorded against',
    incomingreferral: 'Incoming Referral Request',
    location: 'The location of the Healthcare Service',
    'location-period':
      'Time period during which the patient was present at the location',
    'part-of': 'Another Encounter this encounter is part of',
    participant: 'Persons involved in the encounter other than the patient',
    'participant-type': 'Role of participant in encounter',
    practitioner: 'Persons involved in the encounter other than the patient',
    reason: 'The reason for the study',
    'service-provider': 'The custodian organization of this Encounter record',
    'special-arrangement': 'Wheelchair, translator, stretcher, etc.',
    'connection-type':
      'Protocol/Profile/Standard to be used with this endpoint connection',
    organization: 'The organization at which this person is a patient',
    'payload-type':
      'The type of content that may be used at this endpoint (e.g. XDS Discharge summaries)',
    'care-manager': 'Care manager/care co-ordinator for the patient',
    condition: 'Conditions/problems/diagnoses this episode of care is for',
    active: 'Whether the practitioner record is active',
    characteristic: "One of the HealthcareService's characteristics",
    endpoint:
      'Technical endpoints providing access to services operated for the organization',
    programname: 'One of the Program Names serviced by this HealthcareService',
    accession: 'The accession identifier for the study',
    basedon: 'The order for the image',
    bodysite: 'The body site studied',
    'dicom-class': 'The type of the instance',
    modality: 'The modality of the series',
    series: 'The identifier of the series of images',
    started: 'When the study was started',
    study: 'The study identifier for the image',
    uid: 'The instance unique identifier',
    'empty-reason': 'Why list is empty',
    item: 'Actual entry',
    notes: 'The annotation  - text content',
    source: 'Who and/or what defined the list contents (aka Author)',
    title: 'The human-friendly name of the questionnaire',
    device: 'The Device that generated the observation data.',
    'effective-time': 'Date administration happened (or did not happen)',
    medication: 'Return prescriptions of this medication reference',
    'not-given': 'Administrations that were not made',
    prescription: 'The identity of a prescription to list administrations from',
    'reason-given': 'Reasons for administering the medication',
    'reason-not-given': 'Reasons for not administering the medication',
    authoredon: 'Return prescriptions written on this date',
    'intended-dispenser':
      'Returns prescriptions intended to be dispensed by this Organization',
    requester: 'Individual making the request',
    'combo-code': 'The code of the observation type or component type',
    'combo-value-concept':
      'The value or component value of the observation, if the value is a CodeableConcept',
    'component-code': 'The component code of the observation type',
    'component-code-value-quantity':
      'Component code and component quantity value parameter pair',
    'component-data-absent-reason':
      'The reason why the expected value in the element Observation.component.value[x] is missing.',
    'component-value-concept':
      'The value of the component observation, if the value is a CodeableConcept',
    'data-absent-reason':
      'The reason why the expected value in the element Observation.value[x] is missing.',
    method: 'The method used for the observation',
    'related-target': 'Resource that is related to this one',
    'related-type':
      'has-member | derived-from | sequel-to | replaces | qualified-by | interfered-by',
    'value-concept':
      'The value of the observation, if the value is a CodeableConcept',
    'value-date':
      'The value of the observation, if the value is a date or period of time',
    'value-string':
      'The value of the observation, if the value is a string, and also searches in CodeableConcept.text',
    address:
      'A server defined search that may match any of the string fields in the Address, including line, city, state, country, postalCode, and/or text',
    'address-city': 'A city specified in an address',
    partof: 'An organization of which this organization forms a part',
    birthdate: "The patient's date of birth",
    email: 'A value in an email contact',
    family: 'A portion of the family name',
    gender: 'Gender of the practitioner',
    'general-practitioner':
      "Patient's nominated general practitioner, not the organization that manages the record",
    given: 'A portion of the given name',
    link: 'All patients linked to the given patient',
    phone: 'A value in a phone contact',
    authored: 'Date request signed',
    definition: 'Protocol or definition',
    occurrence: 'When procedure should occur',
    'performer-type': 'Performer role',
    replaces: 'What request replaces',
    requisition: 'Composite Request ID',
    effective:
      'The time during which the questionnaire is intended to be in use',
    jurisdiction: 'Intended jurisdiction for the questionnaire',
    publisher: 'Name of the publisher of the questionnaire',
    channels: 'Channels',
    layout: 'Layout',
    fullChart: 'Full chart',
    handleToDrag: 'Handle to drag',
    leadsVisibility: 'Leads visibility',
    amplitude: 'Amplitude (mm/mV)',
    timeScale: 'Time scale (mm/s)',
    examPerformedCorrectly: 'Is the exam performed correctly technically?',
    technicalIrregularities: 'Description of technical irregularities',
    examId: 'Exam ID',
    examDate: 'Exam Date',
    examHour: 'Exam hour',
    examCarriedOutIncorrectly: 'Exam carried out incorrectly technically',
    heartRhythm: 'HR (heart-rhythm)',
    parameter: 'Parameter',
    value: 'Value',
    unit: 'Unit',
    distance: 'Distance',
    point: 'Point',
    amplitudeLabel: 'Amplitude',
    time: 'Time',
    general: 'General',
    left: 'Left',
    right: 'Right',
    change: 'Change',
    trend: 'Trend',
    infoDataDiagnostic:
      'The data resulting from the segmentation mechanism is not diagnostic data. These are illustrative data.',
    showTrendLine: 'Wyłącz linię trendu',
    hideTrendLine: 'Ukryj linię trendu',
    selectValue: 'Select value',
    selectDateToCompare: 'Select date to compare',
    trendLineMode: 'Trend line mode',
    startStudy: 'Beginning of the study',
    endStudy: 'End of study',
    channelVisibility: 'Channel visibility',
    selectAll: 'Select all',
    uncheckEverything: 'Uncheck everything',
    settings: 'Settings',
    resetZoom: 'Reset zoom',
    hideOptions: 'Hide options',
    showOptions: 'Show options',
    close: 'Close',
    studyDate: 'Study date',
    studyHour: 'Study hour',
    badlyDoneExamination: 'The test was performed incorrectly technically',
    mainCourse: 'Main course',
    dragAndDrop: 'Drag & drop'
  },
  resourcesStructures: {
    AllergyIntolerance: {
      clinicalStatus: 'Clinical status',
      verificationStatus: 'Verification status',
      type: 'Type',
      category: 'Category',
      criticality: 'Criticality',
      code: 'Code',
      patient: 'Patient',
      encounter: 'Encounter',
      onsetDateTime: 'Onset date time',
      onsetAge: 'Onset age',
      onsetPeriod: 'Onset period',
      onsetRange: 'Onset range',
      onsetString: 'Onset string',
      recorderDate: 'Recorder date',
      recorder: 'Recorder',
      asserter: 'Asserter',
      lastOccurence: 'Last Occurence',
      note: 'Note',
      reaction: 'Reaction',
      reactions: 'Reactions',
      'reaction-substance': 'Reaction substance',
      'reaction-manifestation': 'Reaction manifestation',
      'reaction-description': 'Reaction description',
      'reaction-onset': 'Reaction onset',
      'reaction-severity': 'Reaction severity',
      'reaction-exposureRoute': 'Reaction exposure Route',
      'reaction-note': 'Reaction note'
    },
    Condition: {
      clinicalStatus: 'Clinical Status',
      verificationStatus: 'Verification Status',
      category: 'Category',
      severity: 'Severity',
      code: 'Code',
      bodySite: 'Body Site',
      subject: 'Subject',
      encounter: 'Encounter',
      onsetDateTime: 'Onset Date Time',
      onsetAge: 'Onset Age',
      onsetPeriod: 'Onset Period',
      onsetRange: 'Onset Range',
      onsetString: 'Onset String',
      abatementDateTime: 'Abatement Date Time',
      abatementAge: 'Abatement Age',
      abatementPeriod: 'Abatement Period',
      abatementRange: 'Abatement Range',
      abatementString: 'Abatement String',
      recordedDate: 'Recorded Date',
      recorder: 'Recorder',
      asserter: 'Asserter',
      stage: 'Stage',
      'stage-summary': 'Stage summary',
      'stage-assessment': 'Stage assessment',
      'stage-type': 'Stage type',
      evidence: 'Evidence',
      'evidence-code': 'Evidence code',
      'evidence-detail': 'Evidence detail',
      note: 'Note'
    },
    DiagnosticReport: {
      basedOn: 'Based On',
      status: 'Status',
      category: 'Category',
      code: 'Code',
      subject: 'Subject',
      encounter: 'Encounter',
      effectiveDateTime: 'Effective Date Time',
      effectivePeriod: 'Effective Period',
      issued: 'Issued',
      performer: 'Performer',
      resultsInterpreter: 'Results Interpreter',
      specimen: 'Specimen',
      result: 'Result',
      imagingStudy: 'Imaging Study',
      media: 'Media',
      'media-comment': 'Media comment',
      'media-link': 'Media link',
      conclusion: 'Conclusion',
      conclusionCode: 'Conclusion Code',
      presentedForm: 'Presented Form'
    },
    DocumentReference: {
      status: 'Status',
      docStatus: 'Doc Status',
      type: 'Type',
      category: 'Category',
      subject: 'Subject',
      date: 'Date',
      author: 'Author',
      authenticator: 'Authenticator',
      custodian: 'Custodian',
      relatesTo: 'Relates To',
      'relatesTo-code': 'Relates To code',
      'relatesTo-target': 'Relates To target',
      description: 'Description',
      securityLabel: 'Security Label',
      content: 'Content',
      'content-attachment': 'Content attachment',
      'content-format': 'Content format',
      context: 'Context',
      'context-encounter': 'Context encounter',
      'context-event': 'Context event',
      'context-period': 'Context period',
      'context-facilityType': 'Context facility Type',
      'context-practiceSetting': 'Context practice Setting',
      'context-sourcePatientInfo': 'Context source Patient Info',
      'context-related': 'Context related'
    },
    Encounter: {
      status: 'Status',
      statusHistory: 'Status History',
      'statusHistory-status': 'Status History status',
      'statusHistory-period': 'Status History period',
      class: 'Class',
      classHistory: 'Class History',
      'classHistory-class': 'Class History class',
      'classHistory-period': 'Class History period',
      type: 'Type',
      serviceType: 'Service Type',
      priority: 'Priority',
      subject: 'Subject',
      episodeOfCare: 'Episode Of Care',
      basedOn: 'Based On',
      participant: 'Participant',
      'participant-type': 'Participant type',
      'participant-period': 'Participant period',
      'participant-individual': 'Participant individual',
      appointment: 'Appointment',
      period: 'Period',
      length: 'Length',
      reasonCode: 'Reason Code',
      reasonReference: 'Reason Reference',
      diagnosis: 'Diagnosis',
      'diagnosis-condition': 'Diagnosis condition',
      'diagnosis-use': 'Diagnosis use',
      'diagnosis-rank': 'Diagnosis rank',
      account: 'Account',
      hospitalization: 'Hospitalization',
      'hospitalization-preAdmissionIdentifier':
        'Hospitalization pre Admission Identifier',
      'hospitalization-origin': 'Hospitalization origin',
      'hospitalization-admitSource': 'Hospitalization admit Source',
      'hospitalization-reAdmission': 'Hospitalization re Admission',
      'hospitalization-dietPreference': 'Hospitalization diet Preference',
      'hospitalization-specialCourtesy': 'Hospitalization special Courtesy',
      'hospitalization-specialArrangement':
        'Hospitalization special Arrangement',
      'hospitalization-destination': 'Hospitalization destination',
      'hospitalization-dischargeDisposition':
        'Hospitalization discharge Disposition',
      location: 'Location',
      'location-location': 'Location location',
      'location-status': 'Location status',
      'location-physicalType': 'Location physical Type',
      'location-period': 'Location period',
      serviceProvider: 'Service Provider',
      partOf: 'Part Of'
    },
    Endpoint: {
      status: 'Status',
      connectionType: 'Connection Type',
      name: 'Name',
      managingOrganization: 'Managing Organization',
      contact: 'Contact',
      period: 'Period',
      payloadType: 'Payload Type',
      payloadMimeType: 'Payload Mime Type',
      address: 'Address',
      header: 'Header',
      unit: 'Unit',
      value: 'Value',
      referenceValue: 'Reference value'
    },
    EpisodeOfCare: {
      status: 'Status',
      statusHistory: 'Status History',
      episodeStatusHistory: 'Status History',
      'statusHistory-status': 'Status History status',
      'statusHistory-period': 'Status History period',
      type: 'Type',
      diagnosis: 'Diagnosis',
      'diagnosis-condition': 'Diagnosis condition',
      'diagnosis-role': 'Diagnosis role',
      'diagnosis-rank': 'Diagnosis rank',
      patient: 'Patient',
      managingOrganization: 'Managing Organization',
      period: 'Period',
      referralRequest: 'Referral Request',
      episodeReferralRequest: 'Referral Request',
      careManager: 'Care Manager',
      episodeCareManager: 'Care Manager',
      team: 'Team',
      account: 'Account'
    },
    HealthcareService: {
      active: 'Active',
      providedBy: 'Provided By',
      category: 'Category',
      type: 'Typ',
      specialty: 'Specialty',
      location: 'Location',
      name: 'Name',
      comment: 'Comment',
      extraDetails: 'Extra Details',
      photo: 'Photo',
      telecom: 'Telecom',
      coverageArea: 'Coverage Area',
      serviceProvisionCode: 'Service Provision Code',
      eligibility: 'Eligibility',
      'eligibility-code': 'Eligibility code',
      'eligibility-comment': 'Eligibility comment',
      program: 'Program',
      characteristic: 'Characteristic',
      communication: 'Communication',
      referralMethod: 'Referral Method',
      appointmentRequired: 'Appointment Required',
      availableTime: 'Available Time',
      'availableTime-daysOfWeek': 'Available Time days Of Week',
      'availableTime-allDay': 'Available Time all Day',
      'availableTime-availableStartTime': 'Available Time available Start Time',
      'availableTime-availableEndTime': 'Available Time available End Time',
      notAvailable: 'Not Available',
      'notAvailable-description': 'Not Available description',
      'notAvailable-during': 'Not Available during',
      availabilityExceptions: 'Availability Exceptions',
      endpoint: 'Endpoint'
    },
    ImagingStudy: {
      status: 'Status',
      modality: 'Modality',
      subject: 'Subject',
      encounter: 'Encounter',
      started: 'Started',
      basedOn: 'Based On',
      referrer: 'Referrer',
      interpreter: 'Interpreter',
      endpoint: 'Endpoint',
      numberOfSeries: 'Number Of Series',
      numberOfInstances: 'Number Of Instances',
      procedureReference: 'Procedure Reference',
      procedureCode: 'Procedure Code',
      location: 'Location',
      reasonCode: 'Reason Code',
      reasonReference: 'Reason Reference',
      note: 'Note',
      description: 'Description',
      series: 'Series',
      'series-number': 'Series number',
      'series-modality': 'Series modality',
      'series-description': 'Series description',
      'series-numberOfInstances': 'Series number Of Instances',
      'series-endpoint': 'Series endpoint',
      'series-bodySite': 'Series body Site',
      'series-laterality': 'Series laterality',
      'series-specimen': 'Series specimen',
      'series-started': 'Series started',
      'series-performer': 'Series performer',
      'series-performer-function': 'Series performer function',
      'series-performer-actor': 'Series performer actor',
      'series-instance': 'Series instance',
      'series-instance-sopClass': 'Series instance sop Class',
      'series-instance-number': 'Series instance number',
      'series-instance-title': 'Series instance title'
    },
    List: {
      identifier: 'Identifier',
      status: 'Status',
      mode: 'Mode',
      title: 'Title',
      code: 'Code',
      subject: 'Subject',
      encounter: 'Encounter',
      date: 'Date',
      source: 'Source',
      orderedBy: 'Ordered By',
      note: 'Note',
      entry: 'Entry',
      'entry-flag': 'Entry flag',
      'entry-deleted': 'Entry deleted',
      'entry-date': 'Entry date',
      'entry-item': 'Entry item',
      emptyReason: 'Empty Reason'
    },
    MedicationAdministration: {
      instantiates: 'Instantiates',
      partOf: 'Part Of',
      status: 'Status',
      statusReason: 'Status Reason',
      category: 'Category',
      medicationCodeableConcept: 'Medication Codeable Concept',
      medicationReference: 'Medication Reference',
      subject: 'Subject',
      context: 'Context',
      supportingInformation: 'Supporting Information',
      effectiveDateTime: 'Effective Date Time',
      effectivePeriod: 'Effective Period',
      performer: 'Performer',
      'performer-function': 'Performer function',
      'performer-actor': 'Performer actor',
      reasonCode: 'Reason Code',
      reasonReference: 'Reason Reference',
      request: 'Request',
      device: 'Device',
      note: 'Note',
      dosage: 'Dosage',
      'dosage-text': 'Dosage text',
      'dosage-site': 'Dosage site',
      'dosage-route': 'Dosage route',
      'dosage-method': 'Dosage method',
      'dosage-dose': 'Dosage dose',
      rateRatio: 'Rate Ratio',
      rateQuantity: 'Rate Quantity',
      eventHistory: 'Event History',
      validityPeriodStart: 'Dispense request period start',
      validityPeriodEnd: 'Dispense request period end',
      patient: 'Patient',
      medicationDosage: 'Dosage',
      medicationDosageRoute: 'Dosage route',
      medicationDosageMethod: 'Dosage method'
    },
    MedicationRequest: {
      status: 'Status',
      context: 'Context',
      statusReason: 'Status Reason',
      intent: 'Intent',
      category: 'Category',
      priority: 'Priority',
      doNotPerform: 'Do Not Perform',
      reportedBoolean: 'Reported Boolean',
      reportedReference: 'Reported Reference',
      medicationCodeableConcept: 'Medication Codeable Concept',
      medicationReference: 'Medication Reference',
      subject: 'Subject',
      encounter: 'Encounter',
      supportingInformation: 'Supporting Information',
      authoredOn: 'Authored On',
      requester: 'Requester',
      performer: 'Performer',
      performerType: 'Performer Type',
      recorder: 'Recorder',
      reasonCode: 'Reason Code',
      reasonReference: 'Reason Reference',
      instantiatesCanonical: 'Instantiates Canonical',
      instantiatesUri: 'Instantiates Uri',
      basedOn: 'Based On',
      groupIdentifier: 'Group Identifier',
      courseOfTherapyType: 'Course Of Therapy Type',
      insurance: 'Insurance',
      note: 'Note',
      dosageInstruction: 'Dosage Instruction',
      dispenseRequest: 'Dispense Request',
      'dispenseRequest-initialFill': 'Dispense Request initial Fill',
      'dispenseRequest-initialFill-quantity':
        'Dispense Request initial Fill quantity',
      'dispenseRequest-initialFill-duration':
        'Dispense Request initial Fill duration',
      'dispenseRequest-dispenseInterval': 'Dispense Request dispense Interval',
      'dispenseRequest-validityPeriod': 'Dispense Request validity Period',
      'dispenseRequest-numberOfRepeatsAllowed':
        'Dispense Request number Of Repeats Allowed',
      'dispenseRequest-quantity': 'Dispense Request quantity',
      'dispenseRequest-expectedSupplyDuration':
        'Dispense Request expected Supply Duration',
      'dispenseRequest-performer': 'Dispense Request performer',
      substitution: 'Substitution',
      'substitution-allowedBoolean': 'Substitution allowed Boolean',
      'substitution-allowedCodeableConcept':
        'Substitution allowed Codeable Concept',
      'substitution-reason': 'Substitution reason',
      priorPrescription: 'Prior Prescription',
      detectedIssue: 'Detected Issue',
      eventHistory: 'Event History',
      dosageInstructions: 'Dosage Instructions',
      prescription: 'Prescription',
      validityPeriodStart: 'Dispense request period start',
      validityPeriodEnd: 'Dispense request period end',
      additionalInstruction: 'Additional instruction',
      route: 'Route of administration',
      method: 'Admission method'
    },
    Observation: {
      basedOn: 'Based On',
      partOf: 'Part Of',
      status: 'Status',
      category: 'Category',
      code: 'Code',
      subject: 'Subject',
      focus: 'Focus',
      encounter: 'Encounter',
      effectiveDateTime: 'Effective Date Time',
      effectivePeriod: 'Effective Period',
      effectiveTiming: 'Effective Timing',
      effectiveInstant: 'Effective Instant',
      issued: 'Issued',
      performer: 'Performer',
      valueQuantity: 'Value Quantity',
      valueCodeableConcept: 'Value Codeable Concept',
      valueString: 'Value String',
      valueBoolean: 'Value Boolean',
      valueInteger: 'Value Integer',
      valueRange: 'Value Range',
      valueRatio: 'Value Ratio',
      valueSampledData: 'Value Sampled Data',
      valueTime: 'Value Time',
      valueDateTime: 'Value Date Time',
      valuePeriod: 'Value Period',
      dataAbsentReason: 'Data Absent Reason',
      interpretation: 'Interpretation',
      note: 'Notatka',
      bodySite: 'Body Site',
      method: 'Method',
      specimen: 'Specimen',
      device: 'Device',
      referenceRange: 'Reference Range',
      'referenceRange-low': 'Reference Range low',
      'referenceRange-high': 'Reference Range high',
      'referenceRange-type': 'Reference Range type',
      'referenceRange-appliesTo': 'Reference Range applies To',
      'referenceRange-age': 'Reference Range age',
      'referenceRange-text': 'Reference Range text',
      hasMember: 'Has Member',
      derivedFrom: 'Derived From',
      component: 'Component',
      'component-code': 'Component code',
      'component-valueQuantity': 'Component value Quantity',
      'component-valueCodeableConcept': 'Component value Codeable Concept',
      'component-valueString': 'Component value String',
      'component-valueBoolean': 'Component value Boolean',
      'component-valueInteger': 'Component value Integer',
      'component-valueRange': 'Component value Range',
      'component-valueRatio': 'Component value Ratio',
      'component-valueSampledData': 'Component value Sampled Data',
      'component-valueTime': 'Component value Time',
      'component-valueDateTime': 'Component value Date Time',
      'component-valuePeriod': 'Component value Period',
      'component-dataAbsentReason': 'Component data Absent Reason',
      'component-interpretation': 'Component interpretation',
      'component-referenceRange': 'Component reference Range',
      temperature: 'Temperature',
      fastBackward: 'Move to the beginning {from} - {to}',
      backward: 'Move backward {from} - {to}',
      fastForward: 'Move to the end {from} - {to}',
      forward: 'Move forward {from} - {to}',
      resetRange: 'Reset range {from} - {to}',
      currentRange: 'Go to current measurements',
      dateRange: '<b>Data range from:</b> {from} <b>to:</b> {to}',
      studyRange: '<b>Study range from:</b> {from} <b>to:</b> {to}',
      timeRange: '<b>Data time range:</b> {diff} s',
      selectAll: 'Check all',
      unselectAll: 'Uncheck all'
    },
    OperationDefinition: {
      url: 'Url',
      version: 'Version',
      name: 'Name',
      title: 'Title',
      status: 'Status',
      kind: 'Kind',
      experimental: 'Experimental',
      date: 'Date',
      publisher: 'Publisher',
      contact: 'Contact',
      description: 'Description',
      useContext: 'Use Context',
      jurisdiction: 'Jurisdiction',
      purpose: 'Purpose',
      affectsState: 'Affects State',
      code: 'Code',
      comment: 'Comment',
      base: 'Base',
      resource: 'Resource',
      system: 'System',
      type: 'Type',
      instance: 'Instance',
      inputProfile: 'Input Profile',
      outputProfile: 'Output Profile',
      parameter: 'Parameter',
      'parameter-name': 'Parameter name',
      'parameter-use': 'Parameter use',
      'parameter-min': 'Parameter min',
      'parameter-max': 'Parameter max',
      'parameter-documentation': 'Parameter documentation',
      'parameter-type': 'Parameter type',
      'parameter-targetProfile': 'Parameter target Profile',
      'parameter-searchType': 'Parameter search Type',
      'parameter-binding': 'Parameter binding',
      'parameter-bindingstrength': 'Parameter bindingstrength',
      'parameter-bindingvalueSet': 'Parameter bindingvalue Set',
      'parameter-referencedFrom': 'Parameter referenced From',
      'parameter-referencedFrom-source': 'Parameter referenced From source',
      'parameter-referencedFrom-sourceId':
        'Parameter referenced From source Id',
      'parameter-part': 'Parameter part',
      overload: 'Overload',
      'overload-parameterName': 'Overload parameter Name',
      'overload-comment': 'Overload comment'
    },
    Organization: {
      active: 'Active',
      type: 'Type',
      name: 'Name',
      alias: 'Alias',
      telecom: 'Telecom',
      address: 'Address',
      partOf: 'Part Of',
      contact: 'Contact',
      'contact-purpose': 'Contact purpose',
      'contact-name': 'Contact name',
      'contact-telecom': 'Contact telecom',
      'contact-address': 'Contact address',
      endpoint: 'Endpoint'
    },
    Patient: {
      active: 'Active',
      name: 'Name',
      telecom: 'Telecom',
      gender: 'Gender',
      birthDate: 'Date of birth',
      deceasedBoolean: 'Deceased Boolean',
      deceasedDateTime: 'Deceased Date Time',
      address: 'Address',
      maritalStatus: 'Marital Status',
      multipleBirthBoolean: 'Multiple Birth Boolean',
      multipleBirthInteger: 'Multiple Birth Integer',
      photo: 'Photo',
      contact: 'Contact',
      'contact-relationship': 'Contact relationship',
      'contact-name': 'Contact name',
      'contact-telecom': 'Contact telecom',
      'contact-address': 'Contact address',
      'contact-gender': 'Contact gender',
      'contact-organization': 'Contact organization',
      'contact-period': 'Contact period',
      communication: 'Communication',
      'communication-language': 'Communication language',
      'communication-preferred': 'Communication preferred',
      generalPractitioner: 'General Practitioner',
      managingOrganization: 'Managing Organization',
      link: 'Link',
      'link-other': 'Link other',
      'link-type': 'Link type',
      age: 'Age'
    },
    Practitioner: {
      active: 'Active',
      name: 'Name',
      telecom: 'Telecom',
      address: 'Address',
      gender: 'Gender',
      birthDate: 'Date of birth',
      photo: 'Photo',
      qualification: 'Qualification',
      'qualification-identifier': 'Qualification identifier',
      'qualification-code': 'Qualification code',
      'qualification-period': 'Qualification period',
      'qualification-issuer': 'Qualification issuer',
      communication: 'Communication',
      firstName: 'First Name',
      lastName: 'Last Name',
      pesel: 'PESEL'
    },
    Questionnaire: {
      url: 'Url',
      identifier: 'Identifier',
      version: 'Version',
      name: 'Name',
      title: 'Title',
      derivedFrom: 'Derived From',
      status: 'Status',
      experimental: 'Experimental',
      subjectType: 'Subject Type',
      date: 'Date',
      publisher: 'Publisher',
      contact: 'Contact',
      description: 'Description',
      useContext: 'Use Context',
      jurisdiction: 'Jurisdiction',
      purpose: 'Purpose',
      copyright: 'Copyright',
      approvalDate: 'Approval Date',
      lastReviewDate: 'Last Review Date',
      effectivePeriod: 'Effective Period',
      code: 'Code',
      'item-linkId': 'Item link Id',
      'item-definition': 'Item definition',
      'item-code': 'Item code',
      'item-prefix': 'Item prefix',
      'item-text': 'Item text',
      'item-type': 'Item type',
      'item-enableWhen': 'Item enable When',
      'item-enableWhen-question': 'Item enable When question',
      'item-enableWhen-operator': 'Item enable When operator',
      'item-enableWhen-answerBoolean': 'Item enable When answer Boolean',
      'item-enableWhen-answerDecimal': 'Item enable When answer Decimal',
      'item-enableWhen-answerInteger': 'Item enable When answer Integer',
      'item-enableWhen-answerDate': 'Item enable When answer Date',
      'item-enableWhen-answerDateTime': 'Item enable When answer Date Time',
      'item-enableWhen-answerTime': 'Item enable When answer Time',
      'item-enableWhen-answerString': 'Item enable When answer String',
      'item-enableWhen-answerCoding': 'Item enable When answer Coding',
      'item-enableWhen-answerQuantity': 'Item enable When answer Quantity',
      'item-enableWhen-answerReference': 'Item enable When answer Reference',
      enableBehavior: 'Enable Behavior',
      required: 'Required',
      repeats: 'Repeats',
      readOnly: 'Read Only',
      maxLength: 'Max Length',
      answerValueSet: 'Answer Value Set',
      answerOption: 'Answer Option',
      'answerOption-valueInteger': 'Answer Option value Integer',
      'answerOption-valueDate': 'Answer Option value Date',
      'answerOption-valueTime': 'Answer Option value Time',
      'answerOption-valueString': 'Answer Option value String',
      'answerOption-valueCoding': 'Answer Option value Coding',
      'answerOption-valueReference': 'Answer Option value Reference',
      'answerOption-initialSelected': 'Answer Option initial Selected',
      initial: 'Initial',
      'initial-valueBoolean': 'Initial value Boolean',
      'initial-valueDecimal': 'Initial value Decimal',
      'initial-valueInteger': 'Initial value Integer',
      'initial-valueDate': 'Initial value Date',
      'initial-valueDateTime': 'Initial value Date Time',
      'initial-valueTime': 'Initial value Time',
      'initial-valueString': 'Initial value String',
      'initial-valueUri': 'Initial value Uri',
      'initial-valueAttachment': 'Initial value Attachment',
      'initial-valueCoding': 'Initial value Coding',
      'initial-valueQuantity': 'Initial value Quantity',
      'initial-valueReference': 'Initial value Reference',
      item: 'Item'
    },
    StructureDefinition: {
      url: 'Url',
      version: 'Version',
      name: 'Name',
      title: 'Title',
      status: 'Status',
      experimental: 'Experimental',
      date: 'Date',
      publisher: 'Publisher',
      contact: 'Contact',
      description: 'Description',
      useContext: 'Use Context',
      jurisdiction: 'Jurisdiction',
      purpose: 'Purpose',
      copyright: 'Copyright',
      keyword: 'Keyword',
      fhirVersion: 'Fhir Version',
      mapping: 'Mapping',
      'mapping-identity': 'Mapping identity',
      'mapping-uri': 'Mapping uri',
      'mapping-name': 'Mapping name',
      'mapping-comment': 'Mapping comment',
      kind: 'Kind',
      abstract: 'Abstract',
      context: 'Context',
      'context-type': 'Context type',
      'context-expression': 'Context expression',
      contextInvariant: 'Context Invariant',
      type: 'Type',
      baseDefinition: 'Base Definition',
      derivation: 'Derivation',
      snapshot: 'Snapshot',
      'snapshot-element': 'Snapshot element',
      differential: 'Differential',
      'differential-element': 'Differential element'
    },
    Medication: {
      code: 'Code',
      status: 'Status',
      manufacturer: 'Manufacturer',
      form: 'Form',
      amount: 'Amount',
      ingredient: 'Ingredient',
      'ingredient-itemCodeableConcept': 'Ingredient item Codeable Concept',
      'ingredient-itemReference': 'Ingredient item Reference',
      'ingredient-isActive': 'Ingredient is Active',
      'ingredient-strength': 'Ingredient strength',
      batch: 'Batch',
      'batch-lotNumber': 'Batch lot Number',
      'batch-expirationDate': 'Batch expiration Date',
      package: 'Package',
      dose: 'Dose'
    },
    Procedure: {
      instantiatesCanonical: 'Instantiates Canonical',
      instantiatesUri: 'Instantiates Uri',
      basedOn: 'Based On',
      partOf: 'Part Of',
      status: 'Status',
      statusReason: 'Status Reason',
      category: 'Category',
      code: 'Code',
      subject: 'Subject',
      encounter: 'Encounter',
      performedDateTime: 'Performed Date Time',
      performedPeriod: 'Performed Period',
      performedString: 'Performed String',
      performedAge: 'Performed Age',
      performedRange: 'Performed Range',
      recorder: 'Recorder',
      asserter: 'Asserter',
      performer: 'Performer',
      'performer-function': 'Performer function',
      'performer-actor': 'Performer actor',
      'performer-onBehalfOf': 'Performer on Behalf Of',
      location: 'Location',
      reasonCode: 'Reason Code',
      reasonReference: 'Reason Reference',
      bodySite: 'Body Site',
      outcome: 'Outcome',
      report: 'Report',
      complication: 'Complication',
      complicationDetail: 'Complication Detail',
      followUp: 'Follow Up',
      note: 'Note',
      focalDevice: 'Focal Device',
      'focalDevice-action': 'Focal Device action',
      'focalDevice-manipulated': 'Focal Device manipulated',
      usedReference: 'Used Reference',
      usedCode: 'Used Code',
      reasonsCode: 'Reasons Code'
    },
    Consent: {
      status: 'Status',
      scope: 'Scope',
      category: 'Category',
      patient: 'Patient',
      dateTime: 'Date Time',
      performer: 'Performer',
      organization: 'Organization',
      sourceAttachment: 'Source Attachment',
      sourceReference: 'Source Reference',
      policy: 'Policy',
      'policy-authority': 'Policy authority',
      'policy-uri': 'Policy uri',
      policyRule: 'Policy Rule',
      verification: 'Verification',
      'verification-verified': 'Verification verified',
      'verification-verifiedWith': 'Verification verified With',
      'verification-verificationDate': 'Verification verification Date',
      'provision-type': 'Provision type',
      'provision-period': 'Provision period',
      'provision-actor': 'Provision actor',
      'provision-actor-role': 'Provision actor role',
      'provision-actor-reference': 'Provision actor reference',
      'provision-action': 'Provision action',
      'provision-securityLabel': 'Provision security Label',
      'provision-purpose': 'Provision purpose',
      'provision-class': 'Provision class',
      'provision-code': 'Provision code',
      'provision-dataPeriod': 'Provision data Period',
      'provision-data': 'Provision data',
      'provision-data-meaning': 'Provision data meaning',
      'provision-data-reference': 'Provision data reference',
      provision: 'Provision',
      attachment: 'Załącznik',
      consentPatient: 'Consent Patient'
    },
    Specimen: {
      accessionIdentifier: 'Accession Identifier',
      status: 'Status',
      type: 'Type',
      subject: 'Subject',
      receivedTime: 'Received Time',
      parent: 'Parent',
      request: 'Request',
      collection: 'Collection',
      'collection-collector': 'Collection collector',
      'collection-collectedDateTime': 'Collection collected Date Time',
      'collection-collectedPeriod': 'Collection collected Period',
      'collection-duration': 'Collection duration',
      'collection-quantity': 'Collection quantity',
      'collection-method': 'Collection method',
      'collection-bodySite': 'Collection body Site',
      'collection-fastingStatusCodeableConcept':
        'Collection fasting Status Codeable Concept',
      'collection-fastingStatusDuration': 'Collection fasting Status Duration',
      processing: 'Processing',
      'processing-description': 'Processing description',
      'processing-procedure': 'Processing procedure',
      'processing-additive': 'Processing additive',
      'processing-timeDateTime': 'Processing time Date Time',
      'processing-timePeriod': 'Processing time Period',
      container: 'Container',
      'container-identifier': 'Container identifier',
      'container-description': 'Container description',
      'container-type': 'Container type',
      'container-capacity': 'Container capacity',
      'container-specimenQuantity': 'Container specimen Quantity',
      'container-additiveCodeableConcept':
        'Container additive Codeable Concept',
      'container-additiveReference': 'Container additive Reference',
      condition: 'Condition',
      note: 'Note',
      types: 'Typ',
      patient: 'Pacjent'
    },
    ProcedureRequest: {
      status: 'Status',
      intent: 'Intent',
      category: 'Category',
      context: 'Context',
      priority: 'Priority',
      authoredOn: 'Authored On'
    },
    Location: {
      status: 'Status',
      operationalStatus: 'Operational Status',
      name: 'Name',
      alias: 'Alias',
      description: 'Description',
      mode: 'Mode',
      type: 'Type',
      telecom: 'Telecom',
      address: 'Address',
      physicalType: 'Physical Type',
      position: 'Position',
      'position-longitude': 'Position longitude',
      'position-latitude': 'Position latitude',
      'position-altitude': 'Position altitude',
      managingOrganization: 'Managing Organization',
      partOf: 'Part Of',
      hoursOfOperation: 'Hours Of Operation',
      'hoursOfOperation-daysOfWeek': 'Hours Of Operation days Of Week',
      'hoursOfOperation-allDay': 'Hours Of Operation all Day',
      'hoursOfOperation-openingTime': 'Hours Of Operation opening Time',
      'hoursOfOperation-closingTime': 'Hours Of Operation closing Time',
      availabilityExceptions: 'Availability Exceptions',
      endpoint: 'Endpoint'
    }
  },
  datatypes: {
    Attachment: {
      contentType: 'Content Type',
      language: 'Language',
      data: 'Data',
      url: 'Url',
      size: 'Size',
      hash: 'Hash',
      title: 'Title',
      creation: 'Creation'
    },
    Coding: {
      system: 'System',
      version: 'Version',
      code: 'Code',
      display: 'Display',
      userSelected: 'User Selected'
    },
    CodeableConcept: {
      coding: 'Coding',
      text: 'Text'
    },
    Quantity: {
      value: 'Value',
      comparator: 'Comparator',
      unit: 'Unit',
      system: 'System',
      code: 'Code'
    },
    Money: {
      value: 'Value',
      currency: 'Currency'
    },
    Range: {
      low: 'Low',
      high: 'High'
    },
    Ratio: {
      numerator: 'Numerator',
      denominator: 'Denominator'
    },
    Period: {
      start: 'Start',
      end: 'End'
    },
    SampledData: {
      origin: 'Origin',
      period: 'Period',
      factor: 'Factor',
      lowerLimit: 'Lower Limit',
      upperLimit: 'Upper Limit',
      dimensions: 'Dimensions',
      data: 'Data'
    },
    Identifier: {
      use: 'Use',
      type: 'Type',
      system: 'System',
      value: 'Value',
      period: 'Period',
      assigner: 'Assigner'
    },
    HumanName: {
      use: 'Use',
      text: 'Text',
      family: 'Family',
      given: 'Given',
      prefix: 'Prefix',
      suffix: 'Suffix',
      period: 'Period'
    },
    Address: {
      use: 'Use',
      type: 'Type',
      text: 'Text',
      line: 'Line',
      city: 'City',
      district: 'District',
      state: 'State',
      postalCode: 'Postal code',
      country: 'Country',
      period: 'Period'
    },
    ContactPoint: {
      system: 'System',
      value: 'Value',
      use: 'Use',
      rank: 'Rank',
      period: 'Period'
    },
    Timing: {
      event: 'Event',
      repeat: 'Repeat',
      boundsDuration: 'Bounds Duration',
      boundsRange: 'Bounds Range',
      boundsPeriod: 'Bounds Period',
      count: 'Count',
      countMax: 'Count Max',
      duration: 'Duration',
      durationMax: 'Duration Max',
      durationUnit: 'Duration Unit',
      frequency: 'Frequency',
      frequencyMax: 'Frequency Max',
      period: 'Period',
      periodMax: 'Period Max',
      periodUnit: 'Period Unit',
      dayOfWeek: 'Day Of Week',
      timeOfDay: 'Time Of Day',
      when: 'When',
      offset: 'Offset',
      code: 'Code'
    },
    Signature: {
      type: 'Type',
      when: 'When',
      who: 'Who',
      onBehalfOf: 'On Behalf Of',
      targetFormat: 'Target Format',
      sigFormat: 'Sig Format',
      data: 'Data'
    },
    Annotation: {
      authorReference: 'Author Reference',
      authorString: 'Author String',
      time: 'Time',
      text: 'Text'
    }
  }
};
