import { ref } from 'vue';
import { DATE_FILTER, DateFilter } from '@/store/search/types';
import { useStore as useSearchStore } from '@/store/search';

export default () => {
  const dateFilter = ref<DateFilter>({});
  const searchStore = useSearchStore();

  const processDateFilters = (dateFilter: DateFilter): void => {
    searchStore.commit(DATE_FILTER, dateFilter);
  };

  return {
    dateFilter,
    searchStore,
    useSearchStore,
    processDateFilters
  };
};
