<template>
  <p class="terms-of-use-description">
    lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsum lorem ipsum lorem
    ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsumlorem ipsumlorem ipsum
    lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsumlorem ipsumlorem
    ipsumlorem ipsum lorem ipsum lorem ipsum lorem ipsum
  </p>
</template>

<script>
export default {
  name: 'TermsOfUseDescription'
};
</script>

<style lang="scss" scoped>
.terms-of-use-description {
  padding: 0.25rem 0.5rem;
}
</style>
