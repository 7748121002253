export interface PatientI {
  birthDate: string | '';
  gender: string | '';
  name: string | '';
  pesel: string | number | '';
  age: string | number | '';
  id: string | '';
}

export class Patient implements PatientI {
  birthDate = '';
  gender = '';
  name = '';
  pesel: string | number = '';
  age: string | number = '';
  id = '';

  constructor(
    birthDate: string,
    gender: string,
    name: string,
    pesel: string | number,
    age: string | number,
    id: string
  ) {
    this.birthDate = birthDate;
    this.gender = gender;
    this.name = name;
    this.pesel = pesel;
    this.age = age;
    this.id = id;
  }

  toString = () =>
    `${this.name || ''} ${this.birthDate || ''} ${this?.pesel || ''}`;
}
