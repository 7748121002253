
import BaseModal from '@/components/Base/BaseModal.vue';
import { computed, defineComponent, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import ConfigRepo from '@/api/ConfigRepo';
import { JsonStorageTenants } from '@/components/ONLOAD_CONFIGURATION/onloadConfigUtils';

import BaseButton from '@/components/Base/BaseButton.vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import usePatient from '@/composables/usePatient';
import useUser from '@/composables/useUser';

export default defineComponent({
  name: 'ONLOAD_CONFIGURATION',
  components: { BaseModal, RadioButton, RadioGroup, BaseButton },
  emits: ['configuration-ok'],
  setup(props, context) {
    const modalConfig = ref(false);
    const loadedTenants = ref<string[]>([]);
    const selectedTenant = ref('');
    const isConfigOk = ref(false);

    const isUnknownUser = computed(() => useUser().isUnknownUser());

    const { t } = useI18n();

    const tokenExist = computed(() => useUser().tokenExist());
    const selectedTenantFromStore = computed(() =>
      useUser().getSelectedTenant()
    );

    const isAdmin = computed(() => useUser().isAdmin());

    watch(tokenExist, async tokenExist => {
      if (tokenExist) {
        const { data: tenantsData } = await ConfigRepo.loadTenants();
        const {
          data: jsonStorageData
        } = await ConfigRepo.loadConfigJsonStorage();

        const dataTenants = tenantsData?.parameter ?? [];

        if (dataTenants.length !== 0) {
          isConfigOk.value = true;
          await useUser().setTenants(dataTenants);

          // if json storage config is empty, show modal
          if (jsonStorageData.length === 0) {
            loadedTenants.value = dataTenants.map(
              (item: JsonStorageTenants) => item.valueString
            );
            modalConfig.value = true;

            /*
              if not empty - save tenants into store
             */
          } else {
            const tenant = jsonStorageData[0]?.json?.selectedTenant ?? null;

            if (tenant) {
              await useUser().setSelectedTenant(tenant);
              await useUser().setSelectedTenantId(jsonStorageData[0]?.id);

              if (!isUnknownUser.value) {
                await usePatient().loadPatient(tenant);
              }

              context.emit('configuration-ok');
            } else {
              modalConfig.value = false;
              context.emit('configuration-ok');
            }
          }
        } else {
          // when loaded tenants are empty - show info about wrong configuration
          modalConfig.value = true;
          isConfigOk.value = false;
        }
      }
    });

    const handleSaveConfiguration = async () => {
      const params = {
        selectedTenant: selectedTenant.value
      };

      try {
        if (isAdmin.value) {
          await ConfigRepo.postConfiguration(params);
          await useUser().setSelectedTenant(selectedTenant.value);
        }

        modalConfig.value = false;
        useNotification().addNotification(
          NotificationType.SUCCESS,
          '',
          t('config.savedTenants')
        );
        context.emit('configuration-ok');
      } catch (e) {
        useNotification().addNotification(
          NotificationType.ERROR,
          '',
          t('config.error')
        );
      }
    };

    return {
      isConfigOk,
      modalConfig,
      loadedTenants,
      selectedTenant,
      selectedTenantFromStore,
      isAdmin,
      handleSaveConfiguration
    };
  }
});
