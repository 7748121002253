<template>
  <Loader v-if="loading" size="small" />
  <button v-else class="button" @click="$emit('click')">
    <slot />
  </button>
</template>

<script>
import Loader from '../Loader/Loader';

export default {
  name: 'Button',
  components: {
    Loader
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style></style>
