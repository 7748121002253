export default {
  filters: {
    desc: 'Filtry wyszukiwania dokumentów pacjenta',
    documentData: 'Data dokumentu {date}',
    documentPatient: 'Pacjent {patient}',
    patients: 'Pacjenci',
    dateAny: 'Dowolna',
    dateToday: 'Dziś',
    dateLast5: 'Ostatnie 5 dni',
    dateLast30: 'Ostatnie 30 dni',
    dateCustom: 'Własny  zakres',
    dateFrom: 'Data od',
    sharedToMe: 'Udostępnione dla mnie',
    showSharedToMe: 'Wyświetl udostępnione dla mnie',
    dateTo: 'Data do',
    date: 'Data',
    dateTree: 'Dostępne daty dokumentów',
    selectDate: 'Filtruj dokumenty po dacie {date}',
    selectPatient: 'Filtruj dokumenty po pacjencie {patient}',
    clearDate: 'Wyczyść filtrowanie dokumentów po dacie {date}',
    clearPatient: 'Wyczyść filtrowanie dokumentów po pacjencie {patient}',
    chooseDate: 'Wybierz datę',
    selectedDate: 'Wybierz datę, wybrana data to {date}',
    prevYear: 'Poprzedni rok',
    prevMonth: 'Poprzedni miesiąc',
    nextYear: 'Następny rok',
    nextMonth: 'Następny miesiąc',
    fromTheNewest: 'Od najnowszych'
  },
  results: {
    error: 'Bład pobierania zasobów',
    reload: 'Odśwież',
    studyIsMarkedAsRead: 'Badanie zostało oznaczone jako przeczytane',
    errorOfMarkedStudy: 'Nie można oznaczyć badania',
    bodyHeightMeasured: 'Zmierzony wzrost ciała',
    bodyWeightMeasured: 'Zmierzona masa ciała',
    tobaccoSmokingStatus: 'Pali papierosy',
    additionalInformationAboutPatient: 'Dodatkowe informacje o pacjencie'
  },
  studyList: 'Lista badań',
  noInformation: 'Brak informacji',
  studyListForPage: 'Lista wszystkich badań na tej stronie'
};
