import { i18nFiles as I18nFiles, messages } from '../utils/i18n';
import resourcesEn from '@/components/FhirComponents/i18n/resources_en';
import searchparamsEn from '@/components/FhirComponents/i18n/searchparams_en';
import { merge } from 'lodash';

export default {
  props: {
    jsonObj: {},
    i18n: {},
    resource: {},
    resources: {},
    shortText: { default: false, type: Boolean },
    maxTextLength: { default: 10000, type: Number },
    lang: {
      type: String,
      required: false
    },
    mode: {
      type: Number,
      required: false,
      default: 2
    },
    token: {
      type: String,
      required: false
    },
    showTags: {
      type: Boolean,
      required: false,
      default: true
    },
    editTags: {
      type: Boolean,
      required: false,
      default: false
    },
    infoHeader: { default: '', type: String, required: false },
    infoFooter: { default: '', type: String, required: false },
    codeTypes: { default: '', type: String },
    showHeader: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    fhir() {
      return this.resource;
    }
  },
  created() {
    if (this.i18n !== undefined) {
      this.initI18n(this.i18n);
    }
  },
  methods: {
    initI18n(i18n) {
      if (i18n) {
        //console.log('initI18n');
        const newMessages = messages(
          require.context('../i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
        );
        if (newMessages != null) {
          Object.keys(newMessages).forEach(e => {
            const old = i18n.global.getLocaleMessage(e);
            const msgs = newMessages[e];
            const finalMsgs = merge({}, old, msgs);
            i18n.global.mergeLocaleMessage(e, finalMsgs);
          });
        }
      }
    }
  },
  watch: {
    lang: {
      immediate: true,
      handler(val) {
        if (val) {
          //console.log('watch lang', val);
        }
      }
    }
  }
};
