<template>
  <dl class="research-search">
    <dd>
      <ExpandablePanel>
        <template #header-controls="{expanded}">
          <template v-if="expanded">
            <span class="research-search__header">
              <span>{{ $t('Header.fullTextSearch.researchName') }}</span>
              <span>
                {{ $t('Header.fullTextSearch.nameAndSurname') }}
              </span>
            </span>
            <span class="research-search__header">
              <span>{{ $t('Header.fullTextSearch.date') }}</span>
              <span>
                {{ $t('Header.fullTextSearch.identifier') }}
              </span>
            </span>
          </template>
          <template v-else>
            <span class="research-search__one">
              {{ $t('Header.fullTextSearch.research') }}
            </span>
          </template>
        </template>
        <ul>
          <li
            v-for="research in researches"
            :key="research.id"
            class="research-search__item"
          >
            <button class="research-search__item__button searcher__item">
              <span>
                {{ research.researchName }}
              </span>
              <span>
                {{ research.date }}
              </span>
              <span style="color: var(--dark-gray);">
                {{ research.patient.name }} {{ research.patient.surname }}
              </span>
              <span style="color: var(--dark-gray);">
                {{ research.patient.identifier }}
              </span>
              <span class="visuallyhidden">
                Kliknij lub naciśnij przycisk enter aby przejść do badania.
              </span>
            </button>
          </li>
        </ul>
      </ExpandablePanel>
    </dd>
  </dl>
</template>

<script lang="ts">
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import { PropType } from 'vue';
import { ResearchesType } from '@/components/FullTextSearch/FullTextSearchType';

export default {
  name: 'ResearchSearch',
  components: { ExpandablePanel },
  props: {
    researches: {
      type: Array as PropType<ResearchesType[]>,
      required: true,
      default: () => []
    }
  }
};
</script>

<style scoped lang="scss">
.research-search {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span:last-child {
      margin-top: 0.5rem;
      color: var(--dark-gray);
    }
  }

  ::v-deep .expandable-panel-header-content {
    display: flex;
    justify-content: space-around;
    padding-right: 1.25rem;

    span {
      text-align: center;
      font-size: 0.825rem;
    }
  }

  &__item {
    width: 100%;
    list-style: none;
    &__button {
      width: 100%;
      padding: 0.5rem 0.25rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      cursor: pointer;
      background-color: transparent;
      border: none;
      transition: background-color 0.3s;
      border-bottom: 1px solid var(--light-gray);

      span:first-child,
      span:nth-child(3) {
        position: relative;
        //&:after {
        //  content: '';
        //  height: 200%;
        //  position: absolute;
        //  right: 0;
        //  top: 0;
        //  width: 1px;
        //  background-color: var(--light-gray);
        //}
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }

  .hide-span {
    position: absolute;
    width: 0;
  }
}
</style>
