<template>
  <div ref="activeHighlight" class="bypass-block-highlight" />
</template>

<script>
import { onMounted } from 'vue';
export default {
  name: 'BypassBlockHighlight',
  emits: ['deactivate-highlight'],
  setup(props, { emit }) {
    onMounted(() => {
      setTimeout(() => {
        emit('deactivate-highlight');
      }, 2000);
    });
  }
};
</script>

<style scoped>
.bypass-block-highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 0, 0);
  z-index: 1;
  animation: pulse 1.7s ease-in-out;
}
@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 0, 0);
  }
  50% {
    background-color: rgba(255, 255, 0, 0.7);
  }
  100% {
    background-color: rgba(255, 255, 0, 0);
  }
}
</style>
