import { createI18n } from 'vue-i18n';

const dynamicLangObject: any = {};

function importAll(resourcePath: any) {
  const getLangKeys: Array<string> = [];
  resourcePath
    .keys()
    .forEach((key: string) =>
      getLangKeys.push(key.replace(/(\.\/|\.ts$)/g, '').split('/')[0])
    );

  const getUniqueKeys: Array<string> = [...new Set(getLangKeys)];

  for (let i = 0; i < getUniqueKeys.length; i++) {
    dynamicLangObject[getUniqueKeys[i]] = {};
  }

  resourcePath.keys().forEach((key: string) => {
    const path = key.replace(/(\.\/|\.ts$)/g, '').split('/');

    if (path[0] in dynamicLangObject) {
      dynamicLangObject[path[0]][path[1]] = resourcePath(key).default;
    }
  });
}

importAll(require.context('./lang', true, /\.ts$/));

const messages: any = {
  pl: {
    ...dynamicLangObject.pl
  },
  en: {
    ...dynamicLangObject.en
  }
};

const i18n = createI18n({
  locale: 'pl',
  fallbackLocale: 'en',
  messages
});

export default i18n;
