
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  onUnmounted
} from 'vue';
import NotificationDialog from '@/components/Commons/NotificationDialog/NotificationDialog.vue';
import { generateUuid } from '@/utils/utils';
import useCommunicator from '@/composables/useCommunicator';
import { ok } from '@fhir/pixel-commons-js';
import { useI18n } from 'vue-i18n';
import useUser from '@/composables/useUser';
export default defineComponent({
  name: 'NotificationButton',
  components: { NotificationDialog },
  setup() {
    const notifications = computed(() => useCommunicator().newMessagesCount());
    const notificationDialog = ref<boolean>(false);
    const buttonId = ref(generateUuid());
    const refreshTime = Number(localStorage.getItem('refreshTime')) || 0;

    const token = computed(() => useUser().getToken());

    const i18n = useI18n();

    const lan = computed(() => i18n.locale.value);

    const init = () => {
      console.log('init communicator', token.value);
      if (ok(token.value)) {
        useCommunicator().connect(lan.value, token.value ?? '');
      }
    };

    let refreshInterval: undefined | number;

    onMounted(() => {
      init();
      if (refreshTime > 0) {
        refreshInterval = setInterval(() => {
          init();
        }, refreshTime * 1000);
      }
    });

    onUnmounted(() => clearInterval(refreshInterval));

    watch(token, v => init());
    watch(lan, v => init());

    return {
      notifications,
      notificationDialog,
      buttonId
    };
  }
});
