export const CalendarButtonInput = function(
  inputNode,
  buttonNode,
  datepicker,
  i18n
) {
  this.inputNode = inputNode;
  this.buttonNode = buttonNode;
  this.imageNode = false;

  this.datepicker = datepicker;

  this.i18n = i18n;

  this.keyCode = Object.freeze({
    ENTER: 13,
    SPACE: 32
  });
};

CalendarButtonInput.prototype.init = function() {
  this.buttonNode.addEventListener('click', this.handleClick.bind(this));
  this.buttonNode.addEventListener('keydown', this.handleKeyDown.bind(this));
  this.buttonNode.addEventListener('focus', this.handleFocus.bind(this));
  this.handleFocus();
};

CalendarButtonInput.prototype.handleKeyDown = function(event) {
  let flag = false;

  switch (event.keyCode) {
    case this.keyCode.SPACE:
    case this.keyCode.ENTER:
      this.datepicker.show();
      this.datepicker.setFocusDay();
      flag = true;
      break;

    default:
      break;
  }

  if (flag) {
    event.stopPropagation();
    event.preventDefault();
  }
};

CalendarButtonInput.prototype.handleClick = function() {
  if (!this.datepicker.isOpen()) {
    this.datepicker.show();
    this.datepicker.setFocusDay();
  } else {
    this.datepicker.hide();
  }

  event.stopPropagation();
  event.preventDefault();
};

CalendarButtonInput.prototype.setLabel = function(str) {
  this.buttonNode.setAttribute('aria-label', str);
};

CalendarButtonInput.prototype.setFocus = function() {
  this.buttonNode.focus();
};

CalendarButtonInput.prototype.setDate = function(day) {
  this.inputNode.value =
    day.getDate() + '/' + (day.getMonth() + 1) + '/' + day.getFullYear();
  this.inputNode.dispatchEvent(new Event('input'));
};

CalendarButtonInput.prototype.getDate = function() {
  return this.inputNode.value;
};

CalendarButtonInput.prototype.getDateLabel = function() {
  let label = '';

  const parts = this.inputNode.value.split('/');

  if (
    parts.length === 3 &&
    Number.isInteger(parseInt(parts[0])) &&
    Number.isInteger(parseInt(parts[1])) &&
    Number.isInteger(parseInt(parts[2]))
  ) {
    const month = parseInt(parts[1]) - 1;
    const day = parseInt(parts[0]);
    const year = parseInt(parts[2]);

    label = this.datepicker.getDateForButtonLabel(year, month, day);
  }

  return label;
};

CalendarButtonInput.prototype.handleFocus = function() {
  const dateLabel = this.getDateLabel();

  if (dateLabel) {
    this.setLabel(
      this.i18n('patient.filters.selectedDate', { date: dateLabel })
    );
  } else {
    this.setLabel(this.i18n('patient.filters.chooseDate'));
  }
};
