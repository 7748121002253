export default {
  Sunday: 'Niedziela',
  Monday: 'Poniedziałek',
  Tuesday: 'Wtorek',
  Wednesday: 'Środa',
  Thursday: 'Czwartek',
  Friday: 'Piątek',
  Saturday: 'Sobota',

  January: 'Styczeń',
  February: 'Luty',
  March: 'Marzec',
  April: 'Kwiecień',
  May: 'Maj',
  June: 'Czerwiec',
  July: 'Lipiec',
  August: 'Sierpień',
  September: 'Wrzesień',
  October: 'Październik',
  November: 'Listopad',
  December: 'Grudzień',

  Su: 'Nd',
  Mo: 'Pn',
  Tu: 'Wt',
  We: 'Śr',
  Th: 'Cz',
  Fr: 'Pi',
  Sa: 'So',

  Cancel: 'Anuluj',
  OK: 'OK',

  keysHelp: 'Użyj kursorów, aby poruszać się po datach',
  wrongRange: 'Zły zakres dat. Data od {from} jest późniejsza niż data do {to}.'
};
