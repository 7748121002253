
import { PropType, defineComponent } from 'vue';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import { PatientsType } from '@/components/FullTextSearch/FullTextSearchType';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PatientsSearch',
  components: { ExpandablePanel },
  props: {
    patients: {
      type: Array as PropType<PatientsType[]>,
      required: true,
      default: () => []
    }
  },
  setup() {
    const router = useRouter();

    const goToPatient = (patient: PatientsType) => {
      console.log(patient);
      // TODO route with router to Patient
    };

    return {
      goToPatient
    };
  }
});
