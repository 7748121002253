
import { computed, defineComponent, PropType } from 'vue';
import useNotification from '@/composables/useNotification';
import { NotificationI } from '@/composables/types/notification';
import BaseButton from '@/components/Base/BaseButton.vue';

export default defineComponent({
  name: 'Notification',
  props: {
    notification: {
      type: Object as PropType<NotificationI>,
      required: true
    }
  },
  components: {
    BaseButton
  },
  setup(props) {
    const removeNotification = () => {
      useNotification().removeNotification(props.notification);
    };

    const closable = computed(
      () => props.notification.closable || props.notification.delay === -1
    );
    const type = computed(() => props.notification.type.toString());

    if (props.notification.delay > 0) {
      setTimeout(() => removeNotification(), props.notification.delay);
    }

    return {
      closable,
      type,
      removeNotification
    };
  }
});
