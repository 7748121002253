<template>
  <div class="loader-container--ecg" :aria-label="$t('base.loading')">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width || shortMode ? 100 : 140"
      :height="height || shortMode ? 50 : 80"
      viewBox="0 0 250 200"
    >
      <template v-if="shortMode">
        <path
          class="ekg--ecg"
          ng-repeat="path in ekg.paths track by $index"
          stroke-expose-length
          fill="none"
          d="m 0 120 l 20 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 10.7 0.1 c 16.7 -4.6 9.8 -7.8 18.87 -11.5 c 6.1 0.5 9.46 9.1 11.3 12.5 l 10.99 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 10.7 0.1 c 16.7 -4.6 9.8 -7.8 18.87 -11.5 c 6.1 0.5 9.46 9.1 11.3 12.5 l 10.99 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 10.7 0.1 c 16.7 -4.6 9.8 -7.8 18.87 -11.5 c 6.1 0.5 9.46 9.1 11.3 12.5 l 10.99 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 10.7 0.1 c 16.7 -4.6 9.8 -7.8 18.87 -11.5 c 6.1 0.5 9.46 9.1 11.3 12.5 l 10.99 -0.16"
        />
      </template>
      <template v-else>
        <path
          class="ekg--ecg"
          ng-repeat="path in ekg.paths track by $index"
          stroke-expose-length
          fill="none"
          d="m 0 120 l 20 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 10.7 0.1 c 16.7 -4.6 9.8 -7.8 18.87 -11.5 c 6.1 0.5 9.46 9.1 11.3 12.5 l 10.99 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 10.7 0.1 c 16.7 -4.6 9.8 -7.8 18.87 -11.5 c 6.1 0.5 9.46 9.1 11.3 12.5 l 10.99 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 10.7 0.1 c 16.7 -4.6 9.8 -7.8 18.87 -11.5 c 6.1 0.5 9.46 9.1 11.3 12.5 l 10.99 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 10.7 0.1 c 16.7 -4.6 9.8 -7.8 18.87 -11.5 c 6.1 0.5 9.46 9.1 11.3 12.5 l 10.99 -0.16
           l 3.5 8.7 l 4.4 -92.45 l 8.27 112.9 l 4.2 -30.8 l 20 0"
        />
      </template>
    </svg>
    <span v-if="loadingTitle" class="loading-title--ecg">
      <slot>
        <!--        {{ $t('base.baseLoading') }}...-->
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'EcgLoader',
  props: {
    loadProgress: {},
    height: {},
    width: {},
    loadingTitle: {
      required: false,
      default: true,
      type: Boolean
    },
    /**
      shortMode - display smaller ecg loader
     **/
    shortMode: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      intervalId: ''
    };
  }
};
</script>

<style lang="scss">
.loader-container--ecg {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes ecg {
  0% {
    stroke-dashoffset: 1500;
    opacity: 0.7;
    stroke: var(--main-color);
    stroke-width: 2;
  }
  8%,
  12%,
  28%,
  32%,
  48%,
  52%,
  68%,
  72%,
  88%,
  92% {
    stroke-width: 3;
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    stroke-width: 5;
  }
  60% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 2;
    opacity: 0.7;
  }
}

.ekg--ecg {
  stroke-dasharray: 1500;
  stroke-dashoffset: 1500;
  stroke-width: 1;
  stroke-linecap: butt;
  animation: ecg 3s linear infinite;
  fill-opacity: 0;
  stroke: var(--main-color);
}

.loading-title--ecg {
  color: var(--font-color);
  font-size: 0.875rem;
  position: relative;
  animation: animate-title 1.2s forwards infinite ease;
}

@keyframes animate-title {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
</style>
