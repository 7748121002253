import { GetTransformTranslateValuesFromHtmlElementType } from '@/components/Base/Utils/BaseUtilsType';

export const ok = (s: string) => s != null && s.toString().trim().length > 0;

export function createUUID() {
  let dateTime = new Date().getTime();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (dateTime + Math.random() * 16) % 16 | 0;
    dateTime = Math.floor(dateTime / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function getTransformTranslateValuesFromHtmlElement(
  element: HTMLElement
): GetTransformTranslateValuesFromHtmlElementType | undefined {
  const style = window.getComputedStyle(element);

  const matrix = style.transform || style.webkitTransform; // || style.mozTransform;

  // No transform property. Simply return 0 values.
  if (matrix === 'none') {
    return {
      x: 0,
      y: 0,
      z: 0,
      position: style?.position,
      el: element
    };
  }

  // Can either be 2d or 3d transform
  const matrixType = matrix.includes('3d') ? '3d' : '2d';
  const matrixValues = matrix
    .match(/matrix.*\((.+)\)/)
    ?.find((f, i) => i == 1)
    ?.split(', ');

  // 2d matrices have 6 values
  // Last 2 values are X and Y.
  // 2d matrices does not have Z value.
  if (matrixType === '2d') {
    return {
      x: matrixValues != null ? matrixValues[4] : 0,
      y: matrixValues != null ? matrixValues[5] : 0,
      z: 0,
      position: style?.position,
      el: element
    };
  }

  // 3d matrices have 16 values
  // The 13th, 14th, and 15th values are X, Y, and Z
  if (matrixType === '3d') {
    return {
      x: matrixValues != null ? matrixValues[12] : 0,
      y: matrixValues != null ? matrixValues[13] : 0,
      z: matrixValues != null ? matrixValues[14] : 0,
      position: style?.position,
      el: element
    };
  }
}
