
import BaseButton from '@/components/Base/BaseButton.vue';
import { nextTick, onMounted, ref, watch } from 'vue';
import { generateUuid } from '@/utils/utils';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';

export default {
  name: 'Cookies',
  components: { BaseButton, FocusLoop },
  setup() {
    const active = ref<boolean>(false);

    const dialogRef = ref<string>(generateUuid());

    const dialogButton = ref<HTMLElement>();

    const setActive = (flag: boolean): void => {
      active.value = flag;
    };
    const acceptCookies = (): void => {
      localStorage.setItem('cookies', 'true');
      setActive(false);
    };
    const declineCookies = (): void => {
      localStorage.setItem('cookies', 'false');
      setActive(false);
    };

    watch(active, x => {
      nextTick(() => {
        if (!x) {
          setTimeout(() => {
            (document.getElementById(
              dialogRef.value
            ) as HTMLDialogElement).close();
            document.body.focus();
          }, 1000);
        }
      });
    });

    onMounted(() => {
      if (!localStorage.getItem('cookies')) {
        setTimeout(() => {
          setActive(true);

          const dialogElement = document.getElementById(
            dialogRef.value
          ) as HTMLDialogElement;

          dialogElement.showModal();
          dialogElement.focus();
        }, 500);
      }
    });

    return {
      active,
      setActive,
      acceptCookies,
      declineCookies,
      dialogRef,
      dialogButton
    };
  }
};
