
import {
  AccessibilityMenuInterface,
  Actions,
  LangsValues
} from './AccessibilityMenuUtils';
import AccessibilityMenuItem from '@/components/Commons/AccessibilityMenu/AccessiblityMenuItem.vue';

export default {
  name: 'AccessibilityMenu',
  components: { AccessibilityMenuItem },
  setup() {
    const accessibilityMenu: Array<AccessibilityMenuInterface> = [
      {
        icon: 'font',
        text: 'accessibilityMenu.changeFontSize',
        action: Actions.fontSize,
        title: 'accessibilityMenu.text',
        translations: [
          'accessibilityMenu.small',
          'accessibilityMenu.medium',
          'accessibilityMenu.big'
        ],
        disabled: false
      },
      {
        icon: 'adjust',
        text: 'accessibilityMenu.changeContrast',
        action: Actions.contrast,
        title: 'accessibilityMenu.contrast',
        translations: [
          'accessibilityMenu.contrastBase',
          'accessibilityMenu.contrastBlackBgFontYellow',
          'accessibilityMenu.contrastYellowBgBlackFont'
        ],
        disabled: false
      },
      {
        icon: 'link',
        text: 'accessibilityMenu.linkHighlightingMode',
        action: Actions.links,
        title: 'accessibilityMenu.links',
        translations: [
          'accessibilityMenu.inactive',
          'accessibilityMenu.active'
        ],
        disabled: false
      },
      {
        icon: 'moon',
        text: 'accessibilityMenu.changeTheme',
        action: Actions.theme,
        title: 'accessibilityMenu.theme',
        translations: [
          'accessibilityMenu.themeBright',
          'accessibilityMenu.themeDark'
        ],
        disabled: false
      },
      {
        icon: 'lang',
        text: 'accessibilityMenu.changeLang',
        action: Actions.langs,
        title: 'accessibilityMenu.lang',
        translations: Object.keys(LangsValues).map(
          it => `accessibilityMenu.${it}`
        ),
        disabled: false
      },
      {
        icon: 'book-reader',
        text: 'accessibilityMenu.mousePointerLineWithZoom',
        action: Actions.mousePointer,
        title: 'accessibilityMenu.mousePointer',
        translations: [
          'accessibilityMenu.base',
          'accessibilityMenu.medium',
          'accessibilityMenu.big'
        ],
        disabled: false
      }
    ];
    return {
      accessibilityMenu
    };
  }
};
