
import {
  Actions,
  ContrastLevels,
  ContrastLevelsValues,
  enumSizes,
  FontLevels,
  FontLevelsWithValues,
  Langs,
  LangsValues,
  Links,
  LinksValues,
  LS,
  MousePointer,
  MousePointerValues,
  Themes,
  ThemesValues
} from '@/components/Commons/AccessibilityMenu/AccessibilityMenuUtils';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';

import { defineComponent, ref } from 'vue';

import {
  changeContrast,
  changeFontSize,
  changeLang,
  changeLinks,
  changeMousePointer,
  changeTheme,
  setELOnLoad
} from '@/components/Commons/AccessibilityMenu/AccessibilityActions';

export default defineComponent({
  name: 'AccessibilityMenuItem',
  components: { RadioGroup, RadioButton },
  props: {
    item: {
      required: true,
      default: Object
    }
  },
  setup(props) {
    const radioState = ref(0);
    let timeoutInterval = 0;
    let timeoutIntervalContrast = 0;
    let timeoutIntervalMousePointer = 0;

    if (props.item.action === Actions.fontSize) {
      radioState.value = setELOnLoad(
        radioState.value,
        LS.fontSize,
        FontLevelsWithValues,
        changeFontSize,
        FontLevels.medium
      );
    }

    if (props.item.action === Actions.theme) {
      radioState.value = setELOnLoad(
        radioState.value,
        LS.theme,
        ThemesValues,
        changeTheme,
        Themes.bright
      );
    }

    if (props.item.action === Actions.langs) {
      radioState.value = setELOnLoad(
        radioState.value,
        LS.langs,
        LangsValues,
        changeLang,
        Langs.pl
      );
    }

    if (props.item.action === Actions.links) {
      timeoutInterval = setTimeout(() => {
        radioState.value = setELOnLoad(
          radioState.value,
          LS.links,
          LinksValues,
          changeLinks,
          Links.off
        );
      }, 0);
    }

    if (props.item.action === Actions.contrast) {
      timeoutIntervalContrast = setTimeout(() => {
        radioState.value = setELOnLoad(
          radioState.value,
          LS.contrast,
          ContrastLevelsValues,
          changeContrast,
          ContrastLevels.base
        );
      }, 0);
    }

    if (props.item.action === Actions.mousePointer) {
      timeoutIntervalMousePointer = setTimeout(() => {
        radioState.value = setELOnLoad(
          radioState.value,
          LS.mousePointer,
          MousePointerValues,
          changeMousePointer,
          MousePointer.base
        );
      }, 0);
    }

    const changeState = (type: Actions): void => {
      radioState.value++;

      // if value is out of the enum size, set radio on initial index
      const idx = enumSizes[Actions[type]];
      if (radioState.value >= idx) {
        radioState.value = 0;
      }

      if (type === Actions.fontSize) {
        changeFontSize(radioState.value);
      }
      if (type === Actions.contrast) {
        changeContrast(radioState.value);
        clearInterval(timeoutIntervalContrast);
      }
      if (type === Actions.theme) {
        changeTheme(radioState.value);
      }
      if (type === Actions.links) {
        changeLinks(radioState.value);
        clearInterval(timeoutInterval);
      }
      if (type === Actions.langs) {
        changeLang(radioState.value);
      }

      if (type === Actions.mousePointer) {
        changeMousePointer(radioState.value);
        clearInterval(timeoutIntervalMousePointer);
      }
    };

    const getSize = (type: Actions): number => {
      return enumSizes[Actions[type]];
    };

    return {
      radioState,
      changeState,
      getSize,
      LangsValues,
      Langs,
      Actions,
      Themes
    };
  }
});
