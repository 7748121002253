
import { computed, defineComponent } from 'vue';
import { NotificationI } from '@/composables/types/notification';
import Notification from '@/components/Base/Notification/Notification.vue';
import useNotification from '@/composables/useNotification';

export default defineComponent({
  name: 'NotificationSystem',
  components: { Notification },
  setup() {
    const notifications = computed(() => {
      const ns: Array<NotificationI> = useNotification().getNotifications();
      return ns.map(m => m).reverse();
    });

    const notificationsWithWarningModeAccept = computed(() =>
      notifications.value.filter(
        (item: NotificationI) => item.type === 'warning-mode-with-accept'
      )
    );

    const notificationsList = computed(() =>
      notifications.value.filter(
        (item: NotificationI) => item.type !== 'warning-mode-with-accept'
      )
    );

    return {
      notificationsWithWarningModeAccept,
      notificationsList
    };
  }
});
