<template>
  <div class="wrapper-logged-user-info">
    <UserAvatar />
    <div class="logged-user-info">
      <transition name="fade">
        <EcgLoader
          v-if="!isAdmin && !patient.name"
          class="ecg-loader-logged-user"
        />
        <div v-else>
          <p class="logged-user-names">
            <span class="visuallyhidden">
              {{ $t('user.nameAndSurnameLoggedUser') }}
            </span>
            {{ userName }}
            <span class="logged-user-smaller-info admin-status" v-if="isAdmin">
              (admin)
            </span>
          </p>
          <p v-if="patients.length === 1" class="logged-user-smaller-info">
            {{ $t('user.patient') }}: {{ patients[0].name }}
          </p>
          <p v-if="!isAdmin && onlyOnePatient" class="logged-user-smaller-info">
            {{ $t('user.pesel') }}: {{ patient.pesel }}
          </p>
          <p v-if="isAdmin" class="logged-user-smaller-info">
            Tenant: {{ getSelectedTenant }}
          </p>
        </div>
      </transition>
    </div>
    <button
      class="user-profile-icon icon"
      :title="$t('Header.openSettings')"
      @click="router.push('/settings')"
    >
      <span class="visuallyhidden">{{ $t('Header.openSettings') }}</span>
      <font-awesome-icon icon="cog" class="header-icon" />
    </button>
  </div>
</template>

<script lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import UserAvatar from '@/components/User/UserAvatar.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import usePatient from '@/composables/usePatient';
import useUser from '@/composables/useUser';

export default {
  name: 'LoggedUserInfo',
  components: { UserAvatar, EcgLoader },
  setup() {
    const router = useRouter();

    const userName = computed(() => useUser().getUserName());

    const isAdmin = computed(() => useUser().isAdmin());

    const getSelectedTenant = computed(() => useUser().getSelectedTenant());

    const patient = computed(() => usePatient().getLoggedPatient());

    const patients = computed(() => usePatient().getPatients());

    const onlyOnePatient = computed(
      () => usePatient().getPatients().length < 2
    );

    return {
      userName,
      router,
      patients,
      isAdmin,
      getSelectedTenant,
      patient,
      onlyOnePatient
    };
  }
};
</script>

<style scoped lang="scss">
.wrapper-logged-user-info {
  display: flex;
  align-items: center;
  margin: var(--app-margin) var(--app-base-space) 0 var(--app-base-space);
  border-bottom: 1px solid var(--light-gray);
  padding-bottom: var(--app-margin);
}

.logged-user-info {
  margin-left: var(--app-margin);
  flex-grow: 10;
}

.logged-user-names {
  color: var(--font-color);
}

.logged-user-smaller-info {
  margin-top: 5px;
  color: var(--dark-gray);
  font-size: 0.875rem;
}

.user-profile-icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  margin-right: var(--app-base-space);
  border: 1px solid transparent;
  transition: transform 0.15s ease, color 0.15s ease;

  &.icon {
    color: var(--main-color);
  }

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
    border: 1px solid transparent;
  }

  &:focus-visible {
    border: 1px solid var(--accent);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.admin-status {
  margin-left: 2px;
}

.ecg-loader-logged-user {
  position: absolute;
  top: 0;
}

.header-icon {
  font-size: 1.675rem;
}
</style>
