<template>
  <div class="mode-0__wrapper mini-flex">
    <div>
      <DisplayCoding
        v-if="fhir"
        :resource="fhir"
        :display-important="true"
        :lang="lang"
        class="mode-0__title"
      />
      <slot></slot>
    </div>
    <div>
      <div v-if="effectiveDateTime" class="mode-0__date-1">
        {{ effectiveDateTime }}
      </div>
      <div v-else-if="issued" class="mode-0__date-2">
        {{ issued }}
      </div>
      <div v-else-if="assertedDate" class="mode-0__date-3">
        {{ assertedDate }}
      </div>
      <div v-else-if="onsetDateTime" class="mode-0__date-4">
        {{ onsetDateTime }}
      </div>
      <div v-else-if="effectivePeriod" class="mode-0__date-5">
        {{ effectivePeriod }}
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import jp from 'jsonpath';
import { dateTimeMoment, dateMoment } from '../utils/time.utils';
import DisplayCoding from '../DisplayCoding/DisplayCoding';

export default {
  name: 'MiniMode',
  components: {
    DisplayCoding
  },
  mixins: [mixins],
  computed: {
    effectiveDateTime() {
      const date = jp.value(this.resource, 'resource.effectiveDateTime') || '';
      if (date) {
        return dateTimeMoment(date);
      } else {
        return null;
      }
    },
    effectivePeriod() {
      const start =
        jp.value(this.resource, 'resource.effectivePeriod.start') || '';
      const end = jp.value(this.resource, 'resource.effectivePeriod.end') || '';
      if (start && end) {
        return `${dateTimeMoment(start)} - ${dateTimeMoment(end)}`;
      } else {
        return null;
      }
    },
    issued() {
      const date = jp.value(this.resource, 'resource.issued') || '';
      if (date) {
        return dateTimeMoment(date);
      } else {
        return null;
      }
    },
    onsetDateTime() {
      const getDateTime =
        jp.value(this.resource, 'resource.onsetDateTime') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    assertedDate() {
      const getDateTime =
        jp.value(this.resource, 'resource.assertedDate') || '';
      if (getDateTime) {
        return dateMoment(getDateTime);
      } else {
        return '';
      }
    }
  }
};
</script>

<style>
@import '../assets/styles/base.css';

.mini-flex {
  display: flex;
  flex-wrap: wrap;
}

.mini-flex > *:not(:first-child) {
  margin-left: 5px;
}
</style>
