import { reactive } from 'vue';
import useDynaroles from '@/composables/useDynaroles';

const state: UserStateI = reactive({
  selectedTenant: '',
  groups: [],
  userName: '',
  token: '',
  tenants: [],
  selectedTenantId: '',
  is401: false
});

export default function useUser(): {
  setTenants: (tenants: Array<any>) => Array<any>;
  getSelectedTenant: () => string;
  getSelectedTenantId: () => string;
  getTenants: () => Array<any>;
  isAdmin: () => boolean;
  setUserToken: (token: string | null) => string | null;
  setSelectedTenantId: (tenantId: string) => string;
  setSelectedTenant: (tenant: string) => string;
  set401: () => boolean;
  isUnknownUser: () => boolean;
  setUserName: (name: string) => string;
  getToken: () => string | null;
  is401: () => boolean;
  setUserGroup: (groups: any) => any;
  getUserName: () => string;
  getUserGroups: () => Array<any>;
  tokenExist: () => boolean;
  isPatient: () => boolean;
} {
  const getUserName = () => state.userName;
  const isAdmin = () => {
    const dynaroles = useDynaroles();
    return dynaroles?.check('portalPacjenta.is.admin') ?? false;
  };
  const tokenExist = () => !!state.token;
  const getToken = () => state.token;
  const getSelectedTenant = () => state.selectedTenant;
  const getTenants = () => state.tenants;
  const getSelectedTenantId = () => state.selectedTenantId;
  const getUserGroups = () => state.groups;
  const isPatient = () =>
    !!state.groups.find(item => item.startsWith('ATTR_PESEL_'));
  const isUnknownUser = () => !isPatient() && !isAdmin();
  const is401 = () => state.is401;

  const setUserGroup = (groups: any) => (state.groups = groups);
  const setUserName = (name: string) => (state.userName = name);
  const setUserToken = (token: string | null) => (state.token = token);

  const setSelectedTenant = (tenant: string) => (state.selectedTenant = tenant);
  const setTenants = (tenants: Array<any>) => (state.tenants = tenants);
  const setSelectedTenantId = (tenantId: string) =>
    (state.selectedTenantId = tenantId);
  const set401 = () => (state.is401 = true);

  return {
    setUserGroup,
    setUserName,
    setUserToken,
    getUserName,
    isAdmin,
    tokenExist,
    getToken,
    getSelectedTenant,
    getTenants,
    getSelectedTenantId,
    setSelectedTenant,
    setTenants,
    setSelectedTenantId,
    getUserGroups,
    isPatient,
    isUnknownUser,
    is401,
    set401
  };
}
