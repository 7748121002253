<template>
  <div>
    <!--    TODO gdy element fieldset będzie możliwy do uzycia należey użyć tagu legend jako opisu grupy przycisków radio-->
    <!--    <legend>{{ inputLabel }}</legend>-->
    <p :class="{ 'field-error': isInvalid }" class="checkbox-group__label">
      {{ inputLabel }}
    </p>
    <ul class="checkbox">
      <li v-for="(el, index) in table" :key="index">
        <BaseCheckbox
          @input="updateValue"
          :checkbox-id="`checkbox__for__${el.value}`"
          :checkbox-label="el.label"
          :checkbox-value="el.value"
        ></BaseCheckbox>
      </li>
    </ul>
  </div>
</template>

<script>
import BaseCheckbox from './BaseCheckbox';
export default {
  name: 'BaseCheckboxGroup',
  components: { BaseCheckbox },
  data() {
    return {
      table: [],
      selectedItems: []
    };
  },
  props: {
    /**
     * Komponent przyjmuje tablicę obiektów. Obiekty powinny zawierać takie pola:
     * @checked - informacja o tym czy zaznaczono
     * @value - własność zwracana po zaznaczeniu danej opcji
     * @label - label danego checkboxa wyświetlana obok samego checkboxa
     */
    mainTable: {
      type: Array,
      required: false,
      default: () => []
    },
    isInvalid: {
      type: Boolean,
      required: false,
      default: false
    },
    inputLabel: {
      type: String,
      required: false
    },
    updateAll: {
      type: Boolean,
      required: false,
      default: false
    },
    infoSlot: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  watch: {
    selectedItems: {
      immediate: false,
      deep: true,
      handler() {
        this.$emit(
          'input',
          this.selectedItems.map(el => el.value)
        );
      }
    },
    mainTable: {
      handler(val, oldVal) {
        let good = 0;
        let array = [];
        this.table = [...this.mainTable];
        if (val.length !== oldVal.length) {
          this.selectedItems = [];
          this.table.forEach(el => {
            this.selectedItems.push({
              isChecked: true,
              id: `checkbox__for__${el.value}`,
              value: el.value
            });
          });
        } else {
          val.map(checkbox => {
            array = oldVal.map(value => {
              return value.value;
            });
            if (array.includes(checkbox.value)) {
              good++;
            }
          });

          if (good !== array.length) {
            this.table.forEach(el => {
              this.selectedItems = [];
              this.selectedItems.push({
                isChecked: true,
                id: `checkbox__for__${el.value}`,
                value: el.value
              });
            });
          }
        }
      }
    }
  },
  methods: {
    updateValue(e) {
      const index = this.table.findIndex(f => f.value === e.value);

      console.log('updateValue', e, index);
      if (index !== -1) {
        this.table[index].checked = e.isChecked;
        this.$emit('get-update-table', this.table);
      }
    },
    setupSelected() {
      this.table.forEach(el => {
        if (el.checked) {
          this.selectedItems.push({
            isChecked: true,
            id: `checkbox__for__${el.value}`,
            value: el.value
          });
        }
      });
    }
  },
  created() {
    this.table = [...this.mainTable];
    if (!this.updateAll) {
      this.setupSelected();
    } else {
      this.table.forEach(el => {
        this.selectedItems.push({
          isChecked: true,
          id: `checkbox__for__${el.value}`,
          value: el.value
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'] {
    --active: #275efe;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #275efe;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    &:after {
      content: '';
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    }
    &:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
      &:checked {
        --b: var(--disabled-inner);
        --bc: var(--border);
      }
      & + label {
        cursor: not-allowed;
      }
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
    &:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    &:not(.switch) {
      width: 21px;
      &:after {
        opacity: var(--o, 0);
      }
      &:checked {
        --o: 1;
      }
    }
    & + label {
      font-family: 'Poppins', sans-serif;
      line-height: 21px;
      display: inline-block;
      vertical-align: top;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  input[type='checkbox'] {
    &:not(.switch) {
      border-radius: 7px;
      &:after {
        width: 5px;
        height: 9px;
        border: 2px solid var(--active-inner);
        border-top: 0;
        border-left: 0;
        left: 7px;
        top: 4px;
        transform: rotate(var(--r, 20deg));
      }
      &:checked {
        --r: 43deg;
      }
    }
    &.switch {
      width: 38px;
      border-radius: 11px;
      &:after {
        left: 2px;
        top: 2px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        background: var(--ab, var(--border));
        transform: translateX(var(--x, 0));
      }
      &:checked {
        --ab: var(--active-inner);
        --x: 17px;
      }
      &:disabled {
        &:not(:checked) {
          &:after {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

li {
  padding: 5px 0 5px 0;
}
html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  &:before,
  &:after {
    box-sizing: inherit;
  }
}
</style>
