<template>
  <div id="watch-observer-to-top" class="watch-observer-to-top"></div>
  <button
    id="go-to-top"
    class="go-to-top"
    :title="$t('base.goToTop')"
    @click="handleGoToTop"
  >
    <span class="visuallyhidden">{{ $t('base.goToTop') }}</span>
    <font-awesome-icon icon="arrow-up" class="arrow-up" />
  </button>
</template>

<script>
import { onMounted } from 'vue';
export default {
  name: 'GoToTop',
  setup() {
    onMounted(() => {
      const goToTop = document.getElementById('go-to-top');

      const observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
          goToTop.classList.add('show-btn');
        } else {
          goToTop.classList.remove('show-btn');
        }
      });

      observer.observe(document.getElementById('watch-observer-to-top'));
    });

    const handleGoToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

    return {
      handleGoToTop
    };
  }
};
</script>

<style scoped lang="scss">
.watch-observer-to-top {
  position: absolute;
  top: 0;
  height: 0;
  width: 0;
  left: 0;
  visibility: hidden;
}
.go-to-top {
  position: fixed;
  bottom: 52px;
  right: 4px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.15s ease;
  border: 3px solid transparent;
  transform: translateX(50px);
  &:before {
    content: '';
    height: 3px;
    width: 20px;
    background-color: var(--primary);
    position: absolute;
    top: 0;
    border-radius: 10px;
  }
  &:hover {
    transform: scale(1.2);
  }
  &:active {
    color: var(--primary);
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    border: 3px solid var(--main-color);
  }

  &.show-btn {
    transform: translateX(0);
  }
}

.arrow-up {
  font-size: 1.675rem;
}
</style>
