export default {
  Elements: {
    table: {
      totalRows: 'Liczba wierszy'
    }
  },
  headers: {
    allergy: 'Alergia'
  }
};
