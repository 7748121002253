
import TermsOfUseTitle from '@/components/TermsOfUse/TermsOfUseTitle.vue';
import TermsOfUseDescription from '@/components/TermsOfUse/TermsOfUseDescription.vue';
import { ref } from 'vue';
import BaseSpacer from '@/components/Base/BaseSpacer.vue';
export default {
  name: 'TermsOfUse',
  components: { BaseSpacer, TermsOfUseDescription, TermsOfUseTitle },
  setup() {
    const firstTitles = ref([
      'Postanowienia ogólne',
      'Definicje',
      'Rodzaj i zakres usług elektronicznych',
      'Warunki świadczenia i zawierania umów o świadczenie usług elektronicznych',
      'Warunki rozwiązania umów o świadczenie usług elektronicznych',
      'Tryb postępowania reklamacyjnego',
      'Własność intelektualna',
      'Postanowienia końcowe'
    ]);

    return {
      firstTitles
    };
  }
};
