
import {
  defineComponent,
  ref,
  watch,
  computed,
  onMounted,
  onUnmounted
} from 'vue';
import { generateUuid } from '@/utils/utils';
import { debounce } from 'lodash';

export default defineComponent({
  name: 'ExpandablePanel',
  props: {
    name: {
      type: String,
      required: false
    },
    expanded: {
      type: Boolean,
      required: false,
      default: true
    },
    border: {
      type: Boolean,
      required: false,
      default: false
    },
    borderBottom: {
      type: Boolean,
      required: false,
      default: false
    },
    keepHeaderVisibleWhenScrolling: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Set default color for expandable header
     * required format for props: '#abcabc'
     */
    backgroundHeader: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Set default color for expandable header
     * required format for props: '#abcabc'
     */
    colorHeader: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Check if study is displayed
     * (for example user choose button with 'study displaed')
     */
    studyIsDisplayed: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * @param notClicable - set exapndable panel not clicable,
     * this options will be used with @param exapanded
     */
    notClicable: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  setup(props) {
    const show = ref<boolean>(props.expanded);

    const uuid = computed(() => generateUuid());

    const scroll = (e: Event) => {
      console.log('scroll', e);
    };

    watch(
      () => props.expanded,
      v => {
        show.value = v;
      }
    );

    const floating = ref(false);

    const toggle = (): void => {
      if (props.notClicable && props.expanded) {
        show.value = true;
      } else {
        if (!floating.value) {
          show.value = !show.value;
        }
      }
    };
    const expandableContainer = ref<HTMLElement>();
    const expandableHeader = ref<HTMLElement>();
    const headerWidth = ref<number>(400);

    const headerFloatingStyle = computed(() => {
      return floating.value ? { width: `${headerWidth.value}px` } : {};
    });

    if (props.keepHeaderVisibleWhenScrolling) {
      const scrollListener = debounce((e: Event) => {
        if (show.value) {
          const top = 50;
          const cbb = expandableContainer.value?.getBoundingClientRect();
          const hbb = expandableHeader.value?.getBoundingClientRect();

          if (
            hbb?.height != null &&
            cbb?.top != null &&
            cbb?.bottom != null &&
            cbb?.top + hbb?.height < top &&
            cbb?.bottom - hbb?.height > top
          ) {
            floating.value = true;
            console.log('toggle float', props.name);
            headerWidth.value = hbb?.width;
          } else {
            floating.value = false;
          }
        }
      }, 100);

      onUnmounted(() => {
        window.removeEventListener('scroll', scrollListener);
      });

      onMounted(() => {
        window.addEventListener('scroll', scrollListener);
      });
    }

    return {
      show,
      toggle,
      uuid,
      floating,
      expandableContainer,
      expandableHeader,
      headerFloatingStyle
    };
  }
});
