<template>
  <div class="loader">
    <div class="spinner">
      <font-awesome-icon
        icon="spinner"
        :class="{ spin: spin }"
        :height="16"
        spin
        pulse
      ></font-awesome-icon>
    </div>
    <span class="label">
      <slot>
        <span v-if="label">{{ label }}</span>
      </slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseLoader',
  props: {
    label: {},
    spin: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  text-align: center;
}
.label {
  margin-left: 10px;
}

.label:empty {
  display: none;
}

.spin {
  animation: spin 2s infinite steps(8);
  /*animation: spin 5s infinite linear;*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
