<template>
  <div class="patient-filters">
    <p class="visuallyhidden">{{ $t('patient.filters.desc') }}</p>

    <ExpandablePanel
      :name="$t('patient.filters.patients')"
      class="patient-filters__expandable-panel"
      expanded
      not-clicable
      v-if="patients && patients.length > 1"
    >
      <RadioGroup direction="vertical">
        <RadioButton
          name="days"
          :first="i === 0"
          @update:modelValue="selectPatient"
          :model-value="selectedPatient"
          :value="patient.id"
          v-for="(patient, i) in patients"
          :key="`patient-radio-${i}`"
          :label="patient.name"
          :aria-label="
            $t('patient.filters.selectPatient', { patient: patient.name })
          "
        >
          <div
            class="resources-dates-close"
            v-if="selectedPatient === patient.id"
          >
            <Button
              @click="selectPatient(null)"
              class="icon-button"
              :aria-label="
                $t('patient.filters.clearPatient', { patient: patient.name })
              "
            >
              <font-awesome-icon icon="times" :height="16"></font-awesome-icon>
            </Button>
          </div>
          <template #additional>
            <div class="ml">
              <p class="user-smaller-info">
                {{ $t('user.pesel') }}: {{ patient.pesel }}
              </p>
              <p class="user-smaller-info">
                {{ $t('user.birthDate') }}: {{ patient.birthDate }}
              </p>
              <p class="user-smaller-info">
                {{ $t('user.age') }}: {{ patient.age }}
              </p>
            </div>
          </template>
        </RadioButton>
      </RadioGroup>
    </ExpandablePanel>
    <ExpandablePanel
      :name="$t('patient.filters.sharedToMe')"
      class="patient-filters__expandable-panel show-shared"
      :expanded="true"
      :not-clicable="true"
    >
      <BaseSwitch
        :on-off="sharedDocuments"
        active-color="main"
        @is-active="setSharedDocuments"
        :name-for-switch="$t('patient.filters.showSharedToMe')"
      />
    </ExpandablePanel>

    <ExpandablePanel
      :name="$t('patient.filters.documentData')"
      class="patient-filters__expandable-panel"
      :expanded="true"
      :not-clicable="true"
    >
      <DateFilters
        :model-value="dateFilter"
        @update:modelValue="processDateFilters"
      />
    </ExpandablePanel>

    <transition name="fade">
      <ExpandablePanel
        :name="$t('patient.filters.dateTree')"
        :expanded="true"
        :not-clicable="true"
        class="mt patient-filters__expandable-panel"
        v-if="resourceDates && resourceDates.length > 1"
      >
        <div class="resources-dates-container">
          <RadioGroup direction="vertical">
            <RadioButton
              name="days"
              :first="i === 0"
              @update:modelValue="selectDate"
              :model-value="selectedDate"
              :value="rd.date"
              v-for="(rd, i) in resourceDates"
              :key="`rdrg-${i}`"
              :label="rd.date"
              :aria-label="$t('patient.filters.selectDate', { date: rd.date })"
            >
              <div
                class="resources-dates-close"
                v-if="selectedDate === rd.date"
              >
                <Button
                  @click="selectDate(null)"
                  class="icon-button"
                  :aria-label="
                    $t('patient.filters.clearDate', { date: rd.date })
                  "
                >
                  <font-awesome-icon
                    icon="times"
                    :height="16"
                  ></font-awesome-icon>
                </Button>
              </div>
            </RadioButton>
          </RadioGroup>
        </div>
      </ExpandablePanel>
    </transition>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import BaseSwitch from '@/components/Base/BaseSwitch.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import { DATE, PATIENT, RESULTS, SHARED } from '@/store/search/types';
import DateFilters from '@/components/Commons/DateFilters.vue';
import Button from '@/components/FhirComponents/Button/Button.vue';
import PatientFilters from '@/components/Patient/PatientFilters';
import usePatient from '@/composables/usePatient';

export default defineComponent({
  name: 'PatientFilters',
  components: {
    Button,
    DateFilters,
    RadioButton,
    RadioGroup,
    BaseSwitch,
    ExpandablePanel
  },
  setup() {
    const { searchStore, processDateFilters, dateFilter } = PatientFilters();

    const sharedDocuments = computed(() => searchStore.getters[SHARED]);
    const setSharedDocuments = (value: boolean): void => {
      searchStore.commit(SHARED, value);
    };

    const resourceDates = computed(() => searchStore.getters[RESULTS]);
    const selectDate = (date: string) => {
      searchStore.commit(DATE, date);
    };
    const selectedDate = computed(() => searchStore.getters[DATE]);

    const patients = computed(() => usePatient().getPatients());
    const selectedPatient = computed(() => searchStore.getters[PATIENT]);
    const selectPatient = (patient: string) => {
      searchStore.commit(PATIENT, patient);
    };

    return {
      resourceDates,
      selectDate,
      selectedDate,
      processDateFilters,
      dateFilter,
      patients,
      selectedPatient,
      selectPatient,
      setSharedDocuments,
      sharedDocuments
    };
  }
});
</script>

<style scoped lang="scss">
.resources-dates-container {
  max-height: 25vh;
  overflow: auto;
  position: relative;
}

.resources-dates-row {
  padding-right: 10px;
  display: flex;
  align-items: center;
}

.resources-dates-close {
  flex-grow: 10;
  text-align: right;
  padding-right: 10px;
}

.resources-dates-dot {
  background-color: var(--main-color);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  z-index: 1;
}

.resources-date-selected .date-button {
  font-weight: bold;
}

.resources-date-selected .resources-dates-dot {
  background-color: var(--accent);
}

.patient-filters__expandable-panel {
  border-bottom: 1px solid var(--light-gray);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.user-info {
  //font-weight: bold;
}

.user-smaller-info {
  color: var(--dark-gray);
  font-size: 0.875rem;
}

.patient-info {
  padding: 3px;
}

.patient-info-border {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--light-gray);
}
</style>
