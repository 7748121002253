<template>
  <div class="notification-container">
    <button
      class="user-profile-icon icon header-icon"
      :title="
        `${$t('Header.openNotifications')} masz ${notifications} powiadomienie`
      "
      :id="buttonId"
      @click="notificationDialog = !notificationDialog"
    >
      <div
        v-if="notifications != null"
        class="user-profile-icon__circle"
        :title="notifications"
      >
        {{ notifications }}
      </div>
      <font-awesome-icon icon="bell" class="header-icon" />
    </button>
    <transition name="dialog-not">
      <NotificationDialog
        v-if="notificationDialog"
        :button-id="buttonId"
        @close="notificationDialog = false"
      />
    </transition>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  onUnmounted
} from 'vue';
import NotificationDialog from '@/components/Commons/NotificationDialog/NotificationDialog.vue';
import { generateUuid } from '@/utils/utils';
import useCommunicator from '@/composables/useCommunicator';
import { ok } from '@fhir/pixel-commons-js';
import { useI18n } from 'vue-i18n';
import useUser from '@/composables/useUser';
export default defineComponent({
  name: 'NotificationButton',
  components: { NotificationDialog },
  setup() {
    const notifications = computed(() => useCommunicator().newMessagesCount());
    const notificationDialog = ref<boolean>(false);
    const buttonId = ref(generateUuid());
    const refreshTime = Number(localStorage.getItem('refreshTime')) || 0;

    const token = computed(() => useUser().getToken());

    const i18n = useI18n();

    const lan = computed(() => i18n.locale.value);

    const init = () => {
      console.log('init communicator', token.value);
      if (ok(token.value)) {
        useCommunicator().connect(lan.value, token.value ?? '');
      }
    };

    let refreshInterval: undefined | number;

    onMounted(() => {
      init();
      if (refreshTime > 0) {
        refreshInterval = setInterval(() => {
          init();
        }, refreshTime * 1000);
      }
    });

    onUnmounted(() => clearInterval(refreshInterval));

    watch(token, v => init());
    watch(lan, v => init());

    return {
      notifications,
      notificationDialog,
      buttonId
    };
  }
});
</script>

<style scoped lang="scss">
.notification-container {
  position: relative !important;
}

.dialog-notification-enter-active,
.dialog-notification-leave-active {
  transition: all 0.8s;
}
.dialog-notification-enter, .dialog-notification-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.user-profile-icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  margin-right: var(--app-base-space);
  border: 1px solid transparent;
  transition: transform 0.15s ease, color 0.15s ease;
  position: relative;

  &__circle {
    position: absolute;
    width: 24px;
    max-width: 24px;
    height: 24px;
    top: -1px;
    right: -1px;
    border-radius: 50%;
    border: 3px solid var(--main-color);
    font-size: 0.75rem;
    background-color: #fff;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.icon {
    color: var(--main-color);
  }

  &:hover {
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
    border: 1px solid transparent;
  }
  &:focus-visible {
    border: 1px solid var(--accent);
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header-icon {
  font-size: 1.675rem;
}
</style>
