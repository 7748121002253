
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import LoggedUserInfo from '@/components/User/LoggedUserInfo.vue';
import PatientFilters from '@/components/Patient/PatientFilters.vue';
import AdminFilters from '@/components/Patient/AdminFilters.vue';
import useUser from '@/composables/useUser';

export default {
  name: 'Sidebar',
  components: { AdminFilters, PatientFilters, LoggedUserInfo },
  events: ['get-width'],
  emits: ['get-width'],
  props: {
    hiddenActive: {
      type: Boolean,
      required: true
    }
  },
  setup(props: any, { emit }: any) {
    const tokenExist = computed(() => useUser().tokenExist());
    const admin = computed(() => useUser().isAdmin());

    const windowWidth = ref<number>(window.innerWidth);

    const onWidthChange = (): number => (windowWidth.value = window.innerWidth);
    onMounted((): void => window.addEventListener('resize', onWidthChange));
    onUnmounted((): void =>
      window.removeEventListener('resize', onWidthChange)
    );

    const hiddenActiveProps = ref(props.hiddenActive);

    const type = computed((): string | undefined => {
      if (windowWidth.value <= 800) return 'mobile';
      if (windowWidth.value > 801) return 'pc';
      return 'pc';
    });

    const width = computed(() => windowWidth.value);

    watch(width, w => {
      emit('get-width', w);
    });

    return { width, type, hiddenActiveProps, admin, tokenExist };
  }
};
