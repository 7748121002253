<template>
  <div class="message">
    <div class="message__items">
      <span class="message__items__date">{{ message.date }}</span>
      <span class="message__items__message">{{ message.resource.msg }}</span>
      <!--
      :to="{
          name: 'GeneratePdf',
          params: { id: docId }
        }"
      -->
      <router-link
        class="message__items__link"
        :to="`/generate-pdf/${docId}`"
        target="_blank"
        v-if="isDocShared"
      >
        <span v-if="docName">{{ docName }}</span>
        <span v-else>{{ $t('base.document') }}</span>
        <font-awesome-icon class="ml" icon="angle-right"></font-awesome-icon>
      </router-link>
      <!--      <pre class="fa-xs">{{ message.resource.source }}</pre>-->
    </div>
    <BaseButton
      :title="$t('communicator.markRead')"
      class="message__button"
      @click="markAsRead(message.resource)"
    >
      <font-awesome-icon icon="check"></font-awesome-icon>
    </BaseButton>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import {
  MessageI,
  NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE
} from '@/composables/types/communicator';
import useCommunicator from '@/composables/useCommunicator';

export default defineComponent({
  name: 'NotificationItem',
  components: { BaseButton },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const markAsRead = (message: MessageI) => {
      useCommunicator().markAsRead(message);
    };

    const isDocShared = computed(
      () =>
        props.message?.resource?.source?.actionCode ===
        NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE
    );

    const docId = computed(() => {
      const args = props.message?.resource?.source?.descriptionArgs;
      return args && args.length > 0 ? args[0] : null;
    });

    const docName = computed(() => {
      const args = props.message?.resource?.source?.descriptionArgs;
      return args && args.length > 8 ? args[9] : null;
    });

    return {
      markAsRead,
      isDocShared,
      docId,
      docName
    };
  }
});
</script>

<style scoped lang="scss">
.message {
  display: grid;
  grid-template-columns: 1fr auto;
  flex-direction: column;
  background-color: var(--slight-light-gray);
  border-radius: var(--main-radius);
  font-size: 0.9rem;
  color: var(--font-color);

  &__button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &__items {
    display: flex;
    flex-direction: column;
    padding: 5px;

    &__date {
      font-weight: bold;
    }

    &__link {
      text-decoration: none;
      color: var(--main-color);
    }
  }
}

.message:hover {
  background-color: var(--light-gray);
}
</style>
