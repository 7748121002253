
import { computed, onMounted, ref, watch } from 'vue';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import FiltersTag from '@/components/Admin/FiltersTag.vue';
import BaseModal from '@/components/Base/BaseModal.vue';
import DateFilters from '@/components/Commons/DateFilters.vue';
import {
  SEX,
  MODALITIES,
  Filter,
  FILTER,
  FILTERING,
  Modality,
  ModalityList,
  TAGS,
  TEXT,
  FAVOURITES
} from '@/store/search/types';
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue';
import PatientFilters from '@/components/Patient/PatientFilters';
import { useI18n } from 'vue-i18n';
import BaseChip from '@/components/Base/BaseChip.vue';
import BaseSwitch from '@/components/Base/BaseSwitch.vue';
import PatientSearch from './PatientSearch.vue';

export default {
  name: 'AdminFilters',
  components: {
    BaseSwitch,
    BaseChip,
    BaseCheckboxGroup,
    DateFilters,
    BaseModal,
    FiltersTag,
    BaseButton,
    RadioButton,
    RadioGroup,
    ExpandablePanel,
    PatientSearch
  },
  setup() {
    let skipWatch = false;
    const { locale } = useI18n();
    const { dateFilter, processDateFilters, searchStore } = PatientFilters();
    const textSearch = ref<string>();
    const favourites = ref<boolean>();

    const isFiltering = computed(() => searchStore.getters[FILTERING]);

    const tags = ref<string[]>([]);

    const handleFavourites = (v: any) => {
      favourites.value = v;
    };

    watch(favourites, v => {
      searchStore.commit(FAVOURITES, v);
    });

    watch(textSearch, v => {
      searchStore.commit(TEXT, v);
    });

    const processTags = () =>
      searchStore.commit(
        TAGS,
        tags.value.map(m => ({ name: m }))
      );

    watch(
      tags,
      () => {
        if (skipWatch) {
          skipWatch = false;
          return;
        }
        processTags();
      },
      { deep: true }
    );

    const acceptInputValue = ref<string>('');

    const modalities = ref<ModalityList[]>(
      (window.modalities || []).map((m: any) => {
        return {
          modality: {
            id: m.FBID,
            label: m.friendlyName(locale.value)
          },
          checked: false
        };
      })
    );

    const selectedModalities = computed<Modality[]>(() =>
      modalities.value.filter(f => f.checked).map(m => m.modality)
    );
    const processModalities = () =>
      searchStore.commit(MODALITIES, selectedModalities.value);

    watch(selectedModalities, () => {
      if (skipWatch) {
        skipWatch = false;
        return;
      }
      processModalities();
    });

    const createFiltersModal = ref<boolean>(false);

    const sex = ref<number>(0);
    const sexItems = computed(() => {
      return [
        {
          id: 0,
          label: 'any'
        },
        {
          id: 1,
          label: 'woman'
        },
        {
          id: 2,
          label: 'man'
        }
      ];
    });

    const processSex = () => searchStore.commit(SEX, sex.value);

    watch(sex, v => {
      if (skipWatch) {
        skipWatch = false;
        return;
      }
      processSex();
    });

    const filter = computed<Filter>(() => searchStore.getters[FILTER]);
    //reagujemy na czyszczenie filtrów
    watch(
      filter,
      v => {
        if (v != null) {
          if (v.sex != null && v.sex !== sex.value) {
            skipWatch = true;
            sex.value = v.sex;
          }
          if (v.modality != null && v.modality.length === 0) {
            //skipWatch = true;
            modalities.value.forEach(e => (e.checked = false));
          }
          if (v.tags == null) {
            tags.value = [];
          }
          if (v.text == null) {
            textSearch.value = '';
          } else {
            textSearch.value = v.text;
          }
          if (v.favourites == null) {
            favourites.value = false;
          }
        }
      },
      { deep: true }
    );

    const addToTags = (value: string): void => {
      tags.value.push(value);
    };

    const deleteFromTags = (ind: number): void => {
      tags.value.splice(ind, 1);
    };

    const clearAllFilters = (): void => {
      dateFilter.value = { type: 1 };
      tags.value = [];
      modalities.value.forEach(e => (e.checked = false));
      sex.value = 0;
    };

    const acceptFilter = async () => {
      try {
        // TODO sprawdzic czy juz istnieje
        if (acceptInputValue.value.length > 3) {
          createFiltersModal.value = false;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const closeModalOnEnter = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        createFiltersModal.value = false;
      }
    };

    onMounted(() => processSex());

    return {
      dateFilter,
      processDateFilters,
      modalities,
      sexItems,
      sex,
      tags,
      addToTags,
      deleteFromTags,
      clearAllFilters,
      isFiltering,
      createFiltersModal,
      acceptFilter,
      acceptInputValue,
      closeModalOnEnter,
      textSearch,
      handleFavourites,
      favourites
    };
  }
};
