<template>
  <nav>
    <ul>
      <li>
        <router-link to="/patient">
          Pacjent
        </router-link>
      </li>
      <li>
        <router-link to="/admin">
          Admin
        </router-link>
      </li>
      <li>
        <router-link to="/terms-of-use">
          Regulamin
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Navigation'
};
</script>

<style scoped></style>
