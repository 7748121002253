import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import patientRoute from '@/router/routes/patient';
import sharedExaminationRoute from '@/router/routes/sharedExamination';
import termsOfUseRoute from '@/router/routes/termsOfUse';
import settingsRoute from '@/router/routes/settings';
import documentRoute from '@/router/routes/document';

const routes: Array<RouteRecordRaw> = [
  ...patientRoute,
  ...settingsRoute,
  ...termsOfUseRoute,
  ...documentRoute,
  ...sharedExaminationRoute,
  {
    path: '/generate-pdf/:resourceType/:resourceId',
    name: 'GeneratePdf',
    component: () => import('../pages/GeneratePdf.vue')
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import('../pages/Forbidden.vue')
  },
  {
    path: '/:catchAll(.*)*',
    name: '404-page',
    component: () => import('../pages/404-page.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
