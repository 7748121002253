<template>
  <div class="terms-of-use-title">
    <p :class="[uppercase ? 'uppercase' : '']">
      {{ activePage }}.
      <span><slot /></span>
    </p>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'TermsOfUseTitle',
  props: {
    page: {
      type: Number,
      default: 0,
      required: true
    },
    uppercase: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  setup(props, context) {
    const activePage = ref(props.page);

    return {
      activePage
    };
  }
};
</script>

<style lang="scss" scoped>
.terms-of-use-title {
  margin-top: 0.25rem;
  font-weight: bold;
  font-size: 1.25rem;

  .uppercase {
    text-transform: uppercase;
  }
}
</style>
