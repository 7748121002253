
import { defineComponent, computed, ref } from 'vue';
import { useStore as useSearchStore } from '@/store/search';
import {
  PATIENTS_NAMES,
  PATIENTS_BIRTH_DATES,
  PATIENTS_PESELS,
  FILTERING
} from '@/store/search/types';
import {
  allowLettersOnly,
  allowDigitsOnly,
  titleCaseSentence
} from '@/utils/utils';

export default defineComponent({
  name: 'PatientSearch',
  setup() {
    const searchStore = useSearchStore();

    const isFiltering = computed(() => searchStore.getters[FILTERING]);

    const peselSearchValue = ref<string>('');
    const nameSearchValue = ref<string>('');
    const birthDateSearchValue = ref<string>('');

    const addNameFilter = () => {
      const name = titleCaseSentence(nameSearchValue.value);
      searchStore.commit(PATIENTS_NAMES, name);
      nameSearchValue.value = '';
    };

    const addPeselFilter = () => {
      searchStore.commit(PATIENTS_PESELS, peselSearchValue.value);
      peselSearchValue.value = '';
    };

    const addBirthDateFilter = () => {
      searchStore.commit(PATIENTS_BIRTH_DATES, birthDateSearchValue.value);
      birthDateSearchValue.value = '';
    };

    const validatePatient = (inputName: string) => {
      if (inputName === 'name') {
        nameSearchValue.value = allowLettersOnly(nameSearchValue.value);
      } else if (inputName === 'pesel') {
        peselSearchValue.value.length > 11
          ? (peselSearchValue.value = peselSearchValue.value.slice(0, -1))
          : (peselSearchValue.value = allowDigitsOnly(peselSearchValue.value));
      }
    };

    return {
      validatePatient,
      peselSearchValue,
      birthDateSearchValue,
      addPeselFilter,
      addBirthDateFilter,
      addNameFilter,
      isFiltering,
      nameSearchValue
    };
  }
});
