<template>
  <button
    @click="handleClick"
    @keyup.enter="handleClick"
    @keyup.space="handleClick"
    class="bypass-block-link visuallyhidden visible-when-focused"
  >
    {{ $t(`accessibilityMenu.${activeText}`) }}
  </button>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'BypassBlock',
  emits: ['active-highlight'],
  props: {
    text: {
      type: String,
      required: true,
      default: 'skipToMainContent'
    },
    goTo: {
      type: String,
      required: true,
      default: 'main'
    }
  },
  setup(props, { emit }) {
    const handleClick = () => {
      document.getElementById(props.goTo).click();
      document.getElementById(props.goTo).focus();
      emit('active-highlight');
    };

    const activeText = ref(props.text);

    return {
      handleClick,
      activeText
    };
  }
};
</script>

<style lang="scss" scoped>
.visible-when-focused:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: absolute;
  white-space: normal;
  width: auto;
  margin: auto;
  transform: translate(0, 0);
}

.bypass-block-link {
  position: absolute;
  text-decoration: none;
  background: var(--yellow);
  padding: 20px;
  z-index: 999999;
  font-size: 1.6rem;
  color: var(--dark);
  font-weight: 700;
  border: 3px dashed var(--dark);
  top: -999;
  left: -999;
  cursor: pointer;
}
</style>
