
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'UserAvatar',
  setup() {
    const showImg = ref(true);
    const error = (e: Error) => {
      showImg.value = false;
    };
    return {
      error,
      showImg
    };
  }
});
