import { InjectionKey } from 'vue';
import { createStore, useStore as baseUseStore, Store } from 'vuex';
import {
  State,
  DateFilter,
  RESULTS,
  DATE_FILTER,
  FILTER,
  Filter,
  ResourceDates,
  SHARED,
  DATE,
  SEX,
  MODALITIES,
  Modality,
  FILTERING,
  PATIENTS_NAMES,
  PATIENTS_BIRTH_DATES,
  PATIENTS_PESELS,
  TAGS,
  Tag,
  TEXT,
  FAVOURITES,
  PATIENT
} from '@/store/search/types';

export const key: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  state: {
    filter: {},
    dates: [],
    date: null,
    isFiltering: false
  },
  mutations: {
    [PATIENT](state: State, patient: string | undefined) {
      state.filter.patient = patient;
    },
    [SHARED](state: State, sharedOnly: boolean) {
      state.filter.shared = sharedOnly;
    },
    [FILTERING](state: State, isFiltering: boolean) {
      state.isFiltering = isFiltering;
    },
    [PATIENTS_NAMES](state: State, value: string) {
      if (value === 'clear') return (state.filter.names = []);
      state.filter.names?.length
        ? state.filter.names.push(value)
        : (state.filter.names = [value]);
    },
    [PATIENTS_PESELS](state: State, value: string) {
      if (value === 'clear') return (state.filter.pesels = []);
      state.filter.pesels?.length
        ? state.filter.pesels.push(value)
        : (state.filter.pesels = [value]);
    },
    [PATIENTS_BIRTH_DATES](state: State, value: string) {
      if (value === 'clear') return (state.filter.birthDates = []);
      state.filter.birthDates?.length
        ? state.filter.birthDates.push(value)
        : (state.filter.birthDates = [value]);
    },
    [DATE_FILTER](state: State, dateFilter: DateFilter) {
      state.filter.date = dateFilter;
    },
    [RESULTS](state: State, results: Array<ResourceDates>) {
      state.dates = results;
      state.date = null;
    },
    [DATE](state: State, date: string | null) {
      state.date = date;
    },
    [SEX](state: State, sex: number | undefined) {
      state.filter.sex = sex;
    },
    [MODALITIES](state: State, modalities: Array<Modality> | undefined) {
      state.filter.modality = modalities;
    },
    [TAGS](state: State, tags: Array<Tag> | undefined) {
      state.filter.tags = tags;
    },
    [TEXT](state: State, text: string | undefined) {
      state.filter.text = text;
    },
    [FAVOURITES](state: State, favourites: boolean | undefined) {
      state.filter.favourites = favourites;
    }
  },
  getters: {
    [PATIENT](state: State): string | undefined {
      return state.filter.patient;
    },
    [SHARED](state: State): boolean | undefined {
      return state.filter.shared;
    },
    [PATIENTS_NAMES](state: State): string[] {
      return state.filter.names ? state.filter.names : [];
    },
    [PATIENTS_PESELS](state: State): string[] {
      return state.filter.pesels ? state.filter.pesels : [];
    },
    [PATIENTS_BIRTH_DATES](state: State): string[] {
      return state.filter.birthDates ? state.filter.birthDates : [];
    },
    [DATE_FILTER](state: State): DateFilter | undefined {
      return state.filter.date;
    },
    [FILTERING](state: State): boolean {
      return state.isFiltering;
    },
    [FILTER](state: State): Filter {
      return state.filter;
    },
    [RESULTS](state: State): Array<ResourceDates> {
      return state.dates;
    },
    [DATE](state: State): string | undefined | null {
      return state.date;
    },
    [SEX](state: State): number | undefined {
      return state.filter.sex;
    },
    [MODALITIES](state: State): Array<Modality> | undefined {
      return state.filter.modality;
    },
    [TAGS](state: State): Array<Tag> | undefined {
      return state.filter.tags;
    },
    [TEXT](state: State): string | undefined {
      return state.filter.text;
    },
    [FAVOURITES](state: State): boolean | undefined {
      return state.filter.favourites;
    }
  }
});

export function useStore(): Store<State> {
  return baseUseStore(key);
}
