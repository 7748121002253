<template>
  <button
    :class="[
      'accessible-item',
      item.disabled ? 'accessible-item--disabled' : ''
    ]"
    @click="changeState(item.action)"
    :disabled="item.disabled"
  >
    <span
      class="wrapper-acc-menu-radio"
      role="radiogroup"
      :aria-labelledby="item.icon"
    >
      <span :id="item.icon" class="visuallyhidden">{{ $t(item.text) }}</span>
      <span
        role="radio"
        :aria-checked="radioState === idx"
        v-for="(it, idx) in getSize(item.action)"
        :key="`${item.title}-${idx}`"
        :class="[
          'acc-menu-radio',
          radioState === idx ? 'selected' : '',
          item.disabled ? 'acc-menu-radio--disabled' : ''
        ]"
      >
        <span class="visuallyhidden" v-if="item.translations[idx]">
          {{ $t(item.translations[idx]) }}
        </span>
      </span>
    </span>

    <span>
      <template v-if="item.action === Actions.theme">
        <font-awesome-icon
          v-if="radioState === Themes.bright"
          icon="moon"
          :class="['icon', item.disabled ? 'icon--disabled' : '']"
        />
        <font-awesome-icon
          v-else
          icon="sun"
          :class="['icon', item.disabled ? 'icon--disabled' : '']"
        />
      </template>

      <template v-else-if="item.icon === 'lang'">
        <span
          :class="['lang-icon', item.disabled ? 'lang-icon--disabled' : '']"
        >
          {{ Object.keys(LangsValues)[radioState] }}
        </span>
      </template>
      <font-awesome-icon
        v-else
        :icon="item.icon"
        :class="['icon', item.disabled ? 'icon--disabled' : '']"
      />
    </span>

    <span :class="['item-title', item.disabled ? 'item-title--disabled' : '']">
      {{ $t(item.title) }}
    </span>
  </button>
</template>

<script lang="ts">
import {
  Actions,
  ContrastLevels,
  ContrastLevelsValues,
  enumSizes,
  FontLevels,
  FontLevelsWithValues,
  Langs,
  LangsValues,
  Links,
  LinksValues,
  LS,
  MousePointer,
  MousePointerValues,
  Themes,
  ThemesValues
} from '@/components/Commons/AccessibilityMenu/AccessibilityMenuUtils';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';

import { defineComponent, ref } from 'vue';

import {
  changeContrast,
  changeFontSize,
  changeLang,
  changeLinks,
  changeMousePointer,
  changeTheme,
  setELOnLoad
} from '@/components/Commons/AccessibilityMenu/AccessibilityActions';

export default defineComponent({
  name: 'AccessibilityMenuItem',
  components: { RadioGroup, RadioButton },
  props: {
    item: {
      required: true,
      default: Object
    }
  },
  setup(props) {
    const radioState = ref(0);
    let timeoutInterval = 0;
    let timeoutIntervalContrast = 0;
    let timeoutIntervalMousePointer = 0;

    if (props.item.action === Actions.fontSize) {
      radioState.value = setELOnLoad(
        radioState.value,
        LS.fontSize,
        FontLevelsWithValues,
        changeFontSize,
        FontLevels.medium
      );
    }

    if (props.item.action === Actions.theme) {
      radioState.value = setELOnLoad(
        radioState.value,
        LS.theme,
        ThemesValues,
        changeTheme,
        Themes.bright
      );
    }

    if (props.item.action === Actions.langs) {
      radioState.value = setELOnLoad(
        radioState.value,
        LS.langs,
        LangsValues,
        changeLang,
        Langs.pl
      );
    }

    if (props.item.action === Actions.links) {
      timeoutInterval = setTimeout(() => {
        radioState.value = setELOnLoad(
          radioState.value,
          LS.links,
          LinksValues,
          changeLinks,
          Links.off
        );
      }, 0);
    }

    if (props.item.action === Actions.contrast) {
      timeoutIntervalContrast = setTimeout(() => {
        radioState.value = setELOnLoad(
          radioState.value,
          LS.contrast,
          ContrastLevelsValues,
          changeContrast,
          ContrastLevels.base
        );
      }, 0);
    }

    if (props.item.action === Actions.mousePointer) {
      timeoutIntervalMousePointer = setTimeout(() => {
        radioState.value = setELOnLoad(
          radioState.value,
          LS.mousePointer,
          MousePointerValues,
          changeMousePointer,
          MousePointer.base
        );
      }, 0);
    }

    const changeState = (type: Actions): void => {
      radioState.value++;

      // if value is out of the enum size, set radio on initial index
      const idx = enumSizes[Actions[type]];
      if (radioState.value >= idx) {
        radioState.value = 0;
      }

      if (type === Actions.fontSize) {
        changeFontSize(radioState.value);
      }
      if (type === Actions.contrast) {
        changeContrast(radioState.value);
        clearInterval(timeoutIntervalContrast);
      }
      if (type === Actions.theme) {
        changeTheme(radioState.value);
      }
      if (type === Actions.links) {
        changeLinks(radioState.value);
        clearInterval(timeoutInterval);
      }
      if (type === Actions.langs) {
        changeLang(radioState.value);
      }

      if (type === Actions.mousePointer) {
        changeMousePointer(radioState.value);
        clearInterval(timeoutIntervalMousePointer);
      }
    };

    const getSize = (type: Actions): number => {
      return enumSizes[Actions[type]];
    };

    return {
      radioState,
      changeState,
      getSize,
      LangsValues,
      Langs,
      Actions,
      Themes
    };
  }
});
</script>

<style scoped lang="scss">
.accessible-item {
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: var(--border-radius);
  width: 100px;
  height: 100px;
  cursor: pointer;

  &:focus {
    outline: solid 2px var(--accent);
  }

  &:hover {
    border: 1px solid var(--light);
  }

  &--disabled {
    cursor: auto;
    &:focus {
      outline: none;
    }
    &:focus-visible {
      border: 1px solid transparent;
    }
    &:hover {
      border: 1px solid transparent;
    }
  }
}

.icon {
  color: var(--main-color);
  margin-left: 2px;
  margin-right: 2px;
  font-size: 2rem;
  &--disabled {
    color: var(--darker-gray);
  }
}

.item-title {
  font-size: 1rem;
  color: var(--main-color);
  font-weight: 600;
  display: block;
  &--disabled {
    color: var(--darker-gray);
  }
}

.wrapper-acc-menu-radio {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
}
.acc-menu-radio {
  height: 3px;
  width: 12px;
  display: block;
  background-color: var(--darker-gray);
  border-radius: var(--border-radius);
  margin-left: 1px;
  margin-right: 1px;
}

.acc-menu-radio.selected {
  background-color: var(--accent);
}

.acc-menu-radio--disabled {
  background-color: var(--dark-gray) !important;
}

.lang-icon {
  color: var(--main-color);
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.75rem;
  display: inline-block;
  &--disabled {
    color: var(--darker-gray);
  }
}
</style>
