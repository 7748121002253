<template>
  <div class="hamburger-inside" id="hamburger-inside">
    <FocusLoop>
      <div class="hamburger-inside-button">
        <span class="hamburger-inside-button-title" v-html="radBox"></span>
        <button class="hamburger-inside-button-close" @click="closeHamburger">
          <font-awesome-icon icon="times" width="14" />
        </button>
      </div>
      <LoggedUserInfo />
      <!-- <div class="hamburger-inside-icons">
        <button
          class="user-profile-icon icon"
          :title="$t('Header.openTaskList')"
        >
          <span class="visuallyhidden">{{ $t('Header.openTaskList') }}</span>
          <font-awesome-icon icon="clipboard-list" class="fa-icon list" height="28" />
          <p>{{ $t('Header.taskList') }}</p>
        </button>
        <button
          class="user-profile-icon icon"
          :title="$t('Header.openNotifications')"
        >
          <span class="visuallyhidden">
            {{ $t('Header.openNotifications') }}
          </span>
          <font-awesome-icon icon="bell" class="fa-icon" height="28" />
          <p>{{ $t('Header.notifications') }}</p>
        </button>
        <button
          class="user-profile-icon icon"
          :title="$t('Header.openSettings')"
        >
          <span class="visuallyhidden">{{ $t('Header.openSettings') }}</span>
          <font-awesome-icon icon="cog" class="fa-icon" height="28" />
          <p>{{ $t('Header.settings') }}</p>
        </button>
        <button
          class="user-profile-icon icon"
          :title="$t('Header.openMoreOptions')"
        >
          <span class="visuallyhidden">{{ $t('Header.openMoreOptions') }}</span>
          <span v-html="moreIcons"></span>
          <p>{{ $t('Header.moreOptions') }}</p>
        </button>
      </div> -->
      <p class="title-filters">{{ $t('Header.filters') }}</p>
      <template v-if="tokenExist">
        <AdminFilters v-show="admin" />
        <PatientFilters v-show="!admin" />
      </template>
    </FocusLoop>
  </div>
</template>

<script lang="ts">
import radBox from '@/assets/alleradVbox.svg';
import moreIcons from '@/assets/more-icons.svg';
import { computed, watch } from 'vue';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';
import FullTextSearch from '@/components/FullTextSearch/FullTextSearch.vue';
import LoggedUserInfo from '@/components/User/LoggedUserInfo.vue';
import AdminFilters from '@/components/Patient/AdminFilters.vue';
import PatientFilters from '@/components/Patient/PatientFilters.vue';
import useUser from '@/composables/useUser';

export default {
  name: 'HamburgerMenu',
  emits: ['close-hamburger'],
  components: {
    PatientFilters,
    AdminFilters,
    FullTextSearch,
    LoggedUserInfo,
    FocusLoop
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props: any, { emit }: any) {
    const tokenExist = computed(() => useUser().tokenExist());
    const admin = computed(() => useUser().isAdmin());

    watch(
      () => props.isOpen,
      v => {
        if (v) {
          document.body.classList.add('modal');
          document.getElementById('hamburger-inside')?.focus();
        } else {
          document.body.classList.remove('modal');
          document.getElementById('hamburger-menu-button')?.focus();
        }
      }
    );

    const closeHamburger = (): void => {
      emit('close-hamburger');
    };

    return {
      closeHamburger,
      radBox,
      moreIcons,
      tokenExist,
      admin
    };
  }
};
</script>

<style scoped lang="scss">
.hamburger-inside {
  max-width: 100%;
  max-height: 100vh;
  min-height: 100vh;
  background-color: #fff;
  overflow-y: scroll;
  z-index: 5;

  &-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;

    &-title {
      margin-top: 12px;
      margin-left: 6%;
    }

    &-close {
      margin: 12px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  &-icons {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    button {
      width: 95%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--main-color);
      height: 40px;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid var(--medium-gray);
      cursor: pointer;

      .fa-icon {
        margin-left: 12px;
        &.list {
          margin-left: 15px;
        }
      }

      span {
        margin-left: 9px;
      }

      p {
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 100%;
      }
    }
  }

  .title-filters {
    margin-top: 1rem;
    text-align: center;
    font-weight: bold;
    color: var(--main-color);
  }
}
</style>
