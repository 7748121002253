import './styles/css/normalize.css';
import { createApp, provide } from 'vue';
import App from './App.vue';
import router from './router';
import store, { key } from './store';

import serachStore, { key as serachStoreKey } from './store/search/index';
import studyShareStore, {
  key as studyShareStoreKey
} from './store/studyShare/index';
import i18n from './lang';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './utils/font-awesome';
import DynarolesPlugin from './plugins/dynarolesPlugin';

createApp(App)
  .use(store, key)
  .use(serachStore, serachStoreKey)
  .use(studyShareStore, studyShareStoreKey)
  .use(router)
  .use(i18n)
  .use(DynarolesPlugin)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
