
import DatePicker from '@/components/Date/datepicker.js';
import { onMounted, ref, defineComponent, watch, computed } from 'vue';
import { formatDate } from '@fhir/pixel-commons-js';
import { generateUuid } from '@/utils/utils';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'AccessibleDatePicker',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const value = ref<string>(
      props.modelValue || formatDate(new Date(), 'dd/MM/yyyy')
    );

    watch(value, v => emit('update:modelValue', v));
    watch(
      () => props.modelValue,
      v => (value.value = v)
    );
    const uuid = computed(() => generateUuid());

    const loadCalendar = (id: string, i18n: (a: string) => string): void => {
      const datePickers = document.querySelectorAll(`#${id}`);

      datePickers.forEach(function(dp) {
        const inputNode = dp.querySelector('input');
        const buttonNode = dp.querySelector('button');
        const dialogNode = dp.querySelector('[role=dialog]');

        const datePicker = new DatePicker(
          inputNode,
          buttonNode,
          dialogNode,
          i18n
        );
        datePicker.init();
      });
    };

    const { t } = useI18n();

    onMounted(() => {
      loadCalendar(uuid.value, t);
    });
    return {
      value,
      uuid
    };
  }
});
