
import { defineComponent, ref, watch, computed } from 'vue';
import { generateUuid } from '@/utils/utils';

export default defineComponent({
  name: 'RadioButton',
  props: {
    first: {
      type: Boolean,
      required: true,
      default: false
    },
    name: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    modelValue: {
      type: [String, Number],
      required: false
    },
    value: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const changevalue = (): void => emit('update:modelValue', props.value);
    const uuid = computed(() => generateUuid());

    return {
      changevalue,
      uuid
    };
  }
});
