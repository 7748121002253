export interface PatientsType {
  id: number | string;
  name: string;
  surname: string;
  identifier: number;
}

export interface ResearchesType {
  id: string | number;
  researchName: string;
  date?: string;
  patient: PatientsType;
}

export enum AccessType {
  Patient = 'Patient',
  Admin = 'Admin'
}

export enum AccessWhere {
  Next = 'next',
  Before = 'before'
}
