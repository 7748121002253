export enum NotificationType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'danger',
  WARNING_MODE_WITH_ACCEPT = 'warning-mode-with-accept'
}

export enum NotificationTypeIcon {
  INFO = 'info',
  SUCCESS = 'check',
  WARNING = 'exclamation',
  ERROR = 'exclamation'
}

export interface NotificationI {
  uid: string;
  type: NotificationType;
  title: string;
  closable: boolean;
  delay: number;
  icon: NotificationTypeIcon;
  msg: string;
}

export class NotificationImpl implements NotificationI {
  uid = '';
  type = NotificationType.INFO;
  title: string;
  closable = true;
  delay = 5000;
  icon = NotificationTypeIcon.INFO;
  msg: string;

  constructor(
    type: NotificationType,
    title: string,
    closable: boolean,
    delay: number,
    icon: NotificationTypeIcon,
    msg: string
  ) {
    this.uid = (Math.random() * 1000 * Date.now()).toString();
    this.type = type;
    this.title = title;
    this.closable = closable;
    this.delay = delay;
    this.icon = icon;
    this.msg = msg;
  }
}
export interface NotificationStateI {
  notifications: Array<NotificationI>;
}
