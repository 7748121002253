<template>
  <div class="button-group"><slot></slot></div>
</template>

<script>
export default {
  name: 'BaseButtonGroup'
};
</script>

<style scoped></style>
