export interface DateFilter {
  type?: number;
  date?: string;
  dateFrom?: string;
  dateTo?: string;
}

export interface DocumentTag {
  code?: string;
  system?: string;
}

export interface SingleResource {
  category: object[];
  code: object;
  comment: string;
  effectiveDateTime: string;
  component: object[];
  id: string;
  issued: string;
  meta: object;
  performer: object[];
  resourceType: string;
  status: string;
  subject: object;
}

export type Resource = PagedResourceOnlyDocument | OtherResource;

export interface PagedResourceOnlyDocument {
  consentor: string | undefined;
  date: string;
  description: string;
  doctor: string;
  id: string;
  patient: string;
  resource: SingleResource;
  ts: number;
  uuid: string;
}

export interface OtherResource {
  date: string;
  description: string;
  doctor: string;
  resource: SingleResource;
  ts: number;
  uuid: string;
}

export interface Filter {
  date?: DateFilter;
  sex?: number | undefined;
  text?: string | undefined;
  patient?: string | undefined;
  favourites?: boolean | undefined;
  shared?: boolean | undefined;
  names?: string[];
  pesels?: string[];
  birthDates?: string[];
  tags?: Array<Tag>;
  modality?: Array<Modality> | undefined;
}

export interface ResourceDates {
  ts: number | undefined;
  date: string;
  display: string;
}

export interface State {
  filter: Filter;
  dates: Array<ResourceDates>;
  date: string | null;
  isFiltering: boolean;
}

export interface Tag {
  name: string;
}

export interface Modality {
  label: string;
  id: string;
}

export interface ModalityList {
  modality: Modality;
  checked: boolean;
}

export const PATIENT = 'patient';
export const PATIENTS_NAMES = 'names';
export const SHARED = 'shared';
export const PATIENTS_BIRTH_DATES = 'birthDates';
export const PATIENTS_PESELS = 'pesels';
export const DATE = 'date';
export const RESULTS = 'results';
export const DATE_FILTER = 'dateFilter';
export const SEX = 'sex';
export const MODALITIES = 'modalities';
export const TEXT = 'text';
export const FILTER = 'filter';
export const FILTERING = 'filtering';
export const TAGS = 'tags';
export const FAVOURITES = 'favourites';
