<template>
  <span class="radiobutton-container">
    <label class="radio-label" :for="uuid">
      <input
        type="radio"
        role="radio"
        class="radiobutton"
        @input="changevalue"
        :name="name"
        :value="value"
        :id="uuid"
        :checked="value === modelValue"
        :tabindex="
          value === modelValue || (modelValue == null && first) ? 0 : -1
        "
        :aria-selected="value === modelValue"
      />
      <span class="radiobutton-toggle"></span>
      <span class="radio-name">{{ label }}</span>
    </label>
    <slot></slot>
  </span>
  <slot name="additional"></slot>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue';
import { generateUuid } from '@/utils/utils';

export default defineComponent({
  name: 'RadioButton',
  props: {
    first: {
      type: Boolean,
      required: true,
      default: false
    },
    name: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    modelValue: {
      type: [String, Number],
      required: false
    },
    value: {
      type: [String, Number],
      required: true
    }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const changevalue = (): void => emit('update:modelValue', props.value);
    const uuid = computed(() => generateUuid());

    return {
      changevalue,
      uuid
    };
  }
});
</script>

<style scoped lang="scss">
.radiobutton-container {
  padding: 2px;
  padding-left: 5px;

  display: flex;
  align-items: center;
}

.radiobutton {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radiobutton-toggle {
  width: 1rem;
  height: 1rem;
  top: 0.1rem;
  border-radius: 50%;
  position: relative;
  background-color: var(--light);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--dark-gray);
}

.radiobutton:checked ~ .radiobutton-toggle {
  background-color: var(--main-color) !important;
  border: 2px solid var(--main-color);
}

.radiobutton:checked ~ .radiobutton-toggle:after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  background: var(--light);
}

.radiobutton:hover ~ .radiobutton-toggle {
  background-color: var(--light-gray);
}

.radio-name {
  padding: 2px;
  margin-left: 3px;
  color: var(--font-color);
  border-radius: var(--main-radius);
}

.radiobutton:checked ~ .radio-name {
  //background-color: var(--light-gray);
}

.radio-label {
  cursor: pointer;
}
</style>
