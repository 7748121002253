<template>
  <div
    :class="['accessibility', showAccessibilityMenu ? 'show' : '']"
    @keyup.esc="showAccessibilityMenu = false"
  >
    <slot />
    <button
      :class="['accessibility-button', showAccessibilityMenu ? 'show' : '']"
      :title="`${$t('base.open')} ${$t('accessibilityMenu.accessibilityMenu')}`"
      @click="showAccessibilityMenu = true"
      v-if="!showAccessibilityMenu"
      aria-labelledby="accessibilityMenu"
      id="accessibilityMenu"
    >
      <font-awesome-icon icon="universal-access" class="icon-acc" />
    </button>
    <div
      v-show="showAccessibilityMenu"
      class="accessibility-menu"
      aria-describedby="AccessibilityDescription"
      :aria-modal="showAccessibilityMenu"
    >
      <span class="visuallyhidden" id="AccessibilityDescription">
        {{ $t('accessibilityMenu.ariaDescribyText') }}
      </span>
      <FocusLoop>
        <p class="accessibility-menu-title">
          {{ $t('accessibilityMenu.accessibilityMenu') }}
        </p>
        <button
          :title="
            `${$t('base.close')} ${$t('accessibilityMenu.accessibilityMenu')}`
          "
          class="close-menu"
          @click="showAccessibilityMenu = false"
        >
          <font-awesome-icon
            icon="times"
            :height="20"
            width="20"
            class="icon-close"
          />
        </button>
        <AccessibilityMenu class="accessibility-menu-items" />
      </FocusLoop>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import AccessibilityMenu from '@/components/Commons/AccessibilityMenu/AccessibilityMenu';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';

export default {
  name: 'AccessibilityMenuButton',
  components: { AccessibilityMenu, FocusLoop },
  setup() {
    const showAccessibilityMenu = ref(false);

    watch(showAccessibilityMenu, x => {
      if (!x) {
        setTimeout(() => {
          document.getElementById('accessibilityMenu').focus();
          console.log('focus');
        }, 0);
      }
    });
    return {
      showAccessibilityMenu
    };
  }
};
</script>

<style lang="scss" scoped>
.accessibility {
  position: fixed;
  bottom: 12px;
  right: 0px;
  border-radius: 5%;
  border: 3px solid transparent;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  z-index: 10;

  &.show {
    border-radius: 1%;
    right: 2%;
    bottom: 2%;
    width: 320px;
    height: 500px;
  }
}

.accessibility-button {
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 5%;
  background-color: transparent;
  border: 3px solid transparent;
  //transition: 0.3s;

  &.show {
    border-radius: 0;
  }
}
.icon-acc {
  color: var(--main-color);
  padding-right: 8px;
  padding-bottom: 8px;
  font-size: 3rem;
}

.accessibility-menu {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  padding-top: 2rem;

  &:first-child {
    box-shadow: 0 0 4px 0 #171717;
  }

  &-title {
    position: absolute;
    font-weight: bold;
    text-wrap: normal;
    font-size: 1.25rem;
    top: 12%;
    width: 100%;
    margin-bottom: 1rem;
    text-align: center;
    color: var(--main-color);
  }

  &-items {
    margin-top: 10%;
    height: 80%;
  }
}

.close-menu {
  position: absolute;
  right: 3.5%;
  top: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0.785rem;
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--light);

  &:hover {
    border: 1px solid var(--light);
  }
  &:focus {
    outline: none;
    border: 1px solid transparent;
  }
  &:focus-visible {
    border: 1px solid var(--accent);
  }
}
</style>
