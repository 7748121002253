export default {
  name: 'Settings',
  desc: 'User settings panel',
  refresh: {
    name: 'Refreshing documents list',
    dontRefresh: "Don't refresh",
    refreshEvery30: 'Refresh every 30 seconds',
    refreshEvery45: 'Refresh every 45 seconds',
    refreshEvery60: 'Refresh every 1 minute',
    refreshEvery90: 'Refresh every 1 minute and 30 seconds',
    refreshEvery120: 'Refresh every 2 minutes'
  },
  avatar: 'Change profile image',
  changePass: 'Change password'
};
