<template>
  <BaseModal
    v-model:value="modal"
    show-fader
    :hide-on-click-outside="false"
    :show-close="false"
    :last-element-clicked="''"
  >
    <template #header>{{ $t('base.logoutFromApp') }}</template>
    <div class="wrapper">
      <p class="logout-text">{{ $t('base.loggedOutText') }}</p>

      <BaseButton class="login-again-btn" @click="handleReload" type="cta">
        {{ $t('base.logInAgain') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '../../Base/BaseModal';
import BaseButton from '../../Base/BaseButton';

import { computed, ref, watch } from 'vue';
import useUser from '@/composables/useUser';
export default {
  name: 'LogoutInfo',
  components: { BaseButton, BaseModal },
  setup() {
    const modal = ref(false);
    const is401 = computed(() => useUser().is401());

    watch(
      () => is401.value,
      () => (modal.value = true)
    );

    const handleReload = () => window.location.reload();

    return {
      modal,
      handleReload
    };
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-top: 20px;
  text-align: center;
}
.login-again-btn {
  margin-top: 30px;
  padding: 10px 30px;
}
.logout-text {
  font-size: 1.3rem;
}
</style>
