export default {
  sharedAmountInfo: 'Ilość osób, dla których udostępnione zostało badanie',
  linkGeneratedInfo: 'Link do badania został wygenerowany',
  favouriteStudyInfo: 'Badanie zostało oznaczone jako ulubione',
  filters: {
    clearFilters: 'Wyczyść filtry',
    takeFilters: 'Użyj filtry',
    placeholderSearchFilters: 'Wyszukaj badanie / pacjenta / lekarza...',
    tags: 'Tagi',
    searchByTag: 'Szukaj po tagu',
    criterion: 'Kryteria',
    sex: 'Płeć',
    searchForPatient: 'Wyszukaj pacjenta',
    searchByName: 'Wprowadź imię i/lub nazwisko',
    searchByBirthDate: 'Wprowadź datę urodzenia',
    searchByPesel: 'Wprowadź pesel',
    text: 'Wyszukiwanie tekstowe',
    patients: 'Pacjenci',
    pesel: 'Pesel',
    birthDates: 'Daty urodzin',
    favouriteResearch: 'Ulubione badania',
    showFavourites: 'Pokaż ulubione',
    enterNewFilter: 'Wprowadź nowe filtry',
    saveFilters: 'Zapisz filtry',
    add: 'Dodaj',
    any: 'Dowolna',
    patientValidation:
      'Pacjent jest już wyszukiwany lub podano za małą ilość znaków',
    tagValidation: 'Tag został już użyty lub jest za krótki',
    woman: 'Kobieta',
    man: 'Mężczyzna',
    clickToRemove: 'Kliknij aby usunąć tag',
    clickToAdd: 'Kliknij aby dodać tag',
    newTag: 'Dodaj tag'
  },
  results: {
    asFavourite: 'Zasób oznaczony jako ulubiony',
    notAsFavourite: 'Zasób usunięty z ulubionych',
    errorFavourite: 'Błąd oznaczania'
  }
};
