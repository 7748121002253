
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import UserAvatar from '@/components/User/UserAvatar.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import usePatient from '@/composables/usePatient';
import useUser from '@/composables/useUser';

export default {
  name: 'LoggedUserInfo',
  components: { UserAvatar, EcgLoader },
  setup() {
    const router = useRouter();

    const userName = computed(() => useUser().getUserName());

    const isAdmin = computed(() => useUser().isAdmin());

    const getSelectedTenant = computed(() => useUser().getSelectedTenant());

    const patient = computed(() => usePatient().getLoggedPatient());

    const patients = computed(() => usePatient().getPatients());

    const onlyOnePatient = computed(
      () => usePatient().getPatients().length < 2
    );

    return {
      userName,
      router,
      patients,
      isAdmin,
      getSelectedTenant,
      patient,
      onlyOnePatient
    };
  }
};
