
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue';
import { generateUuid } from '@/utils/utils';
import useCommunicator from '@/composables/useCommunicator';
import { sortBy } from 'lodash';
import { dateSeconds } from '@fhir/pixel-commons-js/src/datetime';
import { MessageI } from '@/composables/types/communicator';
import BaseButton from '@/components/Base/BaseButton.vue';
import NotificationItem from '@/components/Commons/NotificationDialog/NotificationItem.vue';
import BaseButtonGroup from '@/components/Base/BaseButtonGroup.vue';
import useUser from '@/composables/useUser';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'NotificationDialog',
  components: { BaseButtonGroup, NotificationItem, BaseButton },
  props: {
    buttonId: {
      type: String,
      required: true,
      default: '0'
    }
  },
  emits: ['close'],
  setup(props, context) {
    const close = () => {
      context.emit('close');
    };

    onMounted(() => {
      console.log(document.getElementById(props.buttonId));
      window.addEventListener('resize', close);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', close);
    });

    const dialogRef = ref<string>(generateUuid());

    const messages = computed(() => {
      const ret = useCommunicator().newMessages();
      return sortBy(ret, 'date')
        .reverse()
        .map(m => ({
          resource: m,
          date: dateSeconds(m.date)
        }));
    });

    const markAllRead = () => {
      useCommunicator().markAllRead();
    };

    const token = computed(() => useUser().getToken());
    const i18n = useI18n();
    const lan = computed(() => i18n.locale.value);

    const reload = () => {
      useCommunicator().connect(lan.value, token.value ?? '');
    };

    return {
      dialogRef,
      messages,
      markAllRead,
      reload
    };
  }
});
