export default function createShortText(
  item,
  shortTextProps,
  maxTextLengthProps
) {
  if (shortTextProps === true && item) {
    return item.length > parseInt(maxTextLengthProps)
      ? item.substring(0, parseInt(maxTextLengthProps)) + '...'
      : item;
  } else {
    return item;
  }
}
