<template>
  <button
    :disabled="isDisabled"
    :class="[
      `base-button__${type}`,
      uppercase ? 'uppercase' : '',
      showMoreWithIconAfterHover ? 'show-more' : '',
      round ? 'round' : ''
    ]"
    @click="$emit('click')"
    :id="buttonId"
  >
    <slot />
    <font-awesome-icon
      v-if="showMoreWithIconAfterHover"
      class="icon-more"
      icon="arrow-right"
      aria-hidden="true"
    />
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary',
      validator: value =>
        [
          'primary',
          'secondary_blue',
          'secondary_gray',
          'secondary_gray_light',
          'cancel',
          'cancel_light',
          'cta',
          'danger'
        ].includes(value)
    },
    uppercase: {
      type: Boolean,
      required: false,
      default: false
    },
    showMoreWithIconAfterHover: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonId: {
      type: String,
      required: false,
      default: '0'
    }
  },
  emits: ['click']
};
</script>

<style scoped lang="scss">
button {
  padding: var(--base-padding);
  border-radius: var(--main-radius);
  transition: 0.3s;
  border: 2px solid var(--primary);
  font-weight: 600;
  cursor: pointer;
  color: var(--font-color);
  &:disabled {
    background-color: var(--disabled);
    cursor: default;
  }
  &:active {
    transform: scale(0.95);
  }

  &:focus {
    outline: none;
    border: 2px solid transparent;
  }
  &:focus-visible {
    border: 2px solid var(--accent);
  }
}
.base-button__main {
  color: var(--light);
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
  font-weight: 500;

  &:disabled {
    background-color: var(--light-gray);
    border: 2px solid var(--light-gray);
  }
}

.base-button__primary {
  color: var(--light);
  background-color: var(--primary);
  border: 2px solid var(--primary);
  font-weight: 500;

  &:disabled {
    background-color: var(--light-gray);
    border: 2px solid var(--light-gray);
  }
}

.base-button__secondary_blue {
  color: var(--main-color);
  background-color: transparent;
  border: 2px solid var(--main-color);
  font-weight: 500;

  &:focus {
    border: 2px solid var(--main-color);
  }
  &:focus-visible {
    border: 2px solid var(--accent);
  }
}
.base-button__danger {
  color: var(--light);
  background-color: var(--danger);
  border: 2px solid transparent;
  font-weight: 500;
  &:focus {
    border: 2px solid transparent;
  }
  &:focus-visible {
    border: 2px solid var(--accent);
  }
}

.base-button__secondary_gray {
  color: var(--dark-gray);
  background-color: transparent;
  border: 2px solid var(--dark-gray);
  font-weight: 500;
}

.base-button__secondary_gray_light {
  color: var(--light-gray);
  background-color: transparent;
  border: 2px solid var(--light-gray);
  font-weight: 500;
}

.base-button__cancel {
  color: var(--dark-gray);
  text-decoration: underline;
  background-color: transparent;
  border: 2px solid transparent;
  font-weight: 500;
}
.base-button__cancel_light {
  color: var(--light-gray);
  text-decoration: underline;
  background-color: transparent;
  border: 2px solid transparent;
  font-weight: 500;
}

.base-button__cta {
  color: var(--primary);
  background-color: var(--accent);
  font-weight: 500;
  border-radius: 16px;

  transition: color 0.15s ease, transform 0.15s ease;
  border: 2px solid transparent;

  &:focus {
    outline: none;
    border: 2px solid transparent;
  }
  &:focus-visible {
    border: 2px solid var(--main-color);
  }

  //&:hover {
  //  color: var(--light);
  //  background-color: var(--light-gray);
  //  border: 2px solid var(--main-color);
  //}
  //&:disabled {
  //  color: var(--disabled-text);
  //  background-color: var(--primary-disabled);
  //  border: 2px solid var(--primary-disabled);
  //}
}

button.uppercase {
  text-transform: uppercase;
}

.show-more {
  overflow: hidden;
  position: relative;
  .icon-more {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(20px, -50%);
    transition: 0.1s transform ease-out;
    height: 2rem;
  }

  &:hover .icon-more {
    transform: translate(-3px, -50%);
  }
}
.round {
  border-radius: 50%;
  min-height: 55px;
  min-width: 55px;
}
/**
  16 px = 1rem
  12 px = 0.75rem
  // font-size 12px - tutaj 12px na remy

  padding 8px 16px
   - stany:
    - focus
    - active
    - disabled
    - hover

    - typy buttona: (props które przyjmie button - 'type')
     - base-button
     - base-button__ghost
     - base-button__call-to-action

   */

// loading-start
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  //width: 60px;
  //height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(30px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #ff1d5e;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// loading-end
</style>

<style>
.button-group button:first-child:not(:last-child):not(.btn-copy-to-clipboard) {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.button-group button:last-child:not(:first-child):not(.btn-copy-to-clipboard) {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.button-group
  button:not(:first-child):not(:last-child):not(.btn-copy-to-clipboard) {
  border-radius: 0px !important;
}
.button-group button:not(:first-child) {
  margin-left: 1px;
}
</style>
