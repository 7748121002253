<template>
  <div class="notification-system">
    <transition-group name="list" tag="div">
      <Notification
        v-for="n in notificationsList"
        :key="`${n.uid}`"
        :notification="n"
      />
    </transition-group>

    <transition name="fade">
      <div
        v-if="notificationsWithWarningModeAccept.length !== 0"
        class="mask-notification"
      />
    </transition>
    <Notification
      v-for="n in notificationsWithWarningModeAccept"
      :key="`${n.uid}-w-m`"
      :notification="n"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { NotificationI } from '@/composables/types/notification';
import Notification from '@/components/Base/Notification/Notification.vue';
import useNotification from '@/composables/useNotification';

export default defineComponent({
  name: 'NotificationSystem',
  components: { Notification },
  setup() {
    const notifications = computed(() => {
      const ns: Array<NotificationI> = useNotification().getNotifications();
      return ns.map(m => m).reverse();
    });

    const notificationsWithWarningModeAccept = computed(() =>
      notifications.value.filter(
        (item: NotificationI) => item.type === 'warning-mode-with-accept'
      )
    );

    const notificationsList = computed(() =>
      notifications.value.filter(
        (item: NotificationI) => item.type !== 'warning-mode-with-accept'
      )
    );

    return {
      notificationsWithWarningModeAccept,
      notificationsList
    };
  }
});
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(200px);
}

.notification-system {
  position: fixed;
  margin-top: 20px;
  z-index: 999;
  top: 10px;
  right: 0px;
  padding-right: 20px;
  width: 350px;
}

.notification-system > div {
  display: grid;
  justify-content: end;
  /*max-height: 105px;*/
}

.mask-notification {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
