import {
  NotificationI,
  NotificationImpl,
  NotificationStateI,
  NotificationType,
  NotificationTypeIcon
} from '@/composables/types/notification';
import { reactive } from 'vue';

const state: NotificationStateI = reactive({
  notifications: []
});

export default function useNotification(): {
  removeNotification: (notification: NotificationI) => void;
  getNotifications: () => Array<NotificationI>;
  addNotification: (type: NotificationType, title: string, msg: string) => void;
} {
  const getIcon = (type: NotificationType): NotificationTypeIcon => {
    if (type === NotificationType.SUCCESS) return NotificationTypeIcon.SUCCESS;
    if (type === NotificationType.ERROR) return NotificationTypeIcon.ERROR;
    if (type === NotificationType.WARNING) return NotificationTypeIcon.WARNING;
    if (type === NotificationType.INFO) return NotificationTypeIcon.INFO;
    return NotificationTypeIcon.INFO;
  };

  const addNotification = (
    type: NotificationType,
    title: string,
    msg?: string
  ) => {
    const delay =
      NotificationType.WARNING_MODE_WITH_ACCEPT === type ? 0 : 11000;

    state.notifications.push(
      new NotificationImpl(
        type,
        title,
        true,
        delay,
        getIcon(type),
        msg ? msg : ''
      )
    );
  };

  const removeNotification = (notification: NotificationI) => {
    const idx = state.notifications.indexOf(notification);
    if (idx >= 0) {
      state.notifications.splice(idx, 1);
    }
  };

  const getNotifications = () => state.notifications;

  return {
    addNotification,
    removeNotification,
    getNotifications
  };
}

// const success = (title: string): void => {
//   state.notifications.push(
//     new NotificationImpl(
//       NotificationType.SUCCESS,
//       title,
//       true,
//       delay,
//       NotificationTypeIcon.SUCCESS
//     )
//   );
// };
//
// const error = (title: string): void => {
//   state.notifications.push(
//     new NotificationImpl(
//       NotificationType.ERROR,
//       title,
//       true,
//       delay,
//       NotificationTypeIcon.ERROR
//     )
//   );
// };
//
// const warning = (title: string): void => {
//   state.notifications.push(
//     new NotificationImpl(
//       NotificationType.WARNING,
//       title,
//       true,
//       delay,
//       NotificationTypeIcon.WARNING
//     )
//   );
// };
//
// const info = (title: string): void => {
//   state.notifications.push(
//     new NotificationImpl(
//       NotificationType.INFO,
//       title,
//       true,
//       delay,
//       NotificationTypeIcon.INFO
//     )
//   );
// };
