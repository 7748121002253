<template>
  <header
    :class="[
      'wrapper-header',
      isSmaller ? 'smaller' : '',
      width < 1000 ? 'hidden' : ''
    ]"
  >
    <div
      class="hamburger-menu"
      v-if="width < 1000"
      @keyup="closeHamburgerOnEsc"
    >
      <button
        :title="$t('base.openMenu')"
        class="hamburger-menu-button"
        @click="hamburgerOpen = !hamburgerOpen"
        id="hamburger-menu-button"
      >
        <font-awesome-icon icon="bars" height="28" />
      </button>
      <transition name="fade">
        <dialog
          :open="hamburgerOpen"
          v-show="hamburgerOpen"
          class="hamburger-menu-dialog"
        >
          <HamburgerMenu
            @close-hamburger="hamburgerOpen = false"
            :is-open="hamburgerOpen"
          />
          <div class="hider-fader" @click="hamburgerOpen = false" />
        </dialog>
      </transition>
    </div>
    <button
      class="wrapper-logo"
      @click="handleRoute"
      :title="$t('base.goToMainPage')"
    >
      <span v-html="radBox"></span>
      <h1 :class="['logo-title', isSmaller ? 'smaller' : '']">
        <span>{{ $t('base.patientPortal') }}</span>
      </h1>
    </button>
    <FullTextSearch v-if="showSearch" :width="width" />
    <div class="wrapper-user">
      <!--      <button class="user-profile-icon icon" :title="$t('Header.openTaskList')">-->
      <!--        <span class="visuallyhidden">{{ $t('Header.openTaskList') }}</span>-->
      <!--        <font-awesome-icon icon="clipboard-list" height="28" />-->
      <!--      </button>-->
      <NotificationButton />

      <OauthWrapper
        id="component-oauth-authorizator-patient-portal"
        ref="oauthComponentPatientPortal"
        client-id="fhir-search"
        headers="Basic Zmhpci1zZWFyY2g6Zmhpci1zZWFyY2gtaGFzbG8="
        lang="pl"
        custom-endpoint-after-logout="portal-pacjenta"
        :logout-random-str="randomStr"
        @refresh-error="refreshError"
        @auth-data-user="authDataUser"
      >
        <button class="btn-logout" :title="$t('base.logout')" @click="logout">
          <!--          <span class="visuallyhidden">{{ $t('base.logout') }}</span>-->
          <font-awesome-icon icon="power-off" class="header-icon" />
        </button>
      </OauthWrapper>
    </div>
  </header>
</template>

<script lang="ts">
import Navigation from '@/layout/Navigation/Navigation.vue';
import { computed, onMounted, ref } from 'vue';
import OauthWrapper from '@fhir/oauth-components/src/components/OauthWrapper.vue';

import radBox from '@/assets/alleradVbox.svg';
import moreIcons from '@/assets/more-icons.svg';
import HamburgerMenu from '@/components/HamburgerMenu.vue';
import FullTextSearch from '@/components/FullTextSearch/FullTextSearch.vue';
import NotificationDialog from '@/components/Commons/NotificationDialog/NotificationDialog.vue';
import { generateUuid } from '@/utils/utils';
import useUser from '@/composables/useUser';
import NotificationButton from '@/components/Commons/NotificationDialog/NotificationButton.vue';
import router from '@/router';

export default {
  name: 'Header',
  components: {
    NotificationButton,
    NotificationDialog,
    FullTextSearch,
    HamburgerMenu,
    Navigation,
    OauthWrapper
  },
  props: {
    width: {
      type: Number,
      required: true,
      default: window.innerWidth
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['header-is-smaller'],
  setup(props: any, context: { emit: (arg0: string, arg1: boolean) => void }) {
    const isSmaller = ref(false);
    const randomStr = ref<string>('');
    const hamburgerOpen = ref<boolean>(false);
    const notifications = ref<number>(1);
    const notificationDialog = ref<boolean>(false);

    const logout = () => (randomStr.value = Date.now().toString());

    const buttonId = ref(generateUuid());

    onMounted(() => {
      const observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
          isSmaller.value = window.pageYOffset > 0;
          context.emit('header-is-smaller', isSmaller.value);
          document.querySelector('.patient-header')?.classList.add('isSmaller');
        } else {
          isSmaller.value = false;
          context.emit('header-is-smaller', isSmaller.value);
          document
            .querySelector('.patient-header')
            ?.classList.remove('isSmaller');
        }
      });

      observer.observe(
        document.getElementById('watch-observer-to-top') as HTMLElement
      );
    });

    const refreshError = () => {
      // TODO
      // userStore.commit(LOGIN_AFTER_401);
    };

    const authDataUser = async (ev: any) => {
      console.log('authDataUser', ev);
      useUser().setUserGroup(ev.user.groups);
      useUser().setUserName(ev.user.name);
      useUser().setUserToken(ev.token);
    };

    const userName = computed(() => useUser().getUserName());

    const closeHamburgerOnEsc = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        hamburgerOpen.value = false;
      }
    };

    const handleRoute = () => {
      router.push({ name: 'Patient' });
    };

    return {
      isSmaller,
      refreshError,
      authDataUser,
      userName,
      logout,
      randomStr,
      radBox,
      moreIcons,
      hamburgerOpen,
      closeHamburgerOnEsc,
      notifications,
      buttonId,
      notificationDialog,
      handleRoute
    };
  }
};
</script>

<style lang="scss" scoped>
.wrapper-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary);
  position: fixed;
  left: var(--app-margin-outer);
  right: var(--app-margin-outer);
  top: var(--app-header-top);
  box-shadow: var(--box-shadow);

  border: 1px solid transparent;
  height: var(--app-header-hight);
  border-radius: var(--border-radius);
  z-index: 2;
  transition: all 0.3s;
  color: var(--light);

  &.hidden {
    height: 60px;
    border: solid 1px transparent;
    border-radius: 0px;
    left: 0px;
    top: 0px;
    right: 0px;
    width: unset;
    box-shadow: var(--box-shadow);

    .wrapper-logo {
      margin-left: 12px;
      margin-right: auto;
    }
    .hamburger-menu {
      margin-left: 12px;
      .hamburger-menu-button {
        background-color: transparent;
        color: var(--main-color);
        padding: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid transparent;
        cursor: pointer;
        &:focus {
          border: 1px solid var(--accent);
        }
      }
    }

    .logo-title {
      visibility: hidden;
      position: absolute;
    }

    .wrapper-user {
      width: auto;
      .icon {
        //visibility: hidden;
        //position: absolute;
      }
    }

    .hamburger-menu-dialog {
      position: fixed;
      left: 0;
      top: 0;
      width: 360px;
      border: none;
      min-height: 100vh;
      background-color: var(--light);
      border-right: 1px solid var(--medium-gray);

      .hider-fader {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: -5;
      }
    }
  }
}

.logo-title {
  transition: font-size 0.2s ease-in-out;
  font-size: 1rem;
  margin-left: var(--app-base-space);
  margin-top: auto;
  color: var(--logo);
}

.wrapper-header.smaller {
  height: 50px;
  border: solid 1px transparent;
  border-radius: 0px;
  left: 0px;
  top: 0px;
  right: 0px;
  width: unset;
  box-shadow: var(--box-shadow);

  .logo-title {
    display: flex;

    span {
      align-self: flex-end;
    }
  }
}

.wrapper-logo {
  display: flex;
  align-items: center;
  margin-left: var(--app-margin);
  background-color: transparent;
  color: var(--light);
  cursor: pointer;
  border: 1px solid transparent;
  transition: color 0.15s ease, transform 0.15s ease;
  &:hover {
    color: var(--accent);
  }
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
    border: 1px solid transparent;
  }
  &:focus-visible {
    border: 1px solid var(--accent);
  }
}
.i-calendar {
  color: var(--main-color);
  margin-left: var(--app-margin);
}

.wrapper-user {
  display: flex;
}

.btn-logout {
  margin-right: var(--app-margin);
  display: flex;
  align-items: center;
  color: var(--main-color);
  background-color: transparent;
  padding: 5px;
  border: 1px solid transparent;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
  &:focus {
    border: 1px solid var(--accent);
  }
}

.header-icon {
  font-size: 1.675rem;
}
</style>
