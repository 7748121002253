<template>
  <div role="radiogroup" class="radiogroup" :aria-labelledby="uuid">
    <h3 class="radiogroup-label" :id="uuid">{{ name }}</h3>
    <span class="radiogroup-items" :class="`radiogroup-${direction}`">
      <slot></slot>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue';
import { generateUuid } from '@/utils/utils';

export default defineComponent({
  name: 'RadioGroup',
  props: {
    name: {
      type: String,
      required: false
    },
    direction: {
      type: String,
      required: false,
      default: 'horizontal',
      validator: (value: string): boolean => {
        return ['horizontal', 'vertical'].indexOf(value) !== -1;
      }
    }
  },
  setup() {
    const uuid = computed(() => generateUuid());
    return {
      uuid
    };
  }
});
</script>

<style scoped lang="scss">
.radiogroup {
}
.radiogroup-label {
}

.radiogroup-items {
  display: flex;
}

.radiogroup-horizontal {
  flex-direction: row;
}
.radiogroup-vertical {
  flex-direction: column;
}
</style>

<style>
.radiogroup-horizontal > *:not(:first-child) {
  margin-left: 5px;
}
.radiogroup-vertical > *:not(:first-child) {
  margin-top: 5px;
}
</style>
