import useUser from '@/composables/useUser';
import { ok } from '@fhir/pixel-commons-js/src/index';
import { System, Tags } from '@/components/Patient/PatientTags';
import { combineURLSearchParams } from '@/utils/utils';
import { Filter, DocumentTag } from '@/store/search/types';
import Repo from '@/apiv2/Repo';
import { reactive } from 'vue';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import i18n from '@/lang';

export const allStudiesParams = () => {
  const params = new URLSearchParams();
  params.append(
    '_type',
    'Observation,ImagingStudy,DiagnosticReport,MedicationAdministration'
  );
  params.append('_include', 'Observation:patient');
  params.append('_include', 'ImagingStudy:patient');
  params.append('_include', 'DiagnosticReport:patient');
  params.append('_include', 'DocumentReference:patient');
  params.append('_include', 'MedicationAdministration:patient');
  params.append('_loadTagsBySystem', '*');

  return params;
};

function resourceCommonParams(filter: Filter) {
  const commonParams: URLSearchParams = new URLSearchParams();
  commonParams.append('_loadTagsBySystem', '*');

  if (filter.date != null) {
    if (filter.date.date != null) {
      commonParams.append('date', filter.date.date);
    }
    if (filter.date.dateFrom != null && filter.date.dateTo != null) {
      commonParams.append('date', `ge${filter.date.dateFrom}`);
      commonParams.append('date', `le${filter.date.dateTo}`);
    }
  }

  if (filter.sex != null && filter.sex !== 0) {
    const gender = filter.sex === 1 ? 'female' : 'male';
    commonParams.append('patient.gender', gender);
  }

  if (ok(filter.text)) {
    commonParams.append('_content', `${filter.text}`);
  }

  if (filter.favourites != null && filter.favourites) {
    commonParams.append(
      '_tag',
      `${System.patientPortalFavouriteSystem}|${useUser().getUserName()}`
    );
  }

  if (filter.tags != null && filter.tags.length > 0) {
    commonParams.append('_tag', filter.tags.map(m => m.name).join(','));
  }

  if (filter.pesels != null && filter.pesels.length > 0) {
    commonParams.append(
      'patient.identifier',
      filter.pesels.map(m => m).join(',')
    );
  }

  if (filter.names != null && filter.names.length > 0) {
    commonParams.append('patient.name', filter.names.map(m => m).join(','));
  }

  if (filter.birthDates != null && filter.birthDates.length > 0) {
    commonParams.append(
      'patient.birthdate',
      filter.birthDates.map(m => m).join(',')
    );
  }

  return commonParams;
}

type State = {
  favourite: Favourite;
  favouriteList: FavouriteList[];
};

type Favourite = {
  id: string;
  val: boolean;
};

type FavouriteList = {
  id: string;
  type: string;
};

const state: State = reactive({
  favourite: {
    id: '',
    val: false
  },
  favouriteList: [
    {
      id: '',
      type: ''
    }
  ]
});

function resourceParamSets(filter: Filter, commonParams: URLSearchParams) {
  const ids: string[] = (filter.modality || []).map((m: any) => m.id);
  const modalities = window.modalities.filter(
    (f: any) => ids.find((id: string) => id === f.FBID) != null
  );

  const paramSets = [];

  if (modalities.length > 0) {
    const params = modalities.flatMap((m: any) => {
      return (m.conditions || []).map((m: any) => {
        const ret = new URLSearchParams();
        ret.append('_type', m.resourceType);
        ret.append('_include', `${m.resourceType}:patient`);
        (m.query || []).forEach((e: any) => {
          const t = e.split('=');
          if (t != null && t.length == 2) {
            ret.append(t[0], t[1]);
          }
        });
        return combineURLSearchParams(ret, commonParams);
      });
    });
    paramSets.push(...params);
  } else {
    const finalParams = combineURLSearchParams(
      allStudiesParams(),
      commonParams
    );
    paramSets.push(finalParams);
  }

  return paramSets;
}

export default function useResource() {
  const searchResources = async (filter: Filter, tenant: string) => {
    const commonParams = resourceCommonParams(filter);
    const paramSets = resourceParamSets(filter, commonParams);

    return Promise.all(
      paramSets.map(params => {
        return Repo.fhir.customGet(tenant, params);
      })
    );
  };

  const getFavouriteList = () => state.favouriteList;

  const addToFavourites = (id: string, type: string) => {
    state.favouriteList.push({ id, type });
  };

  const documentExistInFavouriteList = (id: string, type: string) => {
    return !!state.favouriteList.find(
      item => item.id === id && item.type === type
    );
  };

  const clearFavouriteList = () => {
    state.favouriteList.splice(0, state.favouriteList.length);
  };

  const removeFromFavourites = (id: string, type: string) => {
    const found = state.favouriteList.find(
      item => item.id === id && item.type === type
    );
    if (found) {
      const index: number = state.favouriteList.indexOf(found);
      state.favouriteList.splice(index, 1);
    }
  };

  const addTag = async (url: string, tags: Tags) => {
    return await Repo.fhir.customPost(url, tags);
  };

  const checkIfTagExistInDocument = (resource: any, tag: DocumentTag) => {
    return !!resource.meta?.tag?.find(
      (f: any) => f.system === tag.system && f.code === tag.code
    );
  };

  const changeFavourite = (id: string, val: boolean) => {
    state.favourite.id = id;
    state.favourite.val = val;
  };

  const getLastFavouriteChange = () => state.favourite;

  const loadResourceByTypeAndId = async (resourceType: string, id: string) => {
    const response = await Repo.fhir.customGet(
      `${useUser().getSelectedTenant()}/${resourceType}/${id}`
    );
    if (response?.data) return response.data;

    useNotification().addNotification(
      NotificationType.ERROR,
      '',
      i18n.global.t('notifications.cantLoadData')
    );
  };

  const findResourceInListByTypeAndId = (
    list: any[],
    resourceType: string,
    id: string
  ) => {
    return list.filter(
      item =>
        item.resource.id === id && item.resource.resourceType === resourceType
    );
  };

  return {
    searchResources,
    addTag,
    checkIfTagExistInDocument,
    changeFavourite,
    addToFavourites,
    getFavouriteList,
    clearFavouriteList,
    documentExistInFavouriteList,
    removeFromFavourites,
    getLastFavouriteChange,
    loadResourceByTypeAndId,
    findResourceInListByTypeAndId
  };
}
