<template>
  <div v-if="visible" class="confirm-container">
    <div class="confirm-fader" />
    <div class="confirm-content">
      <div class="confirm-window">
        <div class="confirm-header">
          {{ title }}
        </div>
        <div class="confirm-body" v-html="body" />
        <div class="confirm-footer">
          <Button @click="resolve">
            {{ $t('tags.yes') }}
          </Button>
          <Button @click="reject">
            {{ $t('tags.no') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../Button/Button';

import mixins from '../utils/mixins';
export default {
  name: 'Confirm',
  components: { Button },
  mixins: [mixins],
  data() {
    return {
      visible: false,
      title: null,
      body: null,
      resolveFn: null,
      rejectFn: null
    };
  },
  methods: {
    confirm(title, body) {
      this.visible = true;
      this.title = title;
      this.body = body;

      return new Promise((resolve, reject) => {
        this.resolveFn = resolve;
        this.rejectFn = reject;
      });
    },
    resolve() {
      if (this.resolveFn) {
        this.resolveFn();
      }
      this.visible = false;
    },
    reject() {
      if (this.rejectFn) {
        this.rejectFn();
      }
      this.visible = false;
    }
  }
};
</script>

<style scoped>
.confirm-container {
  position: relative;
}
.confirm-fader {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}
.confirm-content {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: grid;
  place-items: center;
  z-index: 1001;
}

.confirm-window {
  background-color: var(--fhir__coponent-background);
  border-radius: 5px;
  padding: 20px;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 10px;
}

.confirm-window div:empty {
  display: none;
}

.confirm-header {
  font-weight: bold;
}

.confirm-body {
}

.confirm-footer {
  justify-self: end;
}
</style>
