import { join } from 'lodash';
const tools = {
  join: (...s) => {
    if (s != null) {
      return join(
        s.filter(f => f != null),
        ' '
      );
    }
    return '';
  },
  append: (s, add, join = ' ') => {
    if (add != null && s != null && add.trim().length > 0) {
      let str = '';
      if (s.trim().length > 0) {
        str += join;
      }
      str += add;
      return s + str;
    }
    return '';
  },
  ok: s => {
    return s != null && s.trim().length > 0;
  },
  hexToRGB: (hex, alpha) => {
    if (hex == null) {
      hex = '#000000';
    }

    const r = parseInt(hex.slice(1, 3), 16);

    const g = parseInt(hex.slice(3, 5), 16);

    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  },
  replace: (str, src, dst) => {
    if (str != null && src != null && dst != null) {
      return str.replace(new RegExp(src, 'g'), dst);
    }
    return null;
  }
};
export default tools;
