
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { generateUuid } from '@/utils/utils';
import { debounce } from 'lodash';
import PatientsSearch from '@/components/FullTextSearch/PatientsSearch.vue';
import ResearchSearch from '@/components/FullTextSearch/ResearchSearch.vue';
import {
  AccessType,
  AccessWhere,
  PatientsType,
  ResearchesType
} from '@/components/FullTextSearch/FullTextSearchType';
import LabelFullTextSearch from '@/components/FullTextSearch/LabelFullTextSearch.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { FILTER, Filter, TEXT } from '@/store/search/types';
import search, { useStore as useSearchStore } from '@/store/search';

const SEARCHER_ITEM = 'searcher__item';
const SEARCHER_TAG = 'expandable-panel-header';
const USER_PROFILE_ICON = 'user-profile-icon';

export default {
  name: 'FullTextSearch',
  components: {
    BaseButton,
    LabelFullTextSearch,
    ResearchSearch,
    PatientsSearch
  },
  props: {
    width: {
      type: Number,
      required: false,
      default: 1001
    }
  },
  setup(props: any) {
    const router = useRouter();
    const searchStore = useSearchStore();

    const activeRouterName = computed<AccessType>(() => {
      return router.currentRoute.value.name as AccessType;
    });

    const fullTextSearchModel = ref<string>('');

    const modal = ref<boolean>(false);

    const inputId = ref<string>(generateUuid());

    const searchVisible = ref<boolean>(false);

    const dialogId = ref<string>(generateUuid());

    const testPatients: PatientsType[] = [
      {
        id: 1,
        name: 'Ala',
        surname: 'Ma',
        identifier: 12345678121
      },
      {
        id: 2,
        name: 'Arnold',
        surname: 'Je',
        identifier: 12345678901
      },
      {
        id: 3,
        name: 'Olek',
        surname: 'Smiga',
        identifier: 12345678921
      },
      {
        id: 1,
        name: 'Ala',
        surname: 'Ma',
        identifier: 12345678121
      },
      {
        id: 2,
        name: 'Arnold',
        surname: 'Je',
        identifier: 12345678901
      },
      {
        id: 3,
        name: 'Olek',
        surname: 'Smiga',
        identifier: 12345678921
      }
    ];

    const testResearches: ResearchesType[] = [
      {
        id: 8,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      },
      {
        id: 12,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      },
      {
        id: 14,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      },
      {
        id: 16,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      },
      {
        id: 18,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      }
    ];

    const toggleSearchVisible = (): void => {
      searchVisible.value = !searchVisible.value;
    };

    const clearInput = (): void => {
      fullTextSearchModel.value = '';
    };

    const searchPatientsBy = (v: string) => {
      // TODO find Patients by name or surname
      console.log('SEARCH PATIENTS', v);
    };

    const searchResearchesBy = (v: string) => {
      // TODO find Fhir Researchers by ....
      console.log('SEARCH RESEARCHES', v);
    };

    const openModal = () => {
      console.log('openModal');
      /*
      if (fullTextSearchModel.value.length >= 2) {
        modal.value = true;
      }
      */
    };

    const hideModal = (e: MouseEvent | KeyboardEvent | any): void => {
      if (
        props.width > 1000 &&
        e.relatedTarget?.id !== inputId.value &&
        e.relatedTarget?.id !== dialogId.value &&
        e.relatedTarget?.classList[1] !== SEARCHER_ITEM &&
        e.relatedTarget?.classList[0] !== SEARCHER_TAG
      ) {
        modal.value = false;
      }
    };

    const searchBy = debounce((x: string): void => {
      if (activeRouterName.value === AccessType.Patient) {
        searchResearchesBy(x);
      } else if (activeRouterName.value === AccessType.Admin) {
        searchPatientsBy(x);
        searchResearchesBy(x);
      }
    }, 500);

    watch(fullTextSearchModel, (x: string): void => {
      searchStore.commit(TEXT, x);
      /*
      if (props.width > 1000) {
        modal.value = x.length >= 2;
      }

      searchBy(x);
       */
    });
    const filter = computed<Filter>(() => searchStore.getters[FILTER]);
    //reagujemy na czyszczenie filtrów
    watch(
      filter,
      v => {
        if (v != null) {
          if (v.text == null) {
            fullTextSearchModel.value = '';
          } else {
            fullTextSearchModel.value = v.text;
          }
        }
      },
      { deep: true }
    );

    const findNextOrBeforeTabStop = (el: any, nextOrBefore: string) => {
      const universe = document.querySelectorAll('input, button, dialog');

      const list = Array.prototype.filter.call(universe, (item: any) => {
        return item.tabIndex >= '0';
      });
      const index = list.indexOf(el);

      if (nextOrBefore === AccessWhere.Next) {
        return list[index + 1] || list[0];
      } else {
        return list[index - 1] || list[list.length - 1];
      }
    };

    const focusOnDialog = () => {
      if (document.getElementById(dialogId.value)) {
        document.getElementById(dialogId.value)?.focus();
      }
    };

    const focusPreviousElement = (e: KeyboardEvent) => {
      e.preventDefault();
      findNextOrBeforeTabStop(document.activeElement, 'before').focus();
    };

    const focusNextElement = (e: KeyboardEvent) => {
      e.preventDefault();

      const nextElement = findNextOrBeforeTabStop(
        document.activeElement,
        AccessWhere.Next as string
      );

      if (nextElement?.classList[0] === USER_PROFILE_ICON) {
        return;
      }
      nextElement?.focus();
    };

    return {
      fullTextSearchModel,
      modal,
      clearInput,
      inputId,
      dialogId,
      activeRouterName,
      testPatients,
      hideModal,
      openModal,
      testResearches,
      AccessType,
      focusOnDialog,
      focusNextElement,
      focusPreviousElement,
      toggleSearchVisible,
      searchVisible
    };
  }
};
