import { format, parseJSON, add, parseISO } from 'date-fns';
import tools from '../utils/tools';

const parseDate = date => {
  // console.log('parseDate', date)
  if (tools.ok(date)) {
    const id = 'Invalid Date';
    let ret = parseISO(date);
    // console.log('parseISO', ret)
    if (ret.toString() === id) {
      ret = parseJSON(date);
      // console.log('parseJSON', ret)
    }
    if (ret.toString() !== id) {
      return ret;
    }
  }
  return null;
};

const pd = date => {
  if (date != null) {
    // console.log('pd', typeof date, date, parseJSON(date), parseISO(date))
    switch (typeof date) {
      case 'string':
        return parseDate(date);
      case 'object':
        return date;
      default:
        return null;
    }
  }
  return null;
};

const datetime = {
  // (date) => pd(date)!=null ? format(pd(date), "yyyy-MM-dd HH:mm") : '-'
  parseDate: pd,
  dateMinutes: date =>
    pd(date) != null ? format(pd(date), 'yyyy-MM-dd HH:mm') : '-',
  dateSeconds: date =>
    pd(date) != null ? format(pd(date), 'yyyy-MM-dd HH:mm:ss') : '-',
  datetime: date => (pd(date) != null ? format(pd(date), 'HH:mm:ss') : '-'),
  datehours: date => (pd(date) != null ? format(pd(date), 'HH:mm') : '-'),
  day: date => (pd(date) != null ? format(pd(date), 'yyyy-MM-dd') : '-'),
  dayInv: date => (pd(date) != null ? format(pd(date), 'dd-MM-yyyy') : '-'),
  ts: date => Number(pd(date) != null ? format(pd(date), 'T') : 0),
  timezone: () => Intl.DateTimeFormat().resolvedOptions().timeZone,
  prevDays: (date, days) =>
    pd(date) != null ? add(date, { days: -1 * days }) : null
};
export default datetime;
