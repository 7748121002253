
import { computed, defineComponent } from 'vue';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import BaseSwitch from '@/components/Base/BaseSwitch.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import { DATE, PATIENT, RESULTS, SHARED } from '@/store/search/types';
import DateFilters from '@/components/Commons/DateFilters.vue';
import Button from '@/components/FhirComponents/Button/Button.vue';
import PatientFilters from '@/components/Patient/PatientFilters';
import usePatient from '@/composables/usePatient';

export default defineComponent({
  name: 'PatientFilters',
  components: {
    Button,
    DateFilters,
    RadioButton,
    RadioGroup,
    BaseSwitch,
    ExpandablePanel
  },
  setup() {
    const { searchStore, processDateFilters, dateFilter } = PatientFilters();

    const sharedDocuments = computed(() => searchStore.getters[SHARED]);
    const setSharedDocuments = (value: boolean): void => {
      searchStore.commit(SHARED, value);
    };

    const resourceDates = computed(() => searchStore.getters[RESULTS]);
    const selectDate = (date: string) => {
      searchStore.commit(DATE, date);
    };
    const selectedDate = computed(() => searchStore.getters[DATE]);

    const patients = computed(() => usePatient().getPatients());
    const selectedPatient = computed(() => searchStore.getters[PATIENT]);
    const selectPatient = (patient: string) => {
      searchStore.commit(PATIENT, patient);
    };

    return {
      resourceDates,
      selectDate,
      selectedDate,
      processDateFilters,
      dateFilter,
      patients,
      selectedPatient,
      selectPatient,
      setSharedDocuments,
      sharedDocuments
    };
  }
});
