export default function disableOnMode(array, mode) {
  if (Array.isArray(array)) {
    return !array.includes(parseInt(mode));
  } else if (typeof array === 'number') {
    return !Array.of(array).includes(parseInt(mode));
  } else if (typeof array === 'string') {
    array = parseInt(array);
    return !Array.of(array).includes(parseInt(mode));
  }
}
