const documentRoute = [
  {
    path: '/document/:id*',
    name: 'document',
    component: () => import('@/views/Document/Document.vue'),
    props: true
  }
];

export default documentRoute;
