import jp from 'jsonpath';
import { diff } from '@fhir/pixel-commons-js';

export function pesel(resource: object) {
  let pesel = jp.value(
    resource,
    "$.identifier[?(@.system=='urn:oid:2.16.840.1.113883.3.4424.11.3.7')].value"
  );
  if (pesel == null) {
    pesel = jp.value(
      resource,
      "$.identifier[?(@.system=='urn:oid:2.16.840.1.113883.3.4424.1.1.616')].value"
    );
  }
  if (pesel == null) {
    return jp.value(resource, '$.identifier[*].value');
  }
  return pesel;
}

export function peselAndSystem(resource: object): any {
  let system = 'urn:oid:2.16.840.1.113883.3.4424.11.3.7';
  let pesel = jp.value(
    resource,
    `$.identifier[?(@.system=='${system}')].value`
  );
  if (pesel == null) {
    system = 'urn:oid:2.16.840.1.113883.3.4424.1.1.616';
    pesel = jp.value(resource, `$.identifier[?(@.system=='${system}')].value`);
  }
  if (pesel == null) {
    system = '';
    pesel = jp.value(resource, '$.identifier[*].value');
  }
  return {
    system: system,
    value: pesel
  };
}

export function getPatientNameNameAndSurname(resource: object) {
  const surname = jp.value(resource, '$.name[0].family');
  const names = jp.value(resource, '$.name[0].given');

  if (surname && names) return `${names} ${surname}`;
  if (surname) return surname;
  if (names) return names;

  return '';
}

export function getPatientAge(resource: object) {
  const birthDate = jp.value(resource, '$.birthDate');
  if (birthDate) {
    return diff(birthDate, new Date())?.diff.toString();
  }
  return '';
}
