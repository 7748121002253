
import BaseButton from '@/components/Base/BaseButton.vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import { computed, defineComponent, ref, watchEffect } from 'vue';

import { JsonStorageTenants } from '@/components/ONLOAD_CONFIGURATION/onloadConfigUtils';
import ConfigRepo from '@/api/ConfigRepo';
import { useI18n } from 'vue-i18n';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import useUser from '@/composables/useUser';

export default defineComponent({
  name: 'TenantsRadio',
  components: { RadioButton, RadioGroup, BaseButton },
  setup() {
    const selectedTenant = ref('');

    const { t } = useI18n();

    const loadedTenants = computed(() =>
      useUser()
        .getTenants()
        .map((item: JsonStorageTenants) => item.valueString)
    );
    const selectedTenantFromStore = computed(() =>
      useUser().getSelectedTenant()
    );

    const selectedTenantId = computed(() => useUser().getSelectedTenantId());

    watchEffect(() => (selectedTenant.value = selectedTenantFromStore.value));

    const handleSaveConfiguration = async () => {
      const params = {
        selectedTenant: selectedTenant.value
      };
      try {
        if (selectedTenantId.value) {
          // update
          const { data } = await ConfigRepo.updateConfiguration(
            params,
            selectedTenantId.value
          );
          await useUser().setSelectedTenantId(data.id);
        } else {
          // post
          await ConfigRepo.postConfiguration(params);
        }

        await useUser().setSelectedTenant(selectedTenant.value);
        useNotification().addNotification(
          NotificationType.SUCCESS,
          '',
          t('config.savedTenants')
        );
      } catch (e) {
        useNotification().addNotification(
          NotificationType.ERROR,
          '',
          t('config.error')
        );
      }
    };

    return {
      loadedTenants,
      selectedTenant,
      handleSaveConfiguration
    };
  }
});
