
import Navigation from '@/layout/Navigation/Navigation.vue';
import { computed, onMounted, ref } from 'vue';
import OauthWrapper from '@fhir/oauth-components/src/components/OauthWrapper.vue';

import radBox from '@/assets/alleradVbox.svg';
import moreIcons from '@/assets/more-icons.svg';
import HamburgerMenu from '@/components/HamburgerMenu.vue';
import FullTextSearch from '@/components/FullTextSearch/FullTextSearch.vue';
import NotificationDialog from '@/components/Commons/NotificationDialog/NotificationDialog.vue';
import { generateUuid } from '@/utils/utils';
import useUser from '@/composables/useUser';
import NotificationButton from '@/components/Commons/NotificationDialog/NotificationButton.vue';
import router from '@/router';

export default {
  name: 'Header',
  components: {
    NotificationButton,
    NotificationDialog,
    FullTextSearch,
    HamburgerMenu,
    Navigation,
    OauthWrapper
  },
  props: {
    width: {
      type: Number,
      required: true,
      default: window.innerWidth
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['header-is-smaller'],
  setup(props: any, context: { emit: (arg0: string, arg1: boolean) => void }) {
    const isSmaller = ref(false);
    const randomStr = ref<string>('');
    const hamburgerOpen = ref<boolean>(false);
    const notifications = ref<number>(1);
    const notificationDialog = ref<boolean>(false);

    const logout = () => (randomStr.value = Date.now().toString());

    const buttonId = ref(generateUuid());

    onMounted(() => {
      const observer = new IntersectionObserver(entries => {
        if (entries[0].boundingClientRect.y < 0) {
          isSmaller.value = window.pageYOffset > 0;
          context.emit('header-is-smaller', isSmaller.value);
          document.querySelector('.patient-header')?.classList.add('isSmaller');
        } else {
          isSmaller.value = false;
          context.emit('header-is-smaller', isSmaller.value);
          document
            .querySelector('.patient-header')
            ?.classList.remove('isSmaller');
        }
      });

      observer.observe(
        document.getElementById('watch-observer-to-top') as HTMLElement
      );
    });

    const refreshError = () => {
      // TODO
      // userStore.commit(LOGIN_AFTER_401);
    };

    const authDataUser = async (ev: any) => {
      console.log('authDataUser', ev);
      useUser().setUserGroup(ev.user.groups);
      useUser().setUserName(ev.user.name);
      useUser().setUserToken(ev.token);
    };

    const userName = computed(() => useUser().getUserName());

    const closeHamburgerOnEsc = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        hamburgerOpen.value = false;
      }
    };

    const handleRoute = () => {
      router.push({ name: 'Patient' });
    };

    return {
      isSmaller,
      refreshError,
      authDataUser,
      userName,
      logout,
      randomStr,
      radBox,
      moreIcons,
      hamburgerOpen,
      closeHamburgerOnEsc,
      notifications,
      buttonId,
      notificationDialog,
      handleRoute
    };
  }
};
