import _axios from '@/services/AxiosService';

export const notificatorService = _axios({
  baseURL: process.env.VUE_APP_NOTIFICATOR_URL
});
export const fhirService = _axios({
  baseURL: process.env.VUE_APP_API_URL
});
export const jsonStorageService = _axios({
  baseURL: process.env.VUE_APP_API_JSON_STORAGE
});
export const localService = _axios({ baseURL: '' });
