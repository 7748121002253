
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BaseChip',
  props: {
    closable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'info',
      validator: (propValue: string) =>
        ['info', 'success', 'warning', 'danger'].includes(propValue)
    },
    ariaLabelNew: {
      type: String,
      default: '',
      required: false
    }
  },
  emits: ['close'],
  setup(props) {
    const { t } = useI18n();

    const ariaLabel = ref(props.ariaLabelNew);

    onMounted(() => {
      ariaLabel.value = props.ariaLabelNew
        ? props.ariaLabelNew
        : t('base.close');
    });

    return {
      ariaLabel
    };
  }
});
