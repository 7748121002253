import Repo from '@/apiv2/Repo';
import { reactive } from 'vue';

import {
  getPatientAge,
  getPatientNameNameAndSurname,
  pesel,
  peselAndSystem
} from '@/components/PatientAndAdmin/utils';
import { Patient, PatientI } from '@/composables/types/patient';
import { DateFilter } from '@/store/search/types';
import { ok } from '@fhir/pixel-commons-js/src/index';
import { combineURLSearchParams } from '@/utils/utils';
import { allStudiesParams } from '@/composables/useResource';
import useUser from '@/composables/useUser';
import { createDateParams } from '@/utils/utils';

const state: PatientI = reactive({
  birthDate: '',
  gender: '',
  name: '',
  pesel: '',
  age: '',
  id: ''
});

const patients: Array<PatientI> = reactive([]);

export default function usePatient(): {
  getLoggedPatient: () => {
    gender: string | '';
    name: string | '';
    id: string | '';
    pesel: string | number | '';
    birthDate: string | '';
    age: string | number | '';
  };
  searchPatients: (
    dateFilter: DateFilter,
    patientFilter: string | undefined,
    tenant: string,
    text: string
  ) => any;
  loadPatient: (tenant: string) => any;
  loadPatientByReference: (tenant: string, reference: string) => any;
  getPatients: () => Array<PatientI>;
  getPatientById: (id: string) => PatientI | undefined;
  matchPatientById: (id?: string) => Patient | undefined;
} {
  const getLoggedPatient = () => {
    return {
      birthDate: state.birthDate,
      gender: state.gender,
      name: state.name,
      pesel: state.pesel,
      age: state.age,
      id: state.id
    };
  };

  const getPatients = () => patients;

  const loadPatientByReference = async (tenant: string, reference: string) => {
    const response = await Repo.fhir.customGet(`${tenant}/${reference}`);

    if (response?.data) {
      const resp = response?.data;

      if (resp) {
        return {
          name: getPatientNameNameAndSurname(resp),
          pesel: peselAndSystem(resp),
          birthDate: resp.birthDate
        };
      }
    }
    return null;
  };

  const getPatientById = (id: string) => patients.find(f => f.id === id);
  const matchPatientById = (id?: string) =>
    patients.find(f => id?.toString()?.includes(f.id));

  const loadPatient = async (tenant: string) => {
    const response = await Repo.fhir.customGet(`${tenant}/Patient`);

    if (response?.data?.entry) {
      patients.length = 0;
      response?.data?.entry?.forEach((item: any, i: number) => {
        if (item && item.resource) {
          const patient: PatientI = new Patient(
            item.resource.birthDate,
            '',
            getPatientNameNameAndSurname(item.resource),
            pesel(item.resource),
            getPatientAge(item.resource) ?? '',
            item.resource.id
          );
          patients.push(patient);

          if (i === 0) {
            state.name = patient.name;
            state.pesel = patient.pesel;
            state.age = patient.age;
            state.birthDate = patient.birthDate;
            state.id = patient.id;
          }
        }
      });
    }
  };

  const searchPatients = async (
    dateFilter: DateFilter,
    patientFilter: string | undefined,
    tenant: string,
    text: string
  ) => {
    console.log('search pat repo', dateFilter);
    const params: URLSearchParams = new URLSearchParams();

    createDateParams(params, dateFilter);

    if (ok(patientFilter)) {
      params.append('subject', `Patient/${patientFilter}`);
    }

    if (ok(text)) {
      params.append('_content', `${text}`);
    }

    const combineParams = combineURLSearchParams(allStudiesParams(), params);

    return await Repo.fhir.customGet(
      useUser().getSelectedTenant(),
      combineParams
    );
  };

  return {
    loadPatient,
    getLoggedPatient,
    searchPatients,
    loadPatientByReference,
    getPatients,
    getPatientById,
    matchPatientById
  };
}
