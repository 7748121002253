<template>
  <div class="checkbox-wrapper">
    <input
      v-bind="$attrs"
      type="checkbox"
      :ref="'inputRef'"
      :id="checkboxId"
      :value="checkboxValue"
      :disabled="disabled"
      :checked="checkboxValue"
      @change="
        handleChange($event, checkboxId, usedAsSingleCheckbox, checkboxValue)
      "
      @keydown.enter="
        handleChange($event, checkboxId, usedAsSingleCheckbox, checkboxValue)
      "
    />
    <label :for="checkboxId" :class="[{ 'with-text': checkboxLabel }]">
      {{ handleTranslation(checkboxLabel) }}
    </label>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  name: 'BaseCheckbox',
  props: {
    checkboxLabel: {
      type: [String, Function],
      required: false
    },
    usedAsSingleCheckbox: {
      type: Boolean,
      required: false,
      default: false
    },
    checkboxValue: {
      type: [Boolean, String, Number, Object],
      required: false
    },
    checkboxId: {
      type: [String, Object],
      required: true,
      default: 'checkbox__test'
    },
    checkboxState: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    handleTranslation(labelInfo) {
      return typeof labelInfo === 'string' ? labelInfo : labelInfo();
    },
    handleChange(event, checkboxId, usedAsSingleCheckbox, checkboxValue) {
      this.$emit('input', {
        isChecked: event.target.checked,
        id: checkboxId,
        value: usedAsSingleCheckbox ? event.target.checked : checkboxValue,
        ev: event
      });
    }
  }
};
</script>

<style scoped lang="scss">
.checkbox-wrapper input[type='checkbox'] {
  opacity: 0;
  height: 0;
  width: 0;
}

.checkbox-wrapper label {
  position: relative;

  /*14px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 22px;
}

.checkbox-wrapper label::before,
.checkbox-wrapper label::after {
  position: absolute;
  content: '';
  transition: transform 0.15s ease, border-color 0.15s ease;

  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox-wrapper label::before {
  height: 14px;
  width: 14px;

  border: 2px solid var(--accent);
  border-radius: 3px;
  left: 0px;

  /*(24px line-height - 14px height of fake checkbox) / 2 - 1px for the border
   *to vertically center it.
   */
  top: 0;
}

.checkbox-wrapper:hover label::before {
  transform: scale(1.1);
}

/*Checkmark of the fake checkbox*/
.checkbox-wrapper label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid var(--accent);
  border-bottom: 2px solid var(--accent);

  transform: rotate(-45deg);

  left: 4px;
  top: 4px;
}

/*Hide the checkmark by default*/
.checkbox-wrapper input[type='checkbox'] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox-wrapper input[type='checkbox']:checked + label::after {
  content: '';
}

.checkbox-wrapper input[type='checkbox']:checked + label::before {
  border-color: var(--accent);
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox-wrapper input[type='checkbox']:focus + label::before {
  border-color: var(--accent);
}
</style>
