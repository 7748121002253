import { AxiosInstance } from 'axios';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';

export type ServerErrorCodeTypes = 404 | 403;

export interface CustomExceptionI {
  code: ServerErrorCodeTypes;
  title: string;
  message: string;
}

export interface ResponseDataFromServerI {
  data?: any;
  status?: any;
  exception?: any;
}

export default class RepoConnector {
  private AXIOS_INSTANCE: AxiosInstance;
  private RESOURCE_NAME: string;

  constructor(repoInstance: AxiosInstance, resourceName: string) {
    this.AXIOS_INSTANCE = repoInstance;
    this.RESOURCE_NAME = resourceName;
  }

  async customGet(
    endpoint: string,
    params?: any,
    customException?: CustomExceptionI
  ): Promise<ResponseDataFromServerI> {
    const midpoint = `${this.RESOURCE_NAME}/${endpoint}`;

    try {
      return await this.AXIOS_INSTANCE.get(midpoint, {
        params: params ? params : {}
      });
    } catch (exception) {
      if (customException?.code) {
        useNotification().addNotification(
          NotificationType.ERROR,
          customException.title,
          customException.message
        );
      } else {
        useNotification().addNotification(
          NotificationType.ERROR,
          exception.name,
          exception.message
        );
      }
      throw exception;
      // return {
      //   exception
      // };
    }
  }

  async customPost(
    endpoint: string,
    body?: any, // FIXME
    params?: any // FIXME
  ): Promise<ResponseDataFromServerI> {
    const midpoint = `${this.RESOURCE_NAME}/${endpoint}`;
    try {
      return await this.AXIOS_INSTANCE.post(midpoint, body, { params: params });
    } catch (exception) {
      // TODO: exception.response.status
      if (exception.response.status !== 403) {
        // chwilowe wyłączenie notyfikacji dla błędu przy udostępnianiu zasobu
        useNotification().addNotification(
          NotificationType.ERROR,
          exception.name,
          exception.message
        );
      }
      throw exception;
      // return {
      //   exception
      // };
    }
  }

  async customPut(
    endpoint: string,
    params?: any,
    customException?: CustomExceptionI
  ): Promise<ResponseDataFromServerI> {
    const midpoint = `${this.RESOURCE_NAME}/${endpoint}`;
    try {
      return await this.AXIOS_INSTANCE.put(midpoint, params);
    } catch (exception) {
      if (customException?.code) {
        useNotification().addNotification(
          NotificationType.ERROR,
          customException.title,
          customException.message
        );
      } else {
        useNotification().addNotification(
          NotificationType.ERROR,
          exception.name,
          exception.message
        );
      }

      throw exception;
      // return {
      //   exception
      // };
    }
  }

  async customDelete(
    endpoint: string,
    params?: any // FIXME
  ): Promise<ResponseDataFromServerI> {
    const midpoint = `${this.RESOURCE_NAME}/${endpoint}`;
    try {
      return await this.AXIOS_INSTANCE.delete(midpoint, { data: params });
    } catch (exception) {
      // TODO: exception.response.status
      useNotification().addNotification(
        NotificationType.ERROR,
        exception.name,
        exception.message
      );
      throw exception;
      // return {
      //   exception
      // };
    }
  }
}
