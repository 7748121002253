export default {
  patientPortal: 'Patient portal',
  nextPage: 'Next page',
  prevPage: 'Previous page',
  pagerOptions: 'Pager options',
  loading: 'Loading data in progress',
  baseLoading: 'Loading',
  perPage: 'Records per page',
  gotoPage: 'Go to page',
  close: 'Close',
  hideExpand: 'Hide expanded content',
  showExpand: 'Expand content',
  removeFilter: 'Remove filter',
  checkboxAriaLabel: 'Select to add to the list of studies to be shared',
  removeShare: 'Usuń element',
  pageNotFound: 'Page could not be found',
  data: {
    dataFetchingError: 'Data fetching error',
    examinationDataError: 'The examination was not found'
  },
  share: {
    name: 'Share documents',
    loading: 'Loading consents',
    listError: 'Error loading consents',
    shareTo: 'Share documents to',
    enterEmailOrPhone: 'Add email or phone number',
    shareByLink: 'Share document by link',
    share: 'Share',
    generateLinkForOneResourceInfo:
      'Link can be generated separately for each document',
    enterValidEmailOrPhone: 'Enter valid email or phone number',
    shareError: 'Error sharing document',
    shared: 'Documents shared',
    sharing: 'Sharing documents',
    copy: 'Copy link',
    generateLink: 'Generate link',
    deleteLink: 'Remove link',
    caution: 'Caution',
    confirmDeleteLink: 'Do you want to remove share link ?',
    linkGenerated: 'Share link generated',
    errorGeneratingLink: 'Error generating share link',
    generatingLink: 'Generating share link',
    linkRemoved: 'Share link removed',
    errorRemovingLink: 'Error removing share link',
    removingLink: 'Removing share link',
    shareRemoved: 'Share for removed',
    errorRemovingShare: 'Error removing share',
    removingShare: 'Removing share',
    confirmDeleteShare: 'Do you want to remove share for {subject} ?',
    shareSelectedStudies: 'Share selected studies',
    consentAlreadyExistForThisActor:
      'Consent exist for this person. Try to write other e-mail address or phone number.'
  },
  confirm: {
    yes: 'Yes',
    no: 'No'
  },
  preview: 'Document preview',
  copyToClipboard: 'Copy to clipboard',
  copied: 'Text copied to clipboard',
  open: 'Open',
  logout: 'Logout',
  logoutFromApp: 'Logged out of the application',
  loggedOutText: 'You have logged out of the application',
  logInAgain: 'Log in again',
  recordOnCd: 'Record on cd',
  download: 'Download',
  print: 'Print',
  document: 'Document',
  studyResults: 'Study results',
  openMenu: 'Open menu',
  goToMainPage: 'Go to main page',
  noStudyResults: 'No study results',
  goToTop: 'Go to top',
  markAsDisplayed: 'Mark as displayed',
  showing: 'Showing',
  expandableExpand: 'Use the arrows to read the content in the drop-down, or',
  settings: 'Settings',
  accessibilityMenu: 'Accessibility Menu',
  searchPlaceholder: 'Search in documents',
  labelFullTextAdmin:
    'Text input field to search for patients and their examinations.',
  labelFullTextPatient:
    'Text input field to search for your research, You must enter at least two letters to start the search.',
  saveConfig: 'Save config',
  noData: 'Empty data',
  setFavourite: 'Mark as favourite',
  unsetFavourite: 'Unmark as favourite',
  male: 'Male',
  female: 'Female',
  sharedForMe: 'Shared for me',
  sharedForMeBy: 'Shared for me by',
  from: 'from',
  studyNotFound: 'Study not found',
  forbidden: 'Forbidden',
  mainPage: 'Main page'
};
