<template>
  <div class="fh-mb">
    <span class="visuallyhidden">
      Poniżej przedstawiony został element typu: {{ resourceType }}.
    </span>
    <div class="fhir-component__header">
      <div class="fhir-component__title">
        <template v-if="!displayOtherHeaderTitle">
          <DisplayCoding
            v-if="fhir"
            ref="displayCodingRef"
            :resource="fhir"
            :lang="lang"
            :display-important="displayImportant"
            @set-active-header="getActiveHeader($event, getDateFromFhir, false)"
          />
          <span v-if="getDateFromFhir" class="fhir-component__date-header">
            <!--            ({{ getDateFromFhir }})-->
          </span>
        </template>
        <template v-else>
          <span class="visuallyhidden">
            {{ getActiveHeader(displayOtherHeaderTitle, null, true) }}
          </span>
        </template>
      </div>

      <slot />
    </div>
    <Tags
      v-if="showTags"
      :editable="editTags"
      :resource="resource"
      :token="token"
      :lang="lang"
    />
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import DisplayCoding from '../DisplayCoding/DisplayCoding';
import { dateTimeMoment } from '../utils/time.utils';
import jp from 'jsonpath';
import Tags from '../Tags/Tags';

export default {
  name: 'FhirHeader',
  components: { Tags, DisplayCoding },
  mixins: [mixins],
  emits: ['get-active-header'],
  props: {
    displayOtherHeaderTitle: { default: '', type: String },
    displayImportant: { required: false, type: Boolean, default: false }
  },
  methods: {
    getActiveHeader(e, date, add) {
      const header = date ? `${e} (${date})` : e;
      this.$parent.$parent.$emit('get-active-header', {
        header,
        parentResource: this.$parent.fhir.resource,
        add
      });
    }
  },
  computed: {
    getDateFromFhir() {
      return (
        this.effectiveDateTime ||
        this.issued ||
        this.assertedDate ||
        this.started ||
        null
      );
    },
    effectiveDateTime() {
      const date = jp.value(this.fhir, 'resource.effectiveDateTime') || '';
      if (date) {
        return dateTimeMoment(date);
      } else {
        return null;
      }
    },
    issued() {
      const date = jp.value(this.fhir, 'resource.issued') || '';
      if (date) {
        return dateTimeMoment(date);
      } else {
        return null;
      }
    },
    assertedDate() {
      const getDateTime = jp.value(this.fhir, 'resource.assertedDate') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    started() {
      const getDateTime = jp.value(this.fhir, 'resource.started') || '';
      if (getDateTime) {
        return dateTimeMoment(getDateTime);
      } else {
        return '';
      }
    },
    resourceType() {
      return this.$parent.resource.resource.resourceType;
    }
  }
};
</script>

<style scoped></style>
