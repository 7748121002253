export default {
  resources: {
    AllergyIntolerance: 'Alergie/nietolerancje pokarmowe',
    Condition: 'Stan',
    DiagnosticReport: 'Opis badania, wynik badania',
    DocumentReference: 'Referencja do dokumentu',
    Encounter: 'Pobyt',
    Endpoint: 'Punkt końcowy',
    EpisodeOfCare: 'Opieka',
    HealthcareService: 'Świadczenie zdrowotne',
    ImagingStudy: 'Badanie obrazowe',
    List: 'Lista',
    MedicationAdministration: 'Podaż (podanie) leku',
    MedicationRequest: 'Zlecenie podaży (podania) leku',
    Observation: 'Obserwacja',
    OperationDefinition: 'Operation definition',
    Organization: 'Świadczeniodawca',
    Patient: 'Pacjent',
    Practitioner: 'Pracownik służby zdrowia',
    ProcedureRequest: 'Skierowanie na procedurę',
    Questionnaire: 'Ankieta',
    StructureDefinition: 'Definicja struktury',
    Medication: 'Lek',
    Procedure: 'Procedura',
    Consent: 'Zgoda',
    Specimen: 'Próbka',
    Location: 'Lokalizacja placówki medycznej',
    VitalSigns: 'Parametry życiowe',
    Imaging: 'Badanie obrazowe',
    ServiceRequest: 'Zlecenie',
    Immunization: 'Szczepionka',
    Device: 'Urządzenie',
    QuestionnaireResponse: 'Odpowiedź na pytanie kwestionariusza'
  },
  wcagText: {
    addressTitle: 'Poniżej przedstawiony został adres',
    allergyIntoleranceTitle: 'Poniżej przedstawione zostały alergie'
  },
  labelsRes: {
    conclusion: 'Wniosek',
    patient: 'Pacjent',
    clinicalStatus:
      'Status kliniczny (wartości takie jak: aktywna/remisja/nawrót/wyleczona)',
    verificationStatus: 'Rodzaj diagnozy',
    type: 'Typ',
    category: 'Kategoria',
    criticality: 'Istotność',
    code: 'Kod',
    onsetDateTime: 'Data rozpoczęcia',
    assertedDate: 'Pierwsze wystąpienie',
    recorder:
      'Osoba która zanotowała wystąpienie jakiegoś stanu/schorzenia/zdarzenia',
    asserter:
      "Osoba dokonująca wpisu w dokumentację podlegającego pod zasób 'Stan",
    note: 'Notatka',
    reaction: 'Reakcja alergiczna',
    substance: 'Substancje',
    manifestation: 'Objaw',
    description: 'Opis',
    onset: 'Początek',
    severity:
      "Nasilenie (nasilenie reakcji alergicznej jako małe/umiarkowane/duże w zasobie 'Alergie/nietolerancje pokarmowe')",
    exposureRoute: 'Droga narażenia (na alergen)',
    authoredOn: 'Napisane w dniu',
    intent:
      "Określa czy dane skierowanie 'request' jest zleceniem/propozycją/planem",
    priority: 'Priorytet',
    context: 'Kontekst',
    name: 'Nazwa',
    telecom: 'Dane kontaktowe email/nr tel. pracownika',
    line: 'Ulica',
    city: 'Miasto',
    state: 'Stan',
    postalCode: 'Kod pocztowy',
    country: 'Państwo',
    _id: 'Identyfikator zasabou',
    'clinical-status':
      'Status kliczniczny schorzenia (może przyjmować wartości: aktywna/remisja/nawrót/wyleczona)',
    date: 'Data publikacji',
    identifier: 'Identyfikator zewnętrzny dla kwestionariusza',
    'last-date': 'Ostatnia data wystąpienia reakcji.',
    'abatement-date': 'Data ustąpienia (schorzenia)',
    'abatement-string': 'Abatement as a string',
    'asserted-date':
      'Data pierwszego wpisu (w historię choroby dotyczącego danego schorzenia)',
    'body-site': 'Okolica anatomiczna ciała',
    encounter: 'An encounter in which this request is made',
    evidence: 'Objaw',
    'evidence-detail':
      'Odniesienie do badań dodatkowych potwierdzających dane schorzenie',
    'onset-date': 'Date related onsets (dateTime and Period)',
    'onset-info': 'Onsets as a string',
    stage: 'Stopień (nasilenie/zaawansowania wg. formalnej skali',
    subject: 'Search by subject',
    'based-on': 'Na podstawie',
    diagnosis: 'Rozpoznanie',
    image: 'Obraz',
    issued: 'Data utworzenia opisu/wyniku',
    performer: 'Osoba opisująca',
    result: 'Wynik',
    specimen: 'Próbka',
    status: 'Status',
    authenticator: 'Podmiot poświadczający dokument',
    author: 'Autor dokumentu',
    class: 'Szpitalny/poradniany/ambulatoryjny/nagły wypadek',
    created: 'Data stworzenia dokumentu',
    custodian: 'Organizacja zarządzająca dokumnetem',
    event: 'Zdarzenia',
    facility: 'Miejsce pobytu pacjenta',
    format: 'Format/rodzaj dokumentu',
    indexed: 'Data utworzenia załącznika do dokumentu',
    period: 'Okres czasu, którego dotyczy dany dokument',
    'related-ref': 'Pokrewny zasób',
    relatesto: 'Odniesienie do dokumentacji',
    relation: 'replaces | transforms | signs | appends',
    securitylabel: 'Document security-tags',
    setting:
      'Additional details about where the content was created (e.g. clinical specialty)',
    appointment:
      'Wizyta podczas której pacjent został skierowany na dany pobyt w szpitalu (zasób pobyt)',
    episodeofcare: 'Opieka',
    incomingreferral: 'Skierowanie do szpitala',
    location: 'Lokalizacja placówki medycznej',
    'location-period': 'Czas pobytu pacjenta w placówce',
    'part-of': 'Another Encounter this encounter is part of',
    participant: 'Inne osoby zaangażowane w pobyt pacjenta, inne niż pacjent',
    'participant-type': 'Rola uczestnika pobytu',
    practitioner: 'Pracownik (każdy pracownik służby zdrowia)',
    reason: 'Przyczyna (przyczyna pobytu pacjenta w szpitalu)',
    'service-provider': 'The custodian organization of this Encounter record',
    'special-arrangement': 'Wózek inwalidzki, tłumacz, nosze, etc.',
    'connection-type':
      'Protocol/Profile/Standard to be used with this endpoint connection',
    organization: 'Organizacja',
    'payload-type':
      'The type of content that may be used at this endpoint (e.g. XDS Discharge summaries)',
    'care-manager': 'Lekarz prowadzący',
    condition: 'Schorzenia, problemy, diagnozy dla epikryzy',
    active: 'Aktywny status pacjenta',
    characteristic: 'Charakterystyka',
    endpoint:
      'Technical endpoints providing access to services operated for the organization',
    programname: 'One of the Program Names serviced by this HealthcareService',
    accession: 'The accession identifier for the study',
    basedon: 'The order for the image',
    bodysite: 'Przebadana okolica anatomiczna ciała',
    'dicom-class': 'Typ instancji',
    modality: 'Modalność',
    series: 'Identyfikator serii obrazków',
    started: 'When the study was started',
    study: 'The study identifier for the image',
    uid: 'The instance unique identifier',
    'empty-reason': 'Dlaczego lista jest pusta',
    item: 'Actual entry',
    notes: 'Adnotacja',
    source: 'Autor',
    title: 'Tytuł kwestionariusza',
    device: 'Urządzenie użyte podczas leczenia.',
    'effective-time': 'Date administration happened (or did not happen)',
    medication: 'Recepty',
    'not-given': 'Administrations that were not made',
    prescription: 'The identity of a prescription to list administrations from',
    'reason-given': 'Powody przepisania leku',
    'reason-not-given': 'Powody nie przepisania leku',
    authoredon: 'Przepisane leki',
    'intended-dispenser':
      'Returns prescriptions intended to be dispensed by this Organization',
    requester: 'Individual making the request',
    'combo-code': 'The code of the observation type or component type',
    'combo-value-concept':
      'The value or component value of the observation, if the value is a CodeableConcept',
    'component-code': 'The component code of the observation type',
    'component-code-value-quantity':
      'Wartości dla komponentów pary kod i jakość',
    'component-data-absent-reason':
      'The reason why the expected value in the element Observation.component.value[x] is missing.',
    'component-value-concept':
      'The value of the component observation, if the value is a CodeableConcept',
    'data-absent-reason':
      'The reason why the expected value in the element Observation.value[x] is missing.',
    method: 'Metoda użyta podczas rozpoznania',
    'related-target': 'Pokrewny zasób',
    'related-type':
      'has-member | derived-from | sequel-to | replaces | qualified-by | interfered-by',
    'value-concept':
      'The value of the observation, if the value is a CodeableConcept',
    'value-date':
      'The value of the observation, if the value is a date or period of time',
    'value-string':
      'The value of the observation, if the value is a string, and also searches in CodeableConcept.text',
    address:
      'A server defined search that may match any of the string fields in the Address, including line, city, state, country, postalCode, and/or text',
    'address-city': 'Miasto',
    partof: 'Główna organizacja',
    birthdate: 'Data urodzenia pacjenta',
    email: 'Adres mailowy',
    family: 'Nazwisko rodowe',
    gender: 'Płeć',
    'general-practitioner':
      "Patient's nominated general practitioner, not the organization that manages the record",
    given: 'Imię',
    link: 'All patients linked to the given patient',
    phone: 'Numer telefonu',
    authored: 'Date request signed',
    definition: 'Definicja',
    occurrence: 'When procedure should occur',
    'performer-type': 'Performer role',
    replaces: 'What request replaces',
    requisition: 'Composite Request ID',
    effective: 'Okres użycia kwestionariusza',
    jurisdiction: 'Intended jurisdiction for the questionnaire',
    publisher: 'Autor publikacji kwestionariusza',
    starts: 'Rozpoczęcie',
    ends: 'Zakończenie',
    channels: 'Kanały',
    layout: 'Układ',
    fullChart: 'Główne odprowadzenie',
    handleToDrag: 'Przeciągnij i upuść',
    leadsVisibility: 'Widoczność odprowadzeń',
    amplitude: 'Amplituda (mm/mV)',
    timeScale: 'Skala czasu (mm/s)',
    examPerformedCorrectly: 'Czy badanie wykonane poprawnie technicznie?',
    technicalIrregularities: 'Opis nieprawidłowości technicznych',
    examId: 'ID Badania',
    examDate: 'Data badania',
    examHour: 'Godzina badania',
    examCarriedOutIncorrectly: 'Badanie wykonane niepoprawnie technicznie',
    heartRhythm: 'HR (rytm serca)',
    parameter: 'Parametr',
    value: 'Wartość',
    unit: 'Jednostka',
    distance: 'Odległość',
    point: 'Punkt',
    amplitudeLabel: 'Amplituda',
    time: 'Czas',
    general: 'Ogólne',
    left: 'Lewa',
    right: 'Prawa',
    change: 'Zmiana',
    trend: 'Trend',
    infoDataDiagnostic:
      'Dane powstałe w wyniku mechanizmu segmentacji nie są danymi diagnostycznymi. Są to dane poglądowe.',
    showTrendLine: 'Włącz linię trendu',
    hideTrendLine: 'Ukryj linię trendu',
    selectValue: 'Wybierz wartość',
    selectDateToCompare: 'Wybierz datę, aby porównać',
    trendLineMode: 'Tryb z linią trendu',
    startStudy: 'Początek badania',
    endStudy: 'Koniec badania',
    channelVisibility: 'Widoczność kanałów',
    selectAll: 'Zaznacz wszystko',
    uncheckEverything: 'Odznacz wszstko',
    settings: 'Ustawienia',
    resetZoom: 'Resetuj przybliżenie',
    hideOptions: 'Ukryj opcje',
    showOptions: 'Pokaż opcje',
    close: 'Zamknij',
    studyDate: 'Data badania',
    studyHour: 'Godzina badania',
    badlyDoneExamination: 'Badanie wykonane niepoprawnie technicznie',
    mainCourse: 'Główny przebieg',
    dragAndDrop: 'Przeciągnij i upuść'
  },
  resourcesStructures: {
    AllergyIntolerance: {
      clinicalStatus:
        'Status kliniczny (wartości takie jak: aktywna/remisja/nawrót/wyleczona)',
      verificationStatus: 'Rodzaj diagnozy',
      type: 'Typ',
      category: 'Kategoria',
      criticality: 'Istotność',
      code: 'Kod',
      patient: 'Pacjent',
      encounter: 'Pobyt',
      assertedDate: 'assertedDate',
      onsetDateTime: 'Data rozpoczęcia',
      onsetAge: '',
      onsetPeriod: '',
      onsetRange: '',
      onsetString: '',
      recorderDate: '',
      recorder:
        'Osoba która zanotowała wystąpienie jakiegoś stanu/schorzenia/zdarzenia',
      asserter:
        "Osoba dokonująca wpisu w dokumentację podlegającego pod zasób 'Stan'",
      lastOccurence: '',
      note: 'Notatka',
      reaction: 'Reakcja alergiczna',
      reactions: 'Reakcje alergiczne',
      'reaction-substance': 'Substancje',
      'reaction-manifestation': 'Objaw',
      'reaction-description': 'Opis',
      'reaction-onset': 'Początek',
      'reaction-severity':
        "Nasilenie (nasilenie reakcji alergicznej jako małe/umiarkowane/duże w zasobie 'Alergie/nietolerancje pokarmowe')",
      'reaction-exposureRoute': 'Droga narażenia (na alergen)',
      'reaction-note': 'Notatka'
    },
    Condition: {
      clinicalStatus:
        'Status kliniczny (wartości takie jak: aktywna/remisja/nawrót/wyleczona)',
      verificationStatus: 'Rodzaj diagnozy',
      category: 'Kategoria',
      severity: 'Nasilenie',
      code: 'Kod',
      context: '',
      bodySite: 'Przebadana okolica anatomiczna ciała',
      conditionSubject: 'Podmiot',
      encounter: 'Pobyt',
      onsetDateTime: 'Data rozpoczęcia',
      assertedDate: 'assertedDate',
      onsetAge: '',
      onsetPeriod: '',
      onsetRange: '',
      onsetString: '',
      abatementDateTime: '',
      abatementAge: '',
      abatementPeriod: '',
      abatementRange: '',
      abatementString: '',
      recordedDate: '',
      recorder:
        'Osoba która zanotowała wystąpienie jakiegoś stanu/schorzenia/zdarzenia',
      asserter:
        "Osoba dokonująca wpisu w dokumentację podlegającego pod zasób 'Stan'",
      stage: 'Stopień (nasilenie/zaawansowania wg. formalnej skali',
      'stage-summary': '',
      'stage-assessment': '',
      'stage-type': '',
      evidence: 'Objaw',
      'evidence-code': '',
      'evidence-detail':
        'Odniesienie do badań dodatkowych potwierdzających dane schorzenie',
      note: 'Notatka'
    },
    DiagnosticReport: {
      basedOn: '',
      status: 'Status',
      category: 'Kategoria',
      code: 'Kod',
      subject: '',
      encounter: 'Pobyt',
      effectiveDateTime: '',
      effectivePeriods: 'Okres efektywny',
      issued: 'Data utworzenia opisu/wyniku',
      performer: 'Osoba opisująca',
      resultsInterpreter: '',
      specimen: 'Próbka',
      result: 'Wynik',
      imagingStudy: 'Badanie obrazowe',
      media: '',
      'media-comment': '',
      'media-link': '',
      conclusion: 'Wniosek',
      conclusionCode: '',
      presentedForm: ''
    },
    DocumentReference: {
      status: 'Status',
      docStatus: '',
      type: 'Typ',
      category: 'Kategoria',
      subject: '',
      date: '',
      author: 'Autor dokumentu',
      authenticator: 'Podmiot poświadczający dokument',
      custodian: 'Organizacja zarządzająca dokumnetem',
      relatesTo: 'Odniesienie do dokumentacji',
      'relatesTo-code': '',
      'relatesTo-target': '',
      description: 'Opis',
      securityLabel: '',
      content: '',
      'content-attachment': '',
      'content-format': '',
      context: 'Kontekst',
      'context-encounter': '',
      'context-event': '',
      'context-period': '',
      'context-facilityType': '',
      'context-practiceSetting': '',
      'context-sourcePatientInfo': '',
      'context-related': ''
    },
    Encounter: {
      status: 'Status',
      statusHistory: '',
      'statusHistory-status': '',
      'statusHistory-period': '',
      class: 'Szpitalny/poradniany/ambulatoryjny/nagły wypadek',
      classHistory: '',
      'classHistory-class': '',
      'classHistory-period': '',
      type: 'Typ',
      serviceType: '',
      priority: 'Priorytet',
      subject: '',
      episodeOfCare: 'Opieka',
      basedOn: '',
      participant: 'Inne osoby zaangażowane w pobyt pacjenta, inne niż pacjent',
      'participant-type': 'Rola uczestnika pobytu',
      'participant-period': '',
      'participant-individual': '',
      appointment:
        'Wizyta podczas której pacjent został skierowany na dany pobyt w szpitalu (zasób pobyt)',
      period: '',
      length: '',
      reasonCode: '',
      reasonReference: '',
      diagnosis: 'Rozpoznanie',
      'diagnosis-condition': '',
      'diagnosis-use': '',
      'diagnosis-rank': '',
      account: '',
      hospitalization: '',
      'hospitalization-preAdmissionIdentifier': '',
      'hospitalization-origin': '',
      'hospitalization-admitSource': '',
      'hospitalization-reAdmission': '',
      'hospitalization-dietPreference': '',
      'hospitalization-specialCourtesy': '',
      'hospitalization-specialArrangement': '',
      'hospitalization-destination': '',
      'hospitalization-dischargeDisposition': '',
      location: 'Lokalizacja placówki medycznej',
      'location-location': '',
      'location-status': '',
      'location-physicalType': '',
      'location-period': 'Czas pobytu pacjenta w placówce',
      encounterServiceProvider: 'serviceProvider',
      partOf: ''
    },
    Endpoint: {
      status: 'Status',
      connectionType: '',
      name: 'Nazwa',
      managingOrganization: '',
      contact: '',
      period: '',
      payloadType: '',
      payloadMimeType: '',
      address: '',
      header: '',
      unit: 'Jednostka',
      value: 'Wartość',
      referenceValue: 'Wartość referencyjna'
    },
    EpisodeOfCare: {
      status: 'Status',
      episodeStatusHistory: 'Historia leczenia',
      'statusHistory-status': '',
      'statusHistory-period': '',
      type: 'Typ',
      diagnosis: 'Rozpoznanie',
      'diagnosis-condition': '',
      'diagnosis-role': '',
      'diagnosis-rank': '',
      patient: 'Pacjent',
      managingOrganization: '',
      period: '',
      episodeReferralRequest: 'Skierowanie',
      episodeCareManager: 'Lekarz prowadzący',
      team: '',
      account: ''
    },
    HealthcareService: {
      active: 'Aktywny status',
      providedBy: '',
      category: 'Kategoria',
      type: 'Typ',
      specialty: '',
      location: 'Lokalizacja placówki medycznej',
      name: 'Nazwa',
      comment: '',
      extraDetails: '',
      photo: '',
      telecom: 'Dane kontaktowe email/nr tel. pracownika',
      coverageArea: '',
      'serviceProvisionCode	': '',
      eligibility: '',
      'eligibility-code': '',
      'eligibility-comment': '',
      program: '',
      characteristic: 'Charakterystyka',
      communication: '',
      referralMethod: '',
      appointmentRequired: '',
      availableTime: '',
      'availableTime-daysOfWeek': '',
      'availableTime-allDay': '',
      'availableTime-availableStartTime': '',
      'availableTime-availableEndTime': '',
      notAvailable: '',
      'notAvailable-description': '',
      'notAvailable-during': '',
      availabilityExceptions: '',
      endpoint: ''
    },
    ImagingStudy: {
      status: 'Status',
      modality: 'Modalność',
      subject: '',
      encounter: 'Pobyt',
      started: '',
      basedOn: '',
      referrer: '',
      interpreter: '',
      endpoint: '',
      numberOfSeries: '',
      numberOfInstances: '',
      procedureReference: '',
      procedureCode: '',
      location: 'Lokalizacja placówki medycznej',
      reasonCode: '',
      reasonReference: '',
      note: 'Notatka',
      description: 'Opis',
      series: 'Identyfikator serii obrazków',
      'series-number': '',
      'series-modality': '',
      'series-description': '',
      'series-numberOfInstances': '',
      'series-endpoint': '',
      'series-bodySite': '',
      'series-laterality': '',
      'series-specimen': '',
      'series-started': '',
      'series-performer': '',
      'series-performer-function': '',
      'series-performer-actor': '',
      'series-instance': '',
      'series-instance-sopClass': '',
      'series-instance-number': '',
      'series-instance-title': ''
    },
    List: {
      identifier: '',
      status: 'Status',
      mode: '',
      title: '',
      code: '',
      subject: '',
      encounter: 'Pobyt',
      date: '',
      source: '',
      orderedBy: '',
      note: 'Notatka',
      entry: '',
      'entry-flag': '',
      'entry-deleted': '',
      'entry-date': '',
      'entry-item': '',
      emptyReason: ''
    },
    Medication: {
      code: '',
      status: 'Status',
      manufacturer: 'Producent',
      medicationForm: 'Forma',
      amounts: 'Ilość',
      ingredients: 'Skład',
      'ingredient-itemCodeableConcept': '',
      'ingredient-itemReference': '',
      'ingredient-isActive': '',
      'ingredient-strength': '',
      batch: '',
      batchLotNumber: 'Numer seryjny partii',
      batchExpirationDate: 'Data ważności',
      dose: 'Dawka',
      medicationManufacturer: 'Producent',
      package: 'Opakowanie',
      form: 'Forma',
      amount: 'Ilość',
      ingredient: 'Skład',
      'batch-lotNumber': 'Numer seryjny partii',
      'batch-expirationDate': 'Data ważności'
    },
    MedicationAdministration: {
      instantiates: '',
      partOf: '',
      status: 'Status',
      statusReason: '',
      category: '',
      context: 'Kontekst',
      medication: 'Lek',
      medicationCodeableConcept: '',
      medicationReference: '',
      subject: '',
      supportingInformation: '',
      effectiveDateTime: '',
      effectivePeriods: 'Okres efektywny',
      performer: 'Osoba opisująca',
      'performer-function': '',
      'performer-actor': '',
      patient: 'Pacjent',
      reasonCode: 'Przyczyna',
      reasonReference: '',
      request: '',
      device: 'Urządzenie użyte podczas leczenia',
      note: 'Notatka',
      medicationDosage: 'Dawkowanie',
      'dosage-text': '',
      'dosage-site': '',
      medicationDosageRoute: 'Sposób podania',
      medicationDosageMethod: 'Metoda dawkowania',
      'dosage-dose': '',
      rateRatio: '',
      rateQuantity: '',
      eventHistory: '',
      validityPeriodStart: 'Początek podawania leku',
      validityPeriodEnd: 'Koniec podawania leku'
    },
    MedicationRequest: {
      status: 'Status',
      context: 'Kontekst',
      statusReason: '',
      intent:
        "Określa czy dane skierowanie 'request' jest zleceniem/propozycją/planem",
      category: '',
      priority: 'Priorytet',
      prescription: 'Recepta',
      doNotPerform: '',
      reportedBoolean: '',
      reportedReference: '',
      method: 'Metoda przyjęcia',
      medicationCodeableConcept: '',
      medicationReference: '',
      subject: '',
      encounter: 'Pobyt',
      supportingInformation: '',
      authoredOn: 'Przepisane leki',
      requester: '',
      performer: 'Osoba opisująca',
      performerType: '',
      recorder:
        'Osoba która zanotowała wystąpienie jakiegoś stanu/schorzenia/zdarzenia',
      reasonCode: '',
      reasonReference: '',
      route: 'Sposób przyjęcia',
      instantiatesCanonical: '',
      instantiatesUri: '',
      basedOn: '',
      groupIdentifier: '',
      courseOfTherapyType: '',
      insurance: '',
      note: 'Notatka',
      dosageInstructions: 'Intrukcja dawkowania',
      dispenseRequest: '',
      'dispenseRequest-initialFill': '',
      'dispenseRequest-initialFill-quantity': '',
      'dispenseRequest-initialFill-duration': '',
      'dispenseRequest-dispenseInterval': '',
      'dispenseRequest-validityPeriod': '',
      'dispenseRequest-numberOfRepeatsAllowed': '',
      'dispenseRequest-quantity': '',
      'dispenseRequest-expectedSupplyDuration': '',
      'dispenseRequest-performer': '',
      substitution: '',
      'substitution-allowedBoolean': '',
      'substitution-allowedCodeableConcept': '',
      'substitution-reason': '',
      priorPrescription: '',
      detectedIssue: '',
      eventHistory: '',
      additionalInstruction: 'Instrukcja dodatkowa',
      validityPeriodStart: 'Początek podawania leku',
      validityPeriodEnd: 'Koniec podawania leku'
    },
    Observation: {
      basedOn: '',
      partOf: '',
      status: 'Status',
      category: 'Kategoria',
      code: '',
      observationSubject: 'Podmiot',
      subject: 'Podmiot',
      focus: '',
      encounter: 'Pobyt',
      effectiveDateTime: 'Data badania',
      effectivePeriod: '',
      effectiveTiming: '',
      effectiveInstant: '',
      issued: 'Data utworzenia opisu/wyniku',
      performer: 'Osoba opisująca',
      performationDate: 'Data wykonania',
      valueQuantity: '',
      valueCodeableConcept: '',
      valueString: '',
      valueBoolean: '',
      valueInteger: '',
      valueRange: '',
      valueRatio: '',
      valueSampledData: '',
      valueTime: '',
      valueDateTime: '',
      valuePeriod: '',
      dataAbsentReason: '',
      interpretation: '',
      note: 'Notatka',
      bodySite: 'Przebadana okolica anatomiczna ciała',
      method: 'Metoda użyta podczas rozpoznania',
      specimen: 'Próbka',
      device: 'Urządzenie użyte podczas leczenia',
      referenceRange: '',
      'referenceRange-low': '',
      'referenceRange-high': '',
      'referenceRange-type': '',
      'referenceRange-appliesTo': '',
      'referenceRange-age': '',
      'referenceRange-text': '',
      hasMember: '',
      derivedFrom: '',
      component: '',
      'component-code': '',
      'component-valueQuantity': 'Wartości dla komponentów pary kod i jakość',
      'component-valueCodeableConcept': '',
      'component-valueString': '',
      'component-valueBoolean': '',
      'component-valueInteger': '',
      'component-valueRange': '',
      'component-valueRatio': '',
      'component-valueSampledData': '',
      'component-valueTime': '',
      'component-valueDateTime': '',
      'component-valuePeriod': '',
      'component-dataAbsentReason': '',
      'component-interpretation': '',
      'component-referenceRange': '',
      temperature: 'Temperatura',
      fastBackward: 'Przesuń do początku {from} - {to}',
      backward: 'Przesuń w tył {from} - {to}',
      fastForward: 'Przesuń do końca {from} - {to}',
      forward: 'Przesuń w przód {from} - {to}',
      resetRange: 'Reset zakresu przeglądania {from} - {to}',
      currentRange: 'Przejdź do bieżących pomiarów',
      dateRange: '<b>Zakres danych od:</b> {from} <b>do:</b> {to}',
      studyRange: '<b>Zakres badania od:</b> {from} <b>do:</b> {to}',
      timeRange: '<b>Zakres czasowy danych:</b> {diff} s',
      selectAll: 'Zaznacz wszystko',
      unselectAll: 'Odznacz wszystko'
    },
    OperationDefinition: {
      url: '',
      version: '',
      name: 'Nazwa',
      title: '',
      status: 'Status',
      kind: '',
      experimental: '',
      date: '',
      publisher: '',
      contact: '',
      description: 'Opis',
      useContext: '',
      jurisdiction: '',
      purpose: '',
      affectsState: '',
      code: '',
      comment: '',
      base: '',
      resource: '',
      system: '',
      type: '',
      instance: '',
      inputProfile: '',
      outputProfile: '',
      parameter: '',
      'parameter-name': '',
      'parameter-use': '',
      'parameter-min': '',
      'parameter-max': '',
      'parameter-documentation': '',
      'parameter-type': '',
      'parameter-targetProfile': '',
      'parameter-searchType': '',
      'parameter-binding': '',
      'parameter-bindingstrength': '',
      'parameter-bindingvalueSet': '',
      'parameter-referencedFrom': '',
      'parameter-referencedFrom-source': '',
      'parameter-referencedFrom-sourceId': '',
      'parameter-part': '',
      overload: '',
      'overload-parameterName': '',
      'overload-comment': ''
    },
    Organization: {
      active: 'Aktywny status',
      type: '',
      name: 'Nazwa',
      alias: '',
      telecom: 'Dane kontaktowe email/nr tel. pracownika',
      address: '',
      partOf: '',
      contact: '',
      'contact-purpose': '',
      'contact-name': '',
      'contact-telecom': '',
      'contact-address': '',
      endpoint: ''
    },
    Patient: {
      active: 'Aktywny status',
      name: 'Nazwa',
      telecom: 'Dane kontaktowe email/nr tel. pracownika',
      gender: 'Płeć',
      birthDate: 'Data urodzenia',
      deceasedBoolean: '',
      deceasedDateTime: '',
      address: '',
      maritalStatus: '',
      multipleBirthBoolean: '',
      multipleBirthInteger: '',
      photo: '',
      contact: '',
      'contact-relationship': '',
      'contact-name': '',
      'contact-telecom': '',
      'contact-address': '',
      'contact-gender': '',
      'contact-organization': '',
      'contact-period': '',
      communication: '',
      'communication-language': '',
      'communication-preferred': '',
      generalPractitioner: '',
      managingOrganization: '',
      link: '',
      'link-other': '',
      'link-type': '',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      line: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      age: 'Wiek'
    },
    Practitioner: {
      active: 'Aktywny status',
      name: 'Nazwa',
      telecom: 'Dane kontaktowe email/nr tel. pracownika',
      address: '',
      gender: 'Płeć',
      birthDate: 'Data urodzenia',
      photo: '',
      qualification: '',
      'qualification-identifier': '',
      'qualification-code': '',
      'qualification-period': '',
      'qualification-issuer': '',
      communication: '',
      identifier: 'Identyfikator',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      pesel: 'PESEL'
    },
    Questionnaire: {
      url: '',
      identifier: '',
      version: '',
      name: 'Nazwa',
      title: '',
      derivedFrom: '',
      status: 'Status',
      experimental: '',
      subjectType: '',
      date: '',
      publisher: '',
      contact: '',
      description: 'Opis',
      useContext: '',
      jurisdiction: '',
      purpose: '',
      copyright: '',
      approvalDate: '',
      lastReviewDate: '',
      effectivePeriod: '',
      code: '',
      'item-linkId': '',
      'item-definition': '',
      'item-code': '',
      'item-prefix': '',
      'item-text': '',
      'item-type': '',
      'item-enableWhen': '',
      'item-enableWhen-question': '',
      'item-enableWhen-operator': '',
      'item-enableWhen-answerBoolean': '',
      'item-enableWhen-answerDecimal': '',
      'item-enableWhen-answerInteger': '',
      'item-enableWhen-answerDate': '',
      'item-enableWhen-answerDateTime': '',
      'item-enableWhen-answerTime': '',
      'item-enableWhen-answerString': '',
      'item-enableWhen-answerCoding': '',
      'item-enableWhen-answerQuantity': '',
      'item-enableWhen-answerReference': '',
      enableBehavior: '',
      required: '',
      repeats: '',
      readOnly: '',
      maxLength: '',
      answerValueSet: '',
      answerOption: '',
      'answerOption-valueInteger': '',
      'answerOption-valueDate': '',
      'answerOption-valueTime': '',
      'answerOption-valueString': '',
      'answerOption-valueCoding': '',
      'answerOption-valueReference': '',
      'answerOption-initialSelected': '',
      initial: '',
      'initial-valueBoolean': '',
      'initial-valueDecimal': '',
      'initial-valueInteger': '',
      'initial-valueDate': '',
      'initial-valueDateTime': '',
      'initial-valueTime': '',
      'initial-valueString': '',
      'initial-valueUri': '',
      'initial-valueAttachment': '',
      'initial-valueCoding': '',
      'initial-valueQuantity': '',
      'initial-valueReference': '',
      item: ''
    },
    StructureDefinition: {
      url: '',
      version: '',
      name: 'Nazwa',
      title: '',
      status: 'Status',
      experimental: '',
      date: '',
      publisher: '',
      contact: '',
      description: 'Opis',
      useContext: '',
      jurisdiction: '',
      purpose: '',
      copyright: '',
      keyword: '',
      fhirVersion: '',
      mapping: '',
      'mapping-identity': '',
      'mapping-uri': '',
      'mapping-name': '',
      'mapping-comment': '',
      kind: '',
      abstract: '',
      context: 'Kontekst',
      'context-type': '',
      'context-expression': '',
      contextInvariant: '',
      type: '',
      baseDefinition: '',
      derivation: '',
      snapshot: '',
      'snapshot-element': '',
      differential: '',
      'differential-element': ''
    },
    Procedure: {
      instantiatesCanonical: '',
      instantiatesUri: '',
      basedOn: '',
      partOf: '',
      status: 'Status',
      statusReason: '',
      category: '',
      code: '',
      subject: '',
      encounter: 'Pobyt',
      performedDateTimes: 'Data wykonania',
      performedPeriod: '',
      performedString: '',
      performedAge: '',
      performedRange: '',
      recorder:
        'Osoba która zanotowała wystąpienie jakiegoś stanu/schorzenia/zdarzenia',
      asserter:
        "Osoba dokonująca wpisu w dokumentację podlegającego pod zasób 'Stan'",
      performer: 'Osoba opisująca',
      'performer-function': '',
      'performer-actor': '',
      'performer-onBehalfOf': '',
      location: 'Lokalizacja placówki medycznej',
      reasonsCode: 'Przyczyna',
      reasonReference: '',
      bodySite: '',
      outcome: '',
      report: '',
      complication: '',
      complicationDetail: '',
      followUp: '',
      note: 'Notatka',
      focalDevice: '',
      'focalDevice-action': '',
      'focalDevice-manipulated': '',
      usedReference: '',
      usedCode: ''
    },
    ProcedureRequest: {
      status: 'Status',
      intent: 'intent',
      category: 'Kategoria',
      context: 'Kontekst',
      priority: 'Priorytet',
      authoredOn: 'Wydane w dniu'
    },
    Consent: {
      attachment: 'Załącznik',
      status: 'Status',
      scope: '',
      category: '',
      consentPatient: 'Pacjent',
      dateTime: '',
      performer: 'Osoba opisująca',
      performedDateTime: 'Data wykonania',
      organization: 'Organizacja',
      sourceAttachment: '',
      sourceReference: '',
      policy: '',
      'policy-authority': '',
      'policy-uri': '',
      policyRule: '',
      verification: '',
      'verification-verified': '',
      'verification-verifiedWith': '',
      'verification-verificationDate': '',
      'provision-type': '',
      'provision-period': '',
      'provision-actor': '',
      'provision-actor-role': '',
      'provision-actor-reference': '',
      'provision-action': '',
      'provision-securityLabel': '',
      'provision-purpose': '',
      'provision-class': '',
      'provision-code': '',
      'provision-dataPeriod': '',
      'provision-data': '',
      'provision-data-meaning': '',
      'provision-data-reference': '',
      provision: ''
    },
    Specimen: {
      accessionIdentifier: '',
      status: 'Status',
      types: 'Typ',
      subject: '',
      receivedTime: '',
      parent: '',
      patient: 'Pacjent',
      request: '',
      collection: '',
      'collection-collector': '',
      'collection-collectedDateTime': '',
      'collection-collectedPeriod': '',
      'collection-duration': '',
      'collection-quantity': '',
      'collection-method': '',
      'collection-bodySite': '',
      'collection-fastingStatusCodeableConcept': '',
      'collection-fastingStatusDuration': '',
      processing: '',
      'processing-description': '',
      'processing-procedure': '',
      'processing-additive': '',
      'processing-timeDateTime': '',
      'processing-timePeriod': '',
      performedDateTime: 'Data wykonania',
      container: '',
      'container-identifier': '',
      'container-description': '',
      'container-type': '',
      'container-capacity': '',
      'container-specimenQuantity': '',
      'container-additiveCodeableConcept': '',
      'container-additiveReference': '',
      condition: 'Schorzenia, problemy, diagnozy dla epikryzy',
      note: 'Notatka'
    },
    Location: {
      status: 'Status',
      operationalStatus: '',
      name: 'Nazwa',
      alias: '',
      description: 'Opis',
      mode: '',
      type: '',
      telecom: 'Dane kontaktowe email/nr tel. pracownika',
      address: '',
      physicalType: '',
      position: '',
      'position-longitude': '',
      'position-latitude': '',
      'position-altitude': '',
      managingOrganization: '',
      partOf: '',
      hoursOfOperation: '',
      'hoursOfOperation-daysOfWeek': '',
      'hoursOfOperation-allDay': '',
      'hoursOfOperation-openingTime': '',
      'hoursOfOperation-closingTime': '',
      availabilityExceptions: '',
      endpoint: ''
    }
  },
  datatypes: {
    Attachment: {
      contentType: '',
      language: '',
      data: '',
      url: '',
      size: '',
      hash: '',
      title: '',
      creation: ''
    },
    Coding: {
      system: '',
      version: '',
      code: '',
      display: '',
      userSelected: ''
    },
    CodeableConcept: {
      coding: '',
      text: ''
    },
    Quantity: {
      value: '',
      comparator: '',
      unit: '',
      system: '',
      code: ''
    },
    Money: {
      value: '',
      currency: ''
    },
    Range: {
      low: '',
      high: ''
    },
    Ratio: {
      numerator: '',
      denominator: ''
    },
    Period: {
      start: '',
      end: ''
    },
    SampledData: {
      origin: '',
      period: '',
      factor: '',
      lowerLimit: '',
      upperLimit: '',
      dimensions: '',
      data: ''
    },
    Identifier: {
      use: '',
      type: '',
      system: '',
      value: '',
      period: '',
      assigner: ''
    },
    HumanName: {
      use: '',
      text: '',
      family: '',
      given: '',
      prefix: '',
      suffix: '',
      period: ''
    },
    Address: {
      use: '',
      type: '',
      text: '',
      line: '',
      city: '',
      district: '',
      state: '',
      postalCode: '',
      country: '',
      period: ''
    },
    ContactPoint: {
      system: '',
      value: '',
      use: '',
      rank: '',
      period: ''
    },
    Timing: {
      event: '',
      repeat: '',
      boundsDuration: '',
      boundsRange: '',
      boundsPeriod: '',
      count: '',
      countMax: '',
      duration: '',
      durationMax: '',
      durationUnit: '',
      frequency: '',
      frequencyMax: '',
      period: '',
      periodMax: '',
      periodUnit: '',
      dayOfWeek: '',
      timeOfDay: '',
      when: '',
      offset: '',
      code: ''
    },
    Signature: {
      type: '',
      when: '',
      who: '',
      onBehalfOf: '',
      targetFormat: '',
      sigFormat: '',
      data: ''
    },
    Annotation: {
      authorReference: '',
      authorString: '',
      time: '',
      text: ''
    }
  }
};
