
import { computed, defineComponent, PropType, ref } from 'vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import {
  MessageI,
  NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE
} from '@/composables/types/communicator';
import useCommunicator from '@/composables/useCommunicator';

export default defineComponent({
  name: 'NotificationItem',
  components: { BaseButton },
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const markAsRead = (message: MessageI) => {
      useCommunicator().markAsRead(message);
    };

    const isDocShared = computed(
      () =>
        props.message?.resource?.source?.actionCode ===
        NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE
    );

    const docId = computed(() => {
      const args = props.message?.resource?.source?.descriptionArgs;
      return args && args.length > 0 ? args[0] : null;
    });

    const docName = computed(() => {
      const args = props.message?.resource?.source?.descriptionArgs;
      return args && args.length > 8 ? args[9] : null;
    });

    return {
      markAsRead,
      isDocShared,
      docId,
      docName
    };
  }
});
