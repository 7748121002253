<template>
  <span class="tag-add">
    <input
      ref="tag"
      v-model="value"
      class="tag-input"
      :placeholder="$t('tags.newtag')"
      @keypress.enter="add"
    />
    <span
      class="tag-add-button"
      :class="{ 'tag-add-button-disabled': !okToSave }"
      @click="add"
    >
      +
    </span>
  </span>
</template>

<script>
import mixins from '../utils/mixins';

export default {
  name: 'AddTag',
  mixins: [mixins],
  props: {
    tag: {},
    editable: { default: true, type: Boolean, required: false }
  },
  data() {
    return {
      value: ''
    };
  },
  computed: {
    okToSave() {
      return this.value != null && this.value.trim().length > 0;
    }
  },
  mounted() {
    this.$nextTick(() => {
      const ref = this.$refs.tag;
      if (ref != null) {
        ref.focus();
      }
    });
  },
  methods: {
    add() {
      if (this.okToSave) {
        this.$emit('add', this.value);
      }
    }
  }
};
</script>

<style scoped></style>
