
import radBox from '@/assets/alleradVbox.svg';
import moreIcons from '@/assets/more-icons.svg';
import { computed, watch } from 'vue';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';
import FullTextSearch from '@/components/FullTextSearch/FullTextSearch.vue';
import LoggedUserInfo from '@/components/User/LoggedUserInfo.vue';
import AdminFilters from '@/components/Patient/AdminFilters.vue';
import PatientFilters from '@/components/Patient/PatientFilters.vue';
import useUser from '@/composables/useUser';

export default {
  name: 'HamburgerMenu',
  emits: ['close-hamburger'],
  components: {
    PatientFilters,
    AdminFilters,
    FullTextSearch,
    LoggedUserInfo,
    FocusLoop
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props: any, { emit }: any) {
    const tokenExist = computed(() => useUser().tokenExist());
    const admin = computed(() => useUser().isAdmin());

    watch(
      () => props.isOpen,
      v => {
        if (v) {
          document.body.classList.add('modal');
          document.getElementById('hamburger-inside')?.focus();
        } else {
          document.body.classList.remove('modal');
          document.getElementById('hamburger-menu-button')?.focus();
        }
      }
    );

    const closeHamburger = (): void => {
      emit('close-hamburger');
    };

    return {
      closeHamburger,
      radBox,
      moreIcons,
      tokenExist,
      admin
    };
  }
};
