<template>
  <div class="settings">
    <div class="center-flex">
      <BaseButton type="secondary_blue" @click="handleGoToMainPage">
        <font-awesome-icon icon="arrow-left" aria-hidden="true" />
        {{ $t('base.mainPage') }}
      </BaseButton>
      <p class="settings__title">
        {{ $t('settings.name') }}
      </p>
    </div>

    <span class="visuallyhidden">{{ $t('settings.desc') }}</span>
    <div class="settings__wrapper">
      <SettingsRefresh class="settings__wrapper__form" />
      <div class="settings__wrapper__right">
        <a class="first-col" :href="avatar">
          <span>{{ $t('settings.avatar') }}</span>
        </a>
        <a class="last-col" :href="avatar">
          <UserAvatar></UserAvatar>
        </a>
        <a class="first-col" :href="pass">
          <span>{{ $t('settings.changePass') }}</span>
        </a>
        <a class="last-col" :href="pass">
          <font-awesome-icon icon="key" height="30"></font-awesome-icon>
        </a>
      </div>
      <TenantsRadio class="settings__wrapper__form" v-if="isAdmin" />
    </div>
  </div>
</template>

<script lang="ts">
import SettingsRefresh from '@/components/Settings/SettingsRefresh.vue';
import Address from '@/components/FhirComponents/Address/Address.vue';
import Patient from '@/components/FhirComponents/Patient/Patient.vue';
import UserAvatar from '@/components/User/UserAvatar.vue';
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import TenantsRadio from '@/components/ONLOAD_CONFIGURATION/TenantsRadio.vue';
import useUser from '@/composables/useUser';
import BaseButton from '@/components/Base/BaseButton.vue';
import router from '@/router';

export default defineComponent({
  name: 'Settings',
  components: {
    BaseButton,
    Patient,
    Address,
    SettingsRefresh,
    UserAvatar,
    TenantsRadio
  },
  setup() {
    const i18n = useI18n();
    const params = computed(() => {
      const params = new URLSearchParams();
      params.append('redirect', '/portal-pacjenta/settings');
      params.append('lang', i18n.locale.value);
      params.append('theme', 'patientPortal');
      return params;
    });
    const url = (base: string) => `${base}?${params.value.toString()}`;

    const avatar = computed(() => url('/auth/avatar'));
    const pass = computed(() => url('/auth/registerPatient'));

    onMounted(() => {
      document.body.focus();
    });

    const isAdmin = computed(() => useUser().isAdmin());
    const handleGoToMainPage = () => router.push({ name: 'Patient' });

    return {
      isAdmin,
      avatar,
      pass,
      handleGoToMainPage
    };
  }
});
</script>

<style scoped lang="scss">
.settings {
  &__title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__form {
      border-radius: 6px;
      border: 1px solid var(--light-gray);
      padding: 1rem;
    }

    &__right {
      padding: 1rem;
      justify-self: end;

      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 10px;

      align-items: center;
      align-content: start;
    }
  }

  .settings__wrapper__right a {
    color: var(--dark);
    text-decoration: none !important;
  }

  .first-col {
    justify-self: end;
  }
  .last-col {
    justify-self: center;
  }
}

.settings__wrapper-ul {
  list-style-type: none;
}

.center-flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
