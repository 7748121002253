<template>
  <div
    class="line-container"
    :class="{ vertical: vertical, horizontal: !vertical }"
  >
    <div
      class="line"
      :class="{ vertical: vertical, horizontal: !vertical, [typedClass]: true }"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseSpacer',
  props: {
    vertical: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default',
      validator: value => {
        return (
          ['default', 'success', 'warning', 'danger', 'info'].indexOf(value) !==
          -1
        );
      }
    }
  },
  computed: {
    typedClass() {
      return `line-${this.type}`;
    }
  }
};
</script>

<style scoped lang="scss">
.line-container {
  display: grid;
  align-self: stretch;
  align-content: stretch;
  justify-content: stretch;
  justify-self: stretch;
  padding: 5px 5px;
  &.vertical {
    padding: 5px 5px;
  }
}

.line {
  border: 0;
  &.horizontal {
    border-top: 2px solid var(--primary);
  }
  &.vertical {
    border-left: 2px solid var(--primary);
  }
}

.line-default {
  border-color: var(--primary) !important;
}
.line-success {
  border-color: var(--green) !important;
}
.line-warning {
  border-color: var(--yellow) !important;
}
.line-danger {
  border-color: var(--red) !important;
}
.line-info {
  border-color: var(--blue) !important;
}
</style>
