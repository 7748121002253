import { fhirService, jsonStorageService } from './AxiosInstanceBuilder';

class ConfigRepo {
  loadTenants(): Promise<any> {
    return fhirService.get('info/$tenants');
  }

  loadConfigJsonStorage(): Promise<any> {
    return jsonStorageService.get(
      'storage/PatientPortal?_includeJsonContent=true&_includeTags='
    );
  }

  /**
   * @param user=*
   * save config with this param to allow all users to show configuration,
   * but only admin can edit config
   *
   */
  postConfiguration(params: any): Promise<any> {
    return jsonStorageService.post('storage/PatientPortal?user=*', params);
  }

  updateConfiguration(params: any, id: string): Promise<any> {
    return jsonStorageService.put(`storage/PatientPortal/${id}`, params);
  }

  removeConfiguration(id: string): Promise<any> {
    return jsonStorageService.delete(`storage/PatientPortal/${id}`);
  }
}
export default new ConfigRepo();
