export default {
  changeFontSize: 'Change font size. Available font sizes: small, medium, big',
  text: 'Text',
  fontSize: 'Font size',
  small: 'small',
  medium: 'medium',
  big: 'big',
  changeContrast: 'Change contrast',
  contrast: 'Contrast',
  contrastBase: 'Base contrast',
  contrastBlackBgFontYellow: 'Black background, yellow text',
  contrastYellowBgBlackFont: 'Yellow background, black text',
  links: 'Links',
  linkHighlightingMode: 'Links highlighting mode',
  active: 'Active',
  inactive: 'Inactive',
  changeTheme: 'Change theme',
  theme: 'Theme',
  themeBright: 'Bright theme',
  themeDark: 'Dark theme',
  changeLang: 'Change language',
  lang: 'Language',
  pl: 'Polish',
  en: 'English',
  base: 'Base',
  mousePointer: 'Cursor',
  mousePointerLineWithZoom:
    'Mouse pointer magnification and a line to make the text easier to read',
  skipToMainContent: 'Skip to main content',
  accessibilityMenu: 'Accessibility menu',
  ariaDescribyText:
    'Accessibility menu that has six options to change the view of the patient portal. The first option is the ability to enlarge the texts. The second option is to change the contrast. The third option is to highlight links. The fourth option is to change the theme to dark or light. The fifth option is to change the language to English or Polish. The last sixth option is to change the mouse cursor.'
};
