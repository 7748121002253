import Axios, { AxiosInstance } from 'axios';
import useUser from '@/composables/useUser';

const baseConfig = {
  baseURL: `${process.env.VUE_APP_API}`,
  timeout: 60 * 1000 // Timeout
};

const _axios = function(config: object): AxiosInstance {
  const axiosInstance = Axios.create({ ...baseConfig, ...config });

  axiosInstance.interceptors.request.use(
    config =>
      // Do something before request is sent
      {
        if (useUser().tokenExist()) {
          config.headers.Authorization = `Bearer ${useUser().getToken()}`;
        }

        return config;
      },
    error =>
      // Do something with request error
      Promise.reject(error)
  );

  // Add a response interceptor
  axiosInstance.interceptors.response.use(
    response =>
      // Do something with response data
      response,
    error => {
      if (error?.response?.status === 401) {
        useUser().set401();
      } else {
        return Promise.reject(error);
      }
    }
  );

  return axiosInstance;
};

export default _axios;
