<template>
  <div v-if="disableOnMode(0, mode)" ref="card" class="fhir-component__card">
    <FhirHeader
      v-if="disableOnMode([3, 4], mode)"
      :show-tags="showTags"
      :edit-tags="editTags"
      :token="token"
      :resource="fhir"
      :lang="lang"
      :display-other-header-title="`${names} ${surname}`"
    >
      <slot />
    </FhirHeader>
    <div
      v-if="(names || surname) && disableOnMode([1, 2, 3], mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resources.Patient')"
        :value="`${names} ${surname}`"
      />
    </div>
    <div v-if="gender" class="fhir-component__body__row">
      <SingleValue
        :label="$t('resourcesStructures.Patient.gender')"
        :value="gender"
        :lang="lang"
      />
    </div>
    <div
      v-if="birthDate && disableOnMode(3, mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('resourcesStructures.Patient.birthDate')"
        :value="birthDate"
        :lang="lang"
      />
    </div>
    <div
      v-if="deceasedDate && disableOnMode(3, mode)"
      class="fhir-component__body__row"
    >
      <SingleValue
        :label="$t('patient.deceasedDate')"
        :value="deceasedDate"
        :lang="lang"
      />
    </div>
    <div
      v-if="address && disableOnMode([2, 3, 4], mode)"
      class="fhir-component__body__row"
    >
      <Address :address="address" :lang="lang" />
    </div>
  </div>
  <div v-else class="fhir-component__mode-0">
    <MiniMode :resource="fhir" :lang="lang">
      {{ `: ${names} ${surname}` }}
    </MiniMode>
  </div>
</template>

<script>
import jp from 'jsonpath';
import { dateTimeMoment, getAge } from '../utils/time.utils';
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import Address from '../Address/Address';
import disableOnMode from '../utils/disableOnMode';
import MiniMode from '../MiniMode/MiniMode';
import FhirHeader from '../Appearance/FhirHeader';
import { ok } from '@fhir/pixel-commons-js';

export default {
  name: 'Patient',
  components: {
    FhirHeader,
    SingleValue,
    Address,
    MiniMode
  },
  mixins: [mixins],
  emits: ['get-active-header'],
  data() {
    return {
      dataEvents: {
        header: {
          label: '',
          names: '',
          surname: ''
        },
        gender: '',
        birthDate: '',
        address: {
          city: '',
          postalCode: '',
          line: ''
        }
      }
    };
  },
  computed: {
    patientId() {
      return jp.value(this.fhir, 'resource.id') || '';
    },
    identificationNumber() {
      return jp.value(this.fhir, 'resource.identifier[0].value') || '';
    },
    names() {
      return jp.value(this.fhir, 'resource.name[*].given') || '';
    },
    surname() {
      return jp.value(this.fhir, 'resource.name[*].family') || '';
    },
    birthDate() {
      const date = jp.value(this.fhir, 'resource.birthDate') || '';
      if (date) {
        return dateTimeMoment(date);
      } else {
        return '';
      }
    },
    deceasedDate() {
      const date = jp.value(this.fhir, 'resource.deceasedDateTime') || '';
      if (date) {
        return dateTimeMoment(date);
      } else {
        return '';
      }
    },
    age() {
      const date = jp.value(this.fhir, 'resource.birthDate') || '';
      if (date) {
        return getAge(date);
      } else {
        return '';
      }
    },
    gender() {
      const sex = jp.value(this.fhir, 'resource.gender') || '';

      return ok(sex) ? this.$t(`base.${sex.trim().toLowerCase()}`) : '';
    },
    address() {
      return jp.value(this.fhir, 'resource.address') || '';
    }
  },
  methods: {
    disableOnMode
  }
};
</script>

<style scoped>
@import '../assets/styles/base.css';

.fhir-component__card {
  padding: 6px;
}
</style>
