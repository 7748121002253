
import { AccessType } from '@/components/FullTextSearch/FullTextSearchType';
import { defineComponent, PropType } from 'vue';
export default defineComponent({
  name: 'LabelFullTextSearch',
  props: {
    activeRouterName: {
      type: String as PropType<AccessType>,
      required: true,
      default: AccessType.Patient as string
    },
    inputId: {
      type: String,
      required: true,
      default: '0'
    }
  }
});
