import { createI18n } from 'vue-i18n';

import resourcesEn from '../i18n/resources_en.ts';
import resourcesPl from '../i18n/resources_pl.ts';
import searchparamsEn from '../i18n/searchparams_en.ts';
import searchparamsPl from '../i18n/searchparams_pl.ts';

function loadLocaleMessagesFHIR(context) {
  const messages = {};
  context.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = context(key);
    }
  });
  return messages;
}

const i18nFilesMessage = {
  en: Object.assign({}, resourcesEn, searchparamsEn),
  pl: Object.assign({}, resourcesPl, searchparamsPl)
};

export function i18nFiles(opts = {}) {
  const { i18nContext } = opts;

  if (i18nContext) {
    const localesFromFiles = loadLocaleMessagesFHIR(i18nContext);

    if (localesFromFiles) {
      Object.keys(i18nFilesMessage).forEach(e => {
        if (localesFromFiles[e] != null) {
          i18nFilesMessage[e] = Object.assign(
            {},
            i18nFilesMessage[e],
            localesFromFiles[e]
          );
        }
      });
    }
  }

  const ret = createI18n({
    locale: 'pl',
    fallbackLocale: 'pl',
    messages: i18nFilesMessage
  });
  //console.log('lan', i18nFilesMessage, ret);
  return ret;
}

export function messages(i18nContext) {
  if (i18nContext) {
    const localesFromFiles = loadLocaleMessagesFHIR(i18nContext);

    if (localesFromFiles) {
      Object.keys(i18nFilesMessage).forEach(e => {
        if (localesFromFiles[e] != null) {
          i18nFilesMessage[e] = Object.assign(
            {},
            i18nFilesMessage[e],
            localesFromFiles[e]
          );
        }
      });
    }
  }

  return i18nFilesMessage;
}
