import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAdjust,
  faCalendar,
  faFont,
  faLink,
  faMoon,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faSun,
  faBookReader,
  faTimesCircle,
  faRedo,
  faFrown,
  faTimes,
  faArrowRight,
  faShareAlt,
  faFileAlt,
  faUniversalAccess,
  faPlus,
  faUser,
  faPowerOff,
  faBell,
  faClipboardList,
  faCog,
  faCompactDisc,
  faDownload,
  faSpinner,
  faBars,
  faCheck,
  faSearch,
  faArrowUp,
  faTruck,
  faBoxes,
  faSortAlphaDown,
  faKey,
  faCogs,
  faBoxOpen,
  faStar,
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faInfoCircle,
  faInfo,
  faUsers,
  faExclamation,
  faCheckDouble,
  faLock,
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarReqular } from '@fortawesome/free-regular-svg-icons';

library.add(
  faCalendar,
  faFont,
  faAdjust,
  faLink,
  faMoon,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faTimesCircle,
  faSun,
  faBookReader,
  faRedo,
  faTimes,
  faArrowRight,
  faShareAlt,
  faFileAlt,
  faUniversalAccess,
  faPlus,
  faUser,
  faPowerOff,
  faCompactDisc,
  faDownload,
  faSpinner,
  faBell,
  faClipboardList,
  faCog,
  faBars,
  faArrowUp,
  faSearch,
  faCheck,
  faFrown,
  faKey,
  faTruck,
  faBoxes,
  faSortAlphaDown,
  faCogs,
  faBoxOpen,
  faExclamationTriangle,
  faExclamationCircle,
  faCheckCircle,
  faInfoCircle,
  faExclamation,
  faInfo,
  faStar,
  faTimes,
  faUsers,
  faTimes,
  faCheckDouble,
  faStarReqular,
  faLock,
  faArrowLeft
);

export default library;
