
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { generateUuid } from '@/utils/utils';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';
import { useRouter } from 'vue-router';

interface ActionPickerListElementType {
  name: string;
  icon: string;
  title: string;
  action: () => void;
}

export default defineComponent({
  name: 'BaseActionPicker',
  components: {
    FocusLoop
  },
  emits: ['active-highlight-main', 'active-highlight-menu'],
  setup(props, context) {
    const isOpen = ref<boolean>(false);
    const buttonId = ref<string>(generateUuid());

    const router = useRouter();

    const positionX = ref<any>('50%');
    const positionY = ref<any>('50%');

    const lastClickedElement = ref<HTMLElement | null>(null);

    const slots: ActionPickerListElementType[] = [
      {
        name: 'goToTop',
        icon: 'arrow-up',
        title: 'goToTop',
        action: () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      },
      {
        name: 'goToMainPage',
        icon: 'sort-alpha-down',
        title: '',
        action: () => {
          document.getElementById('main')?.click();
          document.getElementById('main')?.focus();
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 0);
          context.emit('active-highlight-main');
        }
      },
      {
        name: 'settings',
        icon: 'cog',
        title: '',
        action: () => {
          router.push({ name: 'Settings' });
        }
      },
      {
        name: 'accessibilityMenu',
        icon: 'universal-access',
        title: '',
        action: () => {
          document.getElementById('accessibilityMenu')?.click();
          document.getElementById('accessibilityMenu')?.focus();
          context.emit('active-highlight-menu');
        }
      }
    ];

    const setLastClickedElement = () => {
      lastClickedElement.value = document.activeElement as HTMLElement;
    };

    const closeActionPicker = () => {
      if (isOpen.value) {
        isOpen.value = false;
        (lastClickedElement.value as HTMLElement)?.focus();
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      destroyEvents();
    };

    const openActionPicker = (event: KeyboardEvent) => {
      event.preventDefault();
      setLastClickedElement();
      document.getElementById(buttonId.value)?.focus();
      isOpen.value = true;
      const actionPicker = document.getElementById('action-picker');
      const circle = (actionPicker as HTMLElement)?.getBoundingClientRect();
      (actionPicker as HTMLElement).style.left = `${positionX.value -
        circle.width / 2}px`;
      (actionPicker as HTMLElement).style.top = `${positionY.value -
        circle.height / 2}px`;
    };

    const toggleActionPicker = (event: KeyboardEvent) => {
      if (event.code === 'F9') {
        if (isOpen.value) {
          closeActionPicker();
        } else {
          openActionPicker(event);
        }
      }
    };

    const setLocation = (event: MouseEvent) => {
      positionX.value = event.pageX - window.scrollX;
      positionY.value = event.pageY - window.scrollY;
    };

    const rotateFulfillment = (e: MouseEvent) => {
      const element = document.querySelector('.fulfillment');
      const iconWrapper = document.querySelector('.icon-wrapper');
      const elementParams = iconWrapper?.getBoundingClientRect();
      if (elementParams) {
        const centerX = elementParams?.left + elementParams?.width / 2;
        const centerY = elementParams?.top + elementParams?.height / 2;
        const radians = Math.atan2(
          positionX.value - centerX,
          positionY.value - centerY
        );
        const degree = radians * (180 / Math.PI) * -1 + 45;
        const stringTransform = `rotate(${+degree}deg)`;
        (element as HTMLElement).style.transform = stringTransform;
      }
    };

    const destroyEvents = () => {
      window.removeEventListener('click', closeActionPicker);
      window.removeEventListener('keyup', toggleActionPicker);
      window.removeEventListener('mousemove', rotateFulfillment);
      window.removeEventListener('mousemove', setLocation);
    };

    onMounted(() => {
      window.addEventListener('keyup', (ev: any) => toggleActionPicker(ev));
      window.addEventListener('click', () => closeActionPicker());
      window.addEventListener('mousemove', (event: any) => {
        rotateFulfillment(event);
        setLocation(event);
      });
    });

    onUnmounted(() => {
      destroyEvents();
    });

    const showVal = () => {
      console.log(document.activeElement);
    };

    return {
      isOpen,
      slots,
      buttonId,
      closeActionPicker,
      showVal
    };
  }
});
