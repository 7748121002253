<template>
  <dl class="patients-search">
    <dd>
      <ExpandablePanel>
        <template #header-controls="{expanded}">
          <template v-if="expanded">
            <span>
              {{ $t('Header.fullTextSearch.nameAndSurname') }}
            </span>
            <span>
              {{ $t('Header.fullTextSearch.identifier') }}
            </span>
          </template>
          <template v-else>
            <span class="patients-search__one">
              {{ $t('Header.fullTextSearch.patients') }}
            </span>
          </template>
        </template>
        <ul>
          <li
            v-for="patient in patients"
            :key="patient.id"
            class="patients-search__item"
          >
            <button
              class="patients-search__item__button searcher__item"
              :title="`Przejdź do profilu ${patient.name} ${patient.surname}`"
              @click="goToPatient(patient)"
            >
              <span>{{ patient.name }} {{ patient.surname }}</span>
              <span>{{ patient.identifier }}</span>
            </button>
          </li>
        </ul>
      </ExpandablePanel>
    </dd>
  </dl>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import { PatientsType } from '@/components/FullTextSearch/FullTextSearchType';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'PatientsSearch',
  components: { ExpandablePanel },
  props: {
    patients: {
      type: Array as PropType<PatientsType[]>,
      required: true,
      default: () => []
    }
  },
  setup() {
    const router = useRouter();

    const goToPatient = (patient: PatientsType) => {
      console.log(patient);
      // TODO route with router to Patient
    };

    return {
      goToPatient
    };
  }
});
</script>

<style scoped lang="scss">
.patients-search {
  ::v-deep .expandable-panel-header-content {
    display: flex;
    justify-content: space-around;
    padding-right: 1.25rem;

    span {
      text-align: center;
      font-size: 0.825rem;
    }
  }

  &__item {
    width: 100%;
    list-style: none;
    &__button {
      width: 100%;
      padding: 0.5rem 0.25rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      cursor: pointer;
      background-color: transparent;
      border: none;
      transition: background-color 0.3s;

      span:first-child {
        position: relative;
        &:after {
          content: '';
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: 1px;
          background-color: var(--light-gray);
        }
      }

      &:hover {
        background-color: var(--light-gray);
      }
    }
  }
}
</style>
