import { reactive } from 'vue';
import {
  Communicator,
  CommunicatorI,
  Message,
  MessageI,
  NOTIFICATION_ACTION_CODE_SHARE_BY_EMAIL_OT_PHONE,
  NOTIFICATION_ADD_MESSAGE_ENDPOINT,
  NOTIFICATION_ALL_READ_ENDPOINT,
  NOTIFICATION_MESSAGE_READ_ENDPOINT,
  NOTIFICATION_SSE_ENDPOINT,
  NotificationRequest
} from '@/composables/types/communicator';
import Repo from '@/apiv2/Repo';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import i18n from '@/lang';

const state: CommunicatorI = reactive(new Communicator());

export default function useCommunicator(): {
  connect: (lan: string, token: string) => void;
  newMessages: () => Array<MessageI>;
  newMessagesCount: () => number;
  markAsRead: (message: MessageI) => void;
  markAllRead: () => void;
  addMessage: (
    actionType: string,
    receivers: Array<string>,
    notificationParams: any
  ) => Promise<any>;
} {
  const connect = (lan: string, token: string) => {
    console.log('connect', lan, token);
    if (state.sse) {
      state.sse.close();
      state.messages = [];
    }
    const addMessage = (id: string, msg: string, date: Date, source: any) => {
      if (!state.messages.some(f => f.id === id)) {
        state.addMessage(
          new Message(id, msg, date, source.readStatus === 'READ', source)
        );
      }
    };

    state.sse = new EventSource(
      `${NOTIFICATION_SSE_ENDPOINT}?lang=${lan || 'pl'}&access_token=${token}`
    );
    state.sse.onmessage = event => {
      const data = JSON.parse(event.data);
      //console.log('message', data);

      const d = new Date();
      d.setTime(data.notificationDate);
      addMessage(data.id, data.description, d, data);
    };

    state.sse.onerror = err => {
      console.log('sse error', err);
    };
  };

  const newMessages = () => state.messages.filter(f => f.read === false);

  const newMessagesCount = () => newMessages().length;

  const markAsRead = async (message: MessageI) => {
    try {
      await Repo.notificator.customGet(
        `${NOTIFICATION_MESSAGE_READ_ENDPOINT}/${message.id}`
      );
      message.read = true;
    } catch (e) {
      // console.error(i18n.global.t('communicator.readError'), e);
      // useNotification().addNotification(
      //   NotificationType.ERROR,
      //   '',
      //   i18n.global.t('communicator.readError')
      // );
    }
  };

  const markAllRead = async () => {
    try {
      await Repo.notificator.customGet(NOTIFICATION_ALL_READ_ENDPOINT);
      state.messages.forEach(m => (m.read = true));
    } catch (e) {
      // console.error(i18n.global.t('communicator.readAllError'), e);
      // useNotification().addNotification(
      //   NotificationType.ERROR,
      //   '',
      //   i18n.global.t('communicator.readError')
      // );
    }
  };

  const addMessage = (
    actionType: string,
    receivers: Array<string>,
    notificationParams: any
  ): Promise<any> => {
    const notification = new NotificationRequest(
      actionType,
      receivers,
      notificationParams
    );
    console.log('addMessage', actionType, receivers, notificationParams);
    //return new Promise<any>((ok, err) => ok('NOGA'));
    return Repo.notificator.customPost(
      NOTIFICATION_ADD_MESSAGE_ENDPOINT,
      notification
    );
  };

  return {
    connect,
    newMessages,
    newMessagesCount,
    addMessage,
    markAsRead,
    markAllRead
  };
}
