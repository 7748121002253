import { id, url } from './PatientUtils';
import { useI18n } from 'vue-i18n';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import useResource from '@/composables/useResource';

export enum System {
  default = 'urn:tag',
  patientPortalSystem = 'urn:tag/patient-portal',
  patientPortalFavouriteSystem = 'urn:tag/patient-portal/favourite'
}

export enum Code {
  displayedResource = 'displayed-resource',
  favourite = 'favourite'
}

export interface Tag {
  system: string;
  code: string;
  display?: '';
}

export interface Tags {
  tag: Tag[];
}

export default () => {
  const { t } = useI18n();

  /**
   * @param readIds - array with ids which was disapled by patient
   */
  const addDisplayedResourceTag = async (
    resource: object,
    readIds?: string[]
  ): Promise<any> => {
    try {
      const tag: Tag = {
        system: System.patientPortalSystem,
        code: Code.displayedResource
      };

      const tags: Tags = {
        tag: [tag]
      };
      const constructUrl = url(resource) ? `${url(resource)}/$meta-add` : '';

      if (constructUrl) {
        await useResource().addTag(constructUrl, tags);
        useNotification().addNotification(
          NotificationType.SUCCESS,
          '',
          t('patient.results.studyIsMarkedAsRead')
        );

        readIds?.push(id(resource));
      } else {
        console.warn('Construct url is not correct', constructUrl);
        useNotification().addNotification(
          NotificationType.ERROR,
          '',
          t('patient.results.errorOfMarkedStudy')
        );
      }
    } catch (e) {
      useNotification().addNotification(
        NotificationType.ERROR,
        '',
        t('patient.results.errorOfMarkedStudy')
      );
    }
  };

  return {
    addDisplayedResourceTag
  };
};
