<template>
  <main><slot /></main>
</template>

<script>
export default {
  name: 'MainPanel'
};
</script>

<style scoped></style>
