<template>
  <div
    class="card"
    :class="{
      'card-bordered': bordered,
      'card-raised': raised || styled,
      'card-hoverable': hoverable,
      [hoverClass]: hoverable,
      'card-round': round || styled,
      'card-fixed-bg': fixedBg,
      [typedClass]: true,
      'hide-bottom-border': hideBottomBorder
    }"
  >
    <div v-if="loading" class="card-loader">
      <slot name="loading">
        <BaseLoader></BaseLoader>
      </slot>
    </div>
    <div class="header">
      <slot name="header"></slot>
    </div>
    <div class="body">
      <slot></slot>
    </div>
    <div class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import BaseLoader from './BaseLoader';
export default {
  name: 'BaseCard',
  components: { BaseLoader },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    bordered: {
      type: Boolean,
      default: false
    },
    raised: {
      type: Boolean,
      default: false
    },
    hoverable: {
      type: Boolean,
      default: false
    },
    hideBottomBorder: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    styled: {
      type: Boolean,
      default: false
    },
    fixedBg: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'default',
      validator: value => {
        return (
          ['default', 'success', 'warning', 'danger', 'info', 'blank'].indexOf(
            value
          ) !== -1
        );
      }
    }
  },
  computed: {
    typedClass() {
      return `card-${this.type}`;
    },
    hoverClass() {
      return `card-hoverable-${this.type}`;
    }
  }
};
</script>

<style scoped lang="scss">
.card-fixed-bg {
  background-color: var(--bg) !important;
}

.card-default {
  background-color: var(--bg);
  border-bottom: 3px solid var(--accent);
}
.card-success {
  background-color: var(--green-alpha);
  border-bottom: 3px solid var(--green);
}
.card-warning {
  background-color: var(--yellow-alpha);
  border-bottom: 3px solid var(--yellow);
}
.card-danger {
  background-color: var(--red-alpha);
  border-bottom: 3px solid var(--red);
}
.card-info {
  background-color: var(--blue-alpha);
  border-bottom: 3px solid var(--blue);
}
.card-blank {
  border-bottom: 3px solid transparent;
}

.hide-bottom-border {
  border-bottom: none !important;
}

.card {
  position: relative;
  /*overflow: hidden;*/
  display: grid;
  grid-template-rows: auto 1fr auto;
  transition-duration: 0.5s;
  color: var(--font-color);
  /*border-bottom: 3px solid var(--accent);*/
  transition: width 0.2s ease-in-out;

  & .card-loader {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    //background-color: var(--background);
    background-color: rgba(255, 255, 255, 0.7);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & > * {
    padding: 5px;
  }

  & > *:empty {
    display: none;
  }

  & .body {
    padding: 10px;
    /*overflow: auto;*/
  }

  & .header {
    padding: 15px;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid var(--bg-alternative);
  }

  & .footer {
    background-color: var(--bg-alternative);
  }
}

.card.card-hoverable:hover {
  box-shadow: 0 0 8px 4px var(--gray-shadow);
}

.card.card-hoverable-default:hover {
  background-color: var(--primary-alpha) !important;
}

.card.card-hoverable-success:hover {
  background-color: var(--green-alpha-1) !important;
}

.card.card-hoverable-danger:hover {
  background-color: var(--red-alpha-1) !important;
}

.card.card-hoverable-warning:hover {
  background-color: var(--yellow-alpha-1) !important;
}

.card.card-hoverable-info:hover {
  background-color: var(--blue-alpha-1) !important;
}

.card-bordered {
  border: solid 1px var(--bg-alternative);
}
.card-raised {
  box-shadow: var(--main-box-shadow);
}
.card-round {
  border-radius: var(--main-radius);
}
</style>
