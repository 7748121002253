<template>
  <div @click="handleEmit">
    <b>{{ label }}:</b>
    <span class="value fh-ml">
      {{ createShortText(value, shortText, maxTextLength) }}
    </span>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import createShortText from '../utils/createShortText';
export default {
  name: 'SingleValue',
  mixins: [mixins],
  props: {
    label: { type: String, required: true },
    value: { type: [String, Number], required: false, default: '' },
    shortText: { default: false, type: Boolean },
    maxTextLength: { default: 10000, type: Number }
  },
  methods: {
    createShortText,
    handleEmit() {
      const data = {
        label: this.label,
        value: this.value
      };
      this.$emit('single-value', data);
    }
  }
};
</script>

<style></style>
