<template>
  <div class="loader" :style="styles" />
</template>

<script>
export default {
  name: 'Loader',
  props: {
    size: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'solid'
    },
    period: {
      type: Number,
      default: 1200
    }
  },
  data() {
    return {
      borderTopColor: '#888',
      borderColor: '#ccc'
    };
  },
  computed: {
    webkitAnimationDuration() {
      return `${this.period}ms`;
    },
    borderStyle() {
      return this.type !== 'dotted' &&
        this.type !== 'double' &&
        this.type !== 'dashed'
        ? 'solid'
        : this.type;
    },
    styles() {
      const [padding, borderWidth] = this.getSizeParameters(this.size);
      return {
        padding,
        borderWidth,
        borderStyle: this.borderStyle,
        borderColor: this.borderColor,
        borderTopColor: this.borderTopColor,
        webkitAnimationDuration: this.webkitAnimationDuration
      };
    }
  },
  methods: {
    getSizeParameters(size) {
      switch (size) {
        case 'small':
          return ['15px', '6px'];
        case 'medium':
          return ['25px', '9px'];
        case 'big':
          return ['45px', '13px'];
        default:
          return ['45px', '13px'];
      }
    }
  }
};
</script>

<style scoped type="scss">
.loader {
  height: 0;
  width: 0;
  border-radius: 100%;
  -webkit-animation: rotate 1s infinite linear;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
</style>
