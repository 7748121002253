export const byPassList = [
  {
    text: 'skipToMainContent',
    gTo: 'main'
  },
  {
    text: 'accessibilityMenu',
    gTo: 'accessibilityMenu'
  }
];
