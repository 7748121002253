export default {
  appConfig: 'Application configuration',
  setTenant: 'Select the source the application will use',
  noPermissions:
    'Application is not configured.<br>Contact system administrator <b>wsparcie@pixel.com.pl</b>.',
  incorrectApplicationConfig: 'Incorrect application configuration',
  noTenants: 'No tenants',
  savedTenants: 'Tenants saved',
  error: 'Error'
};
