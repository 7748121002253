import { getEnumSize } from '@/utils/utils';

export enum LS {
  contrast = 'portal-pacjenta_vbyxd_pixel-technology--contrast',
  fontSize = 'portal-pacjenta_vbyxd_pixel-technology--font-size',
  theme = 'portal-pacjenta_vbyxd_pixel-technology--theme',
  links = 'portal-pacjenta_vbyxd_pixel-technology--links',
  langs = 'portal-pacjenta_vbyxd_pixel-technology--langs',
  mousePointer = 'portal-pacjenta_vbyxd_pixel-technology--mouse-pointer'
}

export enum Actions {
  contrast,
  fontSize,
  theme,
  links,
  langs,
  mousePointer
}

export enum MousePointer {
  base,
  medium,
  big
}

export enum MousePointerValues {
  base = 'mouse-pointer--base',
  medium = 'mouse-pointer--medium',
  big = 'mouse-pointer--big'
}

export enum FontLevels {
  small,
  medium,
  big
}

export enum FontLevelsWithValues {
  small = '12px',
  medium = '16px',
  big = '20px'
}

export enum ContrastLevels {
  base,
  blackBgFontYellow,
  yellowBgBlackFont
}
export enum ContrastLevelsValues {
  base = 'contrast--base',
  blackBgFontYellow = 'contrast--black-bg-font-yellow',
  yellowBgBlackFont = 'contrast--yellow-bg-black-font'
}

export enum Themes {
  bright,
  dark
}

export enum ThemesValues {
  bright = 'light-theme',
  dark = 'dark-theme'
}

export enum Links {
  off,
  on
}

export enum LinksValues {
  off = 'links-colors-off',
  on = 'links-colors-on'
}

export enum Langs {
  pl,
  en
}

export enum LangsValues {
  pl = 'pl',
  en = 'en'
}

export interface AccessibilityMenuInterface {
  icon: string;
  height?: number;
  width?: number;
  text: string;
  title: string;
  action: Actions;
  translations?: Array<string>;
  disabled: boolean;
}

export const enumSizes: any = {
  fontSize: getEnumSize(Object.keys(FontLevels)),
  contrast: getEnumSize(Object.keys(ContrastLevels)),
  theme: getEnumSize(Object.keys(Themes)),
  links: getEnumSize(Object.keys(Links)),
  langs: getEnumSize(Object.keys(Langs)),
  mousePointer: getEnumSize(Object.keys(MousePointer))
};
