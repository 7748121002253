export default {
  name: 'Ustawienia',
  desc: 'Panel ustawień umożliwiający zmianę preferencji użytkownika.',
  refresh: {
    name: 'Odświeżanie listy dokumentów',
    dontRefresh: 'Nie odświeżaj',
    refreshEvery30: 'Odświeżaj co 30 sekund',
    refreshEvery45: 'Odświeżaj co 45 sekund',
    refreshEvery60: 'Odświeżaj co 1 minutę',
    refreshEvery90: 'Odświeżaj co 1 minuę i 30 sekund',
    refreshEvery120: 'Odświeżaj co 2 minuty'
  },
  avatar: 'Zmień zdjęcie profilowe',
  changePass: 'Zmień hasło'
};
