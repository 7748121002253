export default {
  filters: {
    desc: 'Patients documents search filters',
    documentData: 'Document date {date}',
    documentPatient: 'Patient {patient}',
    patients: 'Patients',
    dateAny: 'Any',
    dateToday: 'Today',
    dateLast5: 'Last 5 days',
    dateLast30: 'Last 30 days',
    dateCustom: 'Custom range',
    dateFrom: 'Date from',
    sharedToMe: 'Shared to me',
    showSharedToMe: 'Show shared to me',
    dateTo: 'Date to',
    date: 'Date',
    dateTree: 'Available document dates',
    selectDate: 'Filter documents by date {date}',
    selectPatient: 'Filter documents by patient {patient}',
    clearDate: 'Clear filtring documents by date {date}',
    clearPatient: 'Clear filtring documents by patient {patient}',
    chooseDate: 'Choose date',
    selectedDate: 'Choose date, selected date is {date}',
    prevYear: 'Previous year',
    prevMonth: 'Previous month',
    nextYear: 'Next year',
    nextMonth: 'Next month',
    fromTheNewest: 'From the newest'
  },
  results: {
    error: 'Error loading resources',
    reload: 'Reload',
    studyIsMarkedAsRead: 'The study is marked as read',
    errorOfMarkedStudy: 'Error of marked study',
    bodyHeightMeasured: 'Body height measured',
    bodyWeightMeasured: 'Body weight measured',
    tobaccoSmokingStatus: 'Tobacco smoking',
    additionalInformationAboutPatient: 'Additional information about patient'
  },
  studyList: 'Study list',
  noInformation: 'No information',
  studyListForPage: 'Study list for page'
};
