export default {
  searchparams: {
    _id: 'The ID of the resource',
    asserter: 'Person who asserts this condition',
    category: 'The classification of the type of observation',
    'clinical-status': 'The clinical status of the condition',
    code: 'A code that corresponds to one of its items in the questionnaire',
    criticality: 'low | high | unable-to-assess',
    date: 'The questionnaire publication date',
    identifier: 'External identifier for the questionnaire',
    'last-date': 'Date(/time) of last known occurrence of a reaction',
    manifestation: 'Clinical symptoms/signs associated with the Event',
    onset: 'Date(/time) when manifestations showed',
    patient: 'Search by subject - a patient',
    recorder: 'Who recorded the sensitivity',
    type: 'A code for the type of organization',
    'verification-status':
      'provisional | differential | confirmed | refuted | entered-in-error | unknown',
    'abatement-date': 'Date-related abatements (dateTime and period)',
    'abatement-string': 'Abatement as a string',
    'asserted-date': 'Date record was believed accurate',
    'body-site': 'Where procedure is going to be done',
    context: 'Encounter or Episode during which request was created',
    encounter: 'An encounter in which this request is made',
    evidence: 'Manifestation/symptom',
    'evidence-detail': 'Supporting information found elsewhere',
    'onset-date': 'Date related onsets (dateTime and Period)',
    'onset-info': 'Onsets as a string',
    severity: 'The severity of the condition',
    stage: 'Simple summary (disease specific)',
    subject: 'Search by subject',
    'based-on': 'What request fulfills',
    diagnosis: 'Reason the encounter takes place (resource)',
    image: 'A reference to the image source.',
    issued: 'When the report was issued',
    performer: 'Requested perfomer',
    result: 'Link to an atomic result (observation resource)',
    specimen: 'Specimen to be tested',
    status: 'The current status of the questionnaire',
    authenticator: 'Who/what authenticated the document',
    author: 'Who and/or what authored the document',
    class: 'inpatient | outpatient | ambulatory | emergency +',
    created: 'Document creation time',
    custodian: 'Organization which maintains the document',
    description: 'The description of the questionnaire',
    event: 'Main clinical acts documented',
    facility: 'Kind of facility where patient was seen',
    format: 'Format/content rules for the document',
    indexed: 'When this document reference was created',
    period: 'Time of service that is being documented',
    'related-ref': 'Related Resource',
    relatesto: 'Target of the relationship',
    relation: 'replaces | transforms | signs | appends',
    securitylabel: 'Document security-tags',
    setting:
      'Additional details about where the content was created (e.g. clinical specialty)',
    appointment: 'The appointment that scheduled this encounter',
    episodeofcare:
      'Episode(s) of care that this encounter should be recorded against',
    incomingreferral: 'Incoming Referral Request',
    location: 'The location of the Healthcare Service',
    'location-period':
      'Time period during which the patient was present at the location',
    'part-of': 'Another Encounter this encounter is part of',
    participant: 'Persons involved in the encounter other than the patient',
    'participant-type': 'Role of participant in encounter',
    practitioner: 'Persons involved in the encounter other than the patient',
    reason: 'The reason for the study',
    'service-provider': 'The custodian organization of this Encounter record',
    'special-arrangement': 'Wheelchair, translator, stretcher, etc.',
    'connection-type':
      'Protocol/Profile/Standard to be used with this endpoint connection',
    name: 'Computationally friendly name of the questionnaire',
    organization: 'The organization at which this person is a patient',
    'payload-type':
      'The type of content that may be used at this endpoint (e.g. XDS Discharge summaries)',
    'care-manager': 'Care manager/care co-ordinator for the patient',
    condition: 'Conditions/problems/diagnoses this episode of care is for',
    active: 'Whether the practitioner record is active',
    characteristic: "One of the HealthcareService's characteristics",
    endpoint:
      'Technical endpoints providing access to services operated for the organization',
    programname: 'One of the Program Names serviced by this HealthcareService',
    accession: 'The accession identifier for the study',
    basedon: 'The order for the image',
    bodysite: 'The body site studied',
    'dicom-class': 'The type of the instance',
    modality: 'The modality of the series',
    series: 'The identifier of the series of images',
    started: 'When the study was started',
    study: 'The study identifier for the image',
    uid: 'The instance unique identifier',
    'empty-reason': 'Why list is empty',
    item: 'Actual entry',
    notes: 'The annotation  - text content',
    source: 'Who and/or what defined the list contents (aka Author)',
    title: 'The human-friendly name of the questionnaire',
    device: 'The Device that generated the observation data.',
    'effective-time': 'Date administration happened (or did not happen)',
    medication: 'Return prescriptions of this medication reference',
    'not-given': 'Administrations that were not made',
    prescription: 'The identity of a prescription to list administrations from',
    'reason-given': 'Reasons for administering the medication',
    'reason-not-given': 'Reasons for not administering the medication',
    authoredon: 'Return prescriptions written on this date',
    'intended-dispenser':
      'Returns prescriptions intended to be dispensed by this Organization',
    intent: 'proposal | plan | order +',
    priority: 'routine | urgent | asap | stat',
    requester: 'Individual making the request',
    'combo-code': 'The code of the observation type or component type',
    'combo-value-concept':
      'The value or component value of the observation, if the value is a CodeableConcept',
    'component-code': 'The component code of the observation type',
    'component-code-value-quantity':
      'Component code and component quantity value parameter pair',
    'component-data-absent-reason':
      'The reason why the expected value in the element Observation.component.value[x] is missing.',
    'component-value-concept':
      'The value of the component observation, if the value is a CodeableConcept',
    'data-absent-reason':
      'The reason why the expected value in the element Observation.value[x] is missing.',
    method: 'The method used for the observation',
    'related-target': 'Resource that is related to this one',
    'related-type':
      'has-member | derived-from | sequel-to | replaces | qualified-by | interfered-by',
    'value-concept':
      'The value of the observation, if the value is a CodeableConcept',
    'value-date':
      'The value of the observation, if the value is a date or period of time',
    'value-string':
      'The value of the observation, if the value is a string, and also searches in CodeableConcept.text',
    address:
      'A server defined search that may match any of the string fields in the Address, including line, city, state, country, postalCode, and/or text',
    'address-city': 'A city specified in an address',
    partof: 'An organization of which this organization forms a part',
    birthdate: "The patient's date of birth",
    email: 'A value in an email contact',
    family: 'A portion of the family name',
    gender: 'Gender of the practitioner',
    'general-practitioner':
      "Patient's nominated general practitioner, not the organization that manages the record",
    given: 'A portion of the given name',
    link: 'All patients linked to the given patient',
    phone: 'A value in a phone contact',
    authored: 'Date request signed',
    definition: 'Protocol or definition',
    occurrence: 'When procedure should occur',
    'performer-type': 'Performer role',
    replaces: 'What request replaces',
    requisition: 'Composite Request ID',
    effective:
      'The time during which the questionnaire is intended to be in use',
    jurisdiction: 'Intended jurisdiction for the questionnaire',
    publisher: 'Name of the publisher of the questionnaire'
  }
};
