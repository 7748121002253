<template>
  <Teleport to="body">
    <transition name="fade">
      <dialog
        :open="modal"
        :ref="containerRef"
        class="modal-container"
        :class="{ 'modal-confirm': confirm }"
        v-if="modal"
        role="dialog"
        :style="modalZ"
      >
        <FocusLoop>
          <div
            ref="fader"
            class="modal-fader"
            v-if="showFader"
            @click.stop="faderClicked"
            :style="faderZ"
          ></div>
          <div ref="modal" class="modal-window" :style="modalZ">
            <div class="header">
              <slot name="header">{{ title }}</slot>
              <button class="icon-button" v-if="showClose" @click="close">
                <font-awesome-icon
                  aria-hidden="true"
                  icon="times"
                  :height="20"
                ></font-awesome-icon>
              </button>
            </div>
            <div class="body">
              <slot></slot>
            </div>
            <div class="footer">
              <slot name="footer"></slot>
            </div>
          </div>
        </FocusLoop>
      </dialog>
    </transition>
  </Teleport>
</template>

<script>
import { createUUID } from './Utils/BaseUtils';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';

export default {
  name: 'BaseModal',
  components: { FocusLoop },
  props: {
    value: {},
    title: {},
    showClose: {
      type: Boolean,
      default: true
    },
    showFader: {
      type: Boolean,
      default: true
    },
    hideOnClickOutside: {
      type: Boolean,
      default: true
    },
    confirm: {
      type: Boolean,
      required: false
    },
    timeout: {
      type: Number,
      required: false
    },
    isActive: {
      type: Boolean,
      required: false,
      default: true
    },
    lastElementClicked: {
      type: String,
      required: true,
      default: 'hamburger-menu-button'
    }
  },
  data() {
    return {
      modal: false,
      props: { x: 0, y: 0 },
      x: 0,
      y: 0,
      z: 0
    };
  },
  computed: {
    containerRef() {
      return `container-${this.uuid}`;
    },
    uuid() {
      return createUUID();
    },
    modalZ() {
      return { 'z-index': this.z + 1 };
    },
    faderZ() {
      return { 'z-index': this.z };
    }
  },
  mounted() {
    this.setModalState(this.value);
    if (this.timeout) {
      setTimeout(() => {
        this.close();
      }, this.timeout);
    }
  },
  watch: {
    value(v) {
      this.setModalState(v);
    },
    isActive(v) {
      if (!v) {
        this.close();
      }
    }
  },
  methods: {
    closeListener(e) {
      if (e && e.key === 'Escape') {
        this.close();
      }
    },
    faderClicked() {
      if (this.showClose && this.hideOnClickOutside) {
        this.close();
      }
    },
    incZ() {
      this.z += 2;
      localStorage.setItem('modal', this.z);
    },
    decZ() {
      this.z -= 2;
      if (this.z < 2) {
        this.z = 0;
      }
      localStorage.setItem('modal', this.z);
    },
    setModalState(state) {
      this.modal = state;

      if (this.showClose) {
        state
          ? document.addEventListener('keydown', this.closeListener)
          : document.removeEventListener('keydown', this.closeListener);
      }

      if (state) {
        document.body.classList.add('modal');
        this.z = Number(localStorage.getItem('modal') || 0);
        this.incZ();
      } else {
        this.decZ();
        if (this.z === 0) {
          document.body.classList.remove('modal');
        }
      }
    },
    close() {
      this.$emit('update:value', false);
      this.decZ();
      if (this.z === 0) {
        document.body.classList.remove('modal');
        document.getElementById(this.lastElementClicked)?.focus();
      }
    }
  },
  emits: ['update:value']
};
</script>

<style scoped lang="scss">
.modal-container {
  position: relative;
  border: none;
  z-index: 2;
}

.modal-confirm {
  z-index: 6 !important;
}

.modal-fader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  cursor: not-allowed;
  background-color: var(--modal-bg);
  z-index: 2;
}

.modal-window {
  cursor: default !important;
  background-color: var(--light);
  border-radius: var(--main-radius);
  max-height: 100vh;
  position: fixed;
  min-width: 20vw;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;

  & .header {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    background-color: var(--primary);
    color: var(--light);
    padding: var(--app-margin);
    border-top-left-radius: var(--main-radius);
    border-top-right-radius: var(--main-radius);
    align-items: center;

    .icon-button {
      color: var(--light);
    }
  }

  & .body {
    padding: 10px;
    position: relative;
  }

  & .footer {
    border-top: solid 1px var(--medium-gray);
    padding: 10px;
    text-align: right;
  }

  & .footer:empty {
    display: none;
  }
}
</style>
