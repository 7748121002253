<template>
  <ONLOAD_CONFIGURATION @configuration-ok="handleIsConfigOk" />
  <transition name="fade">
    <EcgLoader v-if="!tokenExist" class="app-main-loader">
      {{ $t('base.baseLoading') }}
    </EcgLoader>
  </transition>
  <transition name="fade">
    <div :class="['app-wrapper', appLoading ? '' : 'app-loading']">
      <template v-if="!disabledOnSpecificPages">
        <BypassBlock
          v-for="(byPass, ind) in byPassListGo"
          @active-highlight="activeHighlight[ind] = true"
          :go-to="byPass.gTo"
          :text="byPass.text"
          :key="byPass.gTo"
        />
        <BaseActionPicker
          @active-highlight-main="activeHighlight[0] = true"
          @active-highlight-menu="activeHighlight[1] = true"
        />
        <NotificationSystem />
        <BaseConfirm ref="confirm"></BaseConfirm>
      </template>

      <Header
        v-show="!disabledOnSpecificPages"
        class="app-header"
        @header-is-smaller="headerIsSmaller = $event"
        :width="width"
        :show-search="activeSidebar"
      />
      <template v-if="!disabledOnSpecificPages">
        <Sidebar
          v-if="activeSidebar"
          :class="[
            'app-sidebar',
            headerIsSmaller ? 'sidebar-smaller' : '',
            width < 1000 && hiddenActive ? 'hidden' : ''
          ]"
          @get-width="setWidth"
          :hidden-active="hiddenActive"
          @click="hiddenActive = false"
        />
      </template>
      <MainPanel
        id="main"
        :class="[
          'app-main-panel',
          !activeSidebar ? 'termsOfUse' : '',
          width < 1000 && hiddenActive ? 'expanded' : '',
          disabledOnSpecificPages ? 'mode-for-pdf' : ''
        ]"
        tabindex="-1"
      >
        <BypassBlockHighlight
          v-if="activeHighlight[0]"
          @deactivate-highlight="activeHighlight[0] = false"
        />
        <router-view v-if="appLoading" v-slot="{ Component }">
          <transition name="fade">
            <span>
              <component :is="Component" />
            </span>
          </transition>
        </router-view>
      </MainPanel>
      <GoToTop v-if="!disabledOnSpecificPages" />
      <AccessibilityMenuButton v-if="!disabledOnSpecificPages">
        <BypassBlockHighlight
          v-if="activeHighlight[1]"
          @deactivate-highlight="activeHighlight[1] = false"
        />
      </AccessibilityMenuButton>
      <Cookies v-if="!disabledOnSpecificPages && appLoading" />
      <LogoutInfo />
      <div class="line-read-accessible" />
    </div>
  </transition>
  <span class="version">v.{{ appVersion }}</span>
</template>

<script lang="ts">
import Header from '@/layout/Header/Header.vue';
import Sidebar from '@/layout/Sidebar/Sidebar.vue';
import MainPanel from '@/layout/MainPanel/MainPanel.vue';
import { defineComponent, ref, watch, provide, computed } from 'vue';
import { provideDynaroles } from '@/plugins/dynarolesPlugin';
import NotificationSystem from '@/components/Base/Notification/NotificationSystem.vue';
import BypassBlock from '@/components/Commons/BypassBlock/BypassBlock.vue';
import BypassBlockHighlight from '@/components/Commons/BypassBlock/BypassBlockHighlight.vue';
import AccessibilityMenuButton from '@/components/Commons/AccessibilityMenu/AccessibilityMenuButton.vue';
import { useRoute } from 'vue-router';
import { byPassList } from '@/components/Commons/BypassBlock/ByPassList';
import Cookies from '@/components/Cookies/Cookies.vue';
import LogoutInfo from '@/components/Commons/LogoutInfo/LogoutInfo.vue';
import BaseConfirm from '@/components/Base/BaseConfirm.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import GoToTop from '@/components/Commons/GotToTop/GoToTop.vue';
import BaseActionPicker from '@/components/BaseActionPicker.vue';
import ONLOAD_CONFIGURATION from '@/components/ONLOAD_CONFIGURATION/ONLOAD_CONFIGURATION.vue';
import useUser from '@/composables/useUser';

export default defineComponent({
  components: {
    ONLOAD_CONFIGURATION,
    BaseActionPicker,
    GoToTop,
    EcgLoader,
    BaseConfirm,
    LogoutInfo,
    Cookies,
    AccessibilityMenuButton,
    BypassBlockHighlight,
    BypassBlock,
    NotificationSystem,
    MainPanel,
    Sidebar,
    Header
  },
  setup() {
    provideDynaroles();
    const byPassListGo = ref(byPassList);
    const activeHighlight = ref(byPassList.map(() => false));
    const headerIsSmaller = ref(false);
    const activeSidebar = ref(true);
    const width = ref(window.innerWidth);
    const hiddenActive = ref(window.innerWidth < 1000);
    const isConfigOk = ref(false);

    const handleIsConfigOk = () => {
      isConfigOk.value = true;
    };
    const tokenExist = computed(() => useUser().tokenExist());

    const router = useRoute();

    watch(
      () => router.path,
      newRouter => {
        activeSidebar.value = !(
          newRouter === '/terms-of-use' ||
          newRouter === '/settings' ||
          newRouter.startsWith('/document')
        );
      }
    );

    const disabledOnSpecificPages = ref(false);
    const disableOnRoutes = [
      '404-page',
      'GeneratePdf',
      'SpecificExamination',
      'SharedExamination',
      'Forbidden'
    ];

    watch(
      () => router.name,
      newRouter => {
        disabledOnSpecificPages.value =
          typeof newRouter === 'string' && disableOnRoutes.includes(newRouter);
      }
    );

    const setWidth = (w: number) => {
      width.value = w;
      hiddenActive.value = w < 1000;
    };

    const confirm = ref<Node>();
    provide('confirm', confirm);

    const selectedTenantFromStore = computed(() =>
      useUser().getSelectedTenant()
    );

    const appLoading = computed(
      () =>
        !!(
          tokenExist.value &&
          isConfigOk.value &&
          selectedTenantFromStore.value
        )
    );

    const appVersion = process.env.VUE_APP_VERSION;

    return {
      activeHighlight,
      headerIsSmaller,
      activeSidebar,
      byPassListGo,
      width,
      hiddenActive,
      setWidth,
      confirm,
      disabledOnSpecificPages,
      tokenExist,
      isConfigOk,
      handleIsConfigOk,
      selectedTenantFromStore,
      appLoading,
      appVersion
    };
  }
});
</script>
<style lang="scss">
.app-sidebar {
  height: calc(100% - 107px);
  position: fixed;
  left: var(--app-margin-outer);
  top: var(--app-sideber-main-panel-calculate-top);
  overflow: auto;

  //margin-left: var(--app-margin-outer);
  //margin-top: var(--app-sideber-main-panel-calculate-top);

  border: 1px solid var(--light-gray);
  border-radius: var(--border-radius);
  width: var(--sidebar-width);
  background-color: var(--light);
  transition: transform 0.2s ease-in-out, height 0.2s 0.2s ease-in-out;

  &.hidden {
    visibility: hidden;
  }
}

.app-sidebar.sidebar-smaller {
  transform: translateY(-32px);
  height: calc(100% - 70px);
}

.app-main-panel {
  position: absolute;
  top: var(--app-sideber-main-panel-calculate-top);
  left: var(--main-panel-left);
  border: 1px solid var(--light-gray);
  border-radius: var(--border-radius);
  width: var(--main-panel-width);
  min-height: calc(
    100% - (var(--app-sideber-main-panel-calculate-top) + var(--app-margin))
  );
  transition: 0.3s;

  &.expanded {
    left: calc(var(--app-margin-outer) / 2);
    width: calc(100% - (2 * var(--app-margin-outer)));

    @media (max-width: 999px) {
      top: calc(
        var(--app-sideber-main-panel-calculate-top) - var(--app-margin)
      );
      width: calc(100% - var(--app-margin-outer));
    }
  }

  &:focus {
    outline: none;
  }

  &.termsOfUse {
    left: calc(var(--app-margin-inner) + var(--app-margin));
    width: calc(
      100% - var(--app-margin-inner) - var(--app-margin) -
        var(--app-margin-outer)
    );
  }
}

.app-main-panel.mode-for-pdf {
  position: unset;
  border: none;
  margin: 10px auto 10px auto;
  width: 80%;

  @media (max-width: 1000px) {
    width: calc(100% - 20px);
  }
}
.app-main-loader {
  position: fixed !important;
  top: 50%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.app-wrapper {
  transition: opacity 0.2s ease-in-out;
}
.app-loading {
  opacity: 0.05;
}

.fhir__component-row-table:hover {
  background-color: transparent !important;
}

.fhir-component__card {
  border: 1px solid transparent !important;
}

.version {
  position: fixed;
  right: 3px;
  bottom: 3px;
  font-size: 0.625rem;
  color: var(--dark-gray);
}
</style>
