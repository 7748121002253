<template>
  <div
    class="switch-toggle"
    @click.stop.prevent="setOnOff"
    @keydown.space.stop.prevent="setOnOff"
  >
    <input
      ref="inputSwitch"
      type="checkbox"
      id="switch"
      :class="activeColor"
      v-model="isActive"
      tabindex="-1"
    />

    <label for="switch" tabindex="-1">
      <span class="visuallyhidden">{{ nameForSwitch }}</span>
    </label>
    <span class="right-information" v-if="nameForSwitch.length > 0">
      {{ nameForSwitch }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'BaseSwitch',
  data() {
    return {
      isActive: false
    };
  },
  watch: {
    isActive: {
      handler(value) {
        // this.$emit('is-active', value);
      }
    },
    onOff() {
      this.init();
    }
  },
  props: {
    onOff: {
      type: Boolean,
      required: false,
      default: false
    },
    nameForSwitch: {
      type: String,
      required: false,
      default: ''
    },
    activeColor: {
      type: String,
      required: false,
      default: 'success'
    }
  },
  emits: ['is-active'],
  methods: {
    setOnOff() {
      this.isActive = !this.isActive;
      this.$emit('is-active', this.isActive);
    },
    init() {
      this.isActive = this.onOff;
      if (this.isActive) {
        this.isActive = true;
        this.$refs.inputSwitch.checked = 'checked';
      } else {
        this.isActive = false;
        this.$refs.inputSwitch.checked = '';
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped lang="scss">
.switch-toggle {
  position: relative;
  display: flex;
  align-items: center;
}

input[type='checkbox'] {
  opacity: 0;
  user-select: none;
  position: absolute;
}

label {
  cursor: pointer;
  width: 35px;
  height: 20px;
  background: grey;
  border-radius: 20px;
  position: relative;
  transition: 0.3s;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  color: white;
  font-weight: bold;
}

label:after {
  content: '';
  position: absolute;
  // 7.5 px
  top: 3px;
  left: 3px;
  // 85 px
  width: 15px;
  height: 15px;
  background: lightgray;
  border-radius: 20px;
  transition: 0.3s;
}

input.success:checked + label,
.active {
  background-color: var(--success);
}

input.main:checked + label,
.active {
  background-color: var(--main-color);
}

input:checked + label:after,
.active {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

label:active:after {
  width: 20px;
}
.right-information {
  user-select: none;
  margin-left: 5px;
}
</style>
