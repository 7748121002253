<template>
  <div
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    class="notification"
    :class="`notification-${type}`"
  >
    <font-awesome-icon
      :icon="notification.icon"
      class="notification-icon-alert"
    />
    <div>
      <div
        class="notification-title"
        v-if="notification.title"
        :title="notification.title"
      >
        {{ notification.title }}
      </div>
      <div v-if="notification.msg" class="notification-message">
        {{ notification.msg }}
      </div>
      <BaseButton
        v-if="notification.type === 'warning-mode-with-accept'"
        type="white"
        class="button-accept-notification"
        @click="removeNotification"
      >
        {{ $t('common.confirm') }}
      </BaseButton>
    </div>
    <button
      v-if="closable && notification.type !== 'warning-mode-with-accept'"
      class="notification-icon"
      @click="removeNotification"
      :aria-label="$t('base.close')"
    >
      <font-awesome-icon icon="times" :height="18"></font-awesome-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';
import useNotification from '@/composables/useNotification';
import { NotificationI } from '@/composables/types/notification';
import BaseButton from '@/components/Base/BaseButton.vue';

export default defineComponent({
  name: 'Notification',
  props: {
    notification: {
      type: Object as PropType<NotificationI>,
      required: true
    }
  },
  components: {
    BaseButton
  },
  setup(props) {
    const removeNotification = () => {
      useNotification().removeNotification(props.notification);
    };

    const closable = computed(
      () => props.notification.closable || props.notification.delay === -1
    );
    const type = computed(() => props.notification.type.toString());

    if (props.notification.delay > 0) {
      setTimeout(() => removeNotification(), props.notification.delay);
    }

    return {
      closable,
      type,
      removeNotification
    };
  }
});
</script>

<style scoped lang="scss">
.notification {
  position: relative;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: var(--box-shadow);
  z-index: 10;
  min-width: 300px;
  min-height: 80px;
  display: flex;
  align-items: center;
}
.notification-title {
  grid-area: title;
  font-size: 1rem;
  overflow: hidden;
  margin-right: 16px;
}
.notification-message {
  font-size: 1rem;
  overflow: auto;
  width: 100%;
  white-space: pre-line;
  word-break: break-word;
  padding-right: 20px;
}
.notification-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: var(--font-color-alternative);
}
.notification-icon-alert {
  position: absolute;
  left: -12px;
  top: -12px;
  border-radius: 50px;
  height: 35px;
  width: 35px;
  padding: 5px;
  box-shadow: var(--box-shadow);
}
.notification-info {
  background-color: var(--primary);
  color: var(--font-color-alternative);
  .notification-icon-alert {
    color: var(--font-color-alternative);
    background-color: var(--primary);
  }
}
.notification-success {
  background-color: var(--success);
  .notification-icon-alert {
    color: var(--font-color-alternative);
    background-color: var(--success);
  }
}
.notification-warning {
  background-color: var(--warning);
  .notification-icon-alert {
    color: var(--font-color-alternative);
    background-color: var(--warning);
  }
}
.notification-warning-mode-with-accept {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--warning);
  .notification-icon-alert {
    color: var(--font-color-alternative);
    background-color: var(--warning);
  }
}
.notification-danger {
  background-color: var(--danger);
  .notification-icon-alert {
    color: var(--font-color-alternative);
    background-color: var(--danger);
  }
}

.button-accept-notification {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--base-space-24);
  display: block;
}
</style>
