import { ts } from '@fhir/pixel-commons-js';
import router from '@/router';
import { DateFilter } from '@/store/search/types';

// REGEXP
const SPECIAL_CHARACTERS = /([\]\\{!@#$%^&*()~.=\-_+[}|;:'",/<>`?])/g;
const DIGITS_ONLY = /\D/g;
const LETTERS_ONLY = /([0-9\]\\{!@#$%^&*()~.=\-_+[}|;:'",/<>`?])/g;

export const removeSpecialCharacters = (sentence: string): string => {
  return sentence ? sentence.replace(SPECIAL_CHARACTERS, '') : '';
};

export const allowLettersOnly = (sentence: string): string => {
  return sentence ? sentence.replace(LETTERS_ONLY, '') : '';
};

export const allowDigitsOnly = (sentence: string): string => {
  return sentence ? sentence.replace(DIGITS_ONLY, '') : '';
};

export const getCurrentIdFromRoute = () => {
  return router.currentRoute.value?.params?.id?.toString();
};

export const getEnumSize = (values: Array<string>): number =>
  values.map(it => parseInt(it)).filter(el => Number.isInteger(el)).length;

export function generateUuid() {
  return `uuid-${Math.ceil(Math.random() * 1000000)}-${ts(new Date())}`;
}

export function sleep(delay: number, ex = false): Promise<never> {
  return new Promise<never>((resolve, reject) => {
    setTimeout(() => {
      if (ex && Math.random() > 0.8) {
        reject('Błąd ze sleepa');
      } else {
        resolve();
      }
    }, delay);
  });
}

export function copy(element: Node | undefined, callback?: () => void) {
  if (element != null) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(element);
    selection?.removeAllRanges();
    selection?.addRange(range);

    try {
      document.execCommand('copy');
      if (callback) {
        callback();
      }
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    } finally {
      //selection?.removeAllRanges();
    }
  }
}

export function combineURLSearchParams(
  param1: URLSearchParams,
  param2: URLSearchParams
) {
  const combineParams = new URLSearchParams();

  for (const [key, val] of [...param1.entries(), ...param2.entries()]) {
    combineParams.append(key, val);
  }

  return combineParams;
}

export function camelCaseSentence(sentence: string) {
  const words = sentence.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  words[0] = words[0].toLowerCase();
  return words.join('');
}

export function titleCaseSentence(sentence: string) {
  const splitStr = sentence.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

export function firstLetterCapitalized(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function createDateParams(
  params: URLSearchParams,
  dateFilter?: DateFilter
) {
  if (dateFilter != null) {
    if (dateFilter.date != null) {
      params.append('date', dateFilter.date);
    }

    if (dateFilter.dateFrom != null && dateFilter.dateTo != null) {
      params.append('date', `ge${dateFilter.dateFrom}`);
      params.append('date', `le${dateFilter.dateTo}`);
    }
  }
}
