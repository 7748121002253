export default {
  changeFontSize:
    'Zmiana rozmiaru czcionki. Dostępne rozmiary: mała, średnia, duża',
  text: 'Tekst',
  fontSize: 'Rozmiar czcionki',
  small: 'mały',
  medium: 'średni',
  big: 'duży',
  changeContrast: 'Zmiana kontrastu',
  contrast: 'Kontrast',
  contrastBase: 'Kontrast podstawowy',
  contrastBlackBgFontYellow: 'Czarne tło, żółte napisy',
  contrastYellowBgBlackFont: 'Żółte tło, czarne napisy',
  links: 'Linki',
  linkHighlightingMode: 'Tryb podświetlania linków',
  active: 'Aktywny',
  inactive: 'Nieaktywny',
  changeTheme: 'Zmień motyw',
  theme: 'Motyw',
  themeBright: 'Jasny motyw',
  themeDark: 'Ciemny motyw',
  changeLang: 'Zmień język',
  lang: 'Język',
  pl: 'Polski',
  en: 'Angielski',
  base: 'Podstawowy',
  mousePointer: 'Kursor',
  mousePointerLineWithZoom:
    'Powiększenie kursora oraz linia wspomagająca czytanie',
  skipToMainContent: 'Przejdź do treści',
  accessibilityMenu: 'Menu dostępności',
  ariaDescribyText:
    'Menu dostępności, które posiada sześć opcji zmiany widoku portalu pacjenta. Pierwszą z opcji jest możliwość powiększenia teksty. Drugą opcją jest zmiana kontrastu. Trzecią opcją jest podświetlenie linków. Czwartą opcją jest zmianu motywu na ciemny lub jasny. Piątą opcją jest zmiana języka na angielski lub polski. Ostatnią szóstą opcją jest zmiana kursoru myszy.'
};
