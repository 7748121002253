<template>
  <div>
    <span class="visuallyhidden">{{ $t('wcagText.addressTitle') }}</span>
    <div v-for="(item, indexAddress) in address" :key="indexAddress">
      <div v-if="item.line" class="fhir-component__body__row no-padding">
        <span>
          <b>{{ $t('labelsRes.line') }}:</b>
        </span>
        <ul
          v-for="(line, index) in item.line"
          :key="index"
          class="fhir-component__list-element"
        >
          <li class="fhir-component__list-element__list-item">
            {{ createShortText(line, shortText, maxTextLength) }}
          </li>
        </ul>
      </div>
      <div v-if="item.city" class="fhir-component__body__row no-padding">
        <SingleValue
          :label="$t('labelsRes.city')"
          :lang="lang"
          :value="createShortText(item.city, shortText, maxTextLength)"
        />
      </div>
      <div v-if="item.state" class="fhir-component__body__row no-padding">
        <SingleValue
          :label="$t('labelsRes.state')"
          :lang="lang"
          :value="createShortText(item.state, shortText, maxTextLength)"
        />
      </div>
      <div v-if="item.postalCode" class="fhir-component__body__row no-padding">
        <SingleValue
          :label="$t('labelsRes.postalCode')"
          :lang="lang"
          :value="item.postalCode"
        />
      </div>
      <div v-if="item.country" class="fhir-component__body__row no-padding">
        <SingleValue
          :label="$t('labelsRes.country')"
          :value="item.country"
          :lang="lang"
        />
      </div>
    </div>
  </div>
</template>

<script>
import mixins from '../utils/mixins';
import SingleValue from '../SingleValue/SingleValue';
import createShortText from '../utils/createShortText';
import disableOnMode from '../utils/disableOnMode';

export default {
  name: 'Address',
  components: {
    SingleValue
  },
  mixins: [mixins],
  props: {
    address: { type: Array, required: true },
    shortText: { default: false, type: Boolean },
    maxTextLength: { default: 10000, type: Number }
  },
  methods: {
    createShortText,
    disableOnMode,
    isValue(value, array, mode) {
      return value && this.disableOnMode(array, mode);
    }
  }
};
</script>

<style scoped>
.no-padding {
  padding: 0px;
}
</style>
