export default {
  patientPortal: 'Portal pacjenta',
  nextPage: 'Następna strona',
  prevPage: 'Poprzednia strona',
  pagerOptions: 'Ustawienia stronicowania',
  loading: 'Trwa wczytywanie danych',
  baseLoading: 'Trwa ładowanie',
  perPage: 'Liczba wyników na stronie',
  gotoPage: 'Przejdź do strony',
  close: 'Zamknij',
  hideExpand: 'Ukryj rozwiniętą zawartość',
  showExpand: 'Rozwiń zawartość',
  removeFilter: 'Usuń filtr',
  checkboxAriaLabel: 'Zaznacz aby dodać do listy badań do udostępnienia',
  removeShare: 'Usuń element',
  pageNotFound: 'Nie udało się odnaleźć strony',
  data: {
    dataFetchingError: 'Błąd pobierania danych',
    examinationDataError: 'Nie znaleziono danego badania'
  },
  share: {
    name: 'Udostępnij dokumenty',
    loading: 'Trwa wczytywanie udostępnień',
    listError: 'Błąd wczytywania udostępnień',
    shareTo: 'Udostępnij dokumenty dla',
    enterEmailOrPhone: 'Dodaj email lub numer telefonu',
    shareByLink: 'Udostępnij przez link',
    share: 'Udostępnij',
    generateLinkForOneResourceInfo:
      'Link wygenerować moża osobno dla każdego dokumentu',
    enterValidEmailOrPhone: 'Podaj poprawny adres email lub numer telefonu',
    shareError: 'Błąd podczas udostępniania dokumentu',
    shared: 'Udostępniono dokument',
    sharing: 'Trwa udostępnianie dokumentów',
    copy: 'Kopiuj link',
    generateLink: 'Generuj link',
    deleteLink: 'Usuń link',
    caution: 'Uwaga',
    confirmDeleteLink: 'Czy usunąć link udostępniania ?',
    linkGenerated: 'Wygenerowano link udostępniania',
    errorGeneratingLink: 'Błąd podczas generowania linku udostępniania',
    generatingLink: 'Trwa generowanie linku udostępniania',
    errorRemovingLink: 'Błąd podczas usuwania linku udostępniania',
    linkRemoved: 'Usunięto link udostępniania',
    removingLink: 'Trwa usuwanie linku udostępniania',
    shareRemoved: 'Udostępnianie dokumentu usunięte',
    errorRemovingShare: 'Błąd usuwania udostępnienia',
    removingShare: 'Trwa usuwanie udostępnienia',
    confirmDeleteShare: 'Czy usunąć udostępnienie dokumentu dla {subject} ?',
    shareSelectedStudies: 'Udostępnij wybrane badania',
    consentAlreadyExistForThisActor:
      'Dokument został już udostępniony dla tej osoby. Wprowadź inny adres email lub telefon.'
  },
  confirm: {
    yes: 'Tak',
    no: 'Nie'
  },
  preview: 'Podgląd dokumentu',
  copyToClipboard: 'Kopiuj do schowka',
  copied: 'Skopiowano tekst do schowka',
  open: 'Otwórz',
  logout: 'Wyloguj',
  logoutFromApp: 'Wylogowano z aplikacji',
  loggedOutText: 'Nastąpiło wylogowanie z aplikacji',
  logInAgain: 'Zaloguj ponownie',
  recordOnCd: 'Nagraj na płytę',
  download: 'Pobierz',
  print: 'Drukuj',
  document: 'Dokument',
  studyResults: 'Wyniki badań',
  openMenu: 'Otwórz menu',
  goToMainPage: 'Przejdź do strony głównej',
  noStudyResults: 'Brak wyników badania',
  goToTop: 'Przewiń do góry',
  markAsDisplayed: 'Oznacz jako przeczytane',
  showing: 'Przeczytano',
  expandableExpand:
    'Użyj strzałek aby odczytać wartości znajdujące się w panelu rozwijanym lub,',
  settings: 'Ustawienia',
  accessibilityMenu: 'Menu dostępności',
  searchPlaceholder: 'Szukaj w dokumentach',
  labelFullTextAdmin:
    'Pole wprowadzania tekstu pozwalające wyszukać pacjentów oraz ich badania.',
  labelFullTextPatient:
    'Pole wprowadzania tekstu pozwalające wyszukać swoje badania, Musisz wpisać przynajmniej dwie litery aby rozpocząć wyszukiwanie.',
  saveConfig: 'Zapisz konfigurację',
  noData: 'Brak danych',
  setFavourite: 'Oznacz jako ulubione',
  unsetFavourite: 'Odznacz jako ulubione',
  male: 'Mężczyzna',
  female: 'Kobieta',
  sharedForMe: 'Udostępnione dla mnie',
  sharedForMeBy: 'Udostępnione dla mnie przez',
  from: 'z',
  studyNotFound: 'Nie znaleziono badania',
  forbidden: 'Brak dostępu',
  mainPage: 'Strona główna'
};
