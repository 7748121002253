
import { defineComponent, ref, watch, computed } from 'vue';
import { generateUuid } from '@/utils/utils';

export default defineComponent({
  name: 'RadioGroup',
  props: {
    name: {
      type: String,
      required: false
    },
    direction: {
      type: String,
      required: false,
      default: 'horizontal',
      validator: (value: string): boolean => {
        return ['horizontal', 'vertical'].indexOf(value) !== -1;
      }
    }
  },
  setup() {
    const uuid = computed(() => generateUuid());
    return {
      uuid
    };
  }
});
