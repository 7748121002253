export default {
  taskList: 'Task list',
  openTaskList: 'Open the task list',
  openNotifications: 'Open notifications',
  notifications: 'Notifications',
  settings: 'Settings',
  openSettings: 'Open settings',
  moreOptions: 'More options',
  openMoreOptions: 'Open more options',
  filters: 'Filters',
  fullTextSearch: {
    search: 'Search...',
    nameAndSurname: 'Name and Surname',
    identifier: 'Identifier',
    patients: 'Patients',
    researchName: 'Research',
    research: 'Researches',
    date: 'Date'
  }
};
