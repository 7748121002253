<template>
  <div class="chip" :class="`chip-${type}`">
    <div>
      <slot></slot>
    </div>

    <button
      class="chip-icon"
      v-if="closable"
      @click="$emit('close')"
      :aria-label="ariaLabel"
    >
      <font-awesome-icon icon="times" :height="14"></font-awesome-icon>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'BaseChip',
  props: {
    closable: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'info',
      validator: (propValue: string) =>
        ['info', 'success', 'warning', 'danger'].includes(propValue)
    },
    ariaLabelNew: {
      type: String,
      default: '',
      required: false
    }
  },
  emits: ['close'],
  setup(props) {
    const { t } = useI18n();

    const ariaLabel = ref(props.ariaLabelNew);

    onMounted(() => {
      ariaLabel.value = props.ariaLabelNew
        ? props.ariaLabelNew
        : t('base.close');
    });

    return {
      ariaLabel
    };
  }
});
</script>

<style scoped>
.chip {
  display: inline-grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: 5px;

  padding: 4px var(--app-base-space) 4px var(--app-base-space);
  border-radius: 10px;
  font-size: 0.75rem;
  color: var(--font-color-alternative);
}
.chip-icon {
  display: inline-grid;
  place-items: center;
  justify-self: end;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0 0 0 var(--app-base-space);
  color: var(--font-color-alternative);
}
.chip-info {
  background-color: var(--info);
}
.chip-success {
  background-color: var(--success);
}
.chip-warning {
  background-color: var(--warning);
}
.chip-danger {
  background-color: var(--danger);
}
.chip-accent {
  background-color: var(--accent);
}
</style>
