<template>
  <div class="admin-filters">
    <!-- <div class="admin-filters-button">
      <div class="admin-filters-button__top">
        <BaseButton
          type="secondary_gray"
          title="Clear all filters to default"
          @click="clearAllFilters"
        >
          {{ $t('admin.filters.clearFilters') }}
        </BaseButton>
        <BaseButton type="secondary_gray">
          {{ $t('admin.filters.takeFilters') }}
        </BaseButton>
      </div>s
    </div> -->
    <!-- <ExpandablePanel
      border-bottom
      :name="$t('admin.filters.text')"
      class="patient-filters__expandable-panel"
    >
      <div class="admin-filters-button__bottom">
        <label for="search-filters" />
        <input
          class="text-input"
          v-model="textSearch"
          name="search-filters"
          id="search-filters"
          :placeholder="$t('admin.filters.placeholderSearchFilters')"
        />
      </div>
    </ExpandablePanel> -->

    <ExpandablePanel
      class="admin-filters__expandable-panel"
      :expanded="true"
      border-bottom
      :name="$t('admin.filters.searchForPatient')"
    >
      <PatientSearch />
    </ExpandablePanel>

    <ExpandablePanel
      border-bottom
      :name="$t('patient.filters.documentData')"
      class="patient-filters__expandable-panel"
    >
      <DateFilters
        :modelValue="dateFilter"
        @update:modelValue="processDateFilters"
      />
    </ExpandablePanel>
    <ExpandablePanel
      :expanded="true"
      border-bottom
      :name="$t('admin.filters.searchByTag')"
    >
      <FiltersTag
        :tags-list="tags"
        @add-tag="addToTags"
        @delete-tag="deleteFromTags"
      />
    </ExpandablePanel>
    <ExpandablePanel
      border-bottom
      :expanded="true"
      :name="$t('admin.filters.sex')"
    >
      <RadioGroup direction="vertical">
        <RadioButton
          :first="i === 0"
          v-model:modelValue="sex"
          :value="item.id"
          v-for="(item, i) in sexItems"
          :key="`${item.id}-${i}-sex-${item.label}`"
          :label="$t(`admin.filters.${item.label}`)"
        />
      </RadioGroup>
    </ExpandablePanel>
    <ExpandablePanel
      :name="$t('admin.filters.favouriteResearch')"
      :expanded="true"
      border-bottom
    >
      <div class="favourite-research">
        <BaseSwitch
          @is-active="handleFavourites"
          :on-off="favourites"
          :name-for-switch="$t('admin.filters.showFavourites')"
        />
      </div>
    </ExpandablePanel>
    <ExpandablePanel :expanded="false" :name="$t('admin.filters.criterion')">
      <div class="criterion-list">
        <!--        <BaseCheckboxGroup :main-table="modalities"></BaseCheckboxGroup>-->
        <button
          v-for="criterion in modalities"
          :key="criterion.modality.id"
          :disabled="isFiltering"
          class="modality-button"
          :class="[criterion.checked ? 'clicked' : '']"
          @click="criterion.checked = !criterion.checked"
        >
          {{ criterion.modality.label }}
        </button>
      </div>
    </ExpandablePanel>
    <!--
    <div class="save-filters-button">
      <BaseButton
        uppercase
        type="secondary_gray"
        @click="createFiltersModal = true"
      >
        {{ $t('admin.filters.saveFilters') }}
      </BaseButton>
    </div>
    <BaseModal v-model:value="createFiltersModal">
      <template #header>{{ $t('admin.filters.enterNewFilter') }}</template>
      <template #default>
        <form class="form-create-filters" @submit.prevent="acceptFilter">
          <input
            type="text"
            name="create-filters"
            id="create-filters"
            v-model="acceptInputValue"
          />
          <BaseButton class="accept-button">
            {{ $t('admin.filters.add') }}
          </BaseButton>
        </form>
      </template>
    </BaseModal>
    -->
  </div>
</template>

<script lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import RadioGroup from '@/components/Commons/Radio/RadioGroup.vue';
import RadioButton from '@/components/Commons/Radio/RadioButton.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import FiltersTag from '@/components/Admin/FiltersTag.vue';
import BaseModal from '@/components/Base/BaseModal.vue';
import DateFilters from '@/components/Commons/DateFilters.vue';
import {
  SEX,
  MODALITIES,
  Filter,
  FILTER,
  FILTERING,
  Modality,
  ModalityList,
  TAGS,
  TEXT,
  FAVOURITES
} from '@/store/search/types';
import BaseCheckboxGroup from '@/components/Base/BaseCheckboxGroup.vue';
import PatientFilters from '@/components/Patient/PatientFilters';
import { useI18n } from 'vue-i18n';
import BaseChip from '@/components/Base/BaseChip.vue';
import BaseSwitch from '@/components/Base/BaseSwitch.vue';
import PatientSearch from './PatientSearch.vue';

export default {
  name: 'AdminFilters',
  components: {
    BaseSwitch,
    BaseChip,
    BaseCheckboxGroup,
    DateFilters,
    BaseModal,
    FiltersTag,
    BaseButton,
    RadioButton,
    RadioGroup,
    ExpandablePanel,
    PatientSearch
  },
  setup() {
    let skipWatch = false;
    const { locale } = useI18n();
    const { dateFilter, processDateFilters, searchStore } = PatientFilters();
    const textSearch = ref<string>();
    const favourites = ref<boolean>();

    const isFiltering = computed(() => searchStore.getters[FILTERING]);

    const tags = ref<string[]>([]);

    const handleFavourites = (v: any) => {
      favourites.value = v;
    };

    watch(favourites, v => {
      searchStore.commit(FAVOURITES, v);
    });

    watch(textSearch, v => {
      searchStore.commit(TEXT, v);
    });

    const processTags = () =>
      searchStore.commit(
        TAGS,
        tags.value.map(m => ({ name: m }))
      );

    watch(
      tags,
      () => {
        if (skipWatch) {
          skipWatch = false;
          return;
        }
        processTags();
      },
      { deep: true }
    );

    const acceptInputValue = ref<string>('');

    const modalities = ref<ModalityList[]>(
      (window.modalities || []).map((m: any) => {
        return {
          modality: {
            id: m.FBID,
            label: m.friendlyName(locale.value)
          },
          checked: false
        };
      })
    );

    const selectedModalities = computed<Modality[]>(() =>
      modalities.value.filter(f => f.checked).map(m => m.modality)
    );
    const processModalities = () =>
      searchStore.commit(MODALITIES, selectedModalities.value);

    watch(selectedModalities, () => {
      if (skipWatch) {
        skipWatch = false;
        return;
      }
      processModalities();
    });

    const createFiltersModal = ref<boolean>(false);

    const sex = ref<number>(0);
    const sexItems = computed(() => {
      return [
        {
          id: 0,
          label: 'any'
        },
        {
          id: 1,
          label: 'woman'
        },
        {
          id: 2,
          label: 'man'
        }
      ];
    });

    const processSex = () => searchStore.commit(SEX, sex.value);

    watch(sex, v => {
      if (skipWatch) {
        skipWatch = false;
        return;
      }
      processSex();
    });

    const filter = computed<Filter>(() => searchStore.getters[FILTER]);
    //reagujemy na czyszczenie filtrów
    watch(
      filter,
      v => {
        if (v != null) {
          if (v.sex != null && v.sex !== sex.value) {
            skipWatch = true;
            sex.value = v.sex;
          }
          if (v.modality != null && v.modality.length === 0) {
            //skipWatch = true;
            modalities.value.forEach(e => (e.checked = false));
          }
          if (v.tags == null) {
            tags.value = [];
          }
          if (v.text == null) {
            textSearch.value = '';
          } else {
            textSearch.value = v.text;
          }
          if (v.favourites == null) {
            favourites.value = false;
          }
        }
      },
      { deep: true }
    );

    const addToTags = (value: string): void => {
      tags.value.push(value);
    };

    const deleteFromTags = (ind: number): void => {
      tags.value.splice(ind, 1);
    };

    const clearAllFilters = (): void => {
      dateFilter.value = { type: 1 };
      tags.value = [];
      modalities.value.forEach(e => (e.checked = false));
      sex.value = 0;
    };

    const acceptFilter = async () => {
      try {
        // TODO sprawdzic czy juz istnieje
        if (acceptInputValue.value.length > 3) {
          createFiltersModal.value = false;
        }
      } catch (e) {
        console.error(e);
      }
    };

    const closeModalOnEnter = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        createFiltersModal.value = false;
      }
    };

    onMounted(() => processSex());

    return {
      dateFilter,
      processDateFilters,
      modalities,
      sexItems,
      sex,
      tags,
      addToTags,
      deleteFromTags,
      clearAllFilters,
      isFiltering,
      createFiltersModal,
      acceptFilter,
      acceptInputValue,
      closeModalOnEnter,
      textSearch,
      handleFavourites,
      favourites
    };
  }
};
</script>

<style lang="scss" scoped>
.admin-filters {
  padding: 10px;
  &-button {
    padding-bottom: 10px;
    border-bottom: 1px solid var(--light-gray);

    &__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin-left: 5%;
      padding: 5px 1rem 0 1rem;
    }

    &__bottom {
      margin-top: 0.3rem;
      input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid var(--dark-gray);
        border-radius: 4px;
      }
    }
  }

  .criterion-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;

    button {
      margin-left: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 0.5rem 0.75rem;
      cursor: pointer;
      border: 1px solid var(--dark-gray);
      border-radius: 4px;
      background-color: var(--font-color-alternative);
      color: var(--font-color);

      &:disabled {
        pointer-events: none;
        cursor: default;
      }

      &.clicked {
        background-color: var(--main-light-color);
        border: 1px solid var(--main-color);
      }
    }
  }

  .favourite-research {
    margin-top: 0.5rem;
  }

  .save-filters-button {
    margin-top: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.form-create-filters {
  //background-color: red;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  input {
    padding: 0.5rem;
    border-radius: 6px;
    border: 1px solid var(--light-gray);
  }

  .accept-button {
    margin-top: 1rem;
  }
}

.text-input {
  background-color: var(--light);
  color: var(--font-color);
}
</style>
