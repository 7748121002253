import {
  ContrastLevels,
  ContrastLevelsValues,
  FontLevels,
  FontLevelsWithValues,
  Langs,
  LangsValues,
  Links,
  LinksValues,
  LS,
  MousePointer,
  MousePointerValues,
  Themes
} from '@/components/Commons/AccessibilityMenu/AccessibilityMenuUtils';

import i18n from '@/lang';

export const changeTheme = (value: number): void => {
  const theme = 'dark-theme';
  if (value === Themes.bright) {
    document.documentElement.classList.remove(theme);
    document.body.classList.remove(theme);
    localStorage.removeItem(LS.theme);
  } else {
    document.documentElement.classList.add(theme);
    document.body.classList.add(theme);
    localStorage.setItem(LS.theme, theme);
  }
};

/*
    Important!
     Add <div class="line-read-accessible"></div> into App.vue
   */

function moveLine(event: any): void {
  const line =
    document.querySelector<HTMLElement>('.line-read-accessible') ?? undefined;
  if (line) line.style.top = `${event.pageY - 25}px`;
}

export const changeMousePointer = (value: number): void => {
  let cursor = MousePointerValues.base;

  if (value === MousePointer.base) {
    cursor = MousePointerValues.base;
  }

  if (value === MousePointer.medium) {
    cursor = MousePointerValues.medium;
  }

  if (value === MousePointer.big) {
    cursor = MousePointerValues.big;
  }

  Object.values(MousePointerValues).forEach((it: string) =>
    document.body.classList.remove(it)
  );
  localStorage.removeItem(LS.mousePointer);

  document.body.classList.add(cursor);
  localStorage.setItem(LS.mousePointer, cursor);

  // remove event listener,
  // add on load
  const line =
    document.querySelector<HTMLElement>('.line-read-accessible') ?? undefined;
  if (cursor === MousePointerValues.big) {
    if (line) {
      line.style.display = 'block';
      document.addEventListener('mousemove', moveLine);
    }
  } else {
    if (line) {
      line.style.display = 'none';
      document.removeEventListener('mousemove', moveLine);
    }
  }
};

export const changeContrast = (value: number): void => {
  let contrast = ContrastLevelsValues.base;

  if (value === ContrastLevels.base) {
    contrast = ContrastLevelsValues.base;
  }

  if (value === ContrastLevels.blackBgFontYellow) {
    contrast = ContrastLevelsValues.blackBgFontYellow;
  }

  if (value === ContrastLevels.yellowBgBlackFont) {
    contrast = ContrastLevelsValues.yellowBgBlackFont;
  }

  Object.values(ContrastLevelsValues).forEach((it: string) =>
    document.body.classList.remove(it)
  );
  localStorage.removeItem(LS.contrast);

  document.body.classList.add(contrast);
  localStorage.setItem(LS.contrast, contrast);
};

export const changeFontSize = (fontSizeLevel: number): void => {
  const body: HTMLElement = document.getElementsByTagName('body')[0];
  if (fontSizeLevel === FontLevels.small) {
    body.style.fontSize = FontLevelsWithValues.small;
    document.documentElement.style.fontSize = FontLevelsWithValues.small;
  }

  if (fontSizeLevel === FontLevels.medium) {
    body.style.fontSize = FontLevelsWithValues.medium;
    document.documentElement.style.fontSize = FontLevelsWithValues.medium;
  }

  if (fontSizeLevel === FontLevels.big) {
    body.style.fontSize = FontLevelsWithValues.big;
    document.documentElement.style.fontSize = FontLevelsWithValues.big;
  }

  localStorage.setItem(LS.fontSize, body.style.fontSize);
};

export const changeLang = (value: number): void => {
  const html = document.getElementsByTagName('html')[0];

  if (value === Langs.pl) {
    html.setAttribute('lang', LangsValues.pl);
    i18n.global.locale = LangsValues.pl;
  }
  if (value === Langs.en) {
    html.setAttribute('lang', LangsValues.en);
    i18n.global.locale = LangsValues.en;
  }

  localStorage.setItem(LS.langs, i18n.global.locale);
};

export const changeLinks = (value: number): void => {
  const allLinks = document.getElementsByTagName('a');

  if (value === Links.on) {
    for (let i = 0; i < allLinks.length; i++) {
      allLinks[i].classList.add(LinksValues.on);
    }
    localStorage.setItem(LS.links, LinksValues.on);
  }

  if (value === Links.off) {
    for (let i = 0; i < allLinks.length; i++) {
      allLinks[i].classList.remove(LinksValues.on);
    }
    localStorage.removeItem(LS.links);
  }
};

export const getLang = (): string | null => localStorage.getItem(LS.langs);

export const setELOnLoad = (
  value: number,
  lsKey: string,
  enumValues: any,
  callbackFunc: Function,
  initialIndex: number
): number => {
  const exist = localStorage.getItem(lsKey);
  let index = initialIndex;

  if (exist) {
    const idx = Object.entries(enumValues).findIndex(it => it[1] === exist);

    if (idx !== undefined && idx !== -1) {
      callbackFunc(idx);
      index = idx;
    } else {
      callbackFunc(initialIndex);
      index = initialIndex;
    }
  } else {
    if (value) {
      callbackFunc(value);
      index = value;
    } else {
      callbackFunc(initialIndex);
      index = initialIndex;
    }
  }

  return index;
};
