export default {
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',

  January: 'January',
  February: 'February',
  March: 'March',
  April: 'April',
  May: 'May',
  June: 'June',
  July: 'July',
  August: 'August',
  September: 'September',
  October: 'October',
  November: 'November',
  December: 'December',

  Su: 'Su',
  Mo: 'Mo',
  Tu: 'Tu',
  We: 'We',
  Th: 'Th',
  Fr: 'Fr',
  Sa: 'Sa',

  Cancel: 'Cancel',
  OK: 'OK',

  keysHelp: 'Cursor keys can navigate dates',
  wrongRange: 'Wrong date range. Date from {from} is later then date to {to}.'
};
