<template>
  <template v-if="width < 700">
    <div class="search-toggler" @click="toggleSearchVisible">
      <font-awesome-icon icon="search" class="search-toggler__icon" />
    </div>
  </template>
  <div
    class="full-text-search"
    :class="{ mobile: width < 700, visible: searchVisible }"
    ref="fullTextSearchRef"
  >
    <font-awesome-icon
      icon="search"
      class="full-text-search__icon-search"
      @click="$refs.FullTextSearchInput.focus()"
    />
    <LabelFullTextSearch
      :input-id="inputId"
      :active-router-name="activeRouterName"
    />
    <input
      :id="inputId"
      v-model="fullTextSearchModel"
      ref="FullTextSearchInput"
      aria-autocomplete="none"
      tabindex="0"
      class="full-text-search__input"
      :class="{ modal: 'with-modal' }"
      :placeholder="$t('base.searchPlaceholder') + '...'"
      type="text"
      @focusin="openModal"
      @focusout="hideModal"
      @keydown.esc="hideModal"
      @keydown.down="focusNextElement"
      @keydown.enter="openModal"
    />
    <font-awesome-icon
      icon="times"
      class="full-text-search__icon-times"
      height="18px"
      @click="
        () => {
          clearInput();
          $refs.FullTextSearchInput.focus();
        }
      "
    />
    <div
      v-if="width < 700"
      class="full-text-search__closer"
      @click="toggleSearchVisible"
    >
      <font-awesome-icon class="arrow" icon="arrow-right" />
    </div>
    <!--    </template>-->
    <!--    <button v-else class="search-hidden" @click="modal = !modal">-->
    <!--      <font-awesome-icon v-if="!modal" icon="search" height="26px" />-->
    <!--      <font-awesome-icon v-else icon="times" height="26px" width="32px" />-->
    <!--    </button>-->
    <!--    <transition name="dialog-searcher">-->
    <!--      <dialog-->
    <!--        role="dialog"-->
    <!--        :id="dialogId"-->
    <!--        class="full-text-search__modal"-->
    <!--        :class="[width < 1000 ? 'smaller' : '']"-->
    <!--        v-if="modal"-->
    <!--        @focusout="hideModal"-->
    <!--        @keydown.esc="hideModal"-->
    <!--        @keydown.down="focusNextElement"-->
    <!--        @keydown.up="focusPreviousElement"-->
    <!--      >-->
    <!--        <template v-if="width < 1000">-->
    <!--          <LabelFullTextSearch-->
    <!--            :input-id="inputId"-->
    <!--            :active-router-name="activeRouterName"-->
    <!--          />-->
    <!--          <font-awesome-icon-->
    <!--            icon="search"-->
    <!--            class="smaller__search__icon"-->
    <!--            height="18px"-->
    <!--            @click="$refs.FullTextSearchInput.focus()"-->
    <!--          />-->
    <!--          <input-->
    <!--            :id="inputId"-->
    <!--            v-model="fullTextSearchModel"-->
    <!--            v-if="activeSearcher"-->
    <!--            :placeholder="$t('base.searchPlaceholder') + '...'"-->
    <!--            aria-autocomplete="none"-->
    <!--            tabindex="0"-->
    <!--            type="text"-->
    <!--          />-->
    <!--          <font-awesome-icon-->
    <!--            icon="times"-->
    <!--            class="smaller__search__times"-->
    <!--            height="18px"-->
    <!--            @click="clearInput()"-->
    <!--          />-->
    <!--        </template>-->
    <!--        <template v-if="activeRouterName === AccessType.Admin">-->
    <!--          <PatientsSearch-->
    <!--            v-if="testPatients.length > 0"-->
    <!--            :patients="testPatients"-->
    <!--          />-->
    <!--          <dl v-else>-->
    <!--            &lt;!&ndash;TODO Dodać komunikat gdzie znajduje się pacjent i dlaczego go nie ma&ndash;&gt;-->
    <!--            <dd>Nie znaleziono pacjenta</dd>-->
    <!--          </dl>-->
    <!--        </template>-->
    <!--        <ResearchSearch-->
    <!--          v-if="testResearches.length > 0"-->
    <!--          :researches="testResearches"-->
    <!--        />-->
    <!--        <dl v-else>-->
    <!--          <dd>Nie znaleziono badania</dd>-->
    <!--        </dl>-->
    <!--      </dialog>-->
    <!--    </transition>-->
  </div>
</template>

<script lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { generateUuid } from '@/utils/utils';
import { debounce } from 'lodash';
import PatientsSearch from '@/components/FullTextSearch/PatientsSearch.vue';
import ResearchSearch from '@/components/FullTextSearch/ResearchSearch.vue';
import {
  AccessType,
  AccessWhere,
  PatientsType,
  ResearchesType
} from '@/components/FullTextSearch/FullTextSearchType';
import LabelFullTextSearch from '@/components/FullTextSearch/LabelFullTextSearch.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import { FILTER, Filter, TEXT } from '@/store/search/types';
import search, { useStore as useSearchStore } from '@/store/search';

const SEARCHER_ITEM = 'searcher__item';
const SEARCHER_TAG = 'expandable-panel-header';
const USER_PROFILE_ICON = 'user-profile-icon';

export default {
  name: 'FullTextSearch',
  components: {
    BaseButton,
    LabelFullTextSearch,
    ResearchSearch,
    PatientsSearch
  },
  props: {
    width: {
      type: Number,
      required: false,
      default: 1001
    }
  },
  setup(props: any) {
    const router = useRouter();
    const searchStore = useSearchStore();

    const activeRouterName = computed<AccessType>(() => {
      return router.currentRoute.value.name as AccessType;
    });

    const fullTextSearchModel = ref<string>('');

    const modal = ref<boolean>(false);

    const inputId = ref<string>(generateUuid());

    const searchVisible = ref<boolean>(false);

    const dialogId = ref<string>(generateUuid());

    const testPatients: PatientsType[] = [
      {
        id: 1,
        name: 'Ala',
        surname: 'Ma',
        identifier: 12345678121
      },
      {
        id: 2,
        name: 'Arnold',
        surname: 'Je',
        identifier: 12345678901
      },
      {
        id: 3,
        name: 'Olek',
        surname: 'Smiga',
        identifier: 12345678921
      },
      {
        id: 1,
        name: 'Ala',
        surname: 'Ma',
        identifier: 12345678121
      },
      {
        id: 2,
        name: 'Arnold',
        surname: 'Je',
        identifier: 12345678901
      },
      {
        id: 3,
        name: 'Olek',
        surname: 'Smiga',
        identifier: 12345678921
      }
    ];

    const testResearches: ResearchesType[] = [
      {
        id: 8,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      },
      {
        id: 12,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      },
      {
        id: 14,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      },
      {
        id: 16,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      },
      {
        id: 18,
        researchName: 'Capecitabine 500mg oral tablet (Xeloda)',
        date: '2020-10-10',
        patient: {
          id: 10,
          name: 'Olek',
          surname: 'Skała',
          identifier: 21479293492
        } as PatientsType
      }
    ];

    const toggleSearchVisible = (): void => {
      searchVisible.value = !searchVisible.value;
    };

    const clearInput = (): void => {
      fullTextSearchModel.value = '';
    };

    const searchPatientsBy = (v: string) => {
      // TODO find Patients by name or surname
      console.log('SEARCH PATIENTS', v);
    };

    const searchResearchesBy = (v: string) => {
      // TODO find Fhir Researchers by ....
      console.log('SEARCH RESEARCHES', v);
    };

    const openModal = () => {
      console.log('openModal');
      /*
      if (fullTextSearchModel.value.length >= 2) {
        modal.value = true;
      }
      */
    };

    const hideModal = (e: MouseEvent | KeyboardEvent | any): void => {
      if (
        props.width > 1000 &&
        e.relatedTarget?.id !== inputId.value &&
        e.relatedTarget?.id !== dialogId.value &&
        e.relatedTarget?.classList[1] !== SEARCHER_ITEM &&
        e.relatedTarget?.classList[0] !== SEARCHER_TAG
      ) {
        modal.value = false;
      }
    };

    const searchBy = debounce((x: string): void => {
      if (activeRouterName.value === AccessType.Patient) {
        searchResearchesBy(x);
      } else if (activeRouterName.value === AccessType.Admin) {
        searchPatientsBy(x);
        searchResearchesBy(x);
      }
    }, 500);

    watch(fullTextSearchModel, (x: string): void => {
      searchStore.commit(TEXT, x);
      /*
      if (props.width > 1000) {
        modal.value = x.length >= 2;
      }

      searchBy(x);
       */
    });
    const filter = computed<Filter>(() => searchStore.getters[FILTER]);
    //reagujemy na czyszczenie filtrów
    watch(
      filter,
      v => {
        if (v != null) {
          if (v.text == null) {
            fullTextSearchModel.value = '';
          } else {
            fullTextSearchModel.value = v.text;
          }
        }
      },
      { deep: true }
    );

    const findNextOrBeforeTabStop = (el: any, nextOrBefore: string) => {
      const universe = document.querySelectorAll('input, button, dialog');

      const list = Array.prototype.filter.call(universe, (item: any) => {
        return item.tabIndex >= '0';
      });
      const index = list.indexOf(el);

      if (nextOrBefore === AccessWhere.Next) {
        return list[index + 1] || list[0];
      } else {
        return list[index - 1] || list[list.length - 1];
      }
    };

    const focusOnDialog = () => {
      if (document.getElementById(dialogId.value)) {
        document.getElementById(dialogId.value)?.focus();
      }
    };

    const focusPreviousElement = (e: KeyboardEvent) => {
      e.preventDefault();
      findNextOrBeforeTabStop(document.activeElement, 'before').focus();
    };

    const focusNextElement = (e: KeyboardEvent) => {
      e.preventDefault();

      const nextElement = findNextOrBeforeTabStop(
        document.activeElement,
        AccessWhere.Next as string
      );

      if (nextElement?.classList[0] === USER_PROFILE_ICON) {
        return;
      }
      nextElement?.focus();
    };

    return {
      fullTextSearchModel,
      modal,
      clearInput,
      inputId,
      dialogId,
      activeRouterName,
      testPatients,
      hideModal,
      openModal,
      testResearches,
      AccessType,
      focusOnDialog,
      focusNextElement,
      focusPreviousElement,
      toggleSearchVisible,
      searchVisible
    };
  }
};
</script>

<style scoped lang="scss">
.search-toggler {
  margin-right: 6px;
  margin-bottom: 2px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  &__icon {
    color: var(--main-color);
    font-size: 1.675rem;
  }
}
.wrapper-header.smaller {
  .full-text-search.mobile {
    height: 51px;
  }
}
.full-text-search.mobile.visible {
  transform: translateX(0);
}
.full-text-search.mobile {
  position: absolute;
  transform: translateX(100%);
  left: 0;
  top: -1;
  background-color: var(--primary);
  padding: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 10;
  height: 61px;
  transition: 0.3s ease-in-out;
  .full-text-search__input {
    width: 100%;
    height: 40px;
    margin: 0;
  }
  .full-text-search__icon-search {
    left: calc(35px - 0.5rem);
  }
  .full-text-search__icon-times {
    right: 5.05rem;
  }
  .full-text-search__closer {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    .arrow {
      font-size: 1.45rem;
    }
  }
}

.full-text-search {
  width: 60%;
  position: absolute;
  left: calc(var(--main-panel-left) - var(--app-margin-outer) - 1rem);

  &.smaller {
    width: auto;
    position: static;
    margin-right: 0.5rem;

    .search-hidden {
      display: flex;
      align-items: center;
      color: #fff;
      background-color: transparent;
      padding: 5px;
      border: 1px solid transparent;
      cursor: pointer;
      transition: 0.3s;
      position: static;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__icon-search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--dark-gray);
    left: calc(1rem + 10px);
    touch-action: none;
    cursor: pointer;
  }

  &__icon-times {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--dark-gray);
    right: 50%;
    touch-action: none;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: var(--danger);
    }
  }

  &__input {
    margin-left: 1rem;
    border-radius: 6px;
    background-color: var(--light-gray);
    height: calc(var(--app-header-hight) - 1.5rem);
    width: 50%;
    padding: 0 36px;
    border: 2px solid transparent;
    color: var(--font-color);

    &.with-modal {
      border-bottom: none;
    }

    &:focus {
      border: 2px solid var(--main-color);
      outline: none;
    }
  }

  &__modal {
    display: block;
    position: absolute;
    left: 1rem;
    top: calc(var(--app-header-hight) - 1.125rem);
    width: 50%;
    height: auto;
    max-height: 400px;
    overflow-y: scroll;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 8px;
    z-index: 101;

    &.smaller {
      left: 50%;
      transform: translateX(-50%);
      top: var(--app-header-hight);
      width: 80%;

      .smaller__search__icon {
        position: absolute;
        left: calc(10% + 1rem);
        top: calc(0.95rem + (var(--app-header-hight) - 1.5rem) / 2);
        color: var(--dark-gray);
      }

      .smaller__search__times {
        position: absolute;
        right: calc(10% + 1.5rem);
        top: calc(0.95rem + (var(--app-header-hight) - 1.5rem) / 2);
      }

      .close-button {
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 6px;
      }

      input {
        width: 80%;
        margin-left: 10%;
        border-radius: 6px;
        background-color: var(--light-gray);
        height: calc(var(--app-header-hight) - 1.5rem);
        padding: 0 36px;
        margin-top: 1rem;
        color: var(--font-color);
      }
    }
  }
}
</style>
