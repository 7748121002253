<template>
  <dialog
    class="cookies"
    :class="[active ? 'active' : 'hidden']"
    aria-labelledby="cookies-title"
    aria-describedby="cookies-description"
    role="dialog"
    :id="dialogRef"
  >
    <h3 id="cookies-title" class="title-cookies">Cookies</h3>
    <p id="cookies-description">
      {{ $t('Cookies.textField') }}
    </p>
    <div class="wrapper-btn-cookies">
      <FocusLoop>
        <BaseButton type="cancel_light" @click="declineCookies">
          {{ $t('Cookies.declineButton') }}
        </BaseButton>
        <BaseButton class="accept_cookies" @click="acceptCookies">
          {{ $t('Cookies.acceptButton') }}
        </BaseButton>
      </FocusLoop>
    </div>
  </dialog>
  <div :class="['mask', active ? 'mask--active' : '']"></div>
</template>

<script lang="ts">
import BaseButton from '@/components/Base/BaseButton.vue';
import { nextTick, onMounted, ref, watch } from 'vue';
import { generateUuid } from '@/utils/utils';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';

export default {
  name: 'Cookies',
  components: { BaseButton, FocusLoop },
  setup() {
    const active = ref<boolean>(false);

    const dialogRef = ref<string>(generateUuid());

    const dialogButton = ref<HTMLElement>();

    const setActive = (flag: boolean): void => {
      active.value = flag;
    };
    const acceptCookies = (): void => {
      localStorage.setItem('cookies', 'true');
      setActive(false);
    };
    const declineCookies = (): void => {
      localStorage.setItem('cookies', 'false');
      setActive(false);
    };

    watch(active, x => {
      nextTick(() => {
        if (!x) {
          setTimeout(() => {
            (document.getElementById(
              dialogRef.value
            ) as HTMLDialogElement).close();
            document.body.focus();
          }, 1000);
        }
      });
    });

    onMounted(() => {
      if (!localStorage.getItem('cookies')) {
        setTimeout(() => {
          setActive(true);

          const dialogElement = document.getElementById(
            dialogRef.value
          ) as HTMLDialogElement;

          dialogElement.showModal();
          dialogElement.focus();
        }, 500);
      }
    });

    return {
      active,
      setActive,
      acceptCookies,
      declineCookies,
      dialogRef,
      dialogButton
    };
  }
};
</script>

<style scoped lang="scss">
@keyframes visib {
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
}
.cookies {
  z-index: 100;
  position: fixed;
  left: 50%;
  transform: translateX(-100%);
  top: 80vh;
  box-shadow: var(--box-shadow);
  border-radius: 0.75rem;
  padding: 12px 16px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out,
    visibility 0.5s ease-in-out;
  opacity: 0;
  max-width: 800px;
  color: var(--light);
  display: block;
  border: 2px soldi var(--primary);
  background: rgb(43, 54, 63);
  background: linear-gradient(
    228deg,
    rgba(43, 54, 63, 1) 0%,
    rgba(64, 157, 255, 1) 100%
  );

  @media (max-width: 1100px) {
    width: 95%;
  }

  &.active {
    bottom: 2%;
    opacity: 1;
    transform: translateX(-50%);
  }
  &.hidden {
    visibility: hidden;
  }

  .title-cookies {
    margin-bottom: 20px;
  }

  .icon_times {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1.25rem;
    top: 16px;
    background-color: transparent;
    padding: 5px;
    border: 1px solid var(--dark);

    &:hover {
      cursor: pointer;
    }
    &:active {
      color: var(--dark);
    }
    &:focus {
      border: 1px solid var(--accent);
    }
  }
}

.accept_cookies {
  margin-left: 10px;
}

.wrapper-btn-cookies {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(228deg, #2b363f 0%, #409dff 100%);
  opacity: 0;
  z-index: 10;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  visibility: hidden;

  &--active {
    opacity: 0.5;
    visibility: visible;
  }
}
</style>
