
import Header from '@/layout/Header/Header.vue';
import Sidebar from '@/layout/Sidebar/Sidebar.vue';
import MainPanel from '@/layout/MainPanel/MainPanel.vue';
import { defineComponent, ref, watch, provide, computed } from 'vue';
import { provideDynaroles } from '@/plugins/dynarolesPlugin';
import NotificationSystem from '@/components/Base/Notification/NotificationSystem.vue';
import BypassBlock from '@/components/Commons/BypassBlock/BypassBlock.vue';
import BypassBlockHighlight from '@/components/Commons/BypassBlock/BypassBlockHighlight.vue';
import AccessibilityMenuButton from '@/components/Commons/AccessibilityMenu/AccessibilityMenuButton.vue';
import { useRoute } from 'vue-router';
import { byPassList } from '@/components/Commons/BypassBlock/ByPassList';
import Cookies from '@/components/Cookies/Cookies.vue';
import LogoutInfo from '@/components/Commons/LogoutInfo/LogoutInfo.vue';
import BaseConfirm from '@/components/Base/BaseConfirm.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import GoToTop from '@/components/Commons/GotToTop/GoToTop.vue';
import BaseActionPicker from '@/components/BaseActionPicker.vue';
import ONLOAD_CONFIGURATION from '@/components/ONLOAD_CONFIGURATION/ONLOAD_CONFIGURATION.vue';
import useUser from '@/composables/useUser';

export default defineComponent({
  components: {
    ONLOAD_CONFIGURATION,
    BaseActionPicker,
    GoToTop,
    EcgLoader,
    BaseConfirm,
    LogoutInfo,
    Cookies,
    AccessibilityMenuButton,
    BypassBlockHighlight,
    BypassBlock,
    NotificationSystem,
    MainPanel,
    Sidebar,
    Header
  },
  setup() {
    provideDynaroles();
    const byPassListGo = ref(byPassList);
    const activeHighlight = ref(byPassList.map(() => false));
    const headerIsSmaller = ref(false);
    const activeSidebar = ref(true);
    const width = ref(window.innerWidth);
    const hiddenActive = ref(window.innerWidth < 1000);
    const isConfigOk = ref(false);

    const handleIsConfigOk = () => {
      isConfigOk.value = true;
    };
    const tokenExist = computed(() => useUser().tokenExist());

    const router = useRoute();

    watch(
      () => router.path,
      newRouter => {
        activeSidebar.value = !(
          newRouter === '/terms-of-use' ||
          newRouter === '/settings' ||
          newRouter.startsWith('/document')
        );
      }
    );

    const disabledOnSpecificPages = ref(false);
    const disableOnRoutes = [
      '404-page',
      'GeneratePdf',
      'SpecificExamination',
      'SharedExamination',
      'Forbidden'
    ];

    watch(
      () => router.name,
      newRouter => {
        disabledOnSpecificPages.value =
          typeof newRouter === 'string' && disableOnRoutes.includes(newRouter);
      }
    );

    const setWidth = (w: number) => {
      width.value = w;
      hiddenActive.value = w < 1000;
    };

    const confirm = ref<Node>();
    provide('confirm', confirm);

    const selectedTenantFromStore = computed(() =>
      useUser().getSelectedTenant()
    );

    const appLoading = computed(
      () =>
        !!(
          tokenExist.value &&
          isConfigOk.value &&
          selectedTenantFromStore.value
        )
    );

    const appVersion = process.env.VUE_APP_VERSION;

    return {
      activeHighlight,
      headerIsSmaller,
      activeSidebar,
      byPassListGo,
      width,
      hiddenActive,
      setWidth,
      confirm,
      disabledOnSpecificPages,
      tokenExist,
      isConfigOk,
      handleIsConfigOk,
      selectedTenantFromStore,
      appLoading,
      appVersion
    };
  }
});
