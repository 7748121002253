<template>
  <div class="icon-user">
    <img
      v-if="showImg"
      class="icon-user-img"
      @error="error"
      src="/auth/getAvatar"
    />
    <font-awesome-icon v-else icon="user" :height="30"></font-awesome-icon>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
export default defineComponent({
  name: 'UserAvatar',
  setup() {
    const showImg = ref(true);
    const error = (e: Error) => {
      showImg.value = false;
    };
    return {
      error,
      showImg
    };
  }
});
</script>

<style scoped lang="scss">
.icon-user {
  overflow: hidden;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  border: 3px solid var(--avatar-gray);
  color: var(--primary);
  display: grid;
  place-items: center;
  background-color: var(--light-gray);
}
.icon-user-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
