<template>
  <BaseModal
    :show-fader="true"
    :show-close="true"
    :hide-on-click-outside="true"
    v-model:value="modal"
    :title="finalTitle"
    :confirm="true"
    @update:value="close"
    :last-element-clicked="''"
    class="confim-modal"
  >
    <template #header>
      <div class="confim-title">{{ finalTitle }}</div>
    </template>

    <div class="confim-body" v-html="message"></div>

    <div class="mt buttons">
      <BaseButton @click="finish(false)">
        {{ $t('base.confirm.no') }}
      </BaseButton>
      <BaseButton ref="ok" @click="finish(true)" type="danger">
        {{ $t('base.confirm.yes') }}
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from './BaseModal';
import BaseButton from './BaseButton';
import BaseSpacer from './BaseSpacer';
import BaseCard from './BaseCard';

export default {
  name: 'BaseConfirm',
  components: {
    BaseCard,
    BaseSpacer,
    BaseButton,
    BaseModal
  },
  data() {
    return {
      modal: false,
      title: null,
      message: null,
      yesCallback: null,
      noCallback: null,
      promiseFns: null
    };
  },
  computed: {
    finalTitle() {
      return this.title;
    },
    finalMessage() {
      return this.message;
    }
  },
  methods: {
    focusButton() {
      if (this.$refs.ok) {
        // this.$refs.ok.focus();
      }
    },
    open(resolve, reject) {
      this.modal = true;
      if (resolve != null && reject != null) {
        this.promiseFns = {
          resolve: resolve,
          reject: reject
        };
      } else {
        this.promiseFns = null;
      }
      this.$nextTick(() => {
        setTimeout(() => this.focusButton(), 10);
      });
    },
    close() {
      this.modal = false;
      if (this.promiseFns != null) {
        this.promiseFns.reject();
        this.clear();
      }
    },
    confirm(title, message) {
      console.log('confirm');
      this.clear();
      this.title = title;
      this.message = message;
      return new Promise((resolve, reject) => {
        this.open(resolve, reject);
      });
    },
    finish(state) {
      if (this.promiseFns != null) {
        if (state) {
          this.promiseFns.resolve();
        } else {
          this.promiseFns.reject();
        }
      }
      this.clear();
      this.modal = false;
    },
    clear() {
      this.title = null;
      this.message = null;
      this.promiseFns = null;
    }
  }
};
</script>

<style scoped lang="scss">
.confim-title {
  color: var(--logo);
}
.confim-body {
  color: var(--font-color);
}
.buttons {
  display: grid;
  justify-content: end;
  place-items: center;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  margin-top: 20px;
}
</style>
