
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'FiltersTag',
  props: {
    tagsList: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  emits: ['add-tag', 'delete-tag'],
  setup(
    props: any,
    context: { emit: (arg0: string, arg1: string | number) => void }
  ) {
    const { t } = useI18n();
    const inputValue = ref<string>('');
    const tags = computed(() => props.tagsList);
    const warning = ref<string | null>(null);

    const checkIfElementExist = computed(() => {
      return (
        tags.value.find((x: string) => {
          return x === inputValue.value;
        }) != null
      );
    });

    const canAddTag = computed(
      () => inputValue.value.length > 3 && !checkIfElementExist.value
    );

    const addTags = () => {
      if (canAddTag.value) {
        context.emit('add-tag', inputValue.value);
        inputValue.value = '';
      } else if (!canAddTag.value && !warning.value) {
        warning.value = t('admin.filters.tagValidation');
        setTimeout(() => (warning.value = ''), 3500);
      }
    };

    const deleteOneTags = (ind: number): void => {
      context.emit('delete-tag', ind);

      if (tags.value.length === 0) {
        document.getElementById('filter-tags-input')?.focus();
      }
    };

    const addTagsByEnter = (e?: KeyboardEvent): void => {
      if (e?.key === 'Enter') {
        addTags();
      }
    };

    return {
      inputValue,
      tags,
      addTags,
      addTagsByEnter,
      deleteOneTags,
      warning,
      canAddTag
    };
  }
};
