<template>
  <dialog
    class="dialog-for-notification"
    aria-labelledby="cookies-title"
    aria-describedby="cookies-description"
    role="dialog"
    :id="dialogRef"
  >
    <teleport to="body">
      <div class="dialog-for-notification__fader" @click="$emit('close')"></div>
    </teleport>
    <BaseButtonGroup>
      <BaseButton class="mark-all-as-read" @click="reload">
        {{ $t('communicator.reload') }}
        <font-awesome-icon class="ml" icon="redo"></font-awesome-icon>
      </BaseButton>
      <BaseButton
        class="mark-all-as-read"
        @click="markAllRead"
        v-if="messages.length > 0"
      >
        {{ $t('communicator.markAllRead') }}
        <font-awesome-icon class="ml" icon="check-double"></font-awesome-icon>
      </BaseButton>
    </BaseButtonGroup>

    <NotificationItem
      v-for="(m, i) in messages"
      :key="i"
      :message="m"
      class="mt"
    ></NotificationItem>
  </dialog>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch
} from 'vue';
import { generateUuid } from '@/utils/utils';
import useCommunicator from '@/composables/useCommunicator';
import { sortBy } from 'lodash';
import { dateSeconds } from '@fhir/pixel-commons-js/src/datetime';
import { MessageI } from '@/composables/types/communicator';
import BaseButton from '@/components/Base/BaseButton.vue';
import NotificationItem from '@/components/Commons/NotificationDialog/NotificationItem.vue';
import BaseButtonGroup from '@/components/Base/BaseButtonGroup.vue';
import useUser from '@/composables/useUser';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'NotificationDialog',
  components: { BaseButtonGroup, NotificationItem, BaseButton },
  props: {
    buttonId: {
      type: String,
      required: true,
      default: '0'
    }
  },
  emits: ['close'],
  setup(props, context) {
    const close = () => {
      context.emit('close');
    };

    onMounted(() => {
      console.log(document.getElementById(props.buttonId));
      window.addEventListener('resize', close);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', close);
    });

    const dialogRef = ref<string>(generateUuid());

    const messages = computed(() => {
      const ret = useCommunicator().newMessages();
      return sortBy(ret, 'date')
        .reverse()
        .map(m => ({
          resource: m,
          date: dateSeconds(m.date)
        }));
    });

    const markAllRead = () => {
      useCommunicator().markAllRead();
    };

    const token = computed(() => useUser().getToken());
    const i18n = useI18n();
    const lan = computed(() => i18n.locale.value);

    const reload = () => {
      useCommunicator().connect(lan.value, token.value ?? '');
    };

    return {
      dialogRef,
      messages,
      markAllRead,
      reload
    };
  }
});
</script>

<style scoped lang="scss">
.dialog-for-notification {
  transform: translateX(-35vw);
  width: 40vw;
  height: 50vh;
  display: block;
  border-radius: 6px;
  border: 2px solid var(--primary);
  overflow: auto;
  padding: 10px;
  background-color: var(--bg);

  &__fader {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    //background-color: rgba(0, 0, 0, 0.5);
    cursor: not-allowed;
  }

  @media (max-width: 500px) {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 50px;
    width: 90%;
  }
}

.mark-all-as-read {
  font-size: 0.75rem;
  color: var(--logo);
}
</style>
