<template>
  <teleport to="body">
    <transition>
      <div
        :class="[{ active: isOpen }, 'action-picker-container']"
        id="action-picker"
        @keydown.esc="closeActionPicker"
        @focusin="showVal"
      >
        <FocusLoop :disabled="!isOpen">
          <div class="circle">
            <div class="fulfillment"></div>
            <button
              class="icon-wrapper"
              :id="buttonId"
              :title="$t('base.close')"
              v-if="isOpen"
            >
              <font-awesome-icon icon="times" class="icon" width="20" />
            </button>
          </div>
          <ul class="circle-list">
            <li
              v-for="(action, index) in slots"
              class="circle-list__item"
              :key="index"
            >
              <button
                :class="[`slot-${index + 1}`, 'action-container']"
                @click="action.action()"
                :title="$t(`base.${action.title}`)"
                v-if="isOpen"
              >
                <svg
                  class="slot-bg"
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M60 60C60 27.1871 33.2283 0.525372 0 0V37C12.3677 37 22.9788 48 22.9788 60H60Z"
                  />
                </svg>
                <div class="action-content">
                  <font-awesome-icon class="action-icon" :icon="action.icon" />
                  <span>{{ $t(`base.${action.name}`) }}</span>
                </div>
              </button>
            </li>
          </ul>
        </FocusLoop>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { generateUuid } from '@/utils/utils';
import FocusLoop from '@vue-a11y/focus-loop/src/FocusLoop.vue';
import { useRouter } from 'vue-router';

interface ActionPickerListElementType {
  name: string;
  icon: string;
  title: string;
  action: () => void;
}

export default defineComponent({
  name: 'BaseActionPicker',
  components: {
    FocusLoop
  },
  emits: ['active-highlight-main', 'active-highlight-menu'],
  setup(props, context) {
    const isOpen = ref<boolean>(false);
    const buttonId = ref<string>(generateUuid());

    const router = useRouter();

    const positionX = ref<any>('50%');
    const positionY = ref<any>('50%');

    const lastClickedElement = ref<HTMLElement | null>(null);

    const slots: ActionPickerListElementType[] = [
      {
        name: 'goToTop',
        icon: 'arrow-up',
        title: 'goToTop',
        action: () => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      },
      {
        name: 'goToMainPage',
        icon: 'sort-alpha-down',
        title: '',
        action: () => {
          document.getElementById('main')?.click();
          document.getElementById('main')?.focus();
          setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, 0);
          context.emit('active-highlight-main');
        }
      },
      {
        name: 'settings',
        icon: 'cog',
        title: '',
        action: () => {
          router.push({ name: 'Settings' });
        }
      },
      {
        name: 'accessibilityMenu',
        icon: 'universal-access',
        title: '',
        action: () => {
          document.getElementById('accessibilityMenu')?.click();
          document.getElementById('accessibilityMenu')?.focus();
          context.emit('active-highlight-menu');
        }
      }
    ];

    const setLastClickedElement = () => {
      lastClickedElement.value = document.activeElement as HTMLElement;
    };

    const closeActionPicker = () => {
      if (isOpen.value) {
        isOpen.value = false;
        (lastClickedElement.value as HTMLElement)?.focus();
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      destroyEvents();
    };

    const openActionPicker = (event: KeyboardEvent) => {
      event.preventDefault();
      setLastClickedElement();
      document.getElementById(buttonId.value)?.focus();
      isOpen.value = true;
      const actionPicker = document.getElementById('action-picker');
      const circle = (actionPicker as HTMLElement)?.getBoundingClientRect();
      (actionPicker as HTMLElement).style.left = `${positionX.value -
        circle.width / 2}px`;
      (actionPicker as HTMLElement).style.top = `${positionY.value -
        circle.height / 2}px`;
    };

    const toggleActionPicker = (event: KeyboardEvent) => {
      if (event.code === 'F9') {
        if (isOpen.value) {
          closeActionPicker();
        } else {
          openActionPicker(event);
        }
      }
    };

    const setLocation = (event: MouseEvent) => {
      positionX.value = event.pageX - window.scrollX;
      positionY.value = event.pageY - window.scrollY;
    };

    const rotateFulfillment = (e: MouseEvent) => {
      const element = document.querySelector('.fulfillment');
      const iconWrapper = document.querySelector('.icon-wrapper');
      const elementParams = iconWrapper?.getBoundingClientRect();
      if (elementParams) {
        const centerX = elementParams?.left + elementParams?.width / 2;
        const centerY = elementParams?.top + elementParams?.height / 2;
        const radians = Math.atan2(
          positionX.value - centerX,
          positionY.value - centerY
        );
        const degree = radians * (180 / Math.PI) * -1 + 45;
        const stringTransform = `rotate(${+degree}deg)`;
        (element as HTMLElement).style.transform = stringTransform;
      }
    };

    const destroyEvents = () => {
      window.removeEventListener('click', closeActionPicker);
      window.removeEventListener('keyup', toggleActionPicker);
      window.removeEventListener('mousemove', rotateFulfillment);
      window.removeEventListener('mousemove', setLocation);
    };

    onMounted(() => {
      window.addEventListener('keyup', (ev: any) => toggleActionPicker(ev));
      window.addEventListener('click', () => closeActionPicker());
      window.addEventListener('mousemove', (event: any) => {
        rotateFulfillment(event);
        setLocation(event);
      });
    });

    onUnmounted(() => {
      destroyEvents();
    });

    const showVal = () => {
      console.log(document.activeElement);
    };

    return {
      isOpen,
      slots,
      buttonId,
      closeActionPicker,
      showVal
    };
  }
});
</script>

<style scoped lang="scss">
.action-picker-container {
  z-index: 9999;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  border-radius: var(--main-radius);
  transition: opacity 0.3s ease;
  font-size: 1rem;

  &.active {
    opacity: 1;
    pointer-events: all;

    .circle-list {
      transform: rotate(45deg);

      .circle-list__item {
        transform: scale(1);
      }
    }
  }

  .circle {
    transition: transform 0.3s ease;
    z-index: 999;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;
    width: 86px;
    height: 86px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #5a5f62;
    border-radius: 50%;
    overflow: hidden;
    .fulfillment {
      background-color: var(--primary);
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 60px;
      height: 60px;
      transform-origin: top left;
    }
    .icon-wrapper {
      position: relative;
      background-color: var(--primary);
      cursor: pointer;
      border: 2px solid #5a5f62;
      border-radius: 50%;
      display: flex;
      height: 50px;
      width: 50px;
      justify-content: center;
      align-items: center;
      .icon {
        color: var(--light-gray);
      }
    }
  }
  .circle-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 340px;
    transform: rotate(-180deg);
    transition: transform 0.4s ease;
    height: 340px;

    .circle-list__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(50% - 2px);
      margin: 1px;
      position: relative;
      transition: transform 0.3s ease;
      transform: scale(0.2);
      .action-container {
        border: none;
        background-color: transparent;
        border-radius: 0 100% 0 0;

        .action-content {
          position: absolute;
          top: 50%;
          left: 50%;
          color: black;
          text-align: center;
          pointer-events: none;
          .action-icon {
            margin-bottom: 5px;
            height: 3rem;
          }
          .title {
            font-size: 0.775rem;
          }
        }
        &.slot-1,
        &.slot-2,
        &.slot-3,
        &.slot-4 {
          transition: transform 0.3s ease;
          width: 160px;
          height: 160px;
          .slot-bg {
            width: 160px;
            height: 160px;
            path {
              cursor: pointer;
              fill: var(--primary) !important;
              opacity: 0.95;

              &:hover {
                fill: var(--accent);
                opacity: 1;
              }
            }
          }

          .action-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #fff;
            font-weight: bold;
          }
        }
        &.slot-1 {
          transform: rotate(270deg);
          .action-content {
            transform: translateY(-45%) translateX(-55%) rotate(45deg);
          }
        }
        &.slot-2 {
          .action-content {
            transform: translateY(-45%) translateX(-55%) rotate(-45deg);
          }
        }

        &.slot-3 {
          transform: rotate(180deg);
          .action-content {
            transform: translateY(-45%) translateX(-55%) rotate(135deg);
          }
        }

        &.slot-4 {
          transform: rotate(90deg);
          .action-content {
            transform: translateY(-45%) translateX(-55%) rotate(225deg);
          }
        }
      }

      &:hover {
        .action-container {
          &.slot-1 {
            transform: rotate(270deg) translateX(5%) translateY(-5%) scale(1.08);
          }
          &.slot-2 {
            transform: rotate(0deg) translateX(5%) translateY(-5%) scale(1.08);
          }

          &.slot-3 {
            transform: rotate(180deg) translateX(5%) translateY(-5%) scale(1.08);
          }

          &.slot-4 {
            transform: rotate(90deg) translateX(5%) translateY(-5%) scale(1.08);
          }
        }
      }
    }
  }
}
</style>
